import {
  useLocation
} from "react-router-dom";
import React from "react";

export function useQuery() {
  const location = useLocation();
  const [params, setParams] = React.useState(new URLSearchParams(location.search));

  React.useEffect(() => {
    setParams(new URLSearchParams(location.search));
  }, [location.search]);

  return params;
}

export type QueryStringParams = Record<string, string | string[]>;

export function useQueryString(): QueryStringParams {
  const location = useLocation();
  const [query, setQuery] = React.useState<QueryStringParams>({});

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query: QueryStringParams = {};
    for (const [key, values] of params.entries()) {
      if (key in query) {
        if (Array.isArray(query[key])) {
          (query[key] as string[]).push(values);
        } else {
          query[key] = [query[key] as string, values];
        }
      } else {
        query[key] = values;
      }
    }
    setQuery(query);
  }, [location]);

  return query;
}

export function queryStringify(query: QueryStringParams) {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      for(const ele of value) {
        params.append(key, ele);
      }
    } else {
      params.append(key, value);
    }
  });
  return params.toString();
}