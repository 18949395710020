import {all} from 'redux-saga/effects';
import {watchFetchProfile, watchProcessSignOut} from './user';
import {watchLoadCategories} from './category';
import {watchFetchConfig} from './config';
import {fetchPendingCountPeriodically} from "./quotation";

export default function* rootSaga() {
  yield all([
    watchFetchProfile(),
    watchLoadCategories(),
    watchProcessSignOut(),
    watchFetchConfig(),
    fetchPendingCountPeriodically()
  ]);
}