import React from 'react';
import {Html5Table, useFilter} from 'window-table';
import {Column, Html5TableProps} from "window-table/dist/core/types";
import {CategorySetting} from "../api/models";

interface WeightColumnProps {
  row?: CategorySetting,
  column?: Column<keyof CategorySetting, CategorySetting>
}

const WeightColumn: React.FunctionComponent<WeightColumnProps> = ({children, row, column}) => {
  return (
    <React.Fragment>
      {children}{row![`${column!.key}Unit`]}
    </React.Fragment>
  );
};

const DEFAULT_TABLE_COLUMNS: Column<keyof CategorySetting, CategorySetting>[] = [
  {key: 'categorySource', width: 100, title: 'Source'},
  {key: 'category', width: 200, title: 'Category'},
  {key: 'weight', width: 50, title: 'Weight', Component: WeightColumn},
  {key: 'volumeMetricWeight', width: 50, title: 'Volume Metric Weight', Component: WeightColumn},
  {key: 'isBlocked', width: 50, title: 'Blocked?'},
  {key: 'isQuotation', width: 50, title: 'Quotation?'},
  {key: 'isDisputable', width: 50, title: 'Disputable?'},
  {key: 'isEqualPrecedence', width: 50, title: 'Equal Precedence?'},
];

function filterData(data: Object[], filterText: string) {
  const castedData = data as CategorySetting[];
  return castedData.filter(cs => cs.category.toLowerCase().includes(filterText));
}

interface CategorySettingsTableProps extends Partial<Html5TableProps<CategorySetting>> {
  categorySettings: CategorySetting[];
  textFilter?: string;
}

function CategorySettingsTable({categorySettings, textFilter, ...others}: CategorySettingsTableProps) {
  const filteredData = useFilter(filterData, categorySettings, textFilter || '') as CategorySetting[];
  return <Html5Table
    headerClassName={""}
    data={filteredData}
    columns={DEFAULT_TABLE_COLUMNS}
    style={{height: '500px'}}
    {...others}
  />;
}

CategorySettingsTable.DEFAULT_TABLE_COLUMNS = DEFAULT_TABLE_COLUMNS;

export default CategorySettingsTable;