import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {genericTextChange} from '../../utils/forms';
import {extractAxiosError} from '../../utils/error';
import {formatDateTimeFromSeconds} from '../../utils/date';
import {useQuery} from '../../utils/route';
import Api from '../../api/api';
import TimeoutAlert from '../timeout_alert';
import HeadTitle from '../head_title';
import {GetAllProductWeightOverride} from "../../api/response_models";

interface ProductEditingMap {
  [productId: string]: boolean
}

interface ProductWeightMap {
  [productId: string]: string;
}

export default function ProductWeightOverridesPage() {
  const [search, setSearch] = React.useState('');
  const [productWeightOverrides, setProductWeightOverrides] = React.useState<GetAllProductWeightOverride.ProductWeightOverrideItem[]>([]);
  const [weightValues, setWeightValues] = React.useState<ProductWeightMap>({});
  const [editing, setEditing] = React.useState<ProductEditingMap>({});


  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const searchQuery = query.get('search');


  React.useEffect(() => {
    let search = undefined;
    if (searchQuery) {
      search = searchQuery;
    }
    Api.staff.productWeightOverride.getAll(search)
      .then(res => {
        setProductWeightOverrides(res.data.productWeightOverrides);
        const newEditing: ProductEditingMap = {};
        for (const override of res.data.productWeightOverrides) {
          newEditing[override.productId] = false;
        }
        setEditing(newEditing);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  }, [searchQuery]);

  React.useEffect(() => {
    const newWeightValues: ProductWeightMap = {};
    for (const override of productWeightOverrides) {
      newWeightValues[override.productId] = override.weight.toString();
    }
    setWeightValues(newWeightValues);
  }, [productWeightOverrides]);

  const handleSearch = () => {
    history.push(`${location.pathname}?search=${search}`);
  };

  const handleReset = () => history.push(location.pathname);

  const handleModify = (productId: string) => () => {
    const newEditing = {
      ...editing,
      [productId]: true,
    };
    setEditing(newEditing);
  };

  const handleCancelModify = (productId: string) => () => {
    const newEditing = {
      ...editing,
      [productId]: false,
    };
    setEditing(newEditing);
  };

  const handleSaveModify = (productId: string) => () => {
    const hasError = validateWeight(productId);
    const index = productWeightOverrides.findIndex(o => o.productId === productId);
    const override = productWeightOverrides[index];
    if (hasError) {
      setErrorMessage('product ' + override.productName + ' has invalid weight');
      return;
    }

    const weight = parseFloat(weightValues[productId]);
    Api.staff.productWeightOverride.set({
      productId,
      weight
    })
      .then(() => {
        setSuccessMessage('Successfully saved weight');
        const newProductWeightOverrides = productWeightOverrides.slice();
        newProductWeightOverrides[index] = {
          ...override,
          weight: weight,
        };
        setProductWeightOverrides(newProductWeightOverrides);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        const newEditing = {
          ...editing,
          [productId]: false,
        };
        setEditing(newEditing);
      });
  };

  const handleRemove = (index: number, productId: string) => () => {
    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) {
      return;
    }

    Api.staff.productWeightOverride.remove(productId.toString())
      .then(() => {
        setSuccessMessage('Successfully removed weight override');
        const newProductWeightOverrides = productWeightOverrides.slice();
        newProductWeightOverrides.splice(index, 1);
        setProductWeightOverrides(newProductWeightOverrides);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  const handleWeightChange = (productId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWeightValues = {
      ...weightValues,
      [productId]: e.target.value,
    };
    setWeightValues(newWeightValues);
  };

  const validateWeight = (productId: string) => {
    const weight = parseFloat(weightValues[productId]);
    if (isNaN(weight)) {
      return 'weight is not a number';
    }

    if (weight <= 0) {
      return 'weight cannot be less than or equal to 0';
    }

    return null;
  };

  return <React.Fragment>
    <HeadTitle
      title="Product Weight Overrides"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />

    <h1>Product Weight Overrides</h1>
    <div className="mb-3">
      <div className="form-group">
        <label>Search</label>
        <input
          type="text"
          className="form-control"
          value={search}
          onChange={genericTextChange(setSearch)}
          placeholder="Search product name"
        />
      </div>

      <button className="btn btn-primary" onClick={handleSearch}>
        Search
      </button>

      <button className="btn btn-secondary" onClick={handleReset}>
        Reset
      </button>
    </div>

    {productWeightOverrides.map((override, index) => {
      const weightError = validateWeight(override.productId);
      return <div className="card mb-2" key={index}>
        <div className="card-body">
          <h5 className="card-title">{override.productName}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{formatDateTimeFromSeconds(override.updatedAt)}</h6>

          <p className="card-text">
            <strong>Weight:</strong>
            {' '}
            {override.weight}kg
          </p>

          {!editing[override.productId] ? (
            <>
              <button className="btn btn-link card-link p-0" onClick={handleModify(override.productId)}>
                Modify
              </button>
              <button className="btn btn-link card-link p-0 text-danger"
                      onClick={handleRemove(index, override.productId)}>
                Delete
              </button>
            </>
          ) : (
            <div className="p-2">
              <label>Modify product weight</label>
              <div className="form-group">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={weightValues[override.productId]}
                    onChange={handleWeightChange(override.productId)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">kg</span>
                  </div>
                </div>
                {weightError && (
                  <div className="form-text text-danger">{weightError}</div>
                )}
              </div>

              <button className="btn btn-success mr-2" onClick={handleSaveModify(override.productId)}>
                Save
              </button>

              <button className="btn btn-secondary" onClick={handleCancelModify(override.productId)}>
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>;

    })}
  </React.Fragment>;
}