import React from 'react';
import HeadTitle from "../../head_title";
import EditCountryTax from "../../edit_country_tax";
import TimeoutAlert from "../../timeout_alert";
import {CountryTax} from "../../../types/models/country_tax";
import {useParams} from "react-router-dom";
import Api from "../../../api/api";
import {extractAxiosError} from "../../../utils/error";

interface EditCountryTaxQueryParams {
    countryTaxId: string;
}

export default function EditCountryTaxPage() {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [countryTax, setCountryTax] = React.useState<CountryTax>();

    const params = useParams<EditCountryTaxQueryParams>();

    const {countryTaxId} = params;

    React.useEffect(() => {
        Api.admin.countryTaxes.get(countryTaxId)
            .then(res => {
                setCountryTax(res.data.countryTax);
            })
            .catch(error => {
                setErrorMessage(extractAxiosError(error));
            });
    }, [countryTaxId]);

    return <>
        <HeadTitle
            title="Edit Country Tax"
        />

        <TimeoutAlert
            errorMessage={errorMessage as string}
            onHide={() => setErrorMessage(null)}
        />

        <h1>Edit Tax</h1>
        <EditCountryTax
            onError={setErrorMessage}
            countryTax={countryTax}
        />
    </>;
}