import currencyPrecision from '../constants/currency_precision';
import Dinero, {Currency} from 'dinero.js';
import DineroFactory from 'dinero.js';
import {ProductWeightUnit} from "../types/models/product";

export function isPositiveInteger(str: string) {
  const n = Math.floor(Number(str) );
  return n !== Infinity && String(n) === str && n >= 0;
}

export function insertDecimalPoint(number: number, precision: number) {
  const numberStr = number.toString();
  const dotLocation = numberStr.length - precision;
  return numberStr.substring(0, dotLocation) + '.' + numberStr.substring(dotLocation);
}

export function formatMoney(currency: string, amount: number) {
  const precision = currencyPrecision[currency];
  const amountStr = amount.toString();
  let majorUnit = amountStr.substring(0, amountStr.length - precision);
  if (majorUnit.length === 0) {
    majorUnit = '0';
  }

  const minorUnit = amountStr.substring(amountStr.length - precision);

  return `${currency} ${majorUnit}.${minorUnit}`;
}

export function formatDinero(dinero: DineroFactory.Dinero) {
  return formatMoney(dinero.getCurrency(), dinero.getAmount());
}

export function findPrecision(number: number | string) {
  const numberString = number.toString();
  const dotIndex = numberString.indexOf('.');
  let precision = 0;
  if (dotIndex !== -1) {
    precision = numberString.length - 1 - dotIndex;
  }
  return precision;
}

export function convertToDineroAmt(priceStr: string, priceCurrency: Currency) {
  const precision = findPrecision(priceStr);
  return Dinero({
    amount: parseInt(priceStr.replace('.', '')),
    precision,
    currency: priceCurrency,
  })
    .convertPrecision(currencyPrecision[priceCurrency])
    .getAmount();
}

export function convertDineroAmtToFloat(amt: number, currency: Currency) {
  const price = Dinero({
    amount: amt,
    currency,
    precision: currencyPrecision[currency],
  });
  return price.toRoundedUnit(price.getPrecision());
}

export function convertDineroAmtToFloatStr(amt: number, currency: Currency) {
  return convertDineroAmtToFloat(amt, currency).toString();
}

export function convertWeightToGrams(weightUnit: ProductWeightUnit, weight: number) {
  if (weight === 0) {
    return 0;
  }

  if (weightUnit === 'g') {
    return weight;
  }

  // currently only supports g and kg
  // the only case left is kg
  const negativeSign = weight < 0 ? '-' : '';
  const weightStr = Math.abs(weight).toString();
  if (!!negativeSign) {
    const kgStr = negativeSign + weightStr.slice(2, 5) + "." + weightStr.slice(5);
    return parseFloat(kgStr);
  } else {
    const dotIndex = weightStr.indexOf(".");
    if (dotIndex === -1) {
      return parseFloat(weightStr + "000");
    } else {
      const gDotIndex = dotIndex + 3;
      const weightStrNoDot = weightStr.slice(0, dotIndex) + weightStr.slice(dotIndex + 1);
      if (weightStrNoDot.length < 4) {
        const zerosNeeded = 4 - weightStrNoDot.length;
        const zeros = new Array(zerosNeeded).fill(0).join("");
        return parseFloat(negativeSign + weightStrNoDot + zeros);
      } else {
        return parseFloat(negativeSign + weightStrNoDot.slice(0, gDotIndex) + "." + weightStrNoDot.slice(gDotIndex));
      }
    }
  }
}