import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {DineroObject} from 'dinero.js';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';
import TimeoutAlert from '../timeout_alert';
import HeadTitle from '../head_title';
import AdjustProductWeightOverrides from '../adjust_product_weight_overrides';
import {DisputeInfo} from "../../api/response_models";
import {formatMoney} from "../../utils/number";
import {AnyOrder} from "../../types/models/order";

interface UpdatedProductPriceItem {
  name: string;
  price: DineroObject;
}

// TODO remove current resolve shipping dispute after confirming new dispute flow
export default function ResolveShippingDispute() {
  const [updatedProductPrices, setUpdatedProductPrices] = React.useState<UpdatedProductPriceItem[] | null>(null);
  const [disableButtons, setDisableButtons] = React.useState(false);
  const [adjustedTotalWeight, setAdjustedTotalWeight] = React.useState(0);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  const history = useHistory();
  const params = useParams<{ orderRef: string }>();
  const {orderRef} = params;

  const orderCache = React.useRef<AnyOrder | null>(null);

  const handleWeightChanges = (disputableItems: DisputeInfo.DisputableItem[], productWeightOverrides: DisputeInfo.ProductWeightOverrideMap) => {
    // calculate the new weights
    // add those disputable with override weight and non disputable with normal weight

    let totalWeight = .0;
    for (const item of disputableItems) {
      let itemWeight;
      const override = productWeightOverrides[item.productId];
      if (item.isDisputable && override) {
        itemWeight = override.weight;
      } else {
        itemWeight = item.weight;
      }
      console.log('itemWeight', itemWeight);
      totalWeight += itemWeight * item.quantity;
    }
    console.log('adjustedTotalWeight', adjustedTotalWeight);
    setAdjustedTotalWeight(totalWeight);
  };

  const handleEstimateCost = () => {};

  // const handleEstimateCost = React.useCallback(async () => {
  //   try {
  //     let order: AnyOrder;
  //     if (!orderCache.current) {
  //       const res = await Api.staff.order.get(orderRef);
  //       if (res.data.order.version === 1) {
  //         setErrorMessage('Cost cannot be estimated for orders using old pricing model');
  //         return;
  //       }
  //       order = res.data.order;
  //       orderCache.current = order;
  //     } else {
  //       order = orderCache.current;
  //     }
  //
  //     const res = await Api.staff.order.estimateUpdatedPrice(orderRef);
  //     const {productPrices} = res.data;
  //     const newUpdatedProductPrices: UpdatedProductPriceItem[] = [];
  //     for (const productId in productPrices) {
  //       if (!productPrices.hasOwnProperty(productId)) {
  //         continue;
  //       }
  //       const cartItem = order.cartItems.find(ci => ci.productId === productId);
  //       if (!cartItem) {
  //         continue;
  //       }
  //
  //       newUpdatedProductPrices.push({
  //         name: cartItem.product.name,
  //         price: {
  //           ...order.totalCost,
  //           amount: productPrices[productId]
  //         }
  //       });
  //     }
  //     setUpdatedProductPrices(newUpdatedProductPrices);
  //   } catch (error: any) {
  //     const errorMessage = extractAxiosError(error);
  //     setErrorMessage(errorMessage);
  //   }
  // }, [orderRef]);

  const handleResolveUpdate = () => {
    setDisableButtons(true);
    Api.staff.order.resolveDispute(orderRef)
      .then(_ => {
        setSuccessMessage('Successfully resolved dispute');
        // redirect to orders page
        history.push('/orders');
      })
      .catch(error => {
        setDisableButtons(false);
        setErrorMessage(extractAxiosError(error));
      });
  };

  const handleCancel = () => {
    window.history.back();
  };

  return <React.Fragment>
    <HeadTitle
      title="Resolve Shipping Disputes"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />
    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />

    <h1>Resolve Shipping Disputes</h1>

    <AdjustProductWeightOverrides
      orderRef={orderRef}
      onError={setErrorMessage}
      onSuccess={setSuccessMessage}
      onChange={handleWeightChanges}
    />

    <div className="mb-2">
      <button className="btn btn-light mr-2" onClick={handleEstimateCost} disabled={disableButtons}>
        Estimate
      </button>

      <button className="btn btn-primary mr-2" onClick={handleResolveUpdate} disabled={disableButtons}>
        Resolve dispute
      </button>

      <button className="btn btn-secondary" onClick={handleCancel} disabled={disableButtons}>
        Cancel
      </button>
    </div>


    {updatedProductPrices && (
      <>
        <h5>Updated Product Price Estimates</h5>
        <table className="table">
          <thead>
          <tr>
            <th>Product Name</th>
            <th>New Price</th>
          </tr>
          </thead>
          <tbody>
          {updatedProductPrices.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{formatMoney(item.price.currency, item.price.amount)}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </>
    )}
    {disableButtons && (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )}
  </React.Fragment>;
}