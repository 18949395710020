import React from 'react';
import {useParams} from 'react-router-dom';
import EditPopup from '../edit_popup';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';

export default function EditPopUpPage() {
  const [popup, setPopup] = React.useState<{ name: string, link: string, active: boolean, imagePath: string } | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const params = useParams<{id: string}>();

  const {id} = params;

  React.useEffect(() => {
    Api.admin.popup.get(id)
      .then(res => {
        setPopup(res.data.popup);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  }, [id]);

  return <>
    <HeadTitle
      title="Edit Pop up"
    />
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <h1>Edit Pop Up</h1>
    <EditPopup
      onError={setErrorMessage}
      popup={popup as { name: string, link: string, active: boolean, imagePath: string, _id: number }}
    />
  </>;
}