import React from "react";
import classNames from "classnames";
import {Socket} from "socket.io-client";
import {useDispatch} from "react-redux";
import quotationItemStyles from "../../styles/quotation_item.module.scss";
import styles from "../../styles/external_quotation_item.module.scss";
import QuotationItemStatusBadgeComponent from "./status_badge";
import {formatDateTimeFromSeconds} from "../../utils/date";
import QuotationItemDeclineReasonLineComponent from "./item_decline_reason_line";
import QuotationItemResolvedInfoLineComponent from "./quotation_item_resolved_info_line";
import QuotationItemClosedAtLineComponent from "./item_closed_at_line";
import DeclineInternalQuotationItemDialog, {DeclineInternalQuotationItemDialogCloseData} from "./decline_internal_item_dialog";
import {ExternalQuotationItem, QuotationItem, QuotationItemStatuses} from "../../types/models/quotation_item";
import Api from "../../api/api";
import {AiFillCaretDown, AiFillCaretUp} from 'react-icons/ai';
import ChatWindow from '../orders/chat_window';
import TimeoutAlert from "../timeout_alert";
import ResolveExternalQuotationItemDialog, {ResolveExternalQuotationItemDialogCloseData} from "./resolve_external_item_dialog";
import {CallbackFunctionVariadic} from "../../types/function";
import {actions} from "../../store/actions";
import {Link, useHistory} from "react-router-dom";

interface ExternalQuotationItemProps {
  quotationItem: ExternalQuotationItem;
  socket: Socket | undefined | null;
  onResolveOrDecline?: (quotationItem: QuotationItem) => void;
  unreadMessageCount?: number;
  showChatAtStart?: boolean;
  linksToSinglePage?: boolean;
}

function getChatGroupRef(quotationReferenceNumber: string) {
  return `external_quotation_${quotationReferenceNumber}`;
}

export default function ExternalQuotationItemComponent({onResolveOrDecline, socket, showChatAtStart, linksToSinglePage, ...props}: ExternalQuotationItemProps) {
  const {quotationItem} = props;
  const {mainImage: imageUrl, instructions} = quotationItem;

  const [showResolveDialog, setShowResolveDialog] = React.useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = React.useState(false);
  const [showChat, setShowChat] = React.useState(showChatAtStart ?? false);
  const [chatErrorMessage, setChatErrorMessage] = React.useState<string | null>(null);
  const [chatToggleHighlight, setChatToggleHighlight] = React.useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(props.unreadMessageCount ?? 0);

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (props.unreadMessageCount) {
      setUnreadMessageCount(props.unreadMessageCount);
    }
  }, [props.unreadMessageCount]);

  React.useEffect(() => {
    if (!socket) {
      return;
    }

    let onNewMessageListener: CallbackFunctionVariadic;
    let onMessagesSeenListener: CallbackFunctionVariadic;
    const chatGroupRef = getChatGroupRef(quotationItem.referenceNumber);

    socket.once('seenMessages', onMessagesSeenListener = (groupRef: string, lastSeenTiming: number, groupUnseenMessageCount: number) => {
      if (groupRef !== chatGroupRef) {
        return;
      }

      setUnreadMessageCount(groupUnseenMessageCount);
    });

    socket.once('newMessage', onNewMessageListener = (groupRef: string) => {
      if (groupRef !== chatGroupRef) {
        return;
      }

      setUnreadMessageCount((unreadMessageCount ?? 0) + 1);
    });

    return () => {
      socket.off('seenMessages', onMessagesSeenListener);
      socket.off('newMessage', onNewMessageListener);
    };

  }, [socket, quotationItem, unreadMessageCount]);

  const isClosureButtonsEnabled = quotationItem.status === QuotationItemStatuses.pending;

  const handleResolveBtn = React.useCallback(() => {
    setShowResolveDialog(true);
  }, []);

  const handleDeclineBtn = React.useCallback(() => {
    setShowDeclineDialog(true);
  }, []);

  const handleDeclineDialogClose = React.useCallback((closeData?: DeclineInternalQuotationItemDialogCloseData) => {
    setShowDeclineDialog(false);
    if (!closeData) {
      return;
    }

    const {declineReason} = closeData;
    Api.staff.quotationItem.decline(quotationItem._id, {
      declineReason
    })
      .then((res) => {
        if (onResolveOrDecline) {
          onResolveOrDecline(res.data.quotationItem);
        }
        dispatch(actions.quotation.decrementExternalPendingCount());
      })
      .catch((e) => {
        console.error('failed to decline quotation item', e);
      });
  }, [onResolveOrDecline, quotationItem, dispatch]);

  const handleResolveDialogClose = React.useCallback((data?: ResolveExternalQuotationItemDialogCloseData) => {
    //console.log(data)
    setShowResolveDialog(false);
    if(!data) {
      return;
    }

    Api.staff.quotationItem.resolveExternal(quotationItem._id, data)
      .then((res) => {
        if (onResolveOrDecline) {
          onResolveOrDecline(res.data.quotationItem);
        }
        dispatch(actions.quotation.decrementExternalPendingCount());
      })
      .catch((e) => {
        console.error('failed to resolve quotation item', e);
      });
  }, [quotationItem, onResolveOrDecline, dispatch]);

  const toggleChatLabel = React.useMemo(() => {
    if (showChat) {
      return <>
        Hide chat
        <AiFillCaretUp />
      </>;
    } else {
      return <>
        Show chat
        <AiFillCaretDown />
      </>;
    }
  }, [showChat]);

  const hideChatErrorMessage = React.useCallback(() => setChatErrorMessage(null), []);
  const stopChatToggleHighlight = React.useCallback(() => setChatToggleHighlight(false), []);
  const handleChatToggle = React.useCallback(() => {
    setShowChat(!showChat);
    stopChatToggleHighlight();
    setUnreadMessageCount(0);
  }, [showChat, stopChatToggleHighlight]);

  const getChatWindow = React.useCallback((quotationReferenceNumber: string) => {
    const chatGroupRef = getChatGroupRef(quotationReferenceNumber);

    return <ChatWindow
      socket={socket}
      key={quotationReferenceNumber}
      groupRef={chatGroupRef}
      onErrorMessage={setChatErrorMessage}
    />;
  }, [socket]);

  const imageContainerAttributes = React.useMemo(() => {
    let imageContainerAttributes: React.HTMLAttributes<HTMLDivElement> = {};
    if (quotationItem && linksToSinglePage) {
      imageContainerAttributes = {
        role: "button",
        onClick: () => {
          history.push(`/quotation_items/external/${quotationItem.referenceNumber}`);
        }
      };
    }

    return imageContainerAttributes;
  }, [quotationItem, history, linksToSinglePage]);


  return <>
    <div className="media">
      <div className={quotationItemStyles.imageContainer} {...imageContainerAttributes}>
        <img src={imageUrl} alt="quotation item" />
      </div>

      <div className="media-body">
        <h5 className="mt-0 col">
          {linksToSinglePage ? (
              <Link className="mr-2" to={`/quotation_items/external/${quotationItem.referenceNumber}`}>
                {quotationItem.name}
              </Link>
          ) : (
              <span className="mr-2">{quotationItem.name}</span>
          )}

          <QuotationItemStatusBadgeComponent
            status={quotationItem.status}
          />
        </h5>

        <dl className="text-muted">
          <dt className="col-auto">Reference Number:</dt>
          <dd className="col">{quotationItem.referenceNumber}</dd>

          <dt className="col-auto">Created On:</dt>
          <dd className="col">{formatDateTimeFromSeconds(quotationItem.createdAt)}</dd>


          <dt className="col-auto">Instructions:</dt>
          <dd className="col">{instructions}</dd>


          <QuotationItemDeclineReasonLineComponent
            quotationItem={quotationItem}
          />

          <QuotationItemResolvedInfoLineComponent
            quotationItem={quotationItem}
          />

          <QuotationItemClosedAtLineComponent
            quotationItem={quotationItem}
          />
        </dl>
      </div>
    </div>
    <div className={classNames("d-flex", quotationItemStyles.quotationItemButtonsContainer)}>
      <div>
        <button
          className={classNames('btn btn-sm btn-outline-primary', {
            [styles.newMessageHighlightBg]: chatToggleHighlight
          })}
          onClick={handleChatToggle}
        >
          {toggleChatLabel}
          {!!unreadMessageCount && (
            <div className={classNames(
              'align-self-center',
              'font-weight-bold',
              'bg-primary',
              styles.unreadMessageCount
            )}>
              {unreadMessageCount}
            </div>
          )}
        </button>
      </div>
      <div className="flex-grow-1 text-right">
        <a
          className="btn btn-sm btn-secondary"
          href={quotationItem.productSourceData.link}
          target="_blank"
          rel="noreferrer"
        >
          Open Product Page (3rd Party)
        </a>

        {isClosureButtonsEnabled && (
          <>
            <button
              className="btn btn-sm btn-outline-success"
              onClick={handleResolveBtn}
            >
              Resolve
            </button>
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={handleDeclineBtn}
            >
              Decline
            </button>
          </>
        )}
      </div>
    </div>
    {showChat && (
      <div onClick={stopChatToggleHighlight}>
        <TimeoutAlert
          onHide={hideChatErrorMessage}
          errorMessage={chatErrorMessage}
        />
        {getChatWindow(quotationItem.referenceNumber)}
      </div>
    )}
    <hr/>

    <ResolveExternalQuotationItemDialog
      show={showResolveDialog}
      onHide={handleResolveDialogClose}
      quotationItem={quotationItem}
    />

    <DeclineInternalQuotationItemDialog
      show={showDeclineDialog}
      onHide={handleDeclineDialogClose}
    />
  </>;
}