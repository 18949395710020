import {actions} from '../actions';
import {createReducer} from "@reduxjs/toolkit";
import {ConfigData} from "../../api/models";

const {setFetchAllError, setAll} = actions.config;

export interface ConfigState {
  data?: ConfigData;
  fetchAllError?: string;
}

const defaultState: ConfigState = {
  data: undefined,
  fetchAllError: undefined
};

const configReducer = createReducer(defaultState, builder => {
  builder
    .addCase(setFetchAllError, (state, action) => {
      state.fetchAllError = action.payload;
    })
    .addCase(setAll, (state, action) => {
      state.data = action.payload;
    });
});

export default configReducer;