import React from 'react';
import EditBanner from '../edit_banner';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';

export default function NewBanner() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  return <>
    <HeadTitle
      title="New Banner"
    />
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <h1>New Banner</h1>
    <EditBanner
      onError={setErrorMessage}
    />
  </>;
}