import React from 'react';
import TimezoneSelect from 'react-timezone-select';
import {ConfigEditorProps} from "../../../generic";

export default function TimezoneSelectWrapper(props: ConfigEditorProps<string>) {
  const {configValue, onDone, onCancel} = props;

  const [timezone, setTimezone] = React.useState(configValue || '');

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();
    onDone(timezone);
  }, [timezone, onDone]);


  return <form className="form-inline" onSubmit={handleSubmit}>
    <div className="form-group">
      <TimezoneSelect
        value={timezone}
        onChange={setTimezone}
      />
    </div>

    <button type="submit" className="btn btn-primary">Save</button>
    <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
  </form>;
}