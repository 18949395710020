import {formatDateTimeFromSeconds} from "../../utils/date";
import {QuotationItemProps} from "./quotation_item_props";
import {QuotationItem, QuotationItemStatuses} from "../../types/models/quotation_item";

export default function QuotationItemClosedAtLineComponent(props: QuotationItemProps) {
  const {quotationItem} = props;
  if (quotationItem.status === QuotationItemStatuses.pending) {
    return null;
  }

  const quotationItemCasted = quotationItem as QuotationItem<QuotationItemStatuses.resolved | QuotationItemStatuses.declined>;
  const {closedAt} = quotationItemCasted.statusData;

  return <>
    <dt className="col-auto">Closed On:</dt>
    <dd className="col">{formatDateTimeFromSeconds(closedAt)}</dd>
  </>;
}