import React from "react";
import Api from "../api/api";
import {UserProfile} from "../api/models";

interface UserNameTextProps {
    userId: string;
}

const getNamePromises: Record<string, ReturnType<typeof Api.user.getNames>> = {};
const getNameCache: Record<string, Pick<UserProfile, 'firstName' | 'lastName'>> = {};

export default function UserNameText({userId}: UserNameTextProps) {
    const [text, setText] = React.useState<string>("");

    React.useEffect(() => {
        let active = true;
        if (getNameCache[userId]) {
            const userProfile = getNameCache[userId];
            setText(`${userProfile.firstName} ${userProfile.lastName}`);
            return;
        }

        let promise = getNamePromises[userId];
        if (!promise) {
            getNamePromises[userId] = promise = Api.user.getNames([userId]);
        }
        promise.then(res => {
            if (active) {
                const userProfile = res.data.usernames[userId];
                setText(`${userProfile.firstName} ${userProfile.lastName}`);
                getNameCache[userId] = userProfile;
            }
        });

        return () => {
            active = false;
        };
    }, [userId]);

    return <>
        {text}
    </>;
}