import React from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {AiFillCaretDown, AiFillCaretUp} from 'react-icons/ai';
import {omit} from "lodash";
import {deserializeDate, formatDateTimeFromSeconds, serializeDate} from '../../utils/date';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';
import TimeoutAlert from '../timeout_alert';
import classNames from 'classnames';
import {genericTextChange} from '../../utils/forms';
import HeadTitle from '../head_title';
import {queryStringify, useQueryString} from "../../utils/route";
import {StaffOrderListing} from '../../types/responses/order';
import {getFirstOrItself} from "../../utils/array";

const VALID_FILTERS = ['pending_payment', 'paid', 'processing', 'cancelled'];

export default function Orders() {
  const [orders, setOrders] = React.useState<StaffOrderListing[]>([]);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [serverError, setServerError] = React.useState<string | null>(null);
  const [filterSearch, setFilterSearch] = React.useState('');
  const [filterDateRange, setFilterDateRange] = React.useState<Date[] | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const history = useHistory();
  const location = useLocation();
  const query = useQueryString();

  const filterAndSortDetails = React.useMemo(() => {
    let filter = getFirstOrItself(query.filter);
    let sortBy = getFirstOrItself(query.sortBy);
    let sortOrder = getFirstOrItself(query.sortOrder);
    if (sortBy) {
      if (!sortOrder) {
        sortOrder = 'asc';
      }
    } else {
      // set default sort
      sortBy = 'createdAt';
      sortOrder = 'desc';
    }

    if (!filter || !VALID_FILTERS.includes(filter)) {
      filter = VALID_FILTERS[0];
    }

    let search: string = '';
    if (typeof(query.search) === 'string') {
      search = query.search;
    }

    let dateRange: string[] | null = null;
    if (Array.isArray(query.dateRange)) {
      try {
        deserializeDate(query.dateRange[0]);
        deserializeDate(query.dateRange[1]);
        dateRange = query.dateRange.slice(0, 2);
      } catch (e) {}
    }

    return {
      filter,
      sortOrder,
      sortBy,
      search,
      dateRange
    };
  }, [query]);

  React.useEffect(() => {
    let isEffectActive = true;
    const executor = <Fn extends (...args: any[]) => any,>(runnable: Fn, ...args: Parameters<Fn>) => {
      if (!isEffectActive) {
        return;
      }
      return runnable(...args);
    };
    const {filter, search, ...others} = filterAndSortDetails;

    let searchOverride: string | undefined;
    if (search.trim().length > 0) {
      searchOverride = search.trim();
    }


    executor(setIsLoading, true);

    let overrideFilter: string | undefined = filter;
    if (searchOverride || others.dateRange) {
      overrideFilter = undefined;
    }
    const query = {
      ...others,
      filter: overrideFilter,
      search: searchOverride
    };

    if (query.dateRange) {
      executor(setFilterDateRange, query.dateRange.map(deserializeDate));
    }

    if (query.search) {
      executor(setFilterSearch, query.search);
    }

    Api.staff.order.getAll(query)
        .then(res => {
          executor(setOrders, res.data.orders);
          executor(setIsLoading, false);
        })
        .catch(error => {
          const errorMessage = extractAxiosError(error);
          executor(setServerError, errorMessage);
        })
        .finally(() => executor(setIsLoading, false));

    return () => {
      isEffectActive = false;
    };
  }, [filterAndSortDetails]);

  const {filter, sortBy, sortOrder} = filterAndSortDetails;

  const handleHideServerError = () => setServerError(null);
  const handleHideSuccessMessage = () => setSuccessMessage(null);

  const handleApplyFilter = () => {
    const filterQuery = Object.assign({}, query);
    delete filterQuery.dateRange;
    delete filterQuery.search;
    delete filterQuery.filter;

    if (filterSearch.length > 0) {
      filterQuery.search = filterSearch;
    }

    if (filterDateRange &&
      Array.isArray(filterDateRange) &&
      filterDateRange.every(i => i !== null)) {
      filterQuery.dateRange = filterDateRange.map(serializeDate).map((date) => date.toString());
    }

    history.push(`${location.pathname}?${queryStringify(filterQuery)}`);
  };

  const handleSortClick = (newSortBy: string) => () => {
    let filterQuery;
    if (newSortBy === sortBy) {
      // toggle the asc desc
      let altOrder;
      if (sortOrder === 'asc') {
        altOrder = 'desc';
      } else {
        altOrder = 'asc';
      }
      filterQuery = Object.assign({}, query, {
        sortBy: newSortBy,
        sortOrder: altOrder
      });
    } else {
      // go to new sort by asc
      filterQuery = Object.assign({}, query, {
        sortBy: newSortBy,
        sortOrder: 'asc'
      });
    }
    history.push(`${location.pathname}?${queryStringify(filterQuery)}`);
  };

  const handleClearSearch = React.useCallback(() => {
    const filterQuery = omit(filterAndSortDetails, 'dateRange', 'search');

    history.push(`${location.pathname}?${queryStringify(filterQuery)}`);
  }, [history, location.pathname, filterAndSortDetails]);

  const isSearching = !!filterAndSortDetails.dateRange || !!filterAndSortDetails.search;

  return <React.Fragment>
    <HeadTitle
      title="Orders"
    />

    <TimeoutAlert
      errorMessage={serverError}
      onHide={handleHideServerError}
    />

    <TimeoutAlert
      errorMessage={successMessage}
      alertColor="success"
      onHide={handleHideSuccessMessage}
    />

    <h1>Orders</h1>
    <h3>Search</h3>
    <div className="mb-3">
      <div className="form-group">
        <label>Order Reference</label>
        <input
          type="text"
          className="form-control"
          placeholder="NOXXXXXXXXXXXXXX"
          value={filterSearch}
          onChange={genericTextChange(setFilterSearch)}
        />
      </div>
      <div className="form-group">
        <label>Date Range</label>
        <br/>
        <DateRangePicker
          onChange={setFilterDateRange}
          value={filterDateRange}
        />
      </div>

      <button className="btn btn-primary" onClick={handleApplyFilter}>Search</button>
      {isSearching && (
        <button className="btn btn-outline-danger ml-1" onClick={handleClearSearch}>Clear Search</button>
      )}
    </div>
    {!isSearching && (
      <ul className="nav nav-pills nav-fill">
        <li className="nav-item">
          <Link
            className={classNames(
              'nav-link', {
                active: filter === VALID_FILTERS[0]
              }
            )}
            to={`/orders?filter=${VALID_FILTERS[0]}`}
          >
            Pending Payment
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={classNames(
              'nav-link', {
                active: filter === VALID_FILTERS[1]
              }
            )}
            to={`/orders?filter=${VALID_FILTERS[1]}`}
          >Paid</Link>
        </li>
        <li className="nav-item">
          <Link
            className={classNames(
              'nav-link', {
                active: filter === VALID_FILTERS[2]
              }
            )}
            to={`/orders?filter=${VALID_FILTERS[2]}`}
          >Processing</Link>
        </li>
        <li className="nav-item">
          <Link
            className={classNames(
              'nav-link', {
                active: filter === VALID_FILTERS[3]
              }
            )}
            to={`/orders?filter=${VALID_FILTERS[3]}`}
          >Cancelled</Link>
        </li>
      </ul>
    )}

    {isLoading ? (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
      <table className="table">
        <thead>
        <tr>
          <th>Order Reference</th>
          <th>
            <button
              className={classNames('btn btn-link p-0 d-inline', {
                'font-weight-bold': sortBy === 'createdAt'
              })}
              onClick={handleSortClick('createdAt')}
            >
              Date
              {sortOrder === 'asc' && (
                <AiFillCaretUp />
              )}
              {sortOrder === 'desc' && (
                <AiFillCaretDown />
              )}
            </button>
          </th>
          <th>Status</th>
          <th>Customer Details</th>
        </tr>
        </thead>
        <tbody>
        {orders.map((order, index) => {
          return <tr key={index}>
            <td>
              <Link to={`/orders/${order.orderRef}`}>
                {order.orderRef}
              </Link>
            </td>
            <td>{formatDateTimeFromSeconds(order.createdAt)}</td>
            <td>{order.status}</td>
            <td>
              <div>
                <strong>First Name:</strong>
                {`${order.ownerInfo.firstName}`}
              </div>
              <div>
                <strong>Last Name:</strong> {`${order.ownerInfo.lastName}`}
              </div>
              <div>
                <strong>Email:</strong> {order.ownerInfo.email}
              </div>
            </td>
          </tr>;
        })}
        </tbody>
      </table>
    )}
  </React.Fragment>;
}