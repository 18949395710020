import React from 'react';
import {useParams} from 'react-router-dom';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import EditMerchantProduct from '../edit_merchant_product';

export default function NewMerchantProductPage() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const params = useParams<{ merchantId: string }>();
  const {merchantId} = params;

  return <>
    <HeadTitle
      title="New Product"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <h1>New Product</h1>

    <EditMerchantProduct
      merchantId={merchantId}
      onError={setErrorMessage}
    />
  </>;
}