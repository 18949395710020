export const VARIANT_TYPES = {
  COLOR: {
    label: 'Color',
    value: 1,
  },
  OTHERS: {
    label: 'Others',
    value: 16,
  },
};


let variantLabelMap: {
  [value: string]: string;
} = {};

export function getVariantLabel(variantTypeValue: number | string) {
  if (variantLabelMap === null) {
    variantLabelMap = {};
    for (const vt of Object.values(VARIANT_TYPES)) {
      variantLabelMap[vt.value] = vt.label;
    }
  }
  return variantLabelMap[variantTypeValue];
}