import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {genericTextChange} from '../../utils/forms';

interface FlagOrderModalProps {
  show: boolean;
  onHide: () => void;
  onFlag: (message: string) => void;
}

export default function FlagOrderModal({show, onHide, onFlag}: FlagOrderModalProps) {
  const [message, setMessage] = React.useState('');
  const handleFlag = () => {
    onFlag(message);
    setMessage('');
  };

  return <Modal
    scrollable={true}
    show={show}
    onHide={onHide}
    animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Flag Message To Admin</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="form-group">
        <label>Message</label>
        <textarea
          className="form-control"
          value={message}
          onChange={genericTextChange(setMessage) as React.ChangeEventHandler<HTMLElement>}
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleFlag}>
        Flag
      </Button>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;
}