import React from "react";
import {pick} from "lodash";
import {InternalQuotationItem, QuotationItemStatuses} from "../../types/models/quotation_item";
import {CartItemProductForOrder} from "../../types/models/cart_item";
import {formatMoney} from "../../utils/number";
import {WeightInfo} from "../../types/responses/product_weight";

interface InternalQuotationItemPendingLineProps {
  quotationItem: InternalQuotationItem;
  product: CartItemProductForOrder;
}

export default function InternalQuotationItemPendingLine(props: InternalQuotationItemPendingLineProps) {
  const {quotationItem, product} = props;
  if (quotationItem.status !== QuotationItemStatuses.pending) {
    return null;
  }

  let weightInfo: WeightInfo;
  if (quotationItem.productSourceData.variantPath && quotationItem.productSourceData.variantPath in product.variantPaths) {
    const variantPath = product.variantPaths[quotationItem.productSourceData.variantPath];
    weightInfo = pick(variantPath, 'weight', 'weightUnit');
  } else {
    weightInfo = pick(product, 'weight', 'weightUnit');
  }

  return <>
    <dt className="col-auto">Pre-quoted Weight:</dt>
    <dd className="col">
      {weightInfo.weight}{weightInfo.weightUnit}
    </dd>

    <dt className="col-auto">Pre-quoted Price:</dt>
    <dd className="col">
      {formatMoney('SGD', product.price['SG'])}
    </dd>
  </>;
}