import React from 'react';
import EditPopup from '../edit_popup';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';

export default function NewPopup() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  return <>
    <HeadTitle
      title="New Pop Up"
    />
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <h1>New Pop Up</h1>
    <EditPopup
      onError={setErrorMessage}
    />
  </>;
}