import React from 'react';
import classNames from 'classnames';
import {toTitleCase} from "../utils/string";

interface ValidationProps {
  errors?: string[];
  className?: string;
}

interface InputFieldWithValidationProps extends ValidationProps, Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> {
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  children?: React.ReactNode;
}

type ValidationResult = [string, null | React.ReactNode];
function useValidation(props: ValidationProps): ValidationResult {
  const {errors, className} = props;
  const showValidation = Array.isArray(errors);
  const isInvalid = showValidation && errors!.length > 0;
  const isValid = showValidation && errors!.length === 0;


  const combinedClassName = classNames(className, {
    'is-valid': isValid,
    'is-invalid': isInvalid,
  });

  let content = null;
  if (showValidation) {
    let itemClass: string;
    if (isInvalid) {
      itemClass = 'invalid-feedback';
    } else {
      itemClass = 'valid-feedback';
    }

    content = errors!.map((error, index) => (
      <div className={itemClass} key={index}>{toTitleCase(error)}</div>
    ));
  }

  return [combinedClassName, content];
}

export function InputFieldWithValidation(props: InputFieldWithValidationProps) {
  const {errors, className, onChange, children, ...others} = props;
  const [combinedClassName, content] = useValidation(props);

  return <React.Fragment>
    <input {...others} onChange={onChange} className={combinedClassName}/>
    {children}
    {content}
  </React.Fragment>;
}

interface SelectFieldWithValidationProps extends ValidationProps, Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'className'> {
  value: string;
  children?: React.ReactNode;
}

export function SelectFieldWithValidation(props: SelectFieldWithValidationProps) {
  const {errors, className, children, ...others} = props;
  const [combinedClassName, content] = useValidation(props);

  return <React.Fragment>
    <select {...others} className={combinedClassName}>
      {props.children}
    </select>
    {children}
    {content}
  </React.Fragment>;
}

interface TextAreaWithValidationProps extends ValidationProps, Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'className'> {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  children?: React.ReactNode;
}

export function TextAreaWithValidation(props: TextAreaWithValidationProps) {
  const {errors, className, onChange, children, ...others} = props;
  const [combinedClassName, content] = useValidation(props);

  return <React.Fragment>
    <textarea {...others} onChange={onChange} className={combinedClassName}/>
    {children}
    {content}
  </React.Fragment>;
}

export function ValidationFeedback(props: ValidationProps) {
  const [combinedClassName, content] = useValidation(props);

  return <React.Fragment>
    <div className="is-invalid d-none"/>
    {content}
    {combinedClassName.includes('is-valid') && (
        <div className="text-success font-size-n1">Looks good!</div>
    )}
  </React.Fragment>;
}