import React, {ChangeEventHandler} from 'react';
import {Link, useHistory} from 'react-router-dom';
import urljoin from 'url-join';
import Api from '../api/api';
import {genericCheckboxChange, genericTextChange, readOneFileAsDataURLFactory} from '../utils/forms';
import {extractAxiosError} from '../utils/error';
import {Popup} from "../api/models";


interface EditPopupProps {
  onError: Function,
  popup?: Popup
}

export default function EditPopup({onError, popup}: EditPopupProps) {
  const editing = !!popup;
  const [name, setName] = React.useState('');
  const [link, setLink] = React.useState('');
  const [active, setActive] = React.useState(true);
  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState<string | null>(null);

  const uploadRef = React.useRef() as { current: { files: any[] } };
  const history = useHistory();

  React.useEffect(() => {
    if (popup) {
      setName(popup.name);
      setLink(popup.link);
      setActive(popup.active);

      const src = urljoin(process.env.REACT_APP_IMAGES_ENDPOINT!, popup.imagePath);
      setPreviewImage(src);
    }
  }, [popup]);

  const handleSelectFile = readOneFileAsDataURLFactory(setPreviewImage);

  const handleSave = () => {
    if (saveDisabled) {
      return;
    }
    setSaveDisabled(true);

    const newPopup = {
      active,
      name,
      link,
    };

    if (!editing) {
      Object.assign(newPopup, {
        image: uploadRef.current.files[0],
      });
    }

    let action;
    if (editing) {
      const {_id} = popup as Popup;
      action = Api.admin.popup.update(_id, newPopup);
    } else {
      action = Api.admin.popup.create(newPopup);
    }

    action
      .then(_ => {
        history.push('/popups');
      })
      .catch(error => {
        onError(extractAxiosError(error));
      })
      .finally(() => {
        setSaveDisabled(false);
      });
  };


  return <>
    {!editing && (
      <div className="form-group">
        <label>Image</label>
        <br/>
        <input type="file" accept="image/*" onChange={handleSelectFile}
               ref={uploadRef as unknown as React.LegacyRef<HTMLInputElement>}/>
      </div>
    )}

    {previewImage && (
      <div className="form-group">
        <label>Image Preview</label>
        <br/>
        <img src={previewImage} alt="preview"/>
      </div>
    )}

    <div className="form-group">
      <label>Name</label>
      <input
        className="form-control"
        type="text"
        value={name}
        onChange={genericTextChange(setName) as unknown as ChangeEventHandler<HTMLInputElement>}
      />
    </div>

    <div className="form-group">
      <label>Link</label>
      <input
        className="form-control"
        type="text"
        value={link}
        onChange={genericTextChange(setLink) as unknown as ChangeEventHandler<HTMLInputElement>}
      />
    </div>

    <div className="form-check mb-2">
      <input
        className="form-check-input"
        type="checkbox"
        id="popup_active"
        onChange={genericCheckboxChange(setActive) as unknown as ChangeEventHandler<HTMLInputElement>}
        checked={active}
      />
      <label className="form-check-label" htmlFor="popup_active">
        Active?
      </label>
    </div>

    <button className="btn btn-primary mr-2" disabled={saveDisabled} onClick={handleSave}>Save</button>
    <Link className="btn btn-secondary" to="/popups">Cancel</Link>
  </>;
}