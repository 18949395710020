import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {QuotationItem, QuotationItemSources, QuotationItemStatuses} from "../../types/models/quotation_item";
import {checkIntForError} from "../../lib/validation";
import {InputFieldWithValidation} from "../fields_with_validation";
import TimeoutAlert from "../timeout_alert";
import {CartItemProductForOrder} from "../../types/models/cart_item";
import {convertWeightToGrams, formatMoney} from "../../utils/number";
import {throttle} from "lodash";
import Api from "../../api/api";
import {extractAxiosError} from "../../utils/error";

export interface ResolveInternalQuotationItemDialogCloseData {
  weightInGrams: number;
}

interface ResolveInternalQuotationItemDialogProps {
  show: boolean;
  onHide: (data?: ResolveInternalQuotationItemDialogCloseData) => void;
  quotationItem: QuotationItem<QuotationItemStatuses, QuotationItemSources.internal>;
  product: CartItemProductForOrder;
  productWeightSource: string;
}

interface ResolveInternalQuotationItemDialogErrors {
  updatedWeight?: string[];
}

export default function ResolveInternalQuotationItemDialog(props: ResolveInternalQuotationItemDialogProps) {
  const {show, onHide, product, productWeightSource, quotationItem} = props;

  const [updatedWeight, setUpdatedWeight] = React.useState('100');
  const [errors, setErrors] = React.useState<ResolveInternalQuotationItemDialogErrors>({});
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [quotedPricePreview, setQuotedPricePreview] = React.useState('-');

  React.useEffect(() => {
    if (!show) {
      return;
    }

    const weightInGrams = convertWeightToGrams(product.weightUnit, product.weight);
    setUpdatedWeight(weightInGrams.toString());
  }, [product, show]);

  const fetchPricePreview = React.useMemo(() => {
    return throttle((weightInGrams: number) => {
      Api.staff.quotationItem.previewInternalProductPrice(quotationItem._id, weightInGrams)
        .then(res => {
          setQuotedPricePreview(formatMoney('SGD', res.data.productPrice['SG']));
        })
        .catch(err => {
          setErrorMessage(extractAxiosError(err));
        });
    }, 1000);
  }, [quotationItem]);

  const preQuotedPrice = React.useMemo(() => {
    const variation = quotationItem.productSourceData.variantPath &&
        product.variantPaths[quotationItem.productSourceData.variantPath];
    if (variation) {
      return formatMoney('SGD', variation.price['SG']);
    } else {
      return formatMoney('SGD', product.price['SG']);
    }
  }, [product, quotationItem]);

  const handleUpdatedWeightChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedWeight(e.target.value);

    const newWeight = parseInt(e.target.value);
    if (!isNaN(newWeight) && newWeight >= 100) {
      fetchPricePreview(newWeight);
    }
  }, [fetchPricePreview]);

  const validate = React.useCallback(() => {
    const errors: Required<ResolveInternalQuotationItemDialogErrors> = {
      updatedWeight: checkIntForError(updatedWeight, {
        attrName: 'updated weight',
        gt: 99
      })
    };
    setErrors(errors);

    return Object.values(errors).every(i => i.length === 0);
  }, [updatedWeight]);

  const handleResolve = React.useCallback(() => {
    const isValid = validate();
    if (!isValid) {
      return;
    }

    onHide({
      weightInGrams: parseInt(updatedWeight)
    });
  }, [validate, onHide, updatedWeight]);

  const handleCancel = React.useCallback(() => {
    onHide();
  }, [onHide]);

  const clearErrorMessage = React.useCallback(() => {
    setErrorMessage(null);
  }, []);

  return <Modal
    scrollable={true}
    show={show}
    onHide={onHide}
    animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Resolve Quotation Item</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <TimeoutAlert
        errorMessage={errorMessage}
        onHide={clearErrorMessage}
      />

      <div className="form-group">
        <label>Product Weight Source</label>
        <input type="text" readOnly className="form-control-plaintext" value={productWeightSource}/>
      </div>

      <div className="form-group">
        <label>Pre-quoted Weight</label>
        <input type="text" readOnly className="form-control-plaintext" value={`${product.weight}${product.weightUnit}`}/>
      </div>

      <div className="form-group">
        <label>Pre-quoted Price</label>
        <input type="text" readOnly className="form-control-plaintext" value={preQuotedPrice}/>
      </div>

      <div className="form-group">
        <label>Quoted Price</label>
        <input type="text" readOnly className="form-control-plaintext" value={quotedPricePreview} />
      </div>

      <div className="form-group">
        <label>Updated Product Weight In Grams</label>
        <InputFieldWithValidation
          type="number"
          step={1}
          min={100}
          className="form-control"
          value={updatedWeight}
          onChange={handleUpdatedWeightChange}
          errors={errors.updatedWeight}
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleResolve}>
        Resolve
      </Button>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;

}