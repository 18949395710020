import React from 'react';
import classNames from 'classnames';
import {useCategories} from '../hooks/categories';
import {CategoryBrowser} from '../lib/categories';
import {Category} from "../api/models";

function LevelDivider() {
  return <span className="mx-1">
		{'>'}
	</span>;
}

interface CategorySelectProps {
  value: number;
  onChange: (categoryId: number) => void;
}

export default function CategorySelect({value, onChange}: CategorySelectProps) {
  const [levels, setLevels] = React.useState<Category[]>([]);
  const [selectedId, setSelectedId] = React.useState<number | null>(null);

  const categories = useCategories();

  const categoryBrowser: CategoryBrowser | null = React.useMemo(() => {
    if (!categories) {
      return null;
    }
    return new CategoryBrowser(categories);
  }, [categories]);

  React.useEffect(() => {
    // populate levels and selectedId when value changes from outside
    if (!categoryBrowser || !value || value === selectedId) {
      return;
    }

    setSelectedId(value);
    const parents = categoryBrowser.findParents(value);
    setLevels(parents);
  }, [selectedId, value, categoryBrowser]);

  let currentCategories: Category[] = [];
  if (categoryBrowser !== null) {
    if (levels.length === 0) {
      currentCategories = Object.values(categoryBrowser.categoryCopy);
    } else {
      const lastLevel = levels[levels.length - 1];
      currentCategories = Object.values(lastLevel.children);
    }
  }

  const handleSelectCategory = (category: Category) => () => {
    if (Object.keys(category.children).length === 0) {
      setSelectedId(category.id);
      onChange(category.id);
      return;
    }

    const newLevels = levels.slice();
    newLevels.push(category);
    setLevels(newLevels);
  };

  const goToLevel = (level: number) => (e: React.FormEvent) => {
    e.preventDefault();
    setLevels(levels.slice(0, level));
  };

  return <>
    <div className="levels">
      <button className="btn btn-link" onClick={goToLevel(0)}>
        Top
      </button>

      {levels.length > 0 && levels.map((level, index) => (
        <React.Fragment key={index}>
          <LevelDivider/>
          <button className="btn btn-link" onClick={goToLevel(index + 1)}>
            {level.name}
          </button>
        </React.Fragment>
      ))}
    </div>

    <div className="categories">
      {currentCategories.map((category, index) => {
        const className = classNames('btn btn-sm btn-outline-primary mr-2 mb-2', {
          active: category.id === selectedId,
        });
        return (
          <button
            key={index}
            className={className}
            onClick={handleSelectCategory(category)}
          >
            {category.name}
          </button>
        );
      })}
    </div>
  </>;
}