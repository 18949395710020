import React from 'react';
import {
  useHistory,
} from "react-router-dom";
import {connect, useDispatch} from 'react-redux';
import '../../styles/login.scss';
import Api from '../../api/api';
import {actions} from '../../store/actions';
import TimeoutAlert from '../timeout_alert';
import {extractAuthError} from '../../utils/error';
import HeadTitle from '../head_title';
import {queryStringify, useQuery} from '../../utils/route';

interface LoginProps {
  signedIn?: boolean;
  tfaPassed?: boolean;
}

function Login(props: LoginProps) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [serverError, setServerError] = React.useState<string | null>(null);

  const history = useHistory();
  // const location = useLocation();
  const query = useQuery();
  const dispatch = useDispatch();

  const postLoginPath = query.get('login_path');

  React.useEffect(() => {
    if (!history) return;
    if (props.signedIn) {
      if (props.tfaPassed) {
        if (postLoginPath) {
          history.replace(postLoginPath);
        } else {
          history.replace('/');
        }
        return;
      } else {
        if (postLoginPath) {
          history.replace(`/tfa?${queryStringify({
            login_path: postLoginPath
          })}`);
        } else {
          history.replace('/tfa');
        }
      }
    }
  }, [history, postLoginPath, props.signedIn, props.tfaPassed]);

  const handleSignIn = (e: React.FormEvent) => {
    e.preventDefault();
    Api.auth.login(email, password)
      .then(function (/*response*/) {
        // const {token} = response.data;
        dispatch(actions.user.signIn());
      })
      .catch(function (error) {
        setServerError(extractAuthError(error));
      });
  };

  return <>
    <HeadTitle
      title="Login"
    />

    <TimeoutAlert
      errorMessage={serverError as string}
      onHide={() => setServerError(null)}
    />

    <div className="text-center">
      <form className="form-signin" onSubmit={handleSignIn}>
        <img src="/favicon.ico" alt="indo4ward logo"/>
        <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
        <label className="sr-only">Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          autoFocus
        />
        <label className="sr-only">Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
        <p className="mt-5 mb-3 text-muted">Indo4ward &copy; 2022</p>
      </form>
    </div>
  </>;
}

const mapStateToProps = (state: {user: {signedIn: boolean, tfaPassed: boolean}}) => {
  return {
    signedIn: state.user.signedIn,
    tfaPassed: state.user.tfaPassed,
  };
};

export default connect(mapStateToProps, null)(Login);