import {MeResponse} from "../api/response_models";
import {CategoryMap, ConfigData} from "../api/models";
import {createAction} from "@reduxjs/toolkit";

export const actionTypes = {
  user: {
    SIGN_IN: 'SIGN_IN',
    TFA_STEP: 'TFA_STEP',
    SIGN_OUT: 'SIGN_OUT',
    REFRESH_ACCESS: 'REFRESH_ACCESS',
    FETCH_PROFILE: 'FETCH_PROFILE',
    SET_FETCH_PROFILE_ERROR: 'SET_FETCH_PROFILE_ERROR',
    PROCESS_SIGN_OUT: 'PROCESS_SIGN_OUT',
    SET_PROFILE: 'SET_PROFILE',
  },
  category: {
    SET_CATEGORIES: 'SET_CATEGORIES',
    FETCH_CATEGORIES: 'FETCH_CATEGORIES',
    SET_FETCH_CATEGORIES_ERROR: 'SET_FETCH_CATEGORIES_ERROR',
  },
  config: {
    FETCH_ALL: 'FETCH_ALL',
    SET_ALL: 'SET_ALL',
    SET_FETCH_ALL_ERROR: 'SET_FETCH_ALL_ERROR'
  },
  quotation: {
    SET_PENDING_COUNT: 'SET_PENDING_COUNT',
    DECREMENT_INTERNAL_PENDING_COUNT: 'DECREMENT_INTERNAL_PENDING_COUNT',
    DECREMENT_EXTERNAL_PENDING_COUNT: 'DECREMENT_EXTERNAL_PENDING_COUNT',
    SET_FETCH_PENDING_COUNT_ERROR: 'SET_FETCH_PENDING_COUNT_ERROR',
    FETCH_PENDING_COUNT: 'FETCH_PENDING_COUNT'
  }
};

export const actions = {
  user: {
    signIn: createAction(actionTypes.user.SIGN_IN),
    tfaStep: createAction(actionTypes.user.TFA_STEP),
    signOut: createAction(actionTypes.user.SIGN_OUT),
    fetchProfile: createAction(actionTypes.user.FETCH_PROFILE),
    setFetchProfileError: createAction<string>(actionTypes.user.SET_FETCH_PROFILE_ERROR),
    processSignOut: createAction(actionTypes.user.PROCESS_SIGN_OUT),
    setProfile: createAction<MeResponse>(actionTypes.user.SET_PROFILE)
  },
  category: {
    loadCategoriesAsync: createAction(actionTypes.category.FETCH_CATEGORIES),
    setCategories: createAction<CategoryMap>(actionTypes.category.SET_CATEGORIES),
    setFetchCategoriesError: createAction<string>(actionTypes.category.SET_FETCH_CATEGORIES_ERROR),
  },
  config: {
    fetchAll: createAction(actionTypes.config.FETCH_ALL),
    setAll: createAction<ConfigData>(actionTypes.config.SET_ALL),
    setFetchAllError: createAction<string>(actionTypes.config.SET_FETCH_ALL_ERROR)
  },
  quotation: {
    setPendingCount: createAction<Record<'internal' | 'external', number>>(actionTypes.quotation.SET_PENDING_COUNT),
    decrementInternalPendingCount: createAction(actionTypes.quotation.DECREMENT_INTERNAL_PENDING_COUNT),
    decrementExternalPendingCount: createAction(actionTypes.quotation.DECREMENT_EXTERNAL_PENDING_COUNT),
    setFetchPendingCountError: createAction<string>(actionTypes.quotation.SET_FETCH_PENDING_COUNT_ERROR),
    fetchPendingCount: createAction(actionTypes.quotation.FETCH_PENDING_COUNT)
  }
};
