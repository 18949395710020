import React from 'react';
import {QuotationItemProps} from "./quotation_item_props";
import {QuotationItem, QuotationItemStatuses} from "../../types/models/quotation_item";
import {formatMoney} from "../../utils/number";
import {isInternal} from "../../lib/quotation_item";

export default function QuotationItemResolvedInfoLineComponent(props: QuotationItemProps) {
  const {quotationItem} = props;
  if (quotationItem.status !== QuotationItemStatuses.resolved) {
    return null;
  }

  const isInternalQuotationItem = isInternal(quotationItem);

  const quotationItemCasted = quotationItem as QuotationItem<QuotationItemStatuses.resolved>;
  return <>
    {isInternalQuotationItem && (
      <>
        <dt className="col-auto">Pre-quoted Weight:</dt>
        <dd className="col">
          {quotationItemCasted.statusData.originalWeightInGrams}g
        </dd>
      </>
    )}

    <dt className="col-auto">Pre-quoted Price:</dt>
    {isInternalQuotationItem ? (
      <dd className="col">
        {formatMoney('SGD', quotationItemCasted.statusData.originalPrice['SG'])}
      </dd>
    ) : (
      <dd className="col">
        {formatMoney(quotationItemCasted.statusData.basePriceCurrency, quotationItemCasted.statusData.basePrice)}
      </dd>
    )}


    <dt className="col-auto">Quoted Weight:</dt>
    <dd className="col">
      {quotationItemCasted.statusData.weightInGrams}g
    </dd>

    <dt className="col-auto">Quoted Price:</dt>
    <dd className="col">
      {formatMoney('SGD', quotationItemCasted.statusData.quotedPrice['SG'])}
    </dd>
  </>;
}