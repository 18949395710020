import React from 'react';
import HeadTitle from "../../head_title";
import {
  InternalQuotationItem,
  QuotationItem,
  QuotationItemSources,
  QuotationItemStatuses,
  QuotationItemStatusFilters
} from "../../../types/models/quotation_item";
import Api from "../../../api/api";
import InternalQuotationItemComponent from "../../quotation_items/internal_quotation_item";
import {CartItemProductForOrder} from "../../../types/models/cart_item";
import {includes, keyBy} from "lodash";
import {queryStringify, useQueryString} from "../../../utils/route";
import {useHistory} from "react-router-dom";
import {getFirstOrItself} from "../../../utils/array";

/**
 * @interface QuotationItemsProductsAndWeights
 * @member {InternalQuotationItem[]} list of internal quotation items from api
 * @member {productById} a mapping from CartItemProductForOrder.uuid to a CartItemProductForOrder
 * @member {productWeightSource} a mapping from product id and variationPath to weight source label
 */
interface QuotationItemsProductsAndWeights {
  quotationItems: InternalQuotationItem[];
  productById: Record<string, CartItemProductForOrder>;
  productWeightSource: Record<string, string>;
}

function keyForProductVariationWeightSource(productId: string, variantPath: string | undefined) {
  if (variantPath) {
    return `${productId}___${variantPath}`;
  } else {
    return productId;
  }
}

export default function InternalQuotationItemsPage() {
  const [itemAndProducts, setItemsAndProducts] = React.useState<QuotationItemsProductsAndWeights>({
    quotationItems: [],
    productById: {},
    productWeightSource: {}
  });
  const [filterStatus, setFilterStatus] = React.useState<QuotationItemStatusFilters>('a');
  const [hasMore, setHasMore] = React.useState(true);

  const {quotationItems, productById, productWeightSource} = itemAndProducts;

  const query = useQueryString();
  const history = useHistory();

  const rawQueryStatus = query['status'];

  const populateProductWeightSource = React.useCallback(async (productById: Record<string, CartItemProductForOrder>) => {
    const productIds = Object.keys(productById);
    const categoryWeightResponse = await Api.productWeight.getCategoryWeightBulk(productIds);
    const overrideWeightResponse = await Api.productWeight.getOverrideWeightBulk(productIds);


    const productWeightSource: Record<string, string> = {};
    for (const productId in categoryWeightResponse.data.weights) {
      const weightSource = categoryWeightResponse.data.weights[productId].weightSource;
      productWeightSource[productId] = weightSource;

      const product = productById[productId];
      if (product.variantPaths) {
        for (const path in product.variantPaths) {
          const key = keyForProductVariationWeightSource(productId, path);
          productWeightSource[key] = weightSource;
        }
      }
    }


    for (const productId in overrideWeightResponse.data.weights) {
      const weightOverrides = overrideWeightResponse.data.weights[productId];
      for (const override of weightOverrides) {
        const key = keyForProductVariationWeightSource(productId, override.variantPath);
        productWeightSource[key] = 'Override';
      }
    }

    for (const productId in categoryWeightResponse.data.weights) {
      Object.assign(productById[productId], categoryWeightResponse.data.weights[productId]);
    }

    for (const productId in overrideWeightResponse.data.weights) {
      const product = productById[productId];
      const weightInfoList = overrideWeightResponse.data.weights[productId];
      for (const weightInfo of weightInfoList) {
        const {weight, weightUnit} = weightInfo;

        if (weightInfo.variantPath && weightInfo.variantPath in product.variantPaths) {
          product.variantPaths[weightInfo.variantPath].weight = weight;
          product.variantPaths[weightInfo.variantPath].weightUnit = weightUnit;
        } else {
          product.weight = weight;
          product.weightUnit = weightUnit;
        }
      }
    }

    return productWeightSource;
  }, []);

  React.useEffect(() => {
    let effectActive = true;
    const effectCleanup = () => {
      effectActive = false;
    };

    let selectedStatus: QuotationItemStatusFilters = 'a';
    const queryStatus = getFirstOrItself(query['status']);
    if (queryStatus && [...Object.values(QuotationItemStatuses), 'a'].includes(queryStatus)) {
      selectedStatus = queryStatus as QuotationItemStatusFilters;
    }
    setFilterStatus(selectedStatus);

    (async () => {
      try {
        const res = await Api.staff.quotationItem.getAll({
          status: selectedStatus, source: QuotationItemSources.internal
        });
        if (!effectActive) return;

        const newProductById = keyBy(res.data.products, 'uuid');
        const newProductWeightSource = await populateProductWeightSource(newProductById);

        setItemsAndProducts({
          quotationItems: res.data.quotationItems as InternalQuotationItem[],
          productById: newProductById,
          productWeightSource: newProductWeightSource
        });
        setHasMore(res.data.quotationItems.length !== 0);
      } catch (e) {
        console.error('failed to fetch quotation items', e);
      }

    })();


    return effectCleanup;
  }, [query, populateProductWeightSource]);

  const handleFilterStatusSelected: React.ChangeEventHandler<HTMLSelectElement> = React.useCallback((e) => {
    setFilterStatus(e.target.value as QuotationItemStatusFilters);

    if (e.target.value !== filterStatus) {
      const status = includes(Object.values(QuotationItemStatuses), e.target.value) ? e.target.value : 'a';
      const query = queryStringify({
        status
      });
      history.push(`/quotation_items/internal?${query}`);
    }
  }, [filterStatus, history]);

  const handleItemUpdated = React.useCallback((index: number) => (quotationItem: QuotationItem, product?: CartItemProductForOrder) => {
    const newQuotationItems = quotationItems.slice();
    newQuotationItems[index] = quotationItem as InternalQuotationItem;

    const newItemsAndProducts = {
      quotationItems: newQuotationItems,
      productById,
      productWeightSource
    };

    if (product) {
      newItemsAndProducts.productById = {
        ...productById,
        [product.uuid]: product
      };
      newItemsAndProducts.productWeightSource = {
        ...productWeightSource,
        [product.uuid]: 'Override'
      };
    }

    setItemsAndProducts(newItemsAndProducts);
  }, [productWeightSource, quotationItems, productById]);

  const handleFilter = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const query = queryStringify({
      status: filterStatus
    });
    if (filterStatus !== getFirstOrItself(rawQueryStatus)) {
      history.push(`/quotation_items/internal?${query}`);
    }
  }, [history, filterStatus, rawQueryStatus]);

  const handleLoadMore = React.useCallback(async () => {
    const { quotationItems } = itemAndProducts;
    if (!hasMore || quotationItems.length === 0) return;

    const beforeT = Math.min(...quotationItems.map(i => i.createdAt));
    const res = await Api.staff.quotationItem.getAll({
      status: filterStatus,
      source: QuotationItemSources.internal,
      beforeT: beforeT
    });
    const incomingQuotationItems = res.data.quotationItems;
    const incomingProducts = res.data.products;

    if (incomingQuotationItems.length > 0) {
      // append quotation items
      const newProductById = keyBy(incomingProducts, 'uuid');
      const newProductWeightSource = await populateProductWeightSource(newProductById);
      setItemsAndProducts({
        quotationItems: itemAndProducts.quotationItems.concat(incomingQuotationItems as InternalQuotationItem[]),
        productById: Object.assign({}, itemAndProducts.productById, newProductById),
        productWeightSource: Object.assign({}, itemAndProducts.productWeightSource, newProductWeightSource),
      });
    } else {
      setHasMore(false);
    }
  }, [hasMore, filterStatus, itemAndProducts, populateProductWeightSource]);


  return <>
    <HeadTitle
      title="Quotation Items"
    />

    <h1>Internal Quotation Items</h1>

    <h3>Filters</h3>
    <form className="form-inline mb-2" onSubmit={handleFilter}>
      <div className="form-group mr-2">
        <label className="mr-1" htmlFor="filter-status">Status</label>
        <select
          className="form-control"
          id="filter-status"
          value={filterStatus}
          onChange={handleFilterStatusSelected}
        >
          <option value="a">All</option>
          <option value="p">Pending</option>
          <option value="r">Resolved</option>
          <option value="d">Declined</option>
        </select>
      </div>
    </form>

    {quotationItems.map((quotationItem, index) => {
      const {productId, variantPath}= quotationItem.productSourceData;
      const weightSourceKey = keyForProductVariationWeightSource(productId, variantPath);
      const weightSource = productWeightSource[weightSourceKey] ?? productWeightSource[productId];

      return <InternalQuotationItemComponent
        key={index}
        quotationItem={quotationItem}
        product={productById[quotationItem.productSourceData.productId]}
        weightSource={weightSource}
        onResolveOrDecline={handleItemUpdated(index)}
      />;
    })}

    <button className={"btn btn-outline-dark btn-block"} disabled={!hasMore} onClick={handleLoadMore}>
      {hasMore ? 'Load More' : 'The End'}
    </button>
  </>;
}