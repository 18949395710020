import React from 'react';
import {Link} from 'react-router-dom';
import HeadTitle from '../head_title';
import {formatDateTimeFromSeconds} from '../../utils/date';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';
import TimeoutAlert from '../timeout_alert';
import {usePagination} from '../../lib/paginator';
import {ImagePage} from "../../api/models";

export default function ImagePages() {
  const [page, paginator, setTotalPages] = usePagination();
  const [imagePages, setImagePages] = React.useState<ImagePage[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    Api.admin.imagePage.getAll(page)
      .then(res => {
        setImagePages(res.data.imagePages);
        setTotalPages(res.data.totalPages);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  }, [setTotalPages, page]);

  const handleRemove = (index: number) => () => {
    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) {
      return;
    }

    const id = imagePages[index]._id;
    Api.admin.imagePage.remove(id)
      .then(_ => {
        const newImagePages = imagePages.slice();
        newImagePages.splice(index, 1);
        setImagePages(newImagePages);

        setSuccessMessage('Successfully removed image page');
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  };

  return <React.Fragment>
    <HeadTitle
      title="Image Pages"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />

    <h1>Image Pages</h1>
    <div className="mb-3">
      <Link to="/image_pages/new" className="btn btn-primary">New</Link>
    </div>

    {imagePages.map((imagePage: ImagePage, index: number) => {
      return (
        <div className="card mb-3" key={index}>
          <div className="card-body">
            <h5 className="card-title">{imagePage.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{formatDateTimeFromSeconds(imagePage.updatedAt)}</h6>

            <p className="card-text">
              <strong>Page Path:</strong>
              {' '}
              {imagePage.pagePath}
              <br/>

              <strong>Active:</strong>
              {' '}
              {imagePage.active.toString()}
              <br/>
            </p>


            <Link to={`/image_pages/${imagePage._id}/edit`} className="card-link btn btn-link">Edit</Link>
            <button className="card-link btn btn-link text-danger" onClick={handleRemove(index)}>Remove</button>
          </div>
        </div>
      );
    })}

    {paginator}
  </React.Fragment>;
}