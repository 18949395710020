import {put} from 'redux-saga/effects';
import errorCodeMessages from '../constants/localization/en/error_codes';
import {csFormat} from './string';
import {AxiosError} from "axios";
import {AuthError} from "../generic";

export function sagaGenericErrorHandling(error: any, errorActionFn: Function) {
  let message = 'unknown error';
  if (error.response && error.response.data && error.response.data.error) {
    message = error.response.data.error;
  } else if (error.message) {
    message = error.message;
  }
  return put(errorActionFn(message));
}

export function extractAxiosError(error: any) {
  if (error.response && error.response.data && error.response.data.error) {
    return error.response.data.error;
  } else {
    return error.message;
  }
}

export function extractAuthError(error: AxiosError<AuthError>) {
  if (error.response?.data?.errorCode) {
    const message: string = errorCodeMessages[error.response.data.errorCode];
    return csFormat(message, ...error.response.data.parameters);
  } else {
    return error.message;
  }
}