import React from 'react';
import {RemoveBtnContent} from "./item_action_buttons/remove_btn_content";
import EditBtnContent from "./item_action_buttons/edit_btn_content";
import {ProductVariationDiffListItem} from "../../types/responses/product_variation_diff";
import Api from "../../api/api";

export interface ProductWeightDiffItemActionButtonsProps {
    diffItem: ProductVariationDiffListItem;
    showEditBtn: boolean;
    onResolved(_id: string): void;
}

export default function ProductWeightDiffItemActionButtons({showEditBtn, diffItem, onResolved}: ProductWeightDiffItemActionButtonsProps) {
    const [isEditing, setEditing] = React.useState(false);
    const [isRemoving, setRemoving] = React.useState(false);
    const [isResolving, setIsResolving] = React.useState(false);

    const removeBtnHandler = React.useCallback(() => {
        if (isRemoving) {
            setRemoving(false);
        } else {
            setRemoving(true);
            setEditing(false);
        }
    }, [isRemoving]);

    const editBtnHandler = React.useCallback(() => {
        if (isEditing) {
            setEditing(false);
        } else {
            setEditing(true);
            setRemoving(false);
        }
    }, [isEditing]);

    const resolvedHandler = React.useCallback(() => {
        if (isResolving) return;
        setIsResolving(true);
        
        Api.staff.productVariationDiff.resolve(diffItem._id)
            .then(() => {
                onResolved(diffItem._id);
            })
            .catch(error => console.error('failed to resolve diff', error))
            .finally(() => setIsResolving(false));
    }, [isResolving, diffItem._id, onResolved]);

    return <>
        <dt>Actions to resolve</dt>
        <dd className={"text-right"}>
            {showEditBtn && (
                <button className="btn btn-primary" onClick={editBtnHandler} disabled={isResolving}>Edit weight override</button>
            )}
            <button className="btn btn-danger ml-2" onClick={removeBtnHandler} disabled={isResolving}>Remove weight override</button>
            <button className="btn btn-warning ml-2" onClick={resolvedHandler} disabled={isResolving}>Ignore</button>
        </dd>

        {isRemoving && (
            <RemoveBtnContent
                productId={diffItem.productId}
                onRemove={resolvedHandler}
                onCancel={removeBtnHandler}
            />
        )}
        {isEditing && (
            <EditBtnContent
                productId={diffItem.productId}
                variantPath={diffItem.path}
                onCancel={editBtnHandler}
                onWeightChange={resolvedHandler}
            />
        )}
    </>;
}