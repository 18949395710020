import React from 'react';
import {AiOutlineSend} from 'react-icons/ai';
import animateScrollTo from 'animated-scroll-to';
import styles from '../../styles/order_chat_window.module.scss';
import {formatDateTimeFromSeconds} from '../../utils/date';
import {genericTextChange} from '../../utils/forms';
import {Socket} from "socket.io-client";
import {ChatMessage} from "../../types/messaging/group";
import {useUser} from "../../hooks/user";
import {isBlank} from "../../utils/string";
import Api from '../../api/api';
import {actions} from "../../store/actions";
import {useDispatch} from "react-redux";
import {extractAuthError} from "../../utils/error";
import {CallbackFunctionVariadic} from "../../types/function";

interface ChatWindowProps {
  groupRef: string;
  socket: Socket | null | undefined;
  onErrorMessage: (errorMessage: string) => void;
  disabled?: boolean;
}

export default function ChatWindow({socket, groupRef, onErrorMessage, disabled}: ChatWindowProps) {
  const [chatMessages, setChatMessages] = React.useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = React.useState('');
  const [usernames, setUsernames] = React.useState<Record<string, Record<'firstName' | 'lastName' | 'uid', string>>>({});
  const [chatDisabled, setChatDisabled] = React.useState(true);

  const messagesEnd = React.useRef<HTMLDivElement>(null);
  const messagesContainer = React.useRef<HTMLDivElement | null>(null);
  const messageContainerObserver =  React.useRef<IntersectionObserver | null>(null);
  const scrollToIndexRef = React.useRef(-1);
  const messageElementRefs = React.useRef<Record<string, HTMLDivElement>>({});
  const usernameFetched = React.useRef<Set<string>>(new Set());
  const lastSeenTimingRef = React.useRef(0);
  const allMessagesFetchedRef = React.useRef(false);

  const dispatch = useDispatch();
  const user = useUser();
  let uid: string | undefined = undefined;
  if (user) {
    uid = user.uid;
  }

  React.useEffect(() => {
    if (disabled) {
      if (!chatDisabled) {
        setChatDisabled(true);
      }
    }
  }, [disabled, chatDisabled]);

  const fetchUserNames = React.useCallback((userIds: string[]) => {
    const userIdsToFetch = userIds
      .filter(userId => !usernameFetched.current.has(userId));
    if (userIdsToFetch.length === 0) {
      return;
    }

    Api.user.getNames(userIdsToFetch)
      .then(res => {
        for (const userId of userIdsToFetch) {
          usernameFetched.current.add(userId);
        }

        setUsernames({
          ...usernames,
          ...res.data.usernames
        });
      })
      .catch((error) => {
        const message = extractAuthError(error);
        onErrorMessage(message);
      });
  }, [usernames, onErrorMessage]);

  const refreshToken = React.useCallback(async (socket: Socket) => {
    try {
      await Api.auth.refreshAccessToken();
      socket.connect();
      return true;
    } catch (e) {
      dispatch(actions.user.processSignOut());
      return false;
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (!socket || !groupRef) {
      return;
    }

    let allMessagesListener: CallbackFunctionVariadic;
    let newMessageListener: CallbackFunctionVariadic;
    let disabledListener: CallbackFunctionVariadic;

    socket.on('allMessages', allMessagesListener = (gRef: string, messages: ChatMessage[], lastSeenTiming: number, groupDisabled: boolean) => {
      if (gRef !== groupRef) {
        return;
      }

      const unseenMessageIndex = messages.findIndex(message => message.createdAt >= lastSeenTiming);

      if (unseenMessageIndex === -1) {
        scrollToIndexRef.current = messages.length - 1;
      } else {
        scrollToIndexRef.current = unseenMessageIndex;
      }

      const userIdsFromMessages = messages.map(message => message.participant.uid);
      const userIds = Array.from(new Set(userIdsFromMessages));
      fetchUserNames(userIds);

      lastSeenTimingRef.current = lastSeenTiming;
      setChatMessages(messages);
      setChatDisabled(groupDisabled);
    });

    socket.on('newMessage', newMessageListener = (gRef: string, msg: ChatMessage) => {
      if (gRef !== groupRef) {
        return;
      }

      setChatMessages([...chatMessages, msg]);

      fetchUserNames([msg.participant.uid]);
      if (msg.participant.uid === uid && messagesEnd.current && messagesContainer.current) {
        animateScrollTo(messagesEnd.current, {
          elementToScroll: messagesContainer.current
        });
      }
    });

    socket.on('disabled', disabledListener = (gRef: string) => {
      if (gRef !== groupRef) {
        return;
      }

      setChatDisabled(true);
    });

    if (!allMessagesFetchedRef.current) {
      socket.emit('getAllMessages', groupRef);
      allMessagesFetchedRef.current = true;
    }

    return () => {
      socket.off('allMessages', allMessagesListener);
      socket.off('newMessage', newMessageListener);
      socket.off('disabled', disabledListener);
    };
  }, [socket, chatMessages, fetchUserNames, refreshToken, uid, groupRef]);

  const messagesContainerRefCB = React.useCallback((ref: HTMLDivElement) => {
    if (!ref) {
      return;
    }

    messagesContainer.current = ref;
    if (messageContainerObserver.current) {
      messageContainerObserver.current.disconnect();
    }
    const options = {
      root: ref,
      rootMargin: '0px',
      threshold: 0.9
    };
    const callback: IntersectionObserverCallback = (entries, observer) => {
      if (!socket) {
        return;
      }
      const lastSeenTiming = lastSeenTimingRef.current;

      entries.forEach(entry => {
        const createdAtStr = entry.target.getAttribute('data-created-at');
        let createdAt: number;
        if (!createdAtStr || (createdAt = parseInt(createdAtStr)) <= lastSeenTiming) {
          observer.unobserve(entry.target);
          return;
        }

        if (entry.isIntersecting && createdAt > lastSeenTiming) {
          lastSeenTimingRef.current = createdAt;
          socket.emit('seeMessage', groupRef, createdAt);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    Object.values(messageElementRefs.current).forEach(messageElement => {
      observer.observe(messageElement);
    });
    messageContainerObserver.current = observer;
  }, [socket, groupRef]);

  const messageRefCB = React.useCallback((ref: HTMLDivElement) => {
    if (!ref || !messagesContainer.current) {
      return;
    }

    const observer = messageContainerObserver.current;
    if (observer) {
      observer.observe(ref);
    }

    const indexStr = ref.getAttribute('data-index');
    if (!indexStr) {
      return;
    }

    if (scrollToIndexRef.current === parseInt(indexStr)) {
      scrollToIndexRef.current = -1;
      animateScrollTo(ref, {
        elementToScroll: messagesContainer.current
      });
    }
    messageElementRefs.current[indexStr] = ref;
  }, []);

  const handleSendMessage = React.useCallback(() => {
    if (!socket || !groupRef || isBlank(newMessage)) {
      return;
    }

    socket.emit('sendMessage', groupRef, newMessage);
    setNewMessage('');
  },  [socket, newMessage, groupRef]);

  const onTextAreaKeyPress: React.KeyboardEventHandler = React.useCallback((e) => {
    // When user hits the enter key
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  }, [handleSendMessage]);

  return <div className="card">
    <div className="card-header bg-primary p-0">
      <img src="/images/chat-person-01.svg" alt="illustration" className={styles.headerImg}/>
      <span className="font-weight-bold text-white">Support Chat</span>
    </div>
    <div ref={messagesContainerRefCB} className={`card-body ${styles.chatWindow}`}>
      {chatMessages.map((chatMessage, index) => {
        const isMe = chatMessage.participant.uid === uid;
        const dateStr = formatDateTimeFromSeconds(chatMessage.createdAt);
        const [date, time] = dateStr.split(',').map(s => s.trim());
        const message = chatMessage.message.split('\n').map((part, index) => {
          return <React.Fragment key={index}>
            {part}
            <br/>
          </React.Fragment>;
        });

        if (isMe) {
          return <div className="row mx-0 justify-content-end" key={index} data-created-at={chatMessage.createdAt} data-index={index} ref={messageRefCB}>
            <div className="col pr-0">
              <div className="font-weight-semibold text-right">
                {user ? `${user.firstName} ${user.lastName}` : chatMessage.participant.uid}
              </div>
              <div className="row justify-content-end flex-nowrap mx-0">
                <small className="d-block pr-0 col-auto text-muted align-self-end font-weight-semibold">
                  {date}
                  <br/>
                  {time}
                </small>
                <div className="col-auto flex-shrink-1 pr-0" style={{overflowWrap: 'anywhere'}}>
                  <div className="d-inline-block bg-dark text-white text-left font-weight-semibold rounded-lg p-3">
                    {message}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className={styles.avatar}>
                <img src="/images/chat-receiver.svg" />
              </div>
            </div>
          </div>;
        } else {
          let displayName = '(loading)';
          if (usernames[chatMessage.participant.uid]) {
            const username = usernames[chatMessage.participant.uid];
            displayName = `${username.firstName} ${username.lastName}`;
          }

          return <div className="row mx-0 justify-content-start" key={index} data-created-at={chatMessage.createdAt} data-index={index} ref={messageRefCB}>
            <div className="col-auto">
              <div className={styles.avatar}>
                <img src="/images/chat-sender.svg" />
              </div>
            </div>
            <div className="col pl-0">
              <div className="font-weight-semibold">
                {displayName}
              </div>
              <div className="row justify-content-start flex-nowrap mx-0">
                <div className="col-auto flex-shrink-1 pl-0" style={{overflowWrap: 'anywhere'}}>
                  <div className="d-inline-block bg-dark text-white font-weight-semibold rounded-lg p-3">
                    {message}
                  </div>
                </div>
                <small className="d-block pl-0 col-auto text-muted align-self-end font-weight-semibold">
                  {date}
                  <br/>
                  {time}
                </small>
              </div>
            </div>
          </div>;
        }
      })}
      <div ref={messagesEnd} className="message-end" />
    </div>

    <div className="card-footer">
      <div className="row">
        <div className="col">
              <textarea
                value={newMessage}
                onChange={genericTextChange(setNewMessage)}
                onKeyPress={onTextAreaKeyPress}
                className="form-control"
                disabled={disabled || chatDisabled}
              />
        </div>
        <div className="col-auto">
          <button className="btn btn-link" onClick={handleSendMessage} disabled={disabled || chatDisabled || newMessage.trim().length === 0}>
            <AiOutlineSend size={28} />
          </button>
        </div>
      </div>
    </div>
  </div>;
}
