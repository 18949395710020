import userErrorCodes from './user';
import authErrorCodes from './auth';
import addressErrorCodes from './address';
import generalErrorCodes from './general';

export default Object.assign(
  {},
  userErrorCodes,
  authErrorCodes,
  addressErrorCodes,
  generalErrorCodes
);