import {epochTime} from '../../utils/date';
import {actions} from '../actions';
import {CategoryMap} from "../../api/models";
import {createReducer} from "@reduxjs/toolkit";

const {setCategories, setFetchCategoriesError} = actions.category;

export interface CategoryState {
  data?: CategoryMap;
  updatedAt: number;
  fetchCategoriesError?: string;
}

const defaultState: CategoryState = {
  data: undefined,
  updatedAt: 0,
  fetchCategoriesError: undefined,
};

const categoryReducer = createReducer(defaultState, builder => {
  builder
    .addCase(setCategories, (state, action) => {
      state.data = action.payload;
      state.updatedAt = epochTime();
    })
    .addCase(setFetchCategoriesError, (state, action) => {
      state.fetchCategoriesError = action.payload;
    });
});

export default categoryReducer;