// https://stackoverflow.com/a/4025958
export function arraysEqual(arr1: Array<any>, arr2: Array<any>): boolean {
  if (arr1.length !== arr2.length) return false;
  for (let i = arr1.length; i--;) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export function splitIntoChunks(
  array: Array<any> | string,
  chunkSize: number
): Array<any> {
  let i, j;
  const result = [];
  for (i = 0, j = array.length; i < j; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export function getFirstOrItself<T>(itemOrItems: T | T[]): T {
  if (Array.isArray(itemOrItems)) {
    return itemOrItems[0];
  } else {
    return itemOrItems;
  }
}