import React from 'react';
import {useAppCurrency} from "../../../lib/config";
import {convertDineroAmtToFloatStr} from "../../../utils/number";

interface MoneyConfigValueProps {
  configValue: number
}

export default function MoneyConfigValue(props: MoneyConfigValueProps) {
  const appCurrency = useAppCurrency();

  return <React.Fragment>
    {appCurrency ? `${appCurrency} ${convertDineroAmtToFloatStr(props.configValue, appCurrency)}` : 'Loading...'}
  </React.Fragment>;
}