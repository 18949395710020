import React from 'react';
import {useParams} from 'react-router-dom';
import EditImagePage, {ImagePageExtended} from '../edit_image_page';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';
import {ImagePage} from "../../api/models";

interface EditImageQueryParams {
  id: string;
}

export default function EditImagePagePage() {
  const [imagePage, setImagePage] = React.useState<ImagePage | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const params = useParams<EditImageQueryParams>();

  const {id} = params;

  React.useEffect(() => {
    Api.admin.imagePage.get(id)
      .then(res => {
        setImagePage(res.data.imagePage);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  }, [id]);

  return <>
    <HeadTitle
      title="Edit Image Page"
    />
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <h1>Edit Image Page</h1>
    <EditImagePage
      onError={setErrorMessage}
      imagePage={imagePage as ImagePageExtended}
        />
        </>;
      };