import React from 'react';
import HeadTitle from "../../head_title";
import {
  ExternalQuotationItem,
  QuotationItem,
  QuotationItemSources,
  QuotationItemStatuses,
  QuotationItemStatusFilters
} from "../../../types/models/quotation_item";
import Api from "../../../api/api";
import ExternalQuotationItemComponent from "../../quotation_items/external_quotation_item";
import {includes, orderBy} from "lodash";
import {queryStringify, useQueryString} from "../../../utils/route";
import {useHistory} from "react-router-dom";
import {getFirstOrItself} from "../../../utils/array";
import {GroupInfo} from "../../../types/responses/messaging";
import useMessagingSocket from "../../../hooks/use_messaging_socket";
import TimeoutAlert from '../../timeout_alert';
import {CallbackFunctionVariadic} from "../../../types/function";
import {epochTime} from "../../../utils/date";

function getChatGroupRef(quotationRef: string) {
  return `external_quotation_${quotationRef}`;
}

export default function ExternalQuotationItemsPage() {
  const [dataQuotationItems, setDataQuotationItems] = React.useState<QuotationItem[]>([]);
  const [quotationItems, setQuotationItems] = React.useState<QuotationItem[]>([]);
  const [chatInfos, setChatInfos] = React.useState<Record<string, GroupInfo>>({});
  const [filterStatus, setFilterStatus] = React.useState<QuotationItemStatusFilters>('a');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [hasMore, setHasMore] = React.useState(true);

  const messagingErrorCallback = React.useCallback((message: string) => setErrorMessage(message), []);

  const socket = useMessagingSocket(messagingErrorCallback);

  const query = useQueryString();
  const history = useHistory();

  const rawQueryStatus = query['status'];
  const rawQueryInactive = query['inactive'];

  const sortQuotationItems = React.useCallback((quotationItems: QuotationItem[], chatInfos: Record<string, GroupInfo>) => {
    const chatInfoEntries = Object.entries(chatInfos);
    if (chatInfoEntries.length > 0) {
      return orderBy(quotationItems, [(item) => {
        const groupRef = getChatGroupRef(item.referenceNumber);
        const groupInfo = chatInfos[groupRef];
        if (groupInfo && groupInfo.lastMessageTime) {
          return groupInfo.lastMessageTime;
        } else {
          return 0;
        }
      }, 'createdAt'], ['desc', 'desc']);
    } else {
      return quotationItems;
    }
  }, []);

  // fetch chat group info
  React.useEffect(() => {
    if (!socket) {
      return;
    }

    socket.off('groupsInfo');
    socket.once('groupsInfo', async (groupsInfo: Record<string, GroupInfo>) => {
      setChatInfos(groupsInfo);
    });

    socket.emit('getGroupsInfo');
  }, [socket]);

  React.useEffect(() => {
    let effectActive = true;
    const effectCleanup = () => {
      effectActive = false;
    };

    let selectedStatus: QuotationItemStatusFilters = 'a';
    const queryStatus = getFirstOrItself(rawQueryStatus);
    if (queryStatus && [...Object.values(QuotationItemStatuses), 'a'].includes(queryStatus)) {
      selectedStatus = queryStatus as QuotationItemStatusFilters;
    }
    setFilterStatus(selectedStatus);

    (async () => {
      try {
        const res = await Api.staff.quotationItem.getAll({
          status: selectedStatus,
          source: QuotationItemSources.external
        });
        let {quotationItems} = res.data;
        if (effectActive) {
          // set quotation items
          setDataQuotationItems(quotationItems);
        }
      } catch (e) {
        console.error('failed to fetch quotation items', e);
      }
    })();

    return effectCleanup;
  }, [history, sortQuotationItems, rawQueryStatus, rawQueryInactive]);

  React.useEffect(() => {
    if (!dataQuotationItems || !chatInfos) return;

    const result = sortQuotationItems(dataQuotationItems, chatInfos);
    setQuotationItems(result);
  }, [dataQuotationItems, chatInfos, sortQuotationItems]);

  React.useEffect(() => {
    if (!socket || quotationItems.length === 0) {
      return;
    }

    let newMessageListener: CallbackFunctionVariadic;
    socket.on('newMessage', newMessageListener = (gRef: string) => {
      const prefix = 'external_quotation_';
      if (!gRef.startsWith(prefix)) {
        return;
      }
      const chatInfo = chatInfos[gRef];
      setChatInfos({
        ...chatInfos,
        [gRef]: {
          ...chatInfo,
          unseenMessagesCount: chatInfo.unseenMessagesCount + 1,
          lastMessageTime: epochTime()
        }
      });
    });

    return () => {
      socket.off('newMessage', newMessageListener);
    };
  }, [socket, quotationItems, chatInfos]);

  const handleFilterStatusSelected: React.ChangeEventHandler<HTMLSelectElement> = React.useCallback((e) => {
    setFilterStatus(e.target.value as QuotationItemStatusFilters);

    if (e.target.value !== filterStatus) {
      const status = includes(Object.values(QuotationItemStatuses), e.target.value) ? e.target.value : 'a';
      const query = queryStringify({
        status,
        inactive: rawQueryInactive
      });
      history.push(`/quotation_items/external?${query}`);
    }
  }, [filterStatus, history, rawQueryInactive]);

  const handleItemUpdated = React.useCallback((index: number) => (quotationItem: QuotationItem) => {
    const newQuotationItems = quotationItems.slice();
    newQuotationItems[index] = quotationItem;

    setQuotationItems(newQuotationItems);
  }, [quotationItems]);

  const handleFilter = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const query = queryStringify({
      status: filterStatus
    });
    if (filterStatus !== getFirstOrItself(rawQueryStatus)) {
      history.push(`/quotation_items/external?${query}`);
    }
  }, [history, filterStatus, rawQueryStatus]);

  const handleMoreLoad = React.useCallback(async () => {
    if (!hasMore || dataQuotationItems.length === 0) return;

    const beforeT = Math.min(...dataQuotationItems.map(i => i.createdAt));
    const res = await Api.staff.quotationItem.getAll({
      status: filterStatus,
      source: QuotationItemSources.external,
      beforeT: beforeT
    });
    const incomingQuotationItems = res.data.quotationItems;

    if (incomingQuotationItems.length > 0) {
      // append quotation items
      setDataQuotationItems(dataQuotationItems.concat(incomingQuotationItems));
    } else {
      setHasMore(false);
    }
  }, [hasMore, filterStatus, dataQuotationItems]);

  return <>
    <HeadTitle
      title="Quotation Items"
    />

    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <h1>External Quotation Items</h1>

    <h3>Filters</h3>
    <form className="form-inline mb-2" onSubmit={handleFilter}>
      <div className="form-group mr-2">
        <label className="mr-1" htmlFor="filter-status">Status</label>
        <select
          className="form-control"
          id="filter-status"
          value={filterStatus}
          onChange={handleFilterStatusSelected}
        >
          <option value="a">All</option>
          <option value="p">Pending</option>
          <option value="r">Resolved</option>
          <option value="d">Declined</option>
        </select>
      </div>
    </form>

    {quotationItems.map((quotationItem, index) => {
      let unreadMessageCount: number | undefined = undefined;
      const chatGroupRef = getChatGroupRef(quotationItem.referenceNumber);
      if (chatInfos[chatGroupRef]) {
        unreadMessageCount = chatInfos[chatGroupRef].unseenMessagesCount;
      }
      return <ExternalQuotationItemComponent
        key={quotationItem.referenceNumber}
        quotationItem={quotationItem as ExternalQuotationItem}
        onResolveOrDecline={handleItemUpdated(index)}
        unreadMessageCount={unreadMessageCount}
        socket={socket}
        linksToSinglePage
      />;
    })}

    <button className={"btn btn-outline-dark btn-block"} disabled={!hasMore} onClick={handleMoreLoad}>
      {hasMore ? 'Load More' : 'The End'}
    </button>
  </>;
}