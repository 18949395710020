import {Product} from "../types/models/product";
import {AnyOrder, OrderV1, OrderV2, OrderV3} from "../types/models/order";
import {keyBy} from "lodash";
import {CartItemProductForOrder, CartItemProductForOrderV1} from "../types/models/cart_item";

interface ProductByIdMapV1 {
  version: 1;
  productById: ProductByIdV1;
}

interface ProductByIdMapV2 {
  version: 2;
  productById: ProductByIdV2;
}

interface ProductByIdMapV3 {
  version: 3;
  productById: Record<string, Product>;
}

export type ProductByIdMap<T extends AnyOrder = AnyOrder> = T extends OrderV1 ? ProductByIdMapV1 :
  T extends OrderV2 ? ProductByIdMapV2 :
    T extends OrderV3 ? ProductByIdMapV3 : never;

type ProductByIdV1 = Record<string, CartItemProductForOrderV1>;
type ProductByIdV2 = Record<string, CartItemProductForOrder>;

export function getProductByIdMap<T extends AnyOrder>(order: T): ProductByIdMap<T>  {
  if (order.version === 1) {
    return {
      version: 1,
      productById: order.cartItems.reduce((result, ci) => {
        result[ci.productId] = ci.product;
        return result;
      }, {} as ProductByIdV1)
    } as ProductByIdMap<T>;
  } else if (order.version === 2) {
    return {
      version: 2,
      productById: order.cartItems.reduce((result, ci) => {
        result[ci.productId] = ci.product;
        return result;
      }, {} as ProductByIdV2)
    } as ProductByIdMap<T>;
  } else if (order.version === 3) {
    return {
      version: 3,
      productById: keyBy(order.productCaptures, 'uuid')
    } as ProductByIdMap<T>;
  }

  throw new Error('invalid order version');
}