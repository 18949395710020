import {call, delay, put, fork, takeLatest, select} from 'redux-saga/effects';
import {actions, actionTypes} from "../actions";
import {AxiosResponse} from "axios";
import Api from "../../api/api";
import {sagaGenericErrorHandling} from "../../utils/error";
import {StaffPendingQuotationCountResponse} from "../../types/responses/quotation_item";
import {RootState} from "../store";

function* fetchPendingCount() {
  try {
    const response: AxiosResponse<StaffPendingQuotationCountResponse> = yield call(Api.staff.quotationItem.getPendingCount);
    const {internal, external} = response.data;
    yield put(actions.quotation.setPendingCount({
      internal,
      external
    }));
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.quotation.setFetchPendingCountError);
  }
}

export function* fetchPendingCountPeriodically() {
  yield fork(function*() {
    let isFirstLoop = true;
    while (true) {
      const loggedIn = yield select((state: RootState) => state.user.signedIn && state.user.tfaPassed);
      if (isFirstLoop || loggedIn) {
        yield fetchPendingCount();
      }
      yield delay(60000);
      isFirstLoop = false;
    }
  });
  yield takeLatest(actionTypes.quotation.FETCH_PENDING_COUNT, fetchPendingCount);
}