import {Button, Modal} from "react-bootstrap";
import React from "react";
import {AnyOrder, OrderRefund} from "../../types/models/order";
import {formatMoney} from "../../utils/number";

interface CapturePaymentDialogProps {
    order: AnyOrder;
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
}

export default function CapturePaymentDialog({show, onConfirm, onHide, order}: CapturePaymentDialogProps) {
    const [isDisabled, setIsDisabled] = React.useState(false);
    React.useEffect(() => {
        // when order is updated enable buttons
        if (order) {
            setIsDisabled(false);
        }
    }, [order]);

    const onConfirmClickHandler = React.useCallback(() => {
        setIsDisabled(true);
        onConfirm();
    }, [onConfirm]);

    const refund: OrderRefund | undefined = React.useMemo(() => {
        if (!Array.isArray(order.preCaptureRefunds) || order.preCaptureRefunds.length === 0) {
            return undefined;
        }

        const refund = order.preCaptureRefunds[order.preCaptureRefunds.length - 1];
        if (refund.amount === 0) {
            return undefined;
        } else {
            return refund;
        }
    }, [order.preCaptureRefunds]);

    return <Modal
        scrollable={true}
        show={show}
        onHide={onHide}
        animation={false}>
        <Modal.Header closeButton>
            <Modal.Title>Capture Payment?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                Please confirm the amount below before capturing from the authorized amount.
            </div>

            <table className="table">
                <thead>
                <tr>
                    <th>Item</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {!!refund && (
                    <tr>
                        <td>Refund #{refund.refNumber}</td>
                        <td>{formatMoney(order.totalCost.currency, refund.amount)}</td>
                    </tr>
                )}
                <tr>
                    <td>Order Amount</td>
                    <td>{formatMoney(order.totalCost.currency, order.totalCost.amount)}</td>
                </tr>
                </tbody>
            </table>

            <div>
                <strong>Take note:</strong>
                <ol>
                    <li>After capturing, there will be additional charges to refund.</li>
                </ol>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={onConfirmClickHandler} disabled={isDisabled}>
                Save
            </Button>
            <Button variant="secondary" onClick={onHide} disabled={isDisabled}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>;
}