import React from 'react';
import {formatAddress} from '../constants/address_fields';
import {UserAddress} from "../types/models/user_address";

interface AddressViewProps {
  address: UserAddress;
}

export default function AddressView(props: AddressViewProps) {
  return <address>
    {formatAddress(props.address).map((line, index) => {
      return <React.Fragment key={index}>
        {line}
        <br/>
      </React.Fragment>;
    })}
  </address>;
}