import React from 'react';
import {useAppCurrency} from '../../../lib/config';
import {generateStep} from '../../../utils/forms';
import currencyPrecision from '../../../constants/currency_precision';
import {ConfigEditorProps} from "../../../generic";
import {convertDineroAmtToFloatStr, convertToDineroAmt} from "../../../utils/number";

export default function MoneyField(props: ConfigEditorProps<number>) {
  const {configKey, configValue, onDone, onCancel} = props;

  const appCurrency = useAppCurrency();
  let fieldStep = '.01';
  if (appCurrency) {
    fieldStep = generateStep(currencyPrecision[appCurrency]);
  }

  const [money, setMoney] = React.useState('0');
  React.useEffect(() => {
    if (!appCurrency) {
      return;
    }

    const value = convertDineroAmtToFloatStr(configValue, appCurrency);
    setMoney(value);
  }, [configValue, appCurrency]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onDone(convertToDineroAmt(money, appCurrency));
  };

  return <form className="form-inline" onSubmit={handleSubmit}>
    <div className="form-group">
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text">{appCurrency}</span>
        </div>
        <input
          type="number"
          className="form-control"
          placeholder={configKey}
          aria-label={configKey}
          aria-describedby={`set ${configKey}`}
          min="0"
          step={fieldStep}
          value={money}
          onChange={(e) => setMoney(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary mb-3">Save</button>
      <button type="button" className="btn btn-secondary mb-3" onClick={onCancel as React.MouseEventHandler<HTMLElement>}>Cancel</button>
    </div>
  </form>;
}