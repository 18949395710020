import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../store/actions';
import {APP_CURRENCY_CONFIG_KEY, ConfigKeysType} from '../constants/config_keys';
import {RootState} from "../store/reducers/reducers";
import {Currency} from "dinero.js";

export function useConfig(configKey: ConfigKeysType) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.config.fetchAll());
  }, [dispatch]);

  return useSelector((state: RootState) => {
    if (!state.config.data) {
      return undefined;
    }
    return state.config.data[configKey];
  });
}

export function useAppCurrency() {
  return useConfig(APP_CURRENCY_CONFIG_KEY) as Currency;
}