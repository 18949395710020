// order statuses
export const ORDER_DISPUTING = 'ORDER_DISPUTING'; // customer opens the order for dispute
export const ORDER_CONFIRMED = 'ORDER_CONFIRMED'; // customer approves, payment happens here
export const ORDER_PAID = 'ORDER_PAID'; // payment has been received
export const ORDER_PURCHASED = 'ORDER_PURCHASED'; // order has been procured
export const ORDER_AT_WAREHOUSE = 'ORDER_AT_WAREHOUSE'; // order has arrived at indonesia warehouse
export const ORDER_IN_TRANSIT = 'ORDER_IN_TRANSIT'; // order is in transit out of indonesia, update shipping status field
export const ORDER_RECEIVED = 'ORDER_RECEIVED'; // order has reached customer's hands
export const ORDER_CANCELLED = 'ORDER_CANCELLED'; // order has reached customer's hands

export const ORDER_STATUSES_LIST = [
  ORDER_DISPUTING,
  ORDER_CONFIRMED,
  ORDER_PAID,
  ORDER_PURCHASED,
  ORDER_AT_WAREHOUSE,
  ORDER_IN_TRANSIT,
  ORDER_RECEIVED,
  ORDER_CANCELLED,
];

export const STATUS_WITHOUT_SUPPORT_CHAT = [
  ORDER_CONFIRMED,
  ORDER_CANCELLED
];