import {put, takeLatest} from 'redux-saga/effects';
import {actions, actionTypes} from '../actions';
import {sagaGenericErrorHandling} from '../../utils/error';
import Api from '../../api/api';
import {AxiosResponse} from "axios";

function* fetchConfigs() {
  try {
    let response: AxiosResponse<any>;
    response = yield Api.config.getAll();
    yield put(actions.config.setAll(response.data));
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.config.setFetchAllError);
  }
}

export function* watchFetchConfig() {
  yield takeLatest(actionTypes.config.FETCH_ALL, fetchConfigs);
}