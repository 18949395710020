import React from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
    authorized: boolean;
}

export default function PrivateRoute({authorized, children, ...rest}: PrivateRouteProps) {
  // const dispatch = useDispatch();
  // const {path} = rest;

  return (
    <Route
      {...rest}
      render={({location}) =>
        authorized ? (
          children
        ) : (
          <Redirect
            to={`/login?login_path=${location.pathname}`}
          />
        )
      }
    />
  );
}