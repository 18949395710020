export enum QuotationItemStatuses {
  pending= 'p',
  resolved='r',
  declined='d'
}

export enum QuotationItemSources {
  internal='i',
  external='e'
}

interface StatusesData {
  [QuotationItemStatuses.pending]: undefined;
  [QuotationItemStatuses.resolved]: {
    originalWeightInGrams: number;
    weightInGrams: number;
    originalPrice: Record<string, number>;
    quotedPrice: Record<string, number>;
    basePrice: number;
    basePriceCurrency: string;
    closedAt: number;
  };
  [QuotationItemStatuses.declined]: {
    declineReason: string;
    closedAt: number;
  }
}

interface SourcesData {
  [QuotationItemSources.internal]: {
    productId: string;
    variantPath?: string;
  };
  [QuotationItemSources.external]: {
    link: string;
  };
}

export interface QuotationItem<Status extends QuotationItemStatuses = QuotationItemStatuses, Source extends QuotationItemSources = QuotationItemSources> {
  _id: string;
  referenceNumber: string;
  name: string;
  mainImage: string;
  status: Status;
  statusData: StatusesData[Status];
  productSource: Source;
  productSourceData: SourcesData[Source];
  quantity: number;
  uid: string;
  instructions: string;
  updatedAt: number;
  createdAt: number;
}

export type QuotationItemUpdate = Partial<Pick<QuotationItem, 'quantity' | 'instructions'>>;

export type QuotationItemStatusFilters = QuotationItemStatuses | 'a';
export type QuotationItemSourceFilters = QuotationItemSources | 'a';

export type InternalQuotationItem = QuotationItem<QuotationItemStatuses, QuotationItemSources.internal>;
export type PendingInternalQuotationItem = QuotationItem<QuotationItemStatuses.pending, QuotationItemSources.internal>;
export type ResolvedInternalQuotationItem = QuotationItem<QuotationItemStatuses.resolved, QuotationItemSources.internal>;
export type DeclinedInternalQuotationItem = QuotationItem<QuotationItemStatuses.declined, QuotationItemSources.internal>;

export type ExternalQuotationItem = QuotationItem<QuotationItemStatuses, QuotationItemSources.external>;
export type PendingExternalQuotationItem = QuotationItem<QuotationItemStatuses.pending, QuotationItemSources.external>;
export type ResolvedExternalQuotationItem = QuotationItem<QuotationItemStatuses.resolved, QuotationItemSources.external>;
export type DeclinedExternalQuotationItem = QuotationItem<QuotationItemStatuses.declined, QuotationItemSources.external>;