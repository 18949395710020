import React from 'react';
import Dinero from 'dinero.js';
import Api from '../api/api';
import {extractAxiosError} from '../utils/error';
import {formatDinero} from '../utils/number';
import {DisputeInfo} from "../api/response_models";

interface AdjustProductWeightOverridesProps {
  orderRef: string;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
  onChange?: Function;
}


export default function AdjustProductWeightOverrides({
  orderRef,
  onSuccess,
  onError,
  onChange
}: AdjustProductWeightOverridesProps) {
  const [productWeightOverrides, setProductWeightOverrides] = React.useState<DisputeInfo.ProductWeightOverrideMap>({});
  const [weightValues, setWeightValues] = React.useState<string[]>([]);
  const [adjustingWeight, setAdjustingWeight] = React.useState<boolean[]>([]);
  const [disputableItems, setDisputableItems] = React.useState<DisputeInfo.DisputableItem[]>([]);
  const [overseasShippingCost, setOverseasShippingCost] = React.useState<Dinero.Dinero | undefined>();

  React.useEffect(() => {
    Api.staff.order.getDisputeInfo(orderRef)
      .then(res => {
        const {
          disputableItems,
          overseasShippingCost,
          productWeightOverrides
        } = res.data;

        setDisputableItems(disputableItems);
        if (overseasShippingCost) {
          setOverseasShippingCost(Dinero(overseasShippingCost));
        }

        setProductWeightOverrides(productWeightOverrides);

        const weightValues = [];
        for (const cartItem of disputableItems) {
          const override = productWeightOverrides[cartItem.productId];
          if (override) {
            weightValues.push(override.weight);
            continue;
          }

          // when there is no override
          // convert to kg
          if (cartItem.originalWeightUnit === 'g') {
            weightValues.push(cartItem.originalWeight / 1000);
          } else {
            weightValues.push(cartItem.originalWeight);
          }
        }
        setWeightValues(weightValues.map(value => value.toString()));

        const newAdjusting = new Array(disputableItems.length);
        newAdjusting.fill(false);
        setAdjustingWeight(newAdjusting);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        onError(errorMessage);
      });
  }, [onError, orderRef]);

  React.useEffect(() => {
    if (!onChange) return;

    onChange(disputableItems, productWeightOverrides);
  }, [onChange, productWeightOverrides, disputableItems]);

  const handleAdjustWeight = React.useCallback((index: number) => () => {
    const newAdjusting = adjustingWeight.slice();
    newAdjusting[index] = !newAdjusting[index];
    setAdjustingWeight(newAdjusting);
  }, [adjustingWeight]);

  const handleWeightChange = React.useCallback((index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWeight = e.target.value;
    if (Number.isNaN(newWeight)) {
      return;
    }

    const newWeightValues = weightValues.slice();
    newWeightValues[index] = newWeight;
    newWeightValues[index] = newWeight;
    setWeightValues(newWeightValues);
  }, [weightValues]);

  const validateWeight = React.useCallback((index: number) => {
    if (Number.isNaN(weightValues[index])) {
      return 'weight is not a number';
    }
    const weight = parseFloat(weightValues[index]);
    if (weight <= 0) {
      return 'weight cannot be less than or equal to 0';
    }

    return null;
  }, [weightValues]);

  const handleWeightSave = React.useCallback((index: number) => () => {
    const cartItem: { name: string, productId: string } = disputableItems[index];
    const hasError = validateWeight(index);
    if (hasError) {
      onError(`product ${cartItem.name}, ${hasError}`);
      return;
    }

    const weight = parseFloat(weightValues[index]);
      Api.staff.productWeightOverride.set({
          productId: cartItem.productId,
          weight
      })
      .then(res => {
        onSuccess('Successfully set weight override for product ' + cartItem.name);

        const newAdjusting = adjustingWeight.slice();
        newAdjusting[index] = false;
        setAdjustingWeight(newAdjusting);

        const newProductWeightOverrides = Object.assign({}, productWeightOverrides, {
          [cartItem.productId]: res.data.override
        });
        setProductWeightOverrides(newProductWeightOverrides);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        onError(`Failed to set weight override for product ${cartItem.name}, ${errorMessage}`);
      });
  }, [disputableItems, weightValues, adjustingWeight, productWeightOverrides, onSuccess, onError, validateWeight]);

  return <>
    {overseasShippingCost && (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Original Overseas Shipping Cost</h5>
          <h6 className="card-subtitle mb-2 text-muted">{formatDinero(overseasShippingCost)}</h6>
        </div>
      </div>
    )}

    {disputableItems.map((ci, index) => {
      return (
        <div className="card" key={index}>
          <div className="card-body">
            <h5 className="card-title mb-2">{ci.name}</h5>
            <div>
              <strong>Item Weight:</strong>
              {' '}
              {ci.weight}kg
            </div>

            <div>
              <strong>Override Item Weight:</strong>
              {' '}
              {productWeightOverrides[ci.productId] ? productWeightOverrides[ci.productId].weight : '-'}kg
            </div>

            <div>
              <strong>Disputable:</strong>
              {' '}
              {ci.isDisputable ? 'Yes' : 'No'}
            </div>

            <button className="btn btn-link card-link p-0" onClick={handleAdjustWeight(index)}>
              Adjust Weight
            </button>

            {adjustingWeight[index] && (
              <>
                <hr/>

                <div className="form-group">
                  <label>Adjusted Weight</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{
                      maxWidth: '100px',
                    }}
                    value={weightValues[index] || ''}
                    onChange={handleWeightChange(index)}
                  />
                </div>

                <button className="btn btn-primary" onClick={handleWeightSave(index)}>
                  Save
                </button>
                <div className="text-danger">{validateWeight(index)}</div>
              </>
            )}
          </div>
        </div>
      );
    })}
  </>;
}