import React from 'react';
import HeadTitle from '../head_title';

const LANDING_PAGE_DESCRIPTION = `
Custom Restricted Products

Magnets 
Perfume 
Batteries (Automotive, electronic gadgets using batteries) Firearms 
Sharp weapons (Knife, Sword, etc) 
Explosives 
Flammable goods 
Radioactive goods 
Drugs, Weed, Bio-Chemicals 
Poisonous goods 
Pornography 
Cigarettes 
Pork based food 
Liquid based goods 
Wet and fresh food (Fruits and Vegetables, Raw meat) An i ma I based goods (Leather bag made from snake, etc) 2 bottles of supplements per AWB 

Contact Info: 
Rayspeed: 
Adminl: (+62) 811-3225-352 
Admin2: (+62) 811-1475-351
Ricky: (+62) 811-167-827 
Muara Karang: (+62) 811-1109-436 

Choir Express: 
Reza: (+62) 811-8396-673 
Cinta Marketing: (+62) 821-1482-7360 
Choir Express Admin: (+62) 813-1722-9308 
`;

function Home() {
  return <>
    <HeadTitle
      title="Home"
    />

    <h1>Home</h1>
    <img className="img-fluid" src={'/landing_page.jpg'} alt={LANDING_PAGE_DESCRIPTION} />
  </>;
}

export default Home;