import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import arrayMove from 'array-move';
import HeadTitle from '../head_title';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import {GrDrag} from 'react-icons/gr';
import {formatDateTimeFromSeconds} from '../../utils/date';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';
import TimeoutAlert from '../timeout_alert';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

export interface Banner {
  _id?: number;
  name?: string;
  link?: string;
  updatedAt?: number;
  active?: boolean;
  position?: number;
  imagePath?: string;
  image?: string;
}

const DragHandle = SortableHandle(() => (
  <OverlayTrigger
    placement="left"
    overlay={<Tooltip id="id">Drag this to change position of banner</Tooltip>}
  >
    <GrDrag size="48"/>
  </OverlayTrigger>
));

const SortableItem = SortableElement(({value, onRemove}: { value: Banner, onRemove: () => void }) => {
  const banner = value;
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h5 className="card-title">{banner.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{formatDateTimeFromSeconds(banner.updatedAt as number)}</h6>

            <p className="card-text">
              <strong>Name:</strong>
              {' '}
              {banner.name}
              <br/>

              <strong>Link:</strong>
              {' '}
              {banner.link}
              <br/>

              <strong>Active:</strong>
              {' '}
              {(banner.active as boolean).toString()}
              <br/>

              <strong>Position:</strong>
              {' '}
              {(banner.position as number).toString()}
              <br/>
            </p>

            <Link to={`/banners/${banner._id}/edit`} className="card-link btn btn-link">Edit</Link>
            <button className="card-link text-danger btn btn-link"
               onClick={onRemove}>Remove</button>
          </div>
          <div className="col-auto align-self-center">
            <DragHandle/>
          </div>
        </div>
      </div>
    </div>
  );
});

const CustomSortableContainer = SortableContainer(({children}: { children: ReactNode }) => {
  return <div>
    {children}
  </div>;
});

export default function Banners() {
  const [banners, setBanners] = React.useState<Banner[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    Api.admin.banner.getAll()
      .then(res => {
        setBanners(res.data.banners);
        setIsLoading(false);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  }, []);

  const handleRemove = (index: number) => () => {
    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) {
      return;
    }

    const id = banners[index]._id as number;
    Api.admin.banner.remove(id)
      .then(() => {
        const newBanners = banners.slice();
        newBanners.splice(index, 1);
        setBanners(newBanners);

        setSuccessMessage('Successfully removed banner');
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  };

  const onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }) => {
    if (oldIndex === newIndex) {
      return;
    }

    const newBanners = arrayMove(banners, oldIndex, newIndex);
    setBanners(newBanners);

    Api.admin.banner.move(oldIndex, newIndex)
      .then(() => {
        setSuccessMessage('Successfully moved banner');
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  };

  return <>
    <HeadTitle
      title="Banners"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />

    <h1>Banners</h1>
    <div className="mb-3">
      <Link to="/banners/new" className="btn btn-primary">New</Link>
    </div>

    {isLoading && (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )}

    {(!isLoading && banners.length === 0) && (
      <p>No banners yet.</p>
    )}
    <CustomSortableContainer onSortEnd={onSortEnd} useDragHandle>
      {banners.map((banner, index) => (
        <SortableItem
          key={index}
          index={index}
          value={banner}
          onRemove={handleRemove(index)}/>
      ))}
    </CustomSortableContainer>
  </>;
}