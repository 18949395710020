import React from 'react';
import {useLocation, Link} from 'react-router-dom';
import {useQuery} from '../utils/route';

interface PageNavigationProps{
  page: number;
  totalPages: number;
}

export default function PageNavigation(props: PageNavigationProps) {
  const {page, totalPages} = props;
  const location = useLocation();
  const query = useQuery();

  const nextPageQuery = new URLSearchParams(query);
  nextPageQuery.set('page', (page + 1).toString());
  const prevPageQuery = new URLSearchParams(query);
  prevPageQuery.set('page', (page - 1).toString());
  const totalPageQuery = new URLSearchParams(query);
  totalPageQuery.set('page', totalPages.toString());

  if (totalPages === 0) {
    return null;
  } else {
    return <nav aria-label="Page navigation">
      <ul className="pagination">
        {page === 1 ? (
          <li className="page-item disabled">
            <span className="page-link">Previous</span>
          </li>
        ) : (
          <li className="page-item">
            <Link className="page-link" to={`${location.pathname}?${prevPageQuery.toString()}`}>Previous</Link>
          </li>
        )}
        <li className="page-item active">
          <span className="page-link">{page}</span>
        </li>
        {totalPages === page ? (
          <li className="page-item disabled">
            <span className="page-link">Next</span>
          </li>
        ) : (
          <React.Fragment>
            <li className="page-item">
              <span className="page-link">...</span>
            </li>
            <li className="page-item">
              <Link className="page-link" to={`${location.pathname}?${totalPageQuery.toString()}`}>{totalPages}</Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to={`${location.pathname}?${nextPageQuery.toString()}`}>Next</Link>
            </li>
          </React.Fragment>
        )}
      </ul>
    </nav>;
  }
}