import {QuotationItem, QuotationItemSources, QuotationItemStatuses} from "../types/models/quotation_item";
import {Product} from "../api/models";
import {VariantInfoProps} from "../components/variant_info";

export function isInternal(item: QuotationItem): item is QuotationItem<QuotationItemStatuses, QuotationItemSources.internal> {
  return item.productSource === 'i';
}

export function getQuotationItemSourceLabel(quotationItem: QuotationItem, product: Pick<Product, 'sourceMarketPlace' | 'merchant'>) {
  if (quotationItem.productSource === QuotationItemSources.external) {
    return 'QUOTATION';
  }

  let sourceLabel = product.sourceMarketPlace;
  if (sourceLabel === 'local' && product.merchant) {
    sourceLabel = product.merchant.name;
  }
  return sourceLabel;
}

export function getQuotationItemVariantInfo(quotationItem: QuotationItem, product?: Pick<Product, 'variants'>): VariantInfoProps | undefined {
  if (product && isInternal(quotationItem)) {
    return {
      variants: product.variants,
      variantPath: quotationItem.productSourceData.variantPath
    };
  }

  return undefined;
}