import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {ModalProps} from "../generic";
import {CategorySetting} from "../api/models";

type CategoryColumn = keyof CategorySetting;
const COLUMN_INDEX_TO_NAME: CategoryColumn[] = [
  'categorySource',
  'category',
  'weight',
  'weightUnit',
  'volumeMetricWeight',
  'volumeMetricWeightUnit',
  'isBlocked',
  'isQuotation',
  'isDisputable',
  'isEqualPrecedence'
];

interface EditCategorySettingModalProps extends ModalProps{
  categorySetting: CategorySetting;
}

type CategorySettingFields = Record<keyof CategorySetting, string>;
const defaultFields: CategorySettingFields = {
  categorySource: '',
  category: '',
  weight: '0',
  weightUnit: 'g',
  volumeMetricWeight: '0',
  volumeMetricWeightUnit: 'g',
  isBlocked: 'N',
  isQuotation: 'N',
  isDisputable: 'N',
  isEqualPrecedence: 'N'
};

export default function EditCategorySettingModal({show, onHide, onSave, categorySetting}: EditCategorySettingModalProps) {
  const [fields, setFields] = React.useState(defaultFields);

  const handleChangeFactory = (columnName: keyof CategorySettingFields) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFields({
      ...fields,
      [columnName]: e.target.value
    });
  };

  React.useEffect(() => {
    if (!categorySetting) return;

    const newFields: CategorySettingFields = {};
    for (const name of COLUMN_INDEX_TO_NAME) {
      newFields[name] = categorySetting[name];
    }
    setFields(newFields);
  }, [categorySetting]);

  const handleSave = () => {
    const categorySetting = {
      ...fields,
      weight: parseFloat(fields.weight),
      volumeMetricWeight: parseFloat(fields.volumeMetricWeight)
    };

    onSave(categorySetting);
  };

  return <Modal scrollable={true} size="xl" show={show} onHide={onHide} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Edit Category Setting</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="form-group">
        <label>Category Source</label>
        <select
          className="form-control"
          value={fields.categorySource}
          onChange={handleChangeFactory('categorySource')}
        >
          <option value="tokopedia">Tokopedia</option>
          <option value="local">Local</option>
        </select>
      </div>
      <div className="form-group">
        <label>Category</label>
        <input
          type="text"
          className="form-control"
          value={fields.category}
          onChange={handleChangeFactory('category')}
        />
      </div>
      <div className="form-group">
        <label>Weight</label>
        <input
          type="number"
          className="form-control"
          value={fields.weight}
          onChange={handleChangeFactory('weight')}
        />
      </div>
      <div className="form-group">
        <label>Weight Unit</label>
        <select
          className="form-control"
          value={fields.weightUnit}
          onChange={handleChangeFactory('weightUnit')}
        >
          <option value="g">Grams</option>
          <option value="kg">Kilograms</option>
        </select>
      </div>
      <div className="form-group">
        <label>Volume Metric Weight</label>
        <input
          type="number"
          className="form-control"
          value={fields.volumeMetricWeight}
          onChange={handleChangeFactory('volumeMetricWeight')}
        />
      </div>
      <div className="form-group">
        <label>Volumne Metric Weight Unit</label>
        <select
          className="form-control"
          value={fields.volumeMetricWeightUnit}
          onChange={handleChangeFactory('volumeMetricWeightUnit')}
        >
          <option value="g">Grams</option>
          <option value="kg">Kilograms</option>
        </select>
      </div>
      <div className="form-group">
        <label>Blocked?</label>
        <select
          className="form-control"
          value={fields.isBlocked}
          onChange={handleChangeFactory('isBlocked')}
        >
          <option value="Y">Yes</option>
          <option value="N">No</option>
        </select>
      </div>
      <div className="form-group">
        <label>Quotation?</label>
        <select
          className="form-control"
          value={fields.isQuotation}
          onChange={handleChangeFactory('isQuotation')}
        >
          <option value="Y">Yes</option>
          <option value="N">No</option>
        </select>
      </div>
      <div className="form-group">
        <label>Disputable?</label>
        <select
          className="form-control"
          value={fields.isDisputable}
          onChange={handleChangeFactory('isDisputable')}
        >
          <option value="Y">Yes</option>
          <option value="N">No</option>
        </select>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleSave}>
        Confirm
      </Button>
      <Button variant="secondary" onClick={onHide as React.MouseEventHandler}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;
}