import React from 'react';
import {toTitleCase} from "../utils/string";

interface TimeoutAlertProps {
  alertColor?: string;
  timeoutDuration?: number;
  onHide: () => void;
  errorMessage?: null | string;
}

export default function TimeoutAlert({alertColor, timeoutDuration, onHide, errorMessage}: TimeoutAlertProps) {
  React.useEffect(() => {
    if (!errorMessage) {
      return;
    }
    const timeoutId = setTimeout(onHide, timeoutDuration);
    return () => clearTimeout(timeoutId);
  }, [onHide, errorMessage, timeoutDuration]);

  const className = `alert alert-${alertColor}`;

  return <>
    {errorMessage !== null && (
      <div className={className} role="alert">
        {toTitleCase(errorMessage!)}
      </div>
    )}
  </>;
}

TimeoutAlert.defaultProps = {
  alertColor: 'danger',
  timeoutDuration: 3000,
};