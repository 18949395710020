import {useSelector} from 'react-redux';
import PrivateRoute from './private_route';
import {RootState} from "../store/reducers/reducers";
import React from "react";
import {RouteProps} from "react-router-dom";

export default function TfaPassedRoute(props: RouteProps) {
  const authorized = useSelector((state: RootState) => state.user.signedIn && state.user.tfaPassed);
  return <PrivateRoute
    authorized={authorized}
    {...props}
  />;
}