import {combineReducers} from 'redux';
import userReducer from './user';
import categoryReducer from './category';
import {persistReducer} from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import configReducer from './config';
import quotationReducer from './quotation';

const categoryPersistConfig = {
  key: 'category',
  storage: localStorage
};

const rootReducer = combineReducers({
  user: userReducer,
  category: persistReducer(categoryPersistConfig, categoryReducer),
  config: configReducer,
  quotation: quotationReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;