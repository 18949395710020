import React from 'react';
import {ConfigEditorProps} from "../../../generic";

export default function PercentageField(props: ConfigEditorProps<number>) {
  const {configKey, configValue, onDone, onCancel} = props;

  const [percentage, setPercentage] = React.useState(configValue.toString());

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onDone(parseFloat(percentage));
  };

  return <form className="form-inline" onSubmit={handleSubmit}>
    <div className="form-group">
      <div className="input-group mb-3">
        <input
          type="number"
          className="form-control"
          placeholder={configKey}
          aria-label={configKey}
          aria-describedby={`set ${configKey}`}
          min="0"
          step=".01"
          value={percentage}
          onChange={(e) => setPercentage(e.target.value)}
          required
        />
        <div className="input-group-append">
          <span className="input-group-text">%</span>
        </div>
      </div>
    </div>
    <button type="submit" className="btn btn-primary mb-3">Save</button>
    <button type="button" className="btn btn-secondary mb-3" onClick={onCancel as React.MouseEventHandler<HTMLElement>}>Cancel</button>
  </form>;
}