import React from 'react';
import { Button, Modal } from "react-bootstrap";
import { ExternalQuotationItem } from "../../types/models/quotation_item";
import { checkFloatForError, checkIntForError, checkStringForError } from "../../lib/validation";
import { InputFieldWithValidation } from "../fields_with_validation";
import TimeoutAlert from "../timeout_alert";
import { convertToDineroAmt, formatMoney } from "../../utils/number";
import { throttle } from "lodash";
import Api from "../../api/api";
import { extractAxiosError } from "../../utils/error";
import CurrencySelect from "../currency_select";
import { genericTextChange } from "../../utils/forms";
import { Currency } from "dinero.js";

export interface ResolveExternalQuotationItemDialogCloseData {
  weightInGrams: number;
  basePrice: number;
  basePriceCurrency: string;
  productName?: string;
}

interface ResolveExternalQuotationItemDialogProps {
  show: boolean;
  onHide: (data?: ResolveExternalQuotationItemDialogCloseData) => void;
  quotationItem: ExternalQuotationItem;
}

interface ResolveExternalQuotationItemDialogErrors {
  updatedWeight?: string[];
  basePrice?: string[];
  newProductName?: string[];
}

export default function ResolveExternalQuotationItemDialog(props: ResolveExternalQuotationItemDialogProps) {
  const { show, onHide, quotationItem } = props;

  const [updatedWeight, setUpdatedWeight] = React.useState('100');
  const [errors, setErrors] = React.useState<ResolveExternalQuotationItemDialogErrors>({});
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [quotedPricePreview, setQuotedPricePreview] = React.useState('-');
  const [basePriceCurrency, setBasePriceCurrency] = React.useState('IDR');
  const [basePrice, setBasePrice] = React.useState('1');
  const [newProductName, setNewProductName] = React.useState(props.quotationItem.name);

  const validate = React.useCallback((shouldSetErrors = true) => {
    const errors: Required<ResolveExternalQuotationItemDialogErrors> = {
      updatedWeight: checkIntForError(updatedWeight, {
        attrName: 'updated weight',
        gt: 99
      }),
      basePrice: checkFloatForError(basePrice, {
        attrName: 'base price',
        gt: 0
      }),
      newProductName: checkStringForError(newProductName, {
        attrName: 'product name',
        minLength: 1
      })
    };
    if (shouldSetErrors) {
      setErrors(errors);
    }

    return Object.values(errors).every(i => i.length === 0);
  }, [updatedWeight, basePrice, newProductName]);

  const fetchPricePreview = React.useMemo(() => {
    return throttle(() => {
      if (!show) {
        return;
      }

      const isValid = validate(false);
      if (!isValid) {
        return;
      }

      const weightInGrams = parseInt(updatedWeight);
      const basePriceAmt = convertToDineroAmt(basePrice, basePriceCurrency as Currency);
      Api.staff.quotationItem.previewExternalProductPrice(quotationItem._id, weightInGrams, basePriceCurrency, basePriceAmt)
        .then(res => {
          setQuotedPricePreview(formatMoney('SGD', res.data.productPrice['SG']));
        })
        .catch(err => {
          setErrorMessage(extractAxiosError(err));
        });
    }, 1000);
  }, [show, validate, quotationItem, updatedWeight, basePrice, basePriceCurrency]);


  React.useEffect(() => {
    fetchPricePreview();
  }, [fetchPricePreview]);

  const handleResolve = React.useCallback(() => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    console.log(newProductName);
    const basePriceAmt = convertToDineroAmt(basePrice, basePriceCurrency as Currency);
    
    onHide({
      weightInGrams: parseInt(updatedWeight),
      basePrice: basePriceAmt,
      basePriceCurrency,
      productName: newProductName
    });
  }, [validate, onHide, updatedWeight, basePrice, basePriceCurrency, newProductName]);

  const handleCancel = React.useCallback(() => {
    onHide();
  }, [onHide]);

  const clearErrorMessage = React.useCallback(() => {
    setErrorMessage(null);
  }, []);

  return <Modal
    scrollable={true}
    show={show}
    onHide={onHide}
    animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Resolve Quotation Item</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <TimeoutAlert
        errorMessage={errorMessage}
        onHide={clearErrorMessage}
      />

      <div className="form-group">
        <label>External Link</label>
        <a
          href={quotationItem.productSourceData.link}
          target="_blank"
          rel="noreferrer"
        >{quotationItem.productSourceData.link}</a>
      </div>

      <div className="form-group">
        <label>Product name</label>
        <input
          type="text"
          readOnly
          className="form-control-plaintext"
          value={quotationItem.name}
        />
      </div>

      <div className="form-group">
        <label>Rename product</label>
        <InputFieldWithValidation
          type="text"
          className="form-control"
          value={newProductName}
          onChange={genericTextChange(setNewProductName)}
          errors={errors.newProductName}
        />
      </div>

      <div className="form-group">
        <label>External Product Price (include domestic shipping)</label>

        <CurrencySelect
          className="form-control"
          value={basePriceCurrency}
          onChange={genericTextChange(setBasePriceCurrency)}
        />

        <InputFieldWithValidation
          type="number"
          className="form-control"
          errors={errors.basePrice}
          value={basePrice}
          onChange={genericTextChange(setBasePrice)}
        />
      </div>

      <div className="form-group">
        <label>Updated Product Weight In Grams</label>
        <InputFieldWithValidation
          type="number"
          step={1}
          min={100}
          className="form-control"
          value={updatedWeight}
          onChange={genericTextChange(setUpdatedWeight)}
          errors={errors.updatedWeight}
        />
      </div>

      <div className="form-group">
        <label>Quoted Price</label>
        <input type="text" readOnly className="form-control-plaintext" value={quotedPricePreview} />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleResolve}>
        Resolve
      </Button>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;

}