import React from 'react';
import {ConfigEditorProps} from "../../../generic";

export default function PositiveInteger(props: ConfigEditorProps<number>) {
  const {configKey, configValue, onDone, onCancel} = props;

  const [integer, setInteger] = React.useState(configValue.toString());

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onDone(parseInt(integer));
  };

  return <form className="form-inline" onSubmit={handleSubmit}>
    <div className="form-group">
      <input
        type="number"
        className="form-control"
        placeholder={configKey}
        aria-label={configKey}
        aria-describedby={`set ${configKey}`}
        min="0"
        step="1"
        value={integer}
        onChange={(e) => setInteger(e.target.value)}
        required
      />
      <button type="submit" className="btn btn-primary mb-3">Save</button>
      <button type="button" className="btn btn-secondary mb-3" onClick={onCancel as React.MouseEventHandler<HTMLElement>}>Cancel</button>
    </div>
  </form>;
}