import React from 'react';
import {Link} from 'react-router-dom';

export default function QuotationItemsPage() {
  return <>
    <h1 className="mb-2">Browser Quotations</h1>
    <div className="d-flex">
      <Link to="/quotation_items/external" className="btn btn-lg btn-outline-dark flex-grow-1 mr-2">External</Link>
      <Link to="/quotation_items/internal" className="btn btn-lg btn-outline-dark flex-grow-1">Internal</Link>
    </div>
  </>;
}