import React from 'react';
import Helmet from 'react-helmet';

interface HeadTitleProps {
  title: string;
  children?: React.ReactNode;
}

export default function HeadTitle({title, children}: HeadTitleProps) {
  let finalTitle = 'Indo4ward';
  if (title) {
    finalTitle = `${title} | Indo4ward`;
  }

  return <Helmet>
    <title>{finalTitle}</title>
    {children}
  </Helmet>;
}