import React from 'react';
import {Link} from 'react-router-dom';
import {Region} from '../../api/models';
import HeadTitle from "../head_title";
import AddressFields from '../../constants/address_fields';
import {Badge} from "react-bootstrap";
import Api from "../../api/api";
import TimeoutAlert from "../timeout_alert";
import {extractAxiosError} from "../../utils/error";

export default function RegionsPage() {
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [regions, setRegions] = React.useState<Region[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    Api.admin.region.getAll()
      .then(res => {
        setRegions(res.data.regions);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleRemove = React.useCallback((index) => {
    const region = regions[index];

    return () => {
      Api.admin.region.remove(region.country)
        .then(() => {
          const newRegions = regions.slice();
          newRegions.splice(index, 1);
          setRegions(newRegions);

          setSuccessMessage('Successfully removed region');
        })
        .catch(error => {
          setErrorMessage(extractAxiosError(error));
        });
    };
  }, [regions]);

  return <>
    <HeadTitle
      title="Regions"
    />

    <TimeoutAlert
      errorMessage={successMessage}
      onHide={() => setSuccessMessage(null)}
    />

    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <h2>Regions</h2>

    <Link className="btn btn-primary" to="/regions/new">
      New Region
    </Link>

    {isLoading && (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )}

    {!isLoading && regions.length === 0 && (
      <p>No Regions</p>
    )}

    {!isLoading && regions.length > 0 && (
      regions.map((region, index) => {
        const country = AddressFields.countries[region.country];
        return (
          <div className="card mb-3" key={index}>
            <div className="card-body">
              <h5 className="card-title">{country.displayValue}</h5>
              <div>
                {region.enabled ? (
                  <Badge variant="success">Enabled</Badge>
                ) : (
                  <Badge variant="danger">Disabled</Badge>
                )}
              </div>

              <strong>Enabled States</strong>
              <div>
                {region.enabledStates.map((stateCode, index) => {
                  const state = country.states[stateCode];
                  return (
                    <Badge variant="dark" key={index}>{state.displayValue}</Badge>
                  );
                })}
              </div>


              <Link to={`/regions/${region.country}/shipping_price_tiers`} className="card-link">
                Shipping Price Tiers
              </Link>
              <Link to={`/regions/${region.country}/edit`} className="card-link">
                Edit
              </Link>
              <button className="card-link text-danger btn btn-link" onClick={handleRemove(index)}>Remove</button>
            </div>
          </div>
        );
      })
    )}
  </>;
}