import React from 'react';
import {Variant} from "../api/models";

export interface VariantInfoProps {
  variants: Variant[];
  variantPath?: string;
}

export default function VariantInfo(props: VariantInfoProps) {
  const {variants, variantPath} = props;

  if (!variantPath) {
    return null;
  }

  const steps = variantPath.split('/');

  if (steps.length !== variants.length) {
    return null;
  }

  const variantChoices = [];
  for (let i = 0; i < steps.length; i++) {
    const variant = variants[i];
    const step = Number.parseInt(steps[i]);
    variantChoices.push(
      <div key={i}>
        <strong>{variant.name}</strong>:
        {' '}
        {variant.options[step].value}
      </div>
    );
  }
  return <React.Fragment>
    {variantChoices}
  </React.Fragment>;
}
