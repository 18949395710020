import React from 'react';
import Api from "../../../api/api";
import {CountryTax} from "../../../types/models/country_tax";
import HeadTitle from "../../head_title";
import countriesAndStates from "../../../constants/address_fields";
import {Link} from "react-router-dom";
import {extractAxiosError} from "../../../utils/error";
import TimeoutAlert from "../../timeout_alert";
import {formatMoney} from "../../../utils/number";

export default function CountryTaxesPage() {
    const [countryTaxes, setCountryTaxes] = React.useState<CountryTax[] | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    React.useEffect(() => {
        Api.admin.countryTaxes.getAll()
            .then((res) => {
                setCountryTaxes(res.data.countryTaxes);
            });
    }, []);

    const removeCountryTax = React.useCallback((id: string) => () => {
        if (!countryTaxes) return;

        const index = countryTaxes.findIndex(ct => ct._id === id);
        Api.admin.countryTaxes.remove(id)
            .then(() => {
                const newCountryTaxes = countryTaxes.slice();
                newCountryTaxes.splice(index, 1);

                setCountryTaxes(newCountryTaxes);
            })
            .catch((err) => {
                const errorMessage = extractAxiosError(err);
                setErrorMessage(errorMessage);
            });
    }, [countryTaxes]);

    let content: React.ReactNode;
    if (!countryTaxes) {
        content = <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
        </div>;
    } else if (countryTaxes.length === 0) {
        content = <div className="text-center">
            There are no country taxes configured.
        </div>;
    } else {
        content = <>
            {countryTaxes.map(countryTax => (
                <div key={countryTax.country}>
                    <h5>{countriesAndStates.countries[countryTax.country].displayValue}</h5>
                    <dl>
                        <dt>Percentage</dt>
                        <dd>{countryTax.percentage}%</dd>

                        <dt>Threshold</dt>
                        <dd>{formatMoney(countryTax.thresholdCurrency, countryTax.threshold)}</dd>

                        <dt>Currency</dt>
                        <dd>{countryTax.thresholdCurrency}</dd>

                        <dt>Label</dt>
                        <dd>{countryTax.label || 'NA'}</dd>
                    </dl>

                    <div className={"text-right"}>
                        <Link className={"btn btn-primary mr-2"} to={`/country_taxes/${countryTax._id}/edit`}>
                            Edit
                        </Link>

                        <button className={"btn btn-danger"} onClick={removeCountryTax(countryTax._id)}>Delete</button>
                    </div>
                    <hr/>
                </div>
            ))}
        </>;
    }


    return <>
        <HeadTitle
            title="Country Taxes"
        />

        <TimeoutAlert
            errorMessage={errorMessage}
            onHide={() => setErrorMessage(null)}
        />

        <h1>Country Taxes</h1>
        <div className="mb-2">
            <Link className="btn btn-primary" to="/country_taxes/new">New Country Tax</Link>
        </div>
        {content}
    </>;
}