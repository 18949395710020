import React from "react";
import {io, Socket} from "socket.io-client";
import {MessagingError, MessagingExtendedError} from "../types/messaging/error";
import {actions} from "../store/actions";
import {useDispatch} from "react-redux";
import Api from "../api/api";
import {CallbackFunctionVariadic} from "../types/function";

export default function useMessagingSocket(onErrorMessage: (message: string) => void) {
  const [socket, setSocket] = React.useState<Socket | null>(null);
  const socketRef = React.useRef<Socket>();
  const dispatch = useDispatch();

  const refreshToken = React.useCallback(async (socket: Socket) => {
    try {
      await Api.auth.refreshAccessToken();
      socket.connect();
      return true;
    } catch (e) {
      dispatch(actions.user.processSignOut());
      return false;
    }
  }, [dispatch]);

  React.useEffect(() => {
    return () => {
      const currentSocket = socketRef.current;
      if (currentSocket) {
        currentSocket.disconnect();
      }
    };
  }, [socketRef]);

  React.useEffect(() => {
    const socket =  io(process.env.REACT_APP_MESSAGING_SERVICE_BASE_URL || `ws://localhost:3007/`, {
      withCredentials: true
    });

    socket.on('connect_error', (err)  => {
      const error = err as MessagingExtendedError;
      if (error.data && error.data.code === 'invalid_token') {
        refreshToken(socket)
          .then(() => {
            const currentSocket = socketRef.current;
            if (currentSocket) {
              currentSocket.disconnect();
            }
            socketRef.current = socket;
            setSocket(socket);
          });
        return;
      }

      console.error(err);
      onErrorMessage('Unable to connect to messaging service');
    });

    const currentSocket = socketRef.current;
    if (currentSocket) {
      currentSocket.disconnect();
    }
    socketRef.current = socket;
    setSocket(socket);

    let errorListener: CallbackFunctionVariadic;

    socket.on('error', errorListener = (error: MessagingError) => {
      if (error.code && error.code === 'invalid_token') {
        socket.disconnect();
        refreshToken(socket)
          .then((reconnected) => {
            if (reconnected && error.lastEvent) {
              const [eventName, ...args] = error.lastEvent;
              socket.emit(eventName, ...args);
            }
          });
      }
      console.error('messaging_error', error);
    });

    return () => {
      socket.off('error', errorListener);
    };
  }, [onErrorMessage, refreshToken]);


  return socket;
}