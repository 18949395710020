import React from "react";
import classNames from "classnames";
import { GoAlert } from "react-icons/go";
import {Button, Modal} from "react-bootstrap";
import {
    convertDineroAmtToFloat,
    convertDineroAmtToFloatStr,
    convertToDineroAmt,
    formatDinero,
    formatMoney
} from "../../utils/number";
import {OrderRefund, OrderV3} from "../../types/models/order";
import {ProductByIdMap} from "../../lib/order";
import VariantInfo, {VariantInfoProps} from "../variant_info";
import {getQuotationItemVariantInfo, isInternal} from "../../lib/quotation_item";
import Dinero from "dinero.js";
import {InputFieldWithValidation, TextAreaWithValidation} from "../fields_with_validation";
import {checkFloatForError, checkStringForError} from "../../lib/validation";
import styles from '../../styles/refund_dialog.module.scss';
import {GetOrderRefundable, OrderRefundLineItemExtra, RefundOrderResponse} from "../../types/responses/order";
import Api from "../../api/api";
import {keyBy, matchesProperty} from "lodash";
import TimeoutAlert from "../timeout_alert";
import {extractAxiosError} from "../../utils/error";
import {OTHER_LINE_ITEM_LABELS} from "../../constants/order_refund";

interface RefundDialogProps {
    order: OrderV3;
    products: ProductByIdMap<OrderV3>;
    show: boolean;
    onHide: () => void;
    onConfirm: (refundRes: RefundOrderResponse) => void;
    refundable: GetOrderRefundable | null;
}
enum NoteType {
    NONE,
    IMMEDIATE_PARTIAL_REFUND,
    IMMEDIATE_FULL_REFUND,
    DEFERRED_PARTIAL_REFUND
}

interface LineItemInput {
    lineItemEx: OrderRefundLineItemExtra;
    checked: boolean;
    amountStr: string;
    amountErrors: string[];
}

interface DescriptionField {
    value: string;
    errors?: string[];
}

function keyFor(item: {type: string, id?: string}): string {
    if (item.id) {
        return `${item.type},${item.id}`;
    } else {
        return item.type;
    }
}


export default function RefundDialog({show, onHide, order, products, refundable, onConfirm}: RefundDialogProps) {
    const [lineItemInputs, setLineItemInputs] = React.useState<Record<string, LineItemInput> | null>(null);
    const [descriptionField, setDescriptionField] = React.useState<DescriptionField>({
        value: ''
    });
    const [submitting, setSubmitting] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (!refundable) return;

        const newLineItemInputs: Record<string, LineItemInput> = {};

        if (!refundable.isCaptured && Array.isArray(order.preCaptureRefunds) && order.preCaptureRefunds.length > 0) {
            const refund = order.preCaptureRefunds[order.preCaptureRefunds.length - 1];
            const existingLineItemsMap = keyBy(refund.lineItems, item => keyFor(item));

            for (const lineItemEx of refundable.lineItems) {
                const {lineItem} = lineItemEx;
                const existingLineItem = existingLineItemsMap[keyFor(lineItem)];
                const checked = !!existingLineItem;
                let amount = lineItemEx.refundableAmount;
                if (existingLineItem) {
                    amount -= existingLineItem.amount;
                }
                newLineItemInputs[keyFor(lineItem)] = {
                    lineItemEx,
                    amountStr: convertDineroAmtToFloatStr(amount, order.totalCost.currency),
                    amountErrors: [],
                    checked
                };
            }
        } else {
            for (const lineItemEx of refundable.lineItems) {
                const {lineItem} = lineItemEx;
                newLineItemInputs[keyFor(lineItem)] = {
                    lineItemEx,
                    amountStr: convertDineroAmtToFloatStr(lineItemEx.refundableAmount, order.totalCost.currency),
                    amountErrors: [],
                    checked: false
                };
            }
        }

        setLineItemInputs(newLineItemInputs);
    }, [refundable, order]);

    const lineItemCheckHandlerFactory = React.useCallback<(lineItem: OrderRefund.LineItem) => React.ChangeEventHandler<HTMLInputElement>>(
        (lineItem: OrderRefund.LineItem) => {
            if (!lineItemInputs) return () => {};

            const key = keyFor(lineItem);
            return (e) => {
                setLineItemInputs({
                    ...lineItemInputs,
                    [key]: {
                        ...lineItemInputs[key],
                        checked: e.target.checked
                    }
                });
            };
        }, [lineItemInputs]);

    const lineItemAmtChangeHandlerFactory = React.useCallback<(lineItem: OrderRefund.LineItem) => React.ChangeEventHandler<HTMLInputElement>>(
        (lineItem: OrderRefund.LineItem) => {
            if (!lineItemInputs) return () => {};

            const key = keyFor(lineItem);
            return (e) => {
                const amtErrors = checkFloatForError(e.target.value, {
                    attrName: 'amt',
                    lte: convertDineroAmtToFloat(lineItemInputs[key].lineItemEx.refundableAmount, order.totalCost.currency),
                    gte: 0
                });
                setLineItemInputs({
                    ...lineItemInputs,
                    [key]: {
                        ...lineItemInputs[key],
                        amountStr: e.target.value,
                        amountErrors: amtErrors
                    }
                });
            };
        }, [lineItemInputs, order]);

    const checkDescriptionForError = React.useCallback((value: string) => {
        return checkStringForError(value, {
            required: true,
            attrName: 'description',
            minLength: 1
        });
    }, []);

    const descriptionChangeHandler = React.useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((e) => {
        setDescriptionField({
            value: e.target.value,
            errors: checkDescriptionForError(e.target.value)
        });
    }, [checkDescriptionForError]);

    const selectedRefund: Dinero.Dinero = React.useMemo(() => {
        const zero = Dinero({
            ...order.totalCost,
            amount: 0
        });
        if (!lineItemInputs) {
            return zero;
        }

        return Object.values(lineItemInputs)
            .filter(input => input.checked)
            .map<Dinero.Dinero>(input => {
                let newAmount: number;
                try {
                    newAmount = convertToDineroAmt(input.amountStr, order.totalCost.currency);
                } catch (e) {
                    newAmount = input.lineItemEx.refundableAmount;
                }

                const refundingAmount = input.lineItemEx.refundableAmount - newAmount;
                return Dinero({
                    ...order.totalCost,
                    amount: refundingAmount
                });
            })
            .reduce((total, item) => total.add(item), zero);
    }, [order, lineItemInputs]);

    const noteType = React.useMemo(() => {
        if (!refundable) {
            return NoteType.NONE;
        }

        const totalRefundableAmount = refundable.lineItems
            .filter(lineItem => lineItem.isRefundable)
            .reduce((total, lineItem) =>
                lineItem.refundableAmount + total, 0);
        if (refundable.isCaptured) {
            if (totalRefundableAmount === selectedRefund.getAmount()) {
                return NoteType.IMMEDIATE_FULL_REFUND;
            } else {
                return NoteType.IMMEDIATE_PARTIAL_REFUND;
            }
        } else {
            if (totalRefundableAmount === selectedRefund.getAmount()) {
                return NoteType.IMMEDIATE_FULL_REFUND;
            } else {
                return NoteType.DEFERRED_PARTIAL_REFUND;
            }
        }
    }, [refundable, selectedRefund]);

    const handleRefund = React.useCallback(() => {
        if (!lineItemInputs || !order) {
            return;
        }

        const descriptionError = checkDescriptionForError(descriptionField.value.trim());
        const hasError = descriptionError.length > 0 || Object.values(lineItemInputs).some(i => i.amountErrors.length > 0);
        if (hasError) {
            setErrorMessage('Please resolve the fields with error to continue.');
            setDescriptionField({
                ...descriptionField,
                errors: descriptionError
            });
            return;
        }

        const lineItems: OrderRefund.LineItem[] = Object.values(lineItemInputs)
            .filter(input => input.checked)
            .map(input => {
                const {lineItem} = input.lineItemEx;
                const newAmount = convertToDineroAmt(input.amountStr, order.totalCost.currency);
                const refundingAmount = input.lineItemEx.refundableAmount - newAmount;

                return {
                    ...lineItem,
                    amount: refundingAmount
                };
            })
            .filter(lineItem => lineItem.amount > 0);

        setSubmitting(true);
        // send refund payload to server
        Api.staff.order.refund(order.orderRef, {
            description: descriptionField.value,
            lineItems
        })
            .then(res => {
                setDescriptionField({
                    value: ''
                });
                onConfirm(res.data);
            })
            .catch(e => {
                setErrorMessage(extractAxiosError(e));
            })
            .finally(() => {
                setSubmitting(false);
            });
    }, [checkDescriptionForError, descriptionField, lineItemInputs, order, onConfirm]);

    return <Modal
        scrollable={true}
        show={show}
        onHide={onHide}
        animation={false}
        size={'lg'}
        backdrop={submitting ? 'static' : true}
        keyboard={!submitting}
    >
        <Modal.Header closeButton={!submitting}>
            <Modal.Title>Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {!!order.preCaptureRefunds?.slice(-1)[0].amount && (
                <div className="alert alert-info mb-2">
                    <div className="media">
                        <GoAlert size="1.5em" />
                        <div className="media-body">
                            You are overriding a pending refund that has yet to be captured,
                            close this dialog to view the refund.
                        </div>
                    </div>
                </div>
            )}

            <table className={classNames('table', styles.lineItemsTable)}>
                <thead>
                <tr>
                    <th></th>
                    <th>Item</th>
                    <th>
                        New Amount
                    </th>
                </tr>
                </thead>
                <tbody>
                {(!!refundable && !!lineItemInputs) ? (
                    refundable.lineItems.map(lineItemEx => {
                        const {lineItem} = lineItemEx;
                        if (lineItem.type === 'cart_item') {
                            const cartItem = order.cartItems.find(matchesProperty('_id', lineItem.id));
                            if (!cartItem) return null;

                            const checkKey = keyFor(lineItem);
                            const cbId = `cb_${checkKey}`;
                            const product = products.productById[cartItem.productId];
                            const cost = order.productCostBreakdown[cartItem._id];

                            return <tr className={classNames({
                                'text-strikethrough': !lineItemEx.isRefundable
                            })} key={checkKey}>
                                <td>
                                    <label className="form-check-label" htmlFor={cbId}>
                                        <input
                                            type="checkbox"
                                            onChange={lineItemCheckHandlerFactory(lineItem)}
                                            value={checkKey}
                                            checked={lineItemInputs[checkKey].checked}
                                            id={cbId}
                                            disabled={!lineItemEx.isRefundable}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <div className="font-weight-lighter">{product.name}</div>
                                    <div className="text-muted">{product.sourceMarketPlace}</div>
                                    <div>
                                        <strong>Quantity</strong>:
                                        {' '}
                                        {cartItem.quantity}
                                    </div>
                                    <div>
                                        <VariantInfo
                                            variants={product.variants}
                                            variantPath={cartItem.variantPath}
                                        />
                                    </div>
                                    <div>
                                        {cartItem.message && (
                                            <>
                                                <strong>Message</strong>:
                                                <br/>
                                                {cartItem.message}
                                            </>
                                        )}
                                    </div>
                                </td>
                                <td>
                                    {cost.currency}
                                    {lineItemEx.isRefundable ? (
                                        <InputFieldWithValidation
                                            type="text"
                                            className={styles.amountInput}
                                            onChange={lineItemAmtChangeHandlerFactory(lineItem)}
                                            value={lineItemInputs[checkKey].amountStr}
                                            errors={lineItemInputs[checkKey].amountErrors}
                                        />
                                    ) : lineItemInputs[checkKey].amountStr}
                                </td>
                            </tr>;
                        } else if (lineItem.type === 'quotation_item') {
                            const quotationItem = order.quotationItems.find(matchesProperty('_id', lineItem.id));
                            if (!quotationItem) return null;

                            const cost = order.quotationCostBreakdown[quotationItem._id];
                            let variantInfo: VariantInfoProps | undefined;
                            let sourceText: string;
                            if (isInternal(quotationItem)) {
                                sourceText = 'INTERNAL';
                                variantInfo = getQuotationItemVariantInfo(quotationItem, products.productById[quotationItem.productSourceData.productId]);
                            } else {
                                sourceText = 'EXTERNAL';
                            }
                            const checkKey = keyFor(lineItem);
                            const cbId = `cb_quotation_item_${quotationItem._id}`;
                            return (
                                <tr className={classNames({
                                    'text-strikethrough': !lineItemEx.isRefundable
                                })} key={quotationItem._id}>
                                    <td>
                                        <label className="form-check-label" htmlFor={cbId}>
                                            <input
                                                type="checkbox"
                                                value={checkKey}
                                                onChange={lineItemCheckHandlerFactory(lineItem)}
                                                checked={lineItemInputs[checkKey].checked}
                                                id={cbId}
                                                disabled={!lineItemEx.isRefundable}
                                            />
                                        </label>
                                    </td>
                                    <td>
                                        <div className="font-weight-lighter">{quotationItem.name}</div>
                                        <div className="text-muted">{sourceText}</div>
                                        <div>
                                            <strong>Quantity</strong>:
                                            {' '}
                                            {quotationItem.quantity}
                                        </div>
                                        <div>
                                            {variantInfo && <VariantInfo
                                                {...variantInfo}
                                            />}
                                        </div>
                                        <div>
                                            <strong>Instructions</strong>:
                                            <br/>
                                            {quotationItem.instructions}
                                        </div>
                                    </td>
                                    <td>
                                        {cost.currency}
                                        {lineItemEx.isRefundable ? (
                                            <InputFieldWithValidation
                                                type="text"
                                                className={styles.amountInput}
                                                onChange={lineItemAmtChangeHandlerFactory(lineItem)}
                                                value={lineItemInputs[checkKey].amountStr}
                                                errors={lineItemInputs[checkKey].amountErrors}
                                            />
                                        ) : lineItemInputs[checkKey].amountStr}
                                    </td>
                                </tr>
                            );
                        } else {
                            const checkKey = lineItem.type;
                            const cbId = `cb_${checkKey}`;
                            return <tr className={classNames({
                                'text-strikethrough': !lineItemEx.isRefundable
                            })} key={checkKey}>
                                <td>
                                    <label className="form-check-label" htmlFor={cbId}>
                                        <input
                                            type="checkbox"
                                            onChange={lineItemCheckHandlerFactory(lineItem)}
                                            checked={lineItemInputs[checkKey].checked}
                                            id={cbId}
                                            disabled={!lineItemEx.isRefundable}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <div className="font-weight-lighter">
                                        {OTHER_LINE_ITEM_LABELS[lineItem.type]}
                                    </div>
                                </td>
                                <td>
                                    {lineItemEx.refundableAmount === 0 ?
                                        formatMoney(order.totalCost.currency, lineItem.amount) : (
                                            <>
                                                {order.totalCost.currency}
                                                {lineItemEx.isRefundable ? (
                                                    <InputFieldWithValidation
                                                        type="text"
                                                        className={styles.amountInput}
                                                        onChange={lineItemAmtChangeHandlerFactory(lineItem)}
                                                        value={lineItemInputs[checkKey].amountStr}
                                                        errors={lineItemInputs[checkKey].amountErrors}
                                                    />
                                                ) : lineItemInputs[checkKey].amountStr}
                                            </>
                                        )}
                                </td>
                            </tr>;
                        }
                    })
                ) : null}
                {order.voucherDiscount && (
                    <tr className="text-strikethrough">
                        <td>
                            <input
                                type="checkbox"
                                disabled={true}
                            />
                        </td>
                        <td>
                            <div className="font-weight-lighter">Voucher</div>
                        </td>
                        <td>
                            ({formatMoney(order.voucherDiscount.currency, order.voucherDiscount.amount)})
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            <div className="form-group">
                <label>Description</label>
                <TextAreaWithValidation
                    className="form-control"
                    value={descriptionField.value}
                    onChange={descriptionChangeHandler}
                    errors={descriptionField.errors}
                />
            </div>

            {noteType === NoteType.IMMEDIATE_PARTIAL_REFUND && (
                <div className="alert alert-danger mb-2">
                    <div className="media">
                        <GoAlert size="1.5em" />
                        <div className="media-body">
                            This is a partial refund. The refund will be submitted <strong>immediately</strong>
                            {' '}after submission of this form. <strong>Fees Applies.</strong>
                        </div>
                    </div>
                </div>
            )}

            {noteType === NoteType.DEFERRED_PARTIAL_REFUND && (
                <div className="alert alert-warning mb-2">
                    <div className="media">
                        <GoAlert size="1.5em" />
                        <div className="media-body">
                            This is a partial refund. This refund will be deducted from the amount captured at a
                            later time.
                        </div>
                    </div>
                </div>
            )}

            {noteType === NoteType.IMMEDIATE_FULL_REFUND && (
                <div className="alert alert-danger mb-2">
                    <div className="media">
                        <GoAlert size="1.5em" />
                        <div className="media-body">
                            This is a full refund. The refund will be submitted <strong>immediately</strong>
                            {' '}and the order status will be updated to REFUNDED after submission of this form.
                            {' '} <strong>Fees Applies.</strong>
                        </div>
                    </div>
                </div>
            )}

            <h5>
                <strong>Total refunds</strong>: {formatDinero(selectedRefund)}
            </h5>

            <TimeoutAlert
                errorMessage={errorMessage}
                onHide={() => setErrorMessage(null)}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide} disabled={submitting}>
                Cancel
            </Button>
            <Button variant="danger" onClick={handleRefund} disabled={submitting}>
                Refund
            </Button>
        </Modal.Footer>
    </Modal>;
}