import React from 'react';
import AddressFields, {ascCountryCodes} from "../constants/address_fields";

interface CountrySelectProps {
  value: string;
  onChange: (country: string) => void;
  disabled?: boolean;
  excludeCountries?: string[];
}

export default function CountrySelect({value, onChange, excludeCountries, disabled}: CountrySelectProps) {
  const handleChangeCountry = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value), [onChange]);
  const countryCodes = React.useMemo(() => {
    if (!excludeCountries) {
      return ascCountryCodes;
    }
    const excludeCountriesSet = new Set(excludeCountries);
    return ascCountryCodes.filter((country) => !excludeCountriesSet.has(country));
  }, [excludeCountries]);

  return <select
    className="form-control"
    value={value}
    onChange={handleChangeCountry}
    disabled={disabled}
  >
    <option>Select a country</option>
    {countryCodes.map((alpha2: string | number, index: number) => {
      const country = AddressFields.countries[alpha2];
      return (
        <option key={index} value={country.countryId}>{country.displayValue}</option>
      );
    })}
  </select>;
}