import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import urljoin from 'url-join';
import Api from '../api/api';
import {genericCheckboxChange, genericTextChange, readOneFileAsDataURLFactory} from '../utils/forms';
import {extractAxiosError} from '../utils/error';
import {Banner} from "./pages/banners";

interface EditBannerProps {
  onError: (message: string) => void;
  banner?: Banner;
}

export default function EditBanner({ onError, banner }: EditBannerProps) {
  const editing = !!banner;
  const [name, setName] = React.useState('');
  const [link, setLink] = React.useState('');
  const [active, setActive] = React.useState(true);
  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState<string | null>(null);
  const [nextPosition, setNextPosition] = React.useState('1');

  const uploadRef = React.useRef<object>() as { current: { files: any[] } };
  const history = useHistory();

  React.useEffect(() => {
    Api.admin.banner.getNextPosition()
      .then(res => {
        const positionStr = (res.data.position + 1).toString();
        setNextPosition(positionStr);
      })
      .catch(err => {
        onError(extractAxiosError(err));
      });
  }, [onError]);

  React.useEffect(() => {
    if (banner) {
      setName(banner.name as string);
      setLink(banner.link as string);
      setActive(banner.active as boolean);

      const src = urljoin(process.env.REACT_APP_IMAGES_ENDPOINT as string, banner.imagePath as string);
      setPreviewImage(src);
    }
  }, [banner]);

  const handleSelectFile = readOneFileAsDataURLFactory(setPreviewImage);

  const handleSave = () => {
    if (saveDisabled) {
      return;
    }

    const newBanner = {
      active,
      name,
      link,
    };

    if (!editing) {
      Object.assign(newBanner, {
        image: uploadRef.current.files[0],
      });
    }

    let action;
    if (editing) {
      const {_id} = banner as Banner;
      action = Api.admin.banner.update(_id as number, newBanner);
    } else {
      action = Api.admin.banner.create(newBanner);
    }

    setSaveDisabled(true);
    action
      .then(_ => {
        history.push('/banners');
      })
      .catch(error => {
        if (onError) {
          onError(extractAxiosError(error));
        }
      })
      .finally(() => setSaveDisabled(false));
  };

  let displayPosition;
  if (editing) {
    const {position} = banner as Banner;
    displayPosition = (position as number) + 1;
  } else {
    displayPosition = nextPosition;
  }


  return <>
    {!editing && (
      <div className="form-group">
        <label>Image</label>
        <br/>
        <input type="file" accept="image/*" onChange={handleSelectFile as unknown as React.ChangeEventHandler}
               ref={uploadRef as unknown as React.LegacyRef<HTMLInputElement>}/>
      </div>
    )}

    {previewImage && (
      <div className="form-group">
        <label>Image Preview</label>
        <br/>
        <img src={previewImage} alt="preview"/>
      </div>
    )}

    <div className="form-group">
      <label>Name</label>
      <input
        className="form-control"
        type="text"
        value={name}
        onChange={genericTextChange(setName) as React.ChangeEventHandler}
      />
    </div>

    <div className="form-group">
      <label>Link</label>
      <input
        className="form-control"
        type="text"
        value={link}
        onChange={genericTextChange(setLink) as React.ChangeEventHandler}
      />
    </div>

    <div className="form-check mb-2">
      <input
        className="form-check-input"
        type="checkbox"
        id="banner_active"
        onChange={genericCheckboxChange(setActive) as React.ChangeEventHandler}
        checked={active}
      />
      <label className="form-check-label" htmlFor="banner_active">
        Active?
      </label>
    </div>

    <div className="form-group">
      <label>Position</label>
      <input
        type="text"
        readOnly
        className="form-control-plaintext"
        value={displayPosition}
      />
    </div>

    <button className="btn btn-primary mr-2" disabled={saveDisabled} onClick={handleSave}>Save</button>
    <Link className="btn btn-secondary" to="/banners">Cancel</Link>
  </>;
}