import React from 'react';
import HeadTitle from "../../head_title";
import EditCountryTax from "../../edit_country_tax";
import TimeoutAlert from "../../timeout_alert";

export default function NewCountryTaxPage() {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    return <>
        <HeadTitle
            title="New Country Tax"
        />

        <TimeoutAlert
            errorMessage={errorMessage as string}
            onHide={() => setErrorMessage(null)}
        />

        <h1>New Country Tax</h1>
        <EditCountryTax
            onError={setErrorMessage}
        />
    </>;
}