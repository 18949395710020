import 'react-image-crop/dist/ReactCrop.css';

import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import urljoin from 'url-join';
import Api from '../api/api';
import {genericCheckboxChange, genericTextChange} from '../utils/forms';
import {extractAxiosError} from '../utils/error';
import {ImagePage} from "../api/models";

function HotspotsOverlay({hotspots, show, onClick}: {hotspots: ReactCrop.PercentCrop[], show: boolean, onClick: (index: number) => void}) {
  const style = {
    backgroundColor: 'black',
    position: 'absolute',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.6',
    marginBottom: '0',
    cursor: 'pointer',
  };

  const className = show ? "" : "d-none";

  const handleClick = (index: number) => () => onClick(index);

  return <div className={className}>
    {hotspots.map((hotspot, index: number) => {
      const itemStyle = Object.assign({
        width: `${hotspot.width}%`,
        height: `${hotspot.height}%`,
        top: `${hotspot.y}%`,
        left: `${hotspot.x}%`,
      }, style);

      return <h4
        key={index}
        style={itemStyle as React.CSSProperties}
        onClick={handleClick(index)}
      >
        #{index + 1}
        <br/>
        Click to Edit
      </h4>;
    })}
  </div>;
}

interface EditImagePageState {
  locked: boolean;
  adding: boolean;
  editing: number;
  crop: ReactCrop.PercentCrop;
  hotspots: ReactCrop.PercentCrop[];
  showHotspots: boolean;
  src: string;
  active: boolean;
  name: string;
  pagePath: string;
  hotspotLinks: string[];
}

const initialState: EditImagePageState = {
  locked: true,
  adding: false,
  editing: -1,
  crop: {},
  hotspots: [],
  showHotspots: true,
  src: '',
  active: true,
  name: '',
  pagePath: '',
  hotspotLinks: []
};

const ACTION_ADD_HOTSPOT = 'ACTION_ADD_HOTSPOT';
const ACTION_HOTSPOT_DONE = 'ACTION_HOTSPOT_DONE';
const ACTION_HOTSPOT_REMOVE = 'ACTION_HOTSPOT_REMOVE';
const ACTION_HOTSPOT_EDIT = 'ACTION_HOTSPOT_EDIT';
const ACTION_UPDATE_ATTR = 'ACTION_UPDATE_ATTR';
const ACTION_LOAD = 'ACTION_LOAD';

type ACTIONTYPE =
    | { type: 'ACTION_ADD_HOTSPOT'; }
    | { type: 'ACTION_HOTSPOT_DONE'; }
    | { type: 'ACTION_HOTSPOT_REMOVE'; }
    | { type: 'ACTION_HOTSPOT_EDIT'; payload: number; }
    | { type: 'ACTION_UPDATE_ATTR'; payload: Partial<typeof initialState>; }
    | { type: 'ACTION_LOAD'; payload: ImagePage; };

const notEditingState = {
  adding: false,
  editing: -1,
  locked: true,
  showHotspots: true,
  crop: {},
};

const reducer = function (state: EditImagePageState, action: ACTIONTYPE) {
  switch (action.type) {
    case ACTION_ADD_HOTSPOT:
      return Object.assign({}, state, {
        adding: true,
        locked: false,
        showHotspots: false,
      });
    case ACTION_HOTSPOT_DONE: {
      let stateUpdate;
      if (state.adding) {
        const newHotspots = state.hotspots.concat(state.crop);

        stateUpdate = {
          hotspots: newHotspots,
          hotspotLinks: state.hotspotLinks.concat('')
        };
      } else if (state.editing !== -1) {
        const newHotspots = state.hotspots.slice();
        newHotspots[state.editing] = state.crop;
        stateUpdate = {
          hotspots: newHotspots,
        };
      }
      return Object.assign({}, state, notEditingState, stateUpdate);
    }
    case ACTION_HOTSPOT_REMOVE: {
      let stateUpdate;
      if (state.editing !== -1) {
        const newHotspots = state.hotspots.slice();
        newHotspots.splice(state.editing, 1);

        const links = state.hotspotLinks.slice();
        links.splice(state.editing, 1);

        stateUpdate = {
          hotspots: newHotspots,
          hotspotLinks: links,
        };
      }
      return Object.assign({}, state, notEditingState, stateUpdate);
    }
    case ACTION_UPDATE_ATTR:
      return Object.assign({}, state, action.payload);
    case ACTION_HOTSPOT_EDIT:
      return Object.assign({}, state, {
        locked: false,
        editing: action.payload,
        showHotspots: false,
        crop: state.hotspots[action.payload as number],
      });
    case ACTION_LOAD: {
      const src = urljoin(process.env.REACT_APP_IMAGES_ENDPOINT as string, action.payload.imagePath);
      const {hotspots, active, name, pagePath} = action.payload;
      const hotspotLinks = hotspots.map((hotspot) => {
        if (hotspot.link) {
          return hotspot.link;
        } else {
          return '';
        }
      });

      return Object.assign({}, state, notEditingState, {
        hotspots,
        src,
        active,
        name,
        pagePath,
        hotspotLinks,
      });
    }
  }
  return state;
};

export interface ImagePageExtended extends ImagePage {
  image?: File
}

interface EditImagePageProps {
  onError: (errorMessage: string) => void,
  imagePage?: ImagePageExtended
}

export default function EditImagePage({onError, imagePage}: EditImagePageProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const setActive = (value: boolean) => dispatch({
    type: ACTION_UPDATE_ATTR,
    payload: {
      active: value,
    }
  });

  const setName = (value: string) => dispatch({
    type: ACTION_UPDATE_ATTR,
    payload: {
      name: value,
    }
  });

  const setPagePath = (value: string) => dispatch({
    type: ACTION_UPDATE_ATTR,
    payload: {
      pagePath: value,
    }
  });

  const setHotspotLinks = (value: string[]) => dispatch({
    type: ACTION_UPDATE_ATTR,
    payload: {
      hotspotLinks: value,
    }
  });

  const uploadRef = React.useRef<HTMLInputElement>();

  const history = useHistory();

  React.useEffect(() => {
    if (imagePage) {
      dispatch({
        type: ACTION_LOAD,
        payload: imagePage,
      });
    }
  }, [imagePage]);

  const handleAddHotspot = () => dispatch({
    type: ACTION_ADD_HOTSPOT,
  });

  const handleDone = () => {
    dispatch({
      type: ACTION_HOTSPOT_DONE,
    });
  };

  const handleRemove = () => dispatch({
    type: ACTION_HOTSPOT_REMOVE,
  });

  const handleCropChange = (crop: ReactCrop.Crop, percentCrop: ReactCrop.PercentCrop) => dispatch({
    type: ACTION_UPDATE_ATTR,
    payload: {
      crop: percentCrop,
    },
  });

  const handleHotspotClick = (index: number) => {
    dispatch({
      type: ACTION_HOTSPOT_EDIT,
      payload: index,
    });
  };

  const renderSelectionAddon = () => {
    return (
      <>
        <button
          className="btn btn-success btn-sm mr-1"
          onClick={handleDone}
        >
          Done
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={handleRemove}
        >
          Remove
        </button>
      </>
    );
  };
  const addHotspotDisabled = !state.src || !state.locked || state.adding;
  const saveDisabled = !state.src;
  const editing = !!imagePage;

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        dispatch({
          type: ACTION_UPDATE_ATTR,
          payload: {
            src: reader.result as string,
          }
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSave = () => {
    if (saveDisabled) {
      return;
    }

    const savingHotspots = state.hotspots.map((hotspot, index: number) => {
      const item = Object.assign({}, hotspot, {
        link: state.hotspotLinks[index]
      });
      delete item.aspect;
      return item;
    });


    const newImagePage = {
      active: state.active,
      name: state.name,
      hotspots: savingHotspots,
      pagePath: state.pagePath,
    };

    if (!editing) {
      Object.assign(newImagePage, {
        image: uploadRef.current!.files![0],
      });
    }

    let action;
    if (editing) {
      action = Api.admin.imagePage.update(imagePage!._id, newImagePage);
    } else {
      action = Api.admin.imagePage.create(newImagePage);
    }

    action
      .then(_ => {
        history.push('/image_pages');
      })
      .catch(error => {
        onError(extractAxiosError(error));
      });
  };

  return <>
    {!editing && (
      <div className="form-group">
        <label>Image</label>
        <input type="file" accept="image/*" onChange={handleSelectFile} ref={uploadRef as unknown as React.RefObject<HTMLInputElement>}/>
      </div>
    )}

    <div className="position-relative d-inline-block">
      <ReactCrop
        src={state.src}
        className="img-fluid"
        crop={state.crop}
        onChange={handleCropChange}
        locked={state.locked}
        renderSelectionAddon={renderSelectionAddon}
      />
      <HotspotsOverlay
        show={state.showHotspots}
        hotspots={state.hotspots}
        onClick={handleHotspotClick}
      />
    </div>
    <br/>

    <div className="form-group">
      <label>Name</label>
      <input
        className="form-control"
        type="text"
        value={state.name}
        onChange={genericTextChange(setName as React.Dispatch<React.SetStateAction<string>>)}
      />
    </div>

    <div className="form-group">
      <label>Page Path</label>
      <input
        className="form-control"
        type="text"
        value={state.pagePath}
        onChange={genericTextChange(setPagePath as React.Dispatch<React.SetStateAction<string>>)}
      />
    </div>

    <div className="form-check mb-2">
      <input
        className="form-check-input"
        type="checkbox"
        id="image_page_active"
        onChange={genericCheckboxChange(setActive as React.Dispatch<React.SetStateAction<boolean>>)}
        checked={state.active}
      />
      <label className="form-check-label" htmlFor="image_page_active">
        Active?
      </label>
    </div>

    <h4>Hotspot Links</h4>
    {state.hotspotLinks.map((link: string, index: number) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const links = state.hotspotLinks.slice();
        links[index] = e.target.value;
        setHotspotLinks(links);
      };

      return <div className="form-group">
        <label>#{index + 1}</label>
        <input
          type="text"
          className="form-control"
          value={link}
          onChange={handleChange}
          placeholder="/product/tkp-768244493-kemplang-kerupuk-panggang-bantet-super-by-tiara-snack-bangka"
        />
      </div>;
    })}


    <button className="btn btn-light" onClick={handleAddHotspot} disabled={addHotspotDisabled}>Add Hotspot</button>

    <button className="btn btn-primary mr-2" disabled={saveDisabled} onClick={handleSave}>Save</button>
    <Link className="btn btn-secondary" to="/image_pages">Cancel</Link>
  </>;
}

EditImagePage.propTypes = {
  onError: PropTypes.func,
  imagePage: PropTypes.object,
};