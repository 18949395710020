export function convertWeightToKg(
  sourceWeight: number,
  sourceWeightUnit: string
) {
  if (sourceWeightUnit === "kg") {
    return sourceWeight;
  }

  if (sourceWeightUnit === "g") {
    return sourceWeight / 1000;
  }

  throw new Error("unknown sourceWeightUnit");
}
