import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {checkStringForError} from "../../lib/validation";
import {TextAreaWithValidation} from "../fields_with_validation";
import TimeoutAlert from "../timeout_alert";

export interface DeclineInternalQuotationItemDialogCloseData {
  declineReason: string;
}

interface DeclineInternalQuotationItemDialogProps {
  show: boolean;
  onHide: (data?: DeclineInternalQuotationItemDialogCloseData) => void;
}

interface DeclineInternalQuotationItemDialogErrors {
  reason?: string[];
}

export default function DeclineInternalQuotationItemDialog(props: DeclineInternalQuotationItemDialogProps) {
  const {show, onHide} = props;

  const [reason, setReason] = React.useState('');
  const [errors, setErrors] = React.useState<DeclineInternalQuotationItemDialogErrors>({});
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleReasonChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  }, []);

  const validate = React.useCallback(() => {
    const errors: Required<DeclineInternalQuotationItemDialogErrors> = {
      reason: checkStringForError(reason, {
        attrName: 'decline reason',
        minLength: 1,
        required: true
      })
    };
    setErrors(errors);

    return Object.values(errors).every(i => i.length === 0);
  }, [reason]);

  const handleDecline = React.useCallback(() => {
    const isValid = validate();
    if (!isValid) {
      return;
    }

    onHide({
      declineReason: reason
    });
  }, [validate, onHide, reason]);

  const handleCancel = React.useCallback(() => {
    onHide();
  }, [onHide]);

  const clearErrorMessage = React.useCallback(() => {
    setErrorMessage(null);
  }, []);

  return <Modal
    scrollable={true}
    show={show}
    onHide={onHide}
    animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Decline Quotation Item</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <TimeoutAlert
        errorMessage={errorMessage}
        onHide={clearErrorMessage}
      />

      <div className="form-group">
        <label>Reason</label>
        <TextAreaWithValidation
          className="form-control"
          value={reason}
          onChange={handleReasonChange}
          errors={errors.reason}
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleDecline}>
        Decline
      </Button>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;

}