import {QuotationItemProps} from "./quotation_item_props";
import {QuotationItem, QuotationItemStatuses} from "../../types/models/quotation_item";

export default function QuotationItemDeclineReasonLineComponent(props: QuotationItemProps) {
  const {quotationItem} = props;
  if (quotationItem.status !== QuotationItemStatuses.declined) {
    return null;
  }

  const quotationItemCasted = quotationItem as QuotationItem<QuotationItemStatuses.declined>;
  return <>
    <dt className="col-auto">Reason for decline:</dt>
    <dd className="col">{quotationItemCasted.statusData.declineReason}</dd>
  </>;
}