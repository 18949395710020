import React from 'react';
import CSVReader from 'react-csv-reader';
import Dinero from 'dinero.js';
import {Link} from 'react-router-dom';
import TimeoutAlert from '../timeout_alert';
import {validateSerializedDate} from '../../utils/date';
import {extractAxiosError} from '../../utils/error';
import {formatDinero} from '../../utils/number';
import Api from '../../api/api';
import HeadTitle from '../head_title';
import {PayNowReconcileParameters} from "../../api/request_models";
import {PayNowReconcileResponse} from "../../api/response_models";

const COLUMN_INDEX_TO_NAME = [
  'date',
  'transactionRef',
  'orderRef',
  'amount',
  'description',
];

interface Transaction extends Partial<PayNowReconcileParameters.Transaction> {
  [columnName: string]: any;

  validationErrors: Partial<Omit<Transaction, 'validationErrors'>>;
}

function validateColumn(index: number, value: string | number | Date): string | null {
  switch (index) {
    case 1:
    case 4:
      return null;
    case 0:
      return validateSerializedDate(value as Date) ? null : 'Invalid Date';
    case 2:
      return !!value ? null : 'Invalid Order Ref';
    case 3: {
      if (Number.isNaN(value)) {
        return 'Invalid Amount';
      }
      value = parseFloat(value as string);
      if (value <= 0) {
        return 'Amount must be positive and more than 0';
      }
      return null;
    }
  }
  // any other column
  return 'Invalid Column';
}

function OrderRefLink(orderRef: string) {
  let link = '';
  if (orderRef.startsWith('NO')) {
    link = '/orders/';
  } else if (orderRef.startsWith('QO')) {
    link = '/quotation_orders/';
  }
  link += orderRef;

  return <Link to={link}>{orderRef}</Link>;
}

const MODE_UPLOADING = 1;
const MODE_CONFIRMING = 2;
const MODE_RESULTS = 3;

export default function ReconcilePaynowPayment() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [mode, setMode] = React.useState(MODE_UPLOADING);
  const [uploadResults, setUploadResults] = React.useState<PayNowReconcileResponse | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const validTransactionsRef = React.useRef<Transaction[]>([]);
  const invalidTransactionsRef = React.useRef<Transaction[]>([]);

  const handleImportError = (error: { message: string }) => {
    setErrorMessage(error.message);
  };

  const handleImport = (data: any[]) => {
    const dataWithoutHeader = data.slice(1);
    // trim last item
    const lastRow = dataWithoutHeader[dataWithoutHeader.length - 1];
    if (!lastRow[0]) {
      dataWithoutHeader.pop();
    }

    if (data[0].length !== COLUMN_INDEX_TO_NAME.length) {
      setErrorMessage('CSV invalid header');
      return;
    }

    const validTransactions: Transaction[] = [];
    const invalidTransactions: Transaction[] = [];

    for (const row of dataWithoutHeader) {

      const transaction: Transaction = {
        validationErrors: {}
      };
      let invalid = false;
      for (let i = 0; i < COLUMN_INDEX_TO_NAME.length; i++) {
        const columnName = COLUMN_INDEX_TO_NAME[i];
        const columnError = validateColumn(i, row[i]);
        if (columnError) {
          invalid = true;
          transaction.validationErrors[columnName] = columnError;
        }
        transaction[columnName] = row[i];
      }
      if (invalid) {
        invalidTransactions.push(transaction);
      } else {
        validTransactions.push(transaction);
      }
    }

    const initialObj: Record<string, Transaction[]> = {};
    const transactionRefMap = validTransactions.reduce((obj, transaction) => {
      const key = transaction.transactionRef as string;
      let list = obj[key];
      if (!list) {
        list = [];
      }
      list.push(transaction);
      obj[key] = list;
      return obj;
    }, initialObj);

    for (const transactions of Object.values(transactionRefMap)) {
      if (transactions.length > 1) {
        for (const transaction of transactions) {
          transaction.validationErrors['transactionRef'] = 'Duplicated transaction reference';
          const index = validTransactions.indexOf(transaction);
          validTransactions.splice(index, 1);
        }
        invalidTransactions.push(...transactions);
      }
    }

    validTransactionsRef.current = validTransactions;
    invalidTransactionsRef.current = invalidTransactions;

    setMode(MODE_CONFIRMING);
  };

  const handleImportTransactions = () => {
    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) {
      return;
    }

    setIsLoading(true);
    const transactions = validTransactionsRef.current.map(transaction => {
      return Object.assign({}, transaction, {
        validationErrors: undefined
      });
    });

    Api.admin.paynow.reconcile(transactions)
      .then(res => {
        setSuccessMessage('Successfully reconciled paynow transactions');
        setUploadResults(res.data);
        setMode(MODE_RESULTS);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
        setMode(MODE_UPLOADING);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let content;
  if (mode === MODE_CONFIRMING) {
    content = <>
      <h3>Confirm Transactions</h3>
      <hr/>
      <h4>Valid Transactions</h4>
      <table className="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Transaction Reference</th>
          <th>Order Reference</th>
          <th>Amount</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        {validTransactionsRef.current.map((trans: Transaction, index: number) => {
          return <tr key={index}>
            {COLUMN_INDEX_TO_NAME.map((columnName, index) => {
              const validationError = trans.validationErrors[columnName];
              if (validationError) {
                return <td key={index}>
                  {trans[columnName]}
                  <br/>
                  <small className="text-danger">{validationError}</small>
                </td>;
              } else {
                return <td key={index}>{trans[columnName]}</td>;
              }
            })}
          </tr>;
        })}
        </tbody>
      </table>

      <h4>Invalid Transactions</h4>
      <table className="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Transaction Reference</th>
          <th>Order Reference</th>
          <th>Amount</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        {invalidTransactionsRef.current.map((trans, index) => {
          return <tr key={index}>
            {COLUMN_INDEX_TO_NAME.map((columnName, index) => {
              const validationError = trans.validationErrors[columnName];
              if (validationError) {
                return <td key={index}>
                  {trans[columnName]}
                  <br/>
                  <small className="text-danger">{validationError}</small>
                </td>;
              } else {
                return <td key={index}>{trans[columnName]}</td>;
              }
            })}
          </tr>;
        })}
        </tbody>
      </table>

      <button
        className="btn btn-primary"
        onClick={handleImportTransactions}
        disabled={isLoading}
      >
        Proceed for valid transactions
      </button>
      <button
        className="btn btn-light"
        onClick={() => setMode(MODE_UPLOADING)}
      >
        Cancel
      </button>
    </>;
  } else if (mode === MODE_RESULTS) {
    if (uploadResults) {
      content = <>
        <h3>Upload Results</h3>
        <h4>Orders that are not Pay Now</h4>
        <table className="table">
          <thead>
          <tr>
            <th>Order Reference</th>
          </tr>
          </thead>
          <tbody>
          {uploadResults.wrongPaymentMethodOrderRefs.map((orderRef, index) => {
            return <tr key={index}>
              <td>{OrderRefLink(orderRef)}</td>
            </tr>;
          })}
          </tbody>
        </table>

        <h4>Amount Unpaid for Orders</h4>
        <table className="table">
          <thead>
          <tr>
            <th>Order Reference</th>
            <th>Amount Left</th>
          </tr>
          </thead>
          <tbody>
          {uploadResults.orderRefLeftovers.map(([orderRef, amountLeft], index) => {
            return <tr key={index}>
              <td>{OrderRefLink(orderRef)}</td>
              <td>{formatDinero(Dinero(amountLeft as Dinero.Options))}</td>
            </tr>;
          })}
          </tbody>
        </table>
      </>;
    } else {
      content = <>
        <h3>Upload Results</h3>
        <p>Loading...</p>
      </>;
    }
  } else {
    content = <>
      <h3>Import via CSV</h3>
      <div className="form-group">
        <CSVReader
          label="Import Reconcile Paynow Payment CSV"
          cssLabelClass="d-block"
          onFileLoaded={handleImport}
          onError={handleImportError}
        />
        <small className="text-muted">Please upload CSV only</small>
      </div>
    </>;
  }

  return <>
    <HeadTitle
      title="Reconcile Paynow Payment"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />

    <h2>Reconcile Paynow Payment</h2>
    <hr/>
    {content}

    <h3>CSV Format</h3>
    <p>A <strong>CSV</strong> format that can be copy and pasted into excel.</p>
    <table className="table">
      <thead>
      <tr>
        <th>Date</th>
        <th>Transaction Reference</th>
        <th>Order Reference</th>
        <th>Amount</th>
        <th>Description</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>20200910</td>
        <td>VT2025301250063900XXXXX</td>
        <th>NO20201022IFM8VT</th>
        <td>12.33</td>
        <td>CALTEX - PAYA LEBAR SG</td>
      </tr>
      </tbody>
    </table>
  </>;
}