import React from 'react';
import {ConfigEditorProps} from "../../../generic";
import {SUPPORTED_CURRENCIES} from "../../../constants/currency";

export default function CurrencyField(props: ConfigEditorProps<string>) {
  const {configValue, onDone, onCancel} = props;

  const [currency, setCurrency] = React.useState(configValue || SUPPORTED_CURRENCIES[0]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onDone(currency);
  };

  return <form className="form-inline" onSubmit={handleSubmit}>
    <div className="form-group">
      <select className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
        {SUPPORTED_CURRENCIES.map((c, index) => (
          <option value={c} key={index}>{c}</option>
        ))}
      </select>
      <button type="submit" className="btn btn-primary mb-3">Save</button>
      <button type="button" className="btn btn-secondary mb-3" onClick={onCancel as React.MouseEventHandler<HTMLElement>}>Cancel</button>
    </div>
  </form>;
}