import React from 'react';
import {Link, useParams} from 'react-router-dom';
import urljoin from 'url-join';
import {extractAxiosError} from '../../utils/error';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import Api from '../../api/api';
import {Merchant} from "../../api/models";

export default function ViewMerchantPage() {
  const [merchant, setMerchant] = React.useState<Merchant | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [products, setProducts] = React.useState([]);

  const params = useParams<{id: string}>();
  const {id} = params;

  React.useEffect(() => {
    setIsLoading(true);
    Api.admin.merchant.get(id)
      .then(res => {
        setMerchant(res.data.merchant);
        return res.data.merchant._id;
      })
      .then(merchantId => Api.admin.product.getAll(merchantId))
      .then(res => {
        setProducts(res.data.products);
      })
      .catch(error => {
        extractAxiosError(error);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  let title = 'View Merchant';
  if (merchant) {
    title = 'Merchant, ' + merchant.name;
  }

  let content;
  if (isLoading) {
    content = (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  } else {
    content = (
      <>
        <h3>Details</h3>
        <div>
          <strong>Email</strong>
          <br/>
          {merchant!.email}
        </div>

        <div>
          <strong>Has Owner</strong>
          <br/>
          {merchant!.hasOwner.toString()}
        </div>

        {merchant!.hasOwner && (
          <div>
            <strong>Owner</strong>
            <br/>
            {merchant!.owner}
          </div>
        )}

        <h3>Products</h3>
        <Link to={`/merchants/${merchant!._id}/products/new`} className="btn btn-primary">
          New
        </Link>
        {products.length === 0 && (
          <p>No products yet</p>
        )}

        {products.map((product, index) => {
          const {paramName, _id: _id1, name: name1} = product;
          const storeLink = urljoin(process.env.REACT_APP_STORE_ENDPOINT as string, 'product', paramName);
          const editLink = `/merchants/${id}/products/${_id1}`;
          return (
            <div className="card" key={index}>
              <div className="card-body">
                <h5 className="card-title">{name1}</h5>
                <a href={storeLink} className="card-link" target="_blank" rel="noreferrer">View on store</a>
                <Link to={editLink} className="card-link">Edit</Link>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return <>
    <HeadTitle
      title={title}
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <h1>{title}</h1>

    {content}
  </>;
}