import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../store/actions';
import {RootState} from "../store/reducers/reducers";

export function useCategories() {
  const dispatch = useDispatch();
  const categories = useSelector((state: RootState) => state.category.data);
  const categoriesLoaded = !!categories;
  if (!categoriesLoaded) {
    dispatch(actions.category.loadCategoriesAsync());
  }
  return categories;
}