import React from "react";
import isEmpty from "lodash/isEmpty";
import urljoin from "url-join";
import {formatDateTimeFromSeconds} from "../../../utils/date";
import Api from "../../../api/api";
import {genericTextChange} from "../../../utils/forms";
import {overrideSourceLabels} from "../../../constants/historic_product_weight";
import HeadTitle from "../../head_title";
import {GetHistoricProductWeightItem} from "../../../api/response_models";

// a page to browse historic product weights under quotations section
export default function QuotationItemHistoricPricesPage() {
    const [totalPages, setTotalPages] = React.useState(2);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [historicProductWeights, setHistoricProductWeights] = React.useState<GetHistoricProductWeightItem[]>([]);
    const [searchText, setSearchText] = React.useState("");
    const [isSearching, setIsSearching] = React.useState(false);
    const [isShowingSearchResult, setIsShowingSearchResult] = React.useState(false);

    const usernamesRef = React.useRef<Record<string, string>>({});

    const previousHistoricProductWeightsRef = React.useRef<GetHistoricProductWeightItem[]>();

    const fetchHistoricProductWeight = React.useCallback(async (page: number, existingHistoricProductWeights: GetHistoricProductWeightItem[]) => {
        const res = await Api.staff.historicProductWeight.paginate(page);

        try {
            const userIds = res.data.historicProductWeights
                .filter(weight => !!weight.createdBy)
                .map(weight => weight.createdBy!);
            const userNamesRes = await Api.user.getNames(userIds);
            const updatedUsernames: Record<string, string> = {
                ...usernamesRef.current
            };
            for (const uid in userNamesRes.data.usernames) {
                const {firstName, lastName} = userNamesRes.data.usernames[uid];
                updatedUsernames[uid] = `${firstName} ${lastName}`;
            }
            usernamesRef.current = updatedUsernames;
        } catch (e) {
            console.error('Failed to fetch user names for historic product weight', e);
        }

        setTotalPages(res.data.totalPages);
        setHistoricProductWeights([...existingHistoricProductWeights, ...res.data.historicProductWeights]);
    }, []);

    React.useEffect(() => {
        fetchHistoricProductWeight(1, []);
    }, [fetchHistoricProductWeight]);

    const loadMoreHandler = React.useCallback(() => {
        setCurrentPage(currentPage + 1);
        fetchHistoricProductWeight(currentPage + 1, historicProductWeights);
    }, [fetchHistoricProductWeight, currentPage, historicProductWeights]);

    const handleSearch = React.useCallback((e: React.FormEvent) => {
        e.preventDefault();
        if (isSearching) return;

        previousHistoricProductWeightsRef.current = historicProductWeights;
        setIsSearching(true);

        Api.staff.historicProductWeight.search(searchText)
            .then(res => {
                setIsShowingSearchResult(true);
                setHistoricProductWeights(res.data.historicProductWeights);
            })
            .finally(() => setIsSearching(false));

        return false;
    }, [searchText, historicProductWeights, isSearching]);

    const handleReset = React.useCallback(() => {
        if (isShowingSearchResult) {
            if (previousHistoricProductWeightsRef.current) {
                setHistoricProductWeights(previousHistoricProductWeightsRef.current);
            } else {
                setHistoricProductWeights([]);
            }
            previousHistoricProductWeightsRef.current = undefined;
            setIsShowingSearchResult(false);
        }

        setSearchText("");
    }, [isShowingSearchResult]);

    return <>
        <HeadTitle
            title="Product Change History"
        />

        <h1 className="mb-2">Product Change History</h1>

        <div className="text-right mb-2" onSubmit={handleSearch}>
            <form>
                <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Search product name here…"
                    value={searchText}
                    onChange={genericTextChange(setSearchText)}
                    required
                />

                <button type="submit" className="btn btn-primary mr-2" disabled={isSearching}>Search</button>
                <button type="button" className="btn btn-primary" onClick={handleReset}>Reset</button>
            </form>
        </div>

        {historicProductWeights.length === 0 && (
            <p className="text-center">There are no results.</p>
        )}

        {historicProductWeights.map((historicProductWeight) => (
            <div className="card mb-2" key={historicProductWeight._id}>
                <div className="card-body">
                    <h4>{historicProductWeight.name}</h4>

                    <dl>
                        <dt>Weight</dt>
                        <dd>{historicProductWeight.weight}{historicProductWeight.weightUnit}</dd>

                        {!isEmpty(historicProductWeight.description) && (
                            <>
                                <dt>Change Description</dt>
                                <dd><pre className="d-inline">{historicProductWeight.description}</pre></dd>
                            </>
                        )}

                        <dt>Edited On</dt>
                        <dd>{formatDateTimeFromSeconds(historicProductWeight.createdAt)}</dd>

                        <dt>Overridden by</dt>
                        <dd>{historicProductWeight.createdBy ? usernamesRef.current[historicProductWeight.createdBy] : 'Unknown'}</dd>

                        <dt>Override source</dt>
                        <dd>{historicProductWeight.changeSource ? overrideSourceLabels[historicProductWeight.changeSource] : 'Unknown'}</dd>
                    </dl>

                    <div className="text-right">
                        <a
                            className="btn btn-sm btn-outline-secondary mr-2"
                            href={urljoin(process.env.REACT_APP_STORE_ENDPOINT!!, 'product', historicProductWeight.product.paramName)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Open Product Page (Indo4ward)
                        </a>

                        <a
                            className="btn btn-sm btn-secondary"
                            href={historicProductWeight.product.sourceMarketPlaceUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Open Product Page (3rd Party)
                        </a>
                    </div>
                </div>
            </div>
        ))}

        <div className="text-center">
            <button
                className="btn btn-secondary"
                disabled={isShowingSearchResult || totalPages === currentPage}
                onClick={loadMoreHandler}
            >Load more</button>
        </div>
    </>;
}