import React from 'react';
import AddressFields from '../constants/address_fields';
import CountrySelect from "./country_select";

interface CountryStateSelectProps {
  countryLabel: string;
  countryValue: string;
  countryClassName: string;
  stateLabel: string;
  stateValue: string;
  stateClassName: string;
  onChange: Function;
}

export default function CountryStateSelect({
                                             countryLabel,
                                             countryValue,
                                             countryClassName,
                                             stateLabel,
                                             stateValue,
                                             stateClassName,
                                             onChange
                                           }: CountryStateSelectProps) {
  countryLabel = countryLabel || 'Country';
  stateLabel = stateLabel || 'State';

  const handleChangeCountry = (country: string) => {
    onChange(country, '');
  };

  const handleChangeState = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(countryValue, e.target.value);
  };

  const country = AddressFields.countries[countryValue];
  const showState = country && country.stateRequired;

  return <>
    <div className={countryClassName}>
      <label>{countryLabel}</label>
      <CountrySelect
        value={countryValue}
        onChange={handleChangeCountry}
      />
    </div>

    {showState && (
      <div className={stateClassName}>
        <label>{stateLabel}</label>
        <select
          className="form-control"
          value={stateValue}
          onChange={handleChangeState}
        >
          <option>Choose State</option>
          {Object.values(AddressFields.countries[countryValue].states).map((state: { stateValue: string | number | readonly string[] | undefined; displayValue: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
            <option key={index} value={state.stateValue}>{state.displayValue}</option>
          ))}
        </select>
      </div>
    )}
  </>;
}