import {createStore, applyMiddleware} from 'redux';
import {persistStore} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers/reducers';
import rootSaga from './sagas/sagas';

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware)
);

export type RootState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);