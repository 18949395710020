import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import SyntaxHighlighter from '../../syntax_highlighter';

interface ExchangeRateConfigValueProps {
  configValue: object
}

export default function ExchangeRateConfigValue(props: ExchangeRateConfigValueProps) {
  const [shown, setShown] = React.useState(false);

  const handleClose = () => setShown(false);

  return <React.Fragment>
    <button className="btn btn-link" onClick={() => setShown(true)}>Show</button>
    <Modal show={shown} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Exchange Rate Config</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SyntaxHighlighter language="json">
          {JSON.stringify(props.configValue, null, 2)}
        </SyntaxHighlighter>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
}