import {call, put, takeLatest} from 'redux-saga/effects';
import {actions, actionTypes} from '../actions';
import Api from '../../api/api';
import {sagaGenericErrorHandling} from '../../utils/error';
import {AxiosResponse} from "axios";

function* loadCategories() {
  try {
    let response: AxiosResponse;
    response = yield call(Api.product.categories);
    const categories = response.data;
    yield put(actions.category.setCategories(categories));
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.category.setFetchCategoriesError);
  }
}

export function* watchLoadCategories() {
  yield takeLatest(actionTypes.category.FETCH_CATEGORIES, loadCategories);
}