import React from 'react';
import {Link} from 'react-router-dom';
import Api from '../../api/api';
import {formatDateTimeFromSerializedDate} from '../../utils/date';
import {formatMoney, insertDecimalPoint} from '../../utils/number';
import {usePagination} from '../../lib/paginator';
import HeadTitle from '../head_title';
import currencyPrecision from "../../constants/currency_precision";
import {Voucher} from "../../types/models/voucher";

interface ExtendedVoucher extends Voucher {
  audienceText: string;
  reductionTypeText: string;
  reductionTargetText: string;
}

export default function VouchersPage() {
  const [page, paginator, setTotalPages] = usePagination();
  const [vouchers, setVouchers] = React.useState<ExtendedVoucher[]>([]);

  React.useEffect(() => {
    Api.admin.voucher.getAll(page)
      .then((resp) => {
        const newVouchers = resp.data.vouchers.map(voucher => {
          let audienceText;
          if (voucher.audience === 'r') {
            audienceText = 'Restricted';
          } else {
            audienceText = 'Public';
          }

          let reductionTypeText;
          if (voucher.reductionType === 'f') {
            reductionTypeText = 'Flat';
          } else {
            reductionTypeText = 'Percent';
          }

          let reductionTargetText;
          if (voucher.reductionTarget === 't') {
            reductionTargetText = 'Total Cost';
          } else {
            reductionTargetText = 'Order Handling Cost';
          }

          return {
            ...voucher,
            audienceText,
            reductionTypeText,
            reductionTargetText
          };
        });

        setVouchers(newVouchers);
        setTotalPages(resp.data.totalPages);
      });
  }, [page, setTotalPages]);

  return <React.Fragment>
    <HeadTitle
      title="Vouchers"
    />

    <h1>Vouchers</h1>
    <Link className="btn btn-primary mb-3" to="/vouchers/new">New Voucher</Link>

    {vouchers.map((voucher, index) => (
      <div className="card mb-3" key={index}>
        <h5 className="card-header">{voucher.code}</h5>
        <div className="card-body">
          <div className="row">
            <div className="col-4">
              <strong>Audience</strong>
              <br/>
              {voucher.audienceText}
            </div>
            {voucher.audience === 'r' && (
              <div className="col-4">
                <strong>Restricted Emails</strong>
                <br/>
                <p>
                  {voucher.audienceEmails && voucher.audienceEmails.map((email, index) => (
                    <React.Fragment key={index}>
                      {email}
                      <br/>
                    </React.Fragment>
                  ))}
                </p>
              </div>
            )}
            <div className="col-4">
              <strong>Reduction Type</strong>
              <br/>
              {voucher.reductionTypeText}
            </div>
            <div className="col-4">
              <strong>Reduction Amount</strong>
              <br/>
              {voucher.reductionType === 'f' ? (
                formatMoney(voucher.reductionCurrency, voucher.reductionAmount)
              ) : (
                `${insertDecimalPoint(voucher.reductionAmount, currencyPrecision[voucher.reductionCurrency])}%`
              )}
            </div>
            <div className="col-4">
              <strong>Reduction Target</strong>
              <br/>
              {voucher.reductionTargetText}
            </div>
            <div className="col-4">
              <strong>Validity Date</strong>
              <br/>
              {formatDateTimeFromSerializedDate(voucher.validityStartDate)}
              -
              {formatDateTimeFromSerializedDate(voucher.validityEndDate)}
            </div>
            <div className="col-4">
              <strong>Is Referral</strong>
              <br/>
              {voucher.isReferral.toString()}
            </div>
            {voucher.isReferral && (
              <div className="col-4">
                <strong>Referral Email</strong>
                <br/>
                {voucher.referralEmail}
              </div>
            )}

            <div className="col-4">
              <strong>Total Usage Limit</strong>
              <br/>
              {voucher.totalUsageLimit}
            </div>

            <div className="col-4">
              <strong>User Usage Limit</strong>
              <br/>
              {voucher.userUsageLimit}
            </div>

            <div className="col-4">
              <strong>Is Enabled</strong>
              <br/>
              {voucher.enabled.toString()}
            </div>

            <div className="col-4">
              <strong>Times Used</strong>
              <br/>
              {voucher.timesUsed}
            </div>

            <div className="col-4">
              <strong>Voucher Creator</strong>
              <br/>
              {voucher.voucherCreator}
            </div>

            <div className="col-12" style={{whiteSpace: 'pre-line'}}>
              <strong>Description</strong>
              <br/>
              {voucher.description}
            </div>
          </div>
        </div>

        <Link to={`/vouchers/${voucher._id}/edit`} className="btn btn-warning">Edit</Link>
      </div>
    ))}

    {paginator}
  </React.Fragment>;
}