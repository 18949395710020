import React from 'react';
import ExchangeRateConfigValue from './value/exchange_rate_config_value';
import {
  EXCHANGE_RATES_CONFIG_KEY,
  MINIMUM_SERVICE_FEE_CONFIG_KEY,
  REMOTE_AREA_FEE_CONFIG_KEY, SHIPPING_FEE_PER_PRODUCT_CONFIG_KEY
} from "../../constants/config_keys";
import MoneyConfigValue from "./value/money_config_value";


interface ConfigValueFactoryProps {
  configKey: string;
  configValue: object | string | number;
}

export default function ConfigValueFactory({configKey, configValue}: ConfigValueFactoryProps) {
  switch (configKey) {
    case EXCHANGE_RATES_CONFIG_KEY:
      return <ExchangeRateConfigValue configValue={configValue as object}/>;
    case REMOTE_AREA_FEE_CONFIG_KEY:
    case MINIMUM_SERVICE_FEE_CONFIG_KEY:
    case SHIPPING_FEE_PER_PRODUCT_CONFIG_KEY:
      return <MoneyConfigValue configValue={configValue as number} />;
  }

  let value: string;
  if (configValue) {
    value = JSON.stringify(configValue);
  } else {
    value = 'null';
  }

  return <>{value}</>;
}