import React from 'react';
import EditImagePage from '../edit_image_page';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';

export default function NewImagePagePage() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  return <>
    <HeadTitle
      title="New Image Page"
    />
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <h1>New Image Page</h1>
    <EditImagePage
      onError={setErrorMessage}
    />
  </>;
}