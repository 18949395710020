import React from 'react';
import QRCode from 'qrcode';
import {
  useHistory,
} from "react-router-dom";
import {connect, useDispatch} from 'react-redux';
import Api from '../../api/api';
import {InputFieldWithValidation} from '../fields_with_validation';
import {actions} from '../../store/actions';
import TimeoutAlert from '../timeout_alert';
import {extractAuthError} from '../../utils/error';
import HeadTitle from '../head_title';
import {RootState} from "../../store/reducers/reducers";
import {Dispatch} from "redux";
import {useQuery} from "../../utils/route";

interface TwoFactorAuthProps {
  signedIn: boolean;
  tfaPassed: boolean;
  onTfaComplete: () => void;
  fetchProfile: () => void;
}

function TwoFactorAuth(props: TwoFactorAuthProps) {
  const [imgDataSrc, setImgDataSrc] = React.useState<string | null>(null);
  const [code, setCode] = React.useState('');
  const [codeErrors, setCodeErrors] = React.useState<string[] | undefined>();
  const [serverError, setServerError] = React.useState<string | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const postLoginPath = query.get('login_path');

  React.useEffect(() => {
    Api.tfa.setup()
      .then(response => {
        // console.log('setup response data', response.data)
        const {data} = response;
        if (!data.alreadySetup) {
          QRCode.toDataURL(data.tfaURL, function (err, url) {
            setImgDataSrc(url);
          });
        }
      });
  }, []);

  React.useEffect(() => {
    if (!history) return;

    if (!props.signedIn) {
      history.replace({
        pathname: "/login"
      });
      return;
    }

    if (props.signedIn && props.tfaPassed) {
      let redirect = '/';
      if (postLoginPath) {
        redirect = postLoginPath;
      }
      history.replace(redirect);
      dispatch(actions.quotation.fetchPendingCount());
    }
  }, [dispatch, postLoginPath, history, props.signedIn, props.tfaPassed]);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // code must be digits
    // code must positive
    const {target} = e;
    const {value} = target;
    if (Number.isNaN(value))
      return;

    if (value.length > 6)
      return;

    const int = parseInt(value);
    if (int < 0)
      return;

    setCodeErrors(undefined);
    setCode(value);
  };

  const validate = () => {
    const errors = [];
    if (code.length < 6) {
      errors.push('Code must be 6 characters long');
    }

    setCodeErrors(errors);
    return errors.length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
      return;
    }

    Api.tfa.verify(code)
      .then(response => {
        if (response.data && response.data.success) {
          props.onTfaComplete();
          props.fetchProfile();
        }
      })
      .catch(error => {
        const errorMessage = extractAuthError(error);
        setServerError(errorMessage);
      });
  };

  return (
    <div>
      <HeadTitle
        title="Two factor authentication"
      />

      <TimeoutAlert
        errorMessage={serverError as string}
        onHide={() => setServerError(null)}
      />

      {imgDataSrc != null && <img src={imgDataSrc} alt="Scan with Google Authenticator app"/>}
      <br/>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Enter code from Google Authenticator</label>
          <InputFieldWithValidation
            type="number"
            value={code}
            onChange={handleCodeChange}
            errors={codeErrors}
            className="form-control"
            autoFocus/>
        </div>
        <button className="btn btn-primary" type="submit">Verify Code</button>
      </form>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    signedIn: state.user.signedIn,
    tfaPassed: state.user.tfaPassed,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onTfaComplete: () => dispatch(actions.user.tfaStep()),
    fetchProfile: () => dispatch(actions.user.fetchProfile())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth);