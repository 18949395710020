import React from "react";
import {AnyOrder, OrderRefund} from "../../types/models/order";
import {formatMoney} from "../../utils/number";
import VariantInfo, {VariantInfoProps} from "../variant_info";
import {ProductByIdMap} from "../../lib/order";
import {matchesProperty} from "lodash";
import {getQuotationItemVariantInfo, isInternal} from "../../lib/quotation_item";
import {OTHER_LINE_ITEM_LABELS} from "../../constants/order_refund";
import {RefundOrderResponse} from "../../types/responses/order";
import Api from "../../api/api";
import {extractAxiosError} from "../../utils/error";
import TimeoutAlert from "../timeout_alert";
import {formatDateTimeFromSeconds} from "../../utils/date";
import UserNameText from "../user_name_text";
import {ORDER_CANCELLED} from "../../constants/order_statuses";
import {
    PAYMENT_METHOD_STRIPE,
    PAYMENT_METHOD_STRIPE_CARD,
    PAYMENT_METHODS_PAY_NOW
} from "../../constants/payment_methods";

interface RefundsSectionProps {
    order: AnyOrder;
    products: ProductByIdMap;
    showRefundButton: boolean;
    onRefundButtonClick: () => void;
    isOrderCaptured: boolean;
    onRemovePreCaptureRefund: (refundRes: RefundOrderResponse) => void;
}

interface RefundCardProps {
    refund: OrderRefund;
    order: AnyOrder;
    products: ProductByIdMap;
    showRemoveButton?: boolean;
    onRemovePreCaptureRefund?(): void;
}


function RefundCard({refund, order, products, showRemoveButton, onRemovePreCaptureRefund}: RefundCardProps) {
    const keyFor = React.useCallback((item: {type: string, id?: string}) => {
        if (item.id) {
            return `${item.type},${item.id}`;
        } else {
            return item.type;
        }
    }, []);
    const handleRemoveClick = React.useCallback(() => {
        if (window.confirm("Are you sure?") && onRemovePreCaptureRefund) {
            onRemovePreCaptureRefund();
        }
    }, [onRemovePreCaptureRefund]);
    let refundCreator: React.ReactChild;
    if (refund.createdBy) {
        refundCreator = <UserNameText userId={refund.createdBy} />;
    } else {
        if ([PAYMENT_METHOD_STRIPE, PAYMENT_METHOD_STRIPE_CARD].includes(order.paymentMethod)) {
            refundCreator = 'I4W-Stripe Admin';
        } else {
            refundCreator = 'Unknown';
        }
    }
    return <div className="card card-body d-block mb-3">
        <div className="row">
            <div className="col">
                <h5>Refund #{refund.refNumber}</h5>
                <div className="text-muted mb-1">
                    Created by {refundCreator}
                    {' @ '}
                    {formatDateTimeFromSeconds(refund.createdAt)}
                </div>
            </div>
            <div className="col-auto">
                {showRemoveButton && (
                    <button className="btn btn-sm btn-danger"
                            onClick={handleRemoveClick}>Remove</button>
                )}
            </div>
        </div>


        <table className="table" id="refundTable">
            <thead>
            <tr>
                <th>Item</th>
                <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            {refund.lineItems.map(lineItem => {
                let row: React.ReactNode;
                if (lineItem.type === 'cart_item') {
                    const cartItem = order.cartItems.find(matchesProperty('_id', lineItem.id));
                    if (!cartItem) return null;
                    const product = products.productById[cartItem.productId];

                    row = <tr>
                        <td>
                            <div className="font-weight-lighter">{product.name}</div>
                            <div className="text-muted">{product.sourceMarketPlace}</div>
                            <div>
                                <strong>Quantity</strong>:
                                {' '}
                                {cartItem.quantity}
                            </div>
                            <div>
                                <VariantInfo
                                    variants={product.variants}
                                    variantPath={cartItem.variantPath}
                                />
                            </div>
                            <div>
                                {cartItem.message && (
                                    <>
                                        <strong>Message</strong>:
                                        <br/>
                                        {cartItem.message}
                                    </>
                                )}
                            </div>
                        </td>
                        <td>{formatMoney(order.totalCost.currency, lineItem.amount)}</td>
                    </tr>;
                } else if (lineItem.type === 'quotation_item') {
                    if (order.version !== 3) {
                        return null;
                    }
                    const quotationItem = order.quotationItems.find(matchesProperty('_id', lineItem.id));
                    if (!quotationItem) return null;
                    let variantInfo: VariantInfoProps | undefined;
                    let sourceText: string;
                    if (isInternal(quotationItem)) {
                        sourceText = 'INTERNAL';
                        variantInfo = getQuotationItemVariantInfo(quotationItem, products.productById[quotationItem.productSourceData.productId]);
                    } else {
                        sourceText = 'EXTERNAL';
                    }

                    row = <tr>
                        <td>
                            <div className="font-weight-lighter">{quotationItem.name}</div>
                            <div className="text-muted">{sourceText}</div>
                            <div>
                                <strong>Quantity</strong>:
                                {' '}
                                {quotationItem.quantity}
                            </div>
                            <div>
                                {variantInfo && <VariantInfo
                                    {...variantInfo}
                                />}
                            </div>
                            <div>
                                <strong>Instructions</strong>:
                                <br/>
                                {quotationItem.instructions}
                            </div>
                        </td>
                        <td>{formatMoney(order.totalCost.currency, lineItem.amount)}</td>
                    </tr>;
                } else {
                    row = <tr>
                        <td>
                            <div className="font-weight-lighter">
                                {OTHER_LINE_ITEM_LABELS[lineItem.type]}
                            </div>
                        </td>
                        <td>{formatMoney(order.totalCost.currency, lineItem.amount)}</td>
                    </tr>;
                }

                return <React.Fragment key={keyFor(lineItem)}>
                    {row}
                </React.Fragment>;
            })}
            <tr>
                <td>Total Refunds</td>
                <td>
                    {formatMoney(order.totalCost.currency, refund.amount)}
                </td>
            </tr>
            </tbody>
        </table>

        <div>
            <h6>Description</h6>
            {refund.description}
        </div>
    </div>;
}

export default function RefundsSection({
                                           order,
                                           products,
                                           onRefundButtonClick,
                                           showRefundButton,
                                           isOrderCaptured,
                                           onRemovePreCaptureRefund
                                       }: RefundsSectionProps) {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [isRemovingPreCaptureRefund, setIsRemovingPreCaptureRefund] = React.useState(false);

    const preCaptureRefund: OrderRefund | undefined = React.useMemo(() => {
        if (!Array.isArray(order.preCaptureRefunds) || order.preCaptureRefunds.length === 0) {
            return undefined;
        }

        const refund = order.preCaptureRefunds[order.preCaptureRefunds.length - 1];
        if (refund.amount === 0) {
            return undefined;
        } else {
            return refund;
        }
    }, [order]);

    const postCaptureRefunds: OrderRefund[] = React.useMemo(() => {
        return Array.isArray(order.postCaptureRefunds) ? order.postCaptureRefunds.slice().reverse() : [];
    }, [order]);

    const totalRefundAmount: number = React.useMemo(() => {
        let result = 0;
        if (preCaptureRefund) {
            result = preCaptureRefund.amount;
        }

        return postCaptureRefunds.reduce((total, refund) => {
            return total + refund.amount;
        }, result);
    }, [preCaptureRefund, postCaptureRefunds]);

    const sectionTitle: string = React.useMemo(() => {
        const isConfirmed = isOrderCaptured || order.status === ORDER_CANCELLED;
        return isConfirmed ? 'Confirmed Refunds' : 'Pending Refunds';
    }, [isOrderCaptured, order.status]);

    const handleRemovePreCaptureRefund = React.useCallback(() => {
        setIsRemovingPreCaptureRefund(true);
        Api.staff.order.refund(order.orderRef, {
            description: 'Cancellation of refund',
            lineItems: []
        })
            .then(res => {
                onRemovePreCaptureRefund(res.data);
            })
            .catch(e => {
                setErrorMessage(extractAxiosError(e));
            })
            .finally(() => {
                setIsRemovingPreCaptureRefund(false);
            });
    }, [order, onRemovePreCaptureRefund]);

    const shouldShowRemoveButton = !isRemovingPreCaptureRefund && !isOrderCaptured && order.status !== ORDER_CANCELLED;

    return <>
        <TimeoutAlert
            errorMessage={errorMessage}
            onHide={() => setErrorMessage(null)}
        />

        {showRefundButton && (
            <button className="btn btn-sm btn-warning float-right"
                    onClick={onRefundButtonClick}>Refund</button>
        )}
        <h4>{sectionTitle}</h4>
        {totalRefundAmount !== 0 ? (
            <>
                {!isOrderCaptured && (
                    <div className="card card-body font-weight-semibold flex-row mb-3">
                        <span className="flex-grow-1">Est. Charge After Refund:</span>
                            <span className="flex-grow-0">
                            {formatMoney(order.totalCost.currency, order.totalCost.amount - totalRefundAmount)}
                        </span>
                    </div>
                )}

                {postCaptureRefunds.map(refund => {
                    return <RefundCard
                        key={refund.refNumber}
                        refund={refund}
                        order={order}
                        products={products}
                    />;
                })}

                {preCaptureRefund && (
                    <RefundCard
                        showRemoveButton={shouldShowRemoveButton}
                        refund={preCaptureRefund}
                        order={order}
                        products={products}
                        onRemovePreCaptureRefund={handleRemovePreCaptureRefund}
                    />
                )}
            </>
        ) : <div className="text-center">
            No refunds for this order.
        </div>}

    </>;
}