import React from 'react';
import {useParams} from 'react-router-dom';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';
import {Product} from "../../api/models";
import EditProductOverride from "../edit_product_override";

interface OverrideProductPageParams {
  productId: string;
}

export default function OverrideProductPage() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [product, setProduct] = React.useState<Product | null>(null);

  const params = useParams<OverrideProductPageParams>();
  const {productId} = params;

  React.useEffect(() => {
    setIsLoading(true);
    Api.admin.product.get(productId)
      .then(res => {
        setProduct(res.data.product);
      })
      .catch((error) => {
        setErrorMessage(extractAxiosError(error));
      })
      .finally(() => setIsLoading(false));
  }, [productId]);

  return <>
    <HeadTitle
      title="Edit Product"
    />

    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <h1>Override Product</h1>

    {isLoading && (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )}

    {!isLoading && (
      <EditProductOverride
        onError={setErrorMessage}
        product={product}
      />
    )}
  </>;
}