import React from 'react';
import {CountryTax} from "../types/models/country_tax";
import CountrySelect from "./country_select";
import {InputFieldWithValidation, TextAreaWithValidation, ValidationFeedback} from "./fields_with_validation";
import {checkFloatForError, checkStringForError} from "../lib/validation";
import countriesAndStates from "../constants/address_fields";
import {Link, useHistory} from "react-router-dom";
import Api from "../api/api";
import {convertDineroAmtToFloatStr, convertToDineroAmt} from "../utils/number";
import {Currency} from "dinero.js";
import {omit} from "lodash";
import {extractAxiosError} from "../utils/error";

interface EditCountryTaxProps {
    onError: (message: string) => void;
    countryTax?: CountryTax;
}

type CountryTaxFields = Record<Exclude<keyof CountryTax, '_id'>, string>;
type FieldErrors = Partial<Record<keyof CountryTaxFields, string[]>>;

export default function EditCountryTax({countryTax, onError}: EditCountryTaxProps) {
    const [fields, setFields] = React.useState<CountryTaxFields>({
        country: '',
        thresholdCurrency: 'SGD',
        threshold: '0',
        percentage: '0',
        label: ''
    });
    const [fieldErrors, setFieldErrors] =  React.useState<FieldErrors>({});

    const history = useHistory();

    const isEditing = React.useMemo(() => {
        if (!!countryTax) {
            setFields({
                country: countryTax.country,
                thresholdCurrency: countryTax.thresholdCurrency,
                threshold: convertDineroAmtToFloatStr(countryTax.threshold, countryTax.thresholdCurrency as Currency),
                percentage: countryTax.percentage.toString(),
                label: countryTax.label ?? ''
            });
            return true;
        }

        return false;
    }, [countryTax]);

    const handleCountryChange = React.useCallback((country: string) => {
        setFields({
            ...fields,
            country
        });
    }, [fields]);

    const handleValueChange = React.useCallback((fieldName: keyof CountryTaxFields) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFields({
            ...fields,
            [fieldName]: e.target.value
        });
    }, [fields]);

    const validateFields = React.useCallback(() => {
        const errors: Required<FieldErrors> = {
            percentage: checkFloatForError(fields.percentage, {
                attrName: 'percentage',
                gt: 0
            }),
            threshold: checkFloatForError(fields.threshold, {
                attrName: 'threshold',
                gte: 0
            }),
            thresholdCurrency: [],
            country: checkStringForError(fields.country, {
                required: true,
                attrName: 'country'
            }),
            label: checkStringForError(fields.label, {
                attrName: 'label'
            })
        };

        const validCountries = Object.keys(countriesAndStates.countries);
        if (errors.country.length === 0 && !validCountries.includes(fields.country)) {
            errors.country.push('invalid country selected.');
        }

        const hasError = Object.values(errors).some(errorList => errorList.length > 0);
        if (hasError) {
            setFieldErrors(errors);
            return false;
        }
        return true;
    }, [fields]);

    const handleSave = React.useCallback(async () => {
        const isFieldsValid = validateFields();
        if (!isFieldsValid) {
            return;
        }

        const changes: Omit<CountryTax, '_id'> = {
            country: fields.country,
            thresholdCurrency: fields.thresholdCurrency,
            threshold: convertToDineroAmt(fields.threshold, fields.thresholdCurrency as Currency),
            percentage: parseFloat(fields.percentage),
            label: fields.label.trim()
        };
        try {
            if (isEditing) {
                await Api.admin.countryTaxes.update(countryTax!._id, omit(changes, 'country'));
            } else {
                await Api.admin.countryTaxes.create(changes);
            }

            history.push('/country_taxes'); 
        } catch (error) {
            onError(extractAxiosError(error));
        }
    }, [validateFields, fields, isEditing, history, onError, countryTax]);

    return <>
        <div className="form-group">
            <label>Country</label>
            <CountrySelect
                value={fields.country}
                onChange={handleCountryChange}
                disabled={isEditing}
            />
            <ValidationFeedback
                errors={fieldErrors.country}
            />
        </div>

        <div className="form-group">
            <label>Threshold Currency</label>
            <input
                className="form-control-plaintext"
                value={fields.thresholdCurrency}
                readOnly
            />
        </div>

        <div className="form-group">
            <label>Threshold</label>
            <InputFieldWithValidation
                type="number"
                value={fields.threshold}
                onChange={handleValueChange("threshold")}
                className="form-control"
                errors={fieldErrors.threshold}
            />
        </div>

        <div className="form-group">
            <label>Percentage</label>
            <InputFieldWithValidation
                type="number"
                value={fields.percentage}
                onChange={handleValueChange("percentage")}
                className="form-control"
                errors={fieldErrors.percentage}
            />
        </div>

        <div className="form-group">
            <label>Label</label>
            <TextAreaWithValidation
                className="form-control"
                onChange={handleValueChange("label")}
                value={fields.label}
                errors={fieldErrors.label}
            />
            <p>
                E.g. You may be liable for taxes & duties in the destination country.
            </p>
        </div>

        <button className="btn btn-primary" onClick={handleSave}>
            Save
        </button>
        <Link className="btn btn-secondary" to="/country_taxes">Cancel</Link>
    </>;
}