import React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import classNames from 'classnames';
import {AiOutlineEyeInvisible, AiOutlineFieldNumber} from 'react-icons/ai';
import urljoin from 'url-join';
import EditHomeProductGroup from '../edit_home_product_group';
import TimeoutAlert from '../timeout_alert';
import {extractAxiosError} from '../../utils/error';
import Api from '../../api/api';
import {HOME_GROUP_TYPE_CATEGORY} from '../../constants/home_group_types';
import {useCategories} from '../../hooks/categories';
import HeadTitle from '../head_title';
import {HomeProductGroup} from "../../api/models";

const SortableItem = SortableElement(({value, onRemove}: {value: HomeProductGroup, onRemove: Function}) => {
  const categories = useCategories();
  const {categoryId} = value.metadata;
  let categoryName = '';
  if (categories) {
    for (const category of Object.values(categories)) {
      if (categoryId === category.id) {
        categoryName = category.name;
        break;
      }
    }
  }

  let title = value.groupType;
  switch (value.groupType) {
    case HOME_GROUP_TYPE_CATEGORY:
      title = `Category: ${categoryName}`;
      break;
  }
  return <div className="card mb-3">
    <div className="card-body">
      {value.headerImagePath && (
        <img
          src={urljoin(process.env.REACT_APP_IMAGES_ENDPOINT as string, value.headerImagePath)}
          alt="header"
        />
      )}
      <h5 className="card-title">
        {title}
      </h5>
      <h6 className="card-subtitle mb-2 text-muted">
        <div>
          <AiOutlineEyeInvisible/>
          Visible:
          {' '}
          {value.isVisible.toString()}
        </div>

        <div>
          <AiOutlineFieldNumber/>
          Limit:
          {' '}
          {value.limit}
        </div>
      </h6>

      <br/>
      <button className="btn btn-danger" onClick={onRemove as React.MouseEventHandler<HTMLButtonElement>}>Remove</button>
    </div>
  </div>;
});

const SortableItems = SortableContainer(({children}: {children: React.ReactNode}) => {
  return <div>{children}</div>;
});


export default function HomeProductGroups() {
  const [productGroups, setProductGroups] = React.useState<HomeProductGroup[]>([]);
  const [showAdd, setShowAdd] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [lockRemoveBtn, setLockRemoveBtn] = React.useState(false);

  React.useEffect(() => {
    Api.admin.homeProductGroup.getAll()
      .then(res => {
        setProductGroups(res.data.homeProductGroups);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  }, []);

  const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
    const newProductGroups = arrayMove(productGroups, oldIndex, newIndex);
    setProductGroups(newProductGroups);
    if (oldIndex === newIndex) {
      return;
    }

    Api.admin.homeProductGroup.move(oldIndex, newIndex)
      .then(_ => {
        setSuccessMessage('Successfully moved group');
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  };

  const handleShowAdd = () => setShowAdd(!showAdd);
  const addContainerClassName = classNames('card', {
    'd-none': !showAdd,
  });

  const handleCreate = (group: any[]) => {
    setSuccessMessage('Successfully added group');
    setProductGroups(productGroups.concat(group));
    setShowAdd(false);
  };

  const handleRemove = (index: number) => () => {
    const group = productGroups[index];
    setLockRemoveBtn(true);
    const {_id} = group;
    Api.admin.homeProductGroup.delete(_id)
      .then(_ => {
        setSuccessMessage('Successfully removed group');
        const newProductGroups = productGroups.slice();
        newProductGroups.splice(index, 1);
        setProductGroups(newProductGroups);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      })
      .finally(() => setLockRemoveBtn(false));
  };

  return <>
    <HeadTitle
      title="Home Product Groups"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />
    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />
    <h1>Home Product Groups</h1>

    <div className="mb-3">
      <button className="btn btn-primary" onClick={handleShowAdd} disabled={lockRemoveBtn}>
        Add
      </button>
    </div>

    <div className={addContainerClassName}>
      <div className="card-header">
        Add Group
      </div>
      <div className="card-body">
        <EditHomeProductGroup
          showCancel={true}
          onCancel={() => setShowAdd(false)}
          onSave={handleCreate}
          onError={setErrorMessage}
        />
      </div>
    </div>

    <SortableItems onSortEnd={onSortEnd}>
      {productGroups.map((value, index) => (
        <SortableItem key={index} index={index} value={value} onRemove={handleRemove(index)}/>
      ))}
    </SortableItems>
  </>;
}