import React from "react";
import {convertToDineroAmt, insertDecimalPoint} from "../utils/number";
import currencyPrecision from "../constants/currency_precision";
import Dinero from "dinero.js";
import {ShippingTier} from "../api/models";
import AddressFields from "../constants/address_fields";
import {genericTextChange} from "../utils/forms";
import CurrencySelect from "./currency_select";

interface EditShippingTierProps {
  onSave: Function;
  onCancel: Function;
  shippingPriceTier?: ShippingTier
  destinationCountry: string;
}

export function EditShippingTier({onSave, onCancel, shippingPriceTier, destinationCountry}: EditShippingTierProps) {
  const [upperWeightLimit, setUpperWeightLimit] = React.useState<string>('1');
  const [price, setPrice] = React.useState<string>('1');
  const [priceCurrency, setPriceCurrency] = React.useState('SGD');

  React.useEffect(() => {
    if (shippingPriceTier) {
      const {
        upperWeightLimit,
        price,
        priceCurrency
      } = shippingPriceTier;

      setUpperWeightLimit(upperWeightLimit.toString());
      setPrice(insertDecimalPoint(price as number, currencyPrecision[priceCurrency]));
      setPriceCurrency(priceCurrency);
    } else {
      setUpperWeightLimit('1');
      setPrice('1');
      setPriceCurrency('SGD');
    }
  }, [shippingPriceTier]);

  const handleSave = () => {
    const shippingPrice = convertToDineroAmt(price, priceCurrency as Dinero.Currency);
    const shippingPriceTier: ShippingTier = {
      upperWeightLimit: parseFloat(upperWeightLimit),
      priceCurrency,
      destinationCountry,
      price: shippingPrice
    };

    onSave(shippingPriceTier);
  };

  return <div className="card">
    <div className="card-body">
      <div className="destinationCountry">
        <label>Destinaton Country</label>
        <input
          type="text"
          readOnly
          className="form-control-plaintext"
          value={AddressFields.countries[destinationCountry].displayValue}
        />
      </div>

      <div className="form-group">
        <label>Upper Weight Limit</label>
        <input
          type="number"
          className="form-control"
          value={upperWeightLimit}
          onChange={genericTextChange(setUpperWeightLimit)}
        />
      </div>
      <div className="form-group">
        <label>Price</label>
        <input
          type="number"
          className="form-control"
          value={price}
          onChange={genericTextChange(setPrice)}
        />
      </div>
      <div className="form-group">
        <label>Currency</label>

        <CurrencySelect
          className="form-control"
          value={priceCurrency}
          onChange={genericTextChange(setPriceCurrency)}
        />
      </div>

      <button className="btn btn-primary mr-2" onClick={handleSave}>Save</button>
      <button className="btn btn-secondary" onClick={onCancel as React.MouseEventHandler}>Cancel</button>
    </div>
  </div>;
}