import React from 'react';
import {useParams} from 'react-router-dom';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';
import EditMerchant from '../edit_merchant';
import {Merchant} from "../../api/models";

export default function EditMerchantPage() {
  const [merchant, setMerchant] = React.useState<Merchant | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const params = useParams<{id: string}>();

  const {id} = params;

  React.useEffect(() => {
    Api.admin.merchant.get(id)
      .then(res => {
        setMerchant(res.data.merchant);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  }, [id]);

  return <>
    <HeadTitle
      title="Edit Merchant"
    />
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <h1>Edit Merchant</h1>
    <EditMerchant
      onError={setErrorMessage}
      merchant={merchant as Merchant}
    />
  </>;
}