import React from "react";
import Api from "../../../api/api";
import TimeoutAlert from "../../timeout_alert";
import {extractAxiosError} from "../../../utils/error";

export interface RemoveBtnContentProps {
    productId: string;
    onRemove: () => void;
    onCancel: () => void;
}

export function RemoveBtnContent(props: RemoveBtnContentProps) {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [isRemoving, setIsRemoving] = React.useState(false);
    
    const removeHandler = React.useCallback(() => {
        if (isRemoving) return;

        setIsRemoving(true);
        Api.staff.productWeightOverride.remove(props.productId)
            .then(() => {
                props.onRemove();
            })
            .catch(e => {
                setErrorMessage(extractAxiosError(e));
            })
            .finally(() => setIsRemoving(false));
    }, [props, isRemoving]);
    
    return <div className={"card"}>
        <div className={"card-body"}>
            <TimeoutAlert
                errorMessage={errorMessage}
                onHide={() => setErrorMessage(null)}
            />
            
            <p>
                Confirm the removal of override?
            </p>
            <div className="text-right">
                <button onClick={removeHandler} className={"btn btn-danger mr-1"}>Remove</button>
                <button onClick={props.onCancel} className={"btn btn-secondary"}>Cancel</button>
            </div>
        </div>
    </div>;
}