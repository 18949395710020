import React from 'react';
import {
  ORDER_PAID,
  ORDER_PURCHASED,
  ORDER_AT_WAREHOUSE,
  ORDER_IN_TRANSIT,
} from '../../constants/order_statuses';
import PropTypes from 'prop-types';

export default function NextStepLabel({currentStatus} : {currentStatus: string}) {
  let nextStatus = null;
  switch (currentStatus) {
    case ORDER_PAID:
      nextStatus = ORDER_PURCHASED;
      break;
    case ORDER_PURCHASED:
      nextStatus = ORDER_AT_WAREHOUSE;
      break;
    case ORDER_AT_WAREHOUSE:
      nextStatus = ORDER_IN_TRANSIT;
      break;
  }
  if (nextStatus === null) {
    return <>
      No Next Step
    </>;
  } else {
    return <>
      Next Step, Update status to {nextStatus}
    </>;
  }
}

const NEXT_STEP_STATUSES = [ORDER_PAID, ORDER_PURCHASED, ORDER_AT_WAREHOUSE];
NextStepLabel.hasNextStep = (status: string) => NEXT_STEP_STATUSES.includes(status);

NextStepLabel.propTypes = {
  currentStatus: PropTypes.string.isRequired,
};