const currency_precision: {[key: string]: number} = {
  "USD": 2,
  "JPY": 0,
  "BGN": 2,
  "CZK": 2,
  "DKK": 2,
  "GBP": 2,
  "HUF": 2,
  "PLN": 2,
  "RON": 2,
  "SEK": 2,
  "CHF": 2,
  "ISK": 0,
  "NOK": 2,
  "HRK": 2,
  "RUB": 2,
  "TRY": 2,
  "AUD": 2,
  "BRL": 2,
  "CAD": 2,
  "CNY": 2,
  "HKD": 2,
  "IDR": 2,
  "ILS": 2,
  "INR": 2,
  "KRW": 0,
  "MXN": 2,
  "MYR": 2,
  "NZD": 2,
  "PHP": 2,
  "SGD": 2,
  "THB": 2,
  "ZAR": 2,
};


export default currency_precision;