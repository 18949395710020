import React from 'react';
import PercentageField from './editors/percentage_field';
import MoneyField from './editors/money_field';
import CurrencyField from './editors/currency_field';
import FlatOrPercentField from './editors/flat_or_percent_field';
import EmailField from './editors/email_field';
import {
  APP_CURRENCY_CONFIG_KEY,
  SERVICE_FEE_CONFIG_KEY,
  MINIMUM_SERVICE_FEE_CONFIG_KEY,
  EXCHANGE_RATE_MARK_UP_CONFIG_KEY,
  SHIPPING_FEE_PER_PRODUCT_CONFIG_KEY,
  OVERSEAS_SHIPPING_MARKUP_CONFIG_KEY,
  SERVICE_FEE_MODE_CONFIG_KEY,
  OPERATION_NOTIFICATION_EMAIL_CONFIG_KEY,
  ConfigValueTypes,
  ConfigKeysType,
  TIMEZONE_KEY,
  REMOTE_AREA_FEE_CONFIG_KEY,
  MAX_PRODUCTS_IN_ORDER
} from '../../constants/config_keys';
import {ConfigEditorProps} from "../../generic";
import {ConfigData} from "../../api/models";
import TimezoneSelectWrapper from "./editors/timezone_select_wrapper";
import PositiveInteger from "./editors/positive_integer_field";

interface ConfigEditorFactoryProps extends ConfigEditorProps<ConfigValueTypes[ConfigKeysType]> {
  editing: boolean;
  configurations: ConfigData;
}

export default function ConfigEditorFactory(props: ConfigEditorFactoryProps) {
  const {configKey, configValue, editing, onDone, onCancel, configurations} = props;
  let serviceFeeMode = configurations[SERVICE_FEE_MODE_CONFIG_KEY];

  if (!editing) {
    return null;
  }

  switch (configKey) {
    // percentage based fields
    case SERVICE_FEE_CONFIG_KEY:
      if (serviceFeeMode === 'f') {
        return <MoneyField
          configKey={configKey}
          configValue={configValue as number}
          onDone={onDone}
          onCancel={onCancel}
        />;
      } else {
        return <PercentageField
          configKey={configKey}
          configValue={configValue as number}
          onDone={onDone}
          onCancel={onCancel}
        />;
      }
    case EXCHANGE_RATE_MARK_UP_CONFIG_KEY:
    case OVERSEAS_SHIPPING_MARKUP_CONFIG_KEY:
      return <PercentageField
        configKey={configKey}
        configValue={configValue as number}
        onDone={onDone}
        onCancel={onCancel}
      />;
    // currency field
    case REMOTE_AREA_FEE_CONFIG_KEY:
    case MINIMUM_SERVICE_FEE_CONFIG_KEY:
    case SHIPPING_FEE_PER_PRODUCT_CONFIG_KEY:
      return <MoneyField
        configKey={configKey}
        configValue={configValue as number}
        onDone={onDone}
        onCancel={onCancel}
      />;
    case APP_CURRENCY_CONFIG_KEY:
      return <CurrencyField
        configKey={configKey}
        configValue={configValue as string}
        onDone={onDone}
        onCancel={onCancel}
      />;
    case SERVICE_FEE_MODE_CONFIG_KEY:
      return <FlatOrPercentField
        configKey={configKey}
        configValue={configValue as string}
        onDone={onDone}
        onCancel={onCancel}
      />;
    case OPERATION_NOTIFICATION_EMAIL_CONFIG_KEY:
      return <EmailField
        configKey={configKey}
        configValue={configValue as string}
        onDone={onDone}
        onCancel={onCancel}
      />;
    case TIMEZONE_KEY:
      return <TimezoneSelectWrapper
        configKey={configKey}
        configValue={configValue as string}
        onDone={onDone}
        onCancel={onCancel}
      />;
    case MAX_PRODUCTS_IN_ORDER:
      return <PositiveInteger configKey={configKey}
        configValue={configValue as number}
        onDone={onDone}
        onCancel={onCancel}
      />;
  }

}