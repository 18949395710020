import {actions} from '../actions';
import {createReducer} from "@reduxjs/toolkit";
import {UserProfile} from "../../api/models";

const { signIn, tfaStep, setProfile, signOut, setFetchProfileError } = actions.user;

export interface UserState {
  signedIn: boolean;
  tfaPassed: boolean;
  profile: UserProfile | null;
  fetchProfileError?: string;
}

const defaultState: UserState = {
  signedIn: false,
  tfaPassed: false,
  profile: null,
  fetchProfileError: undefined,
};

const userReducer = createReducer(defaultState, builder => {
  builder
    .addCase(signIn, (state) => {
      state.signedIn = true;
    })
    .addCase(tfaStep, (state) => {
      state.tfaPassed = true;
    })
    .addCase(setProfile, (state, action) => {
      Object.assign(state, {
        profile: action.payload.profile,
        signedIn: action.payload.signedIn,
        tfaPassed: action.payload.tfaPassed,
      });
    })
    .addCase(signOut, () => {
      return defaultState;
    })
    .addCase(setFetchProfileError, (state, action) => {
      state.fetchProfileError = action.payload;
    });
});

export default userReducer;