import React from 'react';
import CSVReader, {IFileInfo} from 'react-csv-reader';
import {Button, Modal} from 'react-bootstrap';
import ScrollUpButton from "react-scroll-up-button";
import deepEqual from 'deep-equal';
import {FiEdit, FiTrash2} from 'react-icons/fi';
import fileDownload from 'js-file-download';
import {useDebouncedState} from 'window-table';
import Api from '../../api/api';
import {genericTextChange} from '../../utils/forms';
import CategorySettingsTable from '../category_settings_table';
import EditCategorySettingModal from '../edit_category_setting_modal';
import CategorySettingConflictModal from '../category_setting_conflict_modal';
import CategorySettingInvalidsModal from '../category_setting_invalids_modal';
import HeadTitle from '../head_title';
import {CategorySetting} from "../../api/models";
import {Column} from "window-table/dist/core/types";
import {toTitleCase} from "../../utils/string";

const COLUMN_INDEX_TO_NAME = [
  'categorySource',
  'category',
  'weight',
  'weightUnit',
  'volumeMetricWeight',
  'volumeMetricWeightUnit',
  'isBlocked',
  'isQuotation',
  'isDisputable',
  'isEqualPrecedence',
];

type CategorySettingRow = Partial<Record<keyof CategorySetting, string>>;

export default function CategorySettings() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [settingsToBeConfirmed, setSettingsToBeConfirmed] = React.useState<CategorySetting[] | null>(null);
  const [categorySettings, setCategorySettings] = React.useState<CategorySetting[]>([]);
  const [editingCategory, setEditingCategory] = React.useState<CategorySetting | null>(null);
  const [conflictingSettings, setConflictingSettings] = React.useState<Record<string, CategorySetting[]>>({});
  const [invalidSettings, setInvalidSettings] = React.useState([]);
  const [textFilter, debouncedTextFilter, setTextFilter] = useDebouncedState('');

  React.useEffect(() => {
    if (!errorMessage) return;

    const timeoutId = setTimeout(() => setErrorMessage(null));
    return () => clearTimeout(timeoutId);
  }, [errorMessage]);

  React.useEffect(() => {
    Api.admin.categorySetting.getAll()
      .then(res => {
        setCategorySettings(res.data.settings);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message);
      });
  }, []);

  const handleImport = (data: Array<string[]>, _: IFileInfo) => {
    const dataWithoutHeader = data.slice(1);

    const lastRow = dataWithoutHeader[dataWithoutHeader.length - 1];
    if (!lastRow[0]) {
      dataWithoutHeader.pop();
    }

    if (data[0].length !== COLUMN_INDEX_TO_NAME.length) {
      setErrorMessage('CSV invalid header');
      return;
    }

    const settings: CategorySetting[] = [];
    const conflicts: Record<string, CategorySetting[]> = {};
    for (const row of dataWithoutHeader) {
      const rowSetting: CategorySettingRow = {};
      for (let i = 0; i < row.length; i++) {
        rowSetting[COLUMN_INDEX_TO_NAME[i]] = row[i];
      }
      const setting: Partial<CategorySetting> = {
        ...rowSetting,
        weight: parseFloat(rowSetting.weight!),
        volumeMetricWeight: parseFloat(rowSetting.volumeMetricWeight!)
      };

      // check for conflict
      const key = `${setting.categorySource}|${setting.category}`;
      let conflictList = conflicts[key];
      if (!conflictList) {
        conflictList = [];
        conflicts[key] = conflictList;
      }

      let similarFound = false;
      for (const item of conflictList) {
        if (deepEqual(item, setting)) {
          similarFound = true;
          break;
        }
      }
      if (!similarFound) {
        conflictList.push(setting as CategorySetting);
      }

      settings.push(setting as CategorySetting);
    }

    const hasConflicts = Object.values(conflicts).some(c => c.length > 1);
    console.log('hasConflicts', hasConflicts);
    if (hasConflicts) {
      // resolve conflict first
      setConflictingSettings(conflicts);
    } else {
      setSettingsToBeConfirmed(settings);
    }
  };

  const handleExport = () => {
    let data = COLUMN_INDEX_TO_NAME.join(',');
    data += '\n';
    for (const categorySetting of categorySettings) {
      const row: string[] = [];
      for (const name of COLUMN_INDEX_TO_NAME) {
        row.push(categorySetting[name]);
      }
      data += row.join(',');
      data += '\n';
    }
    fileDownload(new Blob([data], {type: 'text/csv'}), 'export.csv');
  };

  const handleImportError = (error: {message: string}) => {
    setErrorMessage(error.message);
    console.error(error);
  };

  const handleHideConfirmationModal = () => setSettingsToBeConfirmed(null);
  const handleConfirmConfirmationModal = () => {
    setSettingsToBeConfirmed(null);
    Api.admin.categorySetting.import(settingsToBeConfirmed as object)
      .then((res) => {
        setCategorySettings(res.data.settings);
        setInvalidSettings(res.data.invalidSettings);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message);
      });
  };

  const hideConflictModal = () => {
    setConflictingSettings({});
  };
  const handleConflictResolved = (newCategorySettings: CategorySetting[]) => {
    setSettingsToBeConfirmed(newCategorySettings);
    hideConflictModal();
  };

  const handleEdit = (categorySetting: CategorySetting) => () => setEditingCategory(categorySetting);
  const closeEditModal = () => setEditingCategory(null);

  const handleDelete = (categorySetting: CategorySetting) => () => {
    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) {
      return;
    }

    const {categorySource, category} = categorySetting;
    Api.admin.categorySetting.delete(categorySource, category)
      .then(_ => {
        const index = categorySettings.findIndex(cs => {
          const {_id} = cs;
          return _id === categorySetting._id;
        });

        const newCategorySettings = categorySettings.slice();
        newCategorySettings.splice(index, 1);
        setCategorySettings(newCategorySettings);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message);
      });
  };

  const handleSave = (categorySetting: CategorySetting) => {
    Api.admin.categorySetting.update(categorySetting)
      .then(_ => {
        const {_id} = editingCategory as CategorySetting;
        const index = categorySettings.findIndex(cs => {
          const {_id: _id1} = cs;
          return _id1 === _id;
        });
        const newCategorySettings: CategorySetting[] = categorySettings.slice();
        newCategorySettings[index] = categorySetting;
        setCategorySettings(newCategorySettings);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message);
      })
      .finally(() => {
        closeEditModal();
      });
  };

  const actionColumn = ({row}: {row?: CategorySetting, column?: Column<keyof CategorySetting, CategorySetting>}) => {
    if (row) {
      return <React.Fragment>
        <button className="btn btn-link" onClick={handleEdit(row)}>
          <FiEdit className="feather"/>
        </button>
        <button className="btn btn-link" onClick={handleDelete(row)}>
          <FiTrash2 className="feather"/>
        </button>
      </React.Fragment>;
    } else {
      return null;
    }
  };

  const tableColumns = [
    ...CategorySettingsTable.DEFAULT_TABLE_COLUMNS,
    {key: 'actions', width: 50, title: 'Actions', Component: actionColumn},
  ];

  const showConfirmModal = settingsToBeConfirmed !== null;
  const showConflictModal = Object.keys(conflictingSettings).length > 0;

  const showInvalidsModal = invalidSettings.length > 0;

  const hideInvalidsModal = () => setInvalidSettings([]);

  return <div>
    <HeadTitle
      title="Category Settings"
    />

    <h1>Category Settings</h1>
    {errorMessage !== null && (
      <div className="alert alert-danger" role="alert">
        {toTitleCase(errorMessage!)}
      </div>
    )}

    <CSVReader
      label="Import Category Setting CSV"
      cssClass="mb-2"
      cssLabelClass="d-block"
      onFileLoaded={handleImport}
      onError={handleImportError}
    />
    <button className="btn btn-secondary mb-3" onClick={handleExport} disabled={categorySettings.length === 0}>Export
    </button>

    <div className="form-group">
      <label>Text Filter</label>
      <input
        type="text"
        className="form-control"
        value={textFilter}
        onChange={genericTextChange(setTextFilter) as React.ChangeEventHandler}
        placeholder="Search category"
      />
    </div>

    <Modal scrollable={true} size="xl" show={showConfirmModal} onHide={handleHideConfirmationModal} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm new category settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The imported settings will replace existing settings, are you sure?

        {settingsToBeConfirmed && <CategorySettingsTable
            categorySettings={settingsToBeConfirmed}
        />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirmConfirmationModal}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleHideConfirmationModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>

    <CategorySettingConflictModal
      show={showConflictModal}
      onHide={hideConflictModal}
      onResolve={handleConflictResolved}
      conflictingSettings={conflictingSettings}
    />

    <CategorySettingInvalidsModal
      show={showInvalidsModal}
      onHide={hideInvalidsModal}
      invalidSettings={invalidSettings}
    />

    <CategorySettingsTable
      categorySettings={categorySettings}
      columns={tableColumns}
      textFilter={debouncedTextFilter}
    />

    <EditCategorySettingModal
      show={editingCategory !== null}
      onHide={closeEditModal}
      categorySetting={(editingCategory !== null && editingCategory) as CategorySetting}
      onSave={handleSave}
    />
    <ScrollUpButton/>
  </div>;
}
