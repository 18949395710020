import {splitIntoChunks} from '../utils/array';
import {Category, CategoryMap} from "../api/models";

type CategoryIdMap = {
  [categoryId: number]: Category;
};

export class CategoryBrowser {
  categoryIdMap: CategoryIdMap;
  categoryCopy: CategoryMap;

  constructor(categories: CategoryMap) {
    this.categoryCopy = JSON.parse(JSON.stringify(categories));
    this.categoryIdMap = {};
    const categoryIdMap: CategoryIdMap = {};

    const initialize = (currentNode=categories, pathParts: string[] = []) => {
      for (const [key, value] of Object.entries(currentNode)) {
        const currentPathParts = pathParts.concat(key);
        categoryIdMap[value.id] = value;
        initialize(value.children, currentPathParts);
      }
    };
    initialize();

    this.categoryIdMap = categoryIdMap;

  }

  findParents(categoryId: number) {
    let idStr: string = categoryId.toString();
    if (idStr.length === 5) {
      idStr = '0' + idStr;
    }

    // split into parts of 2
    const parts = splitIntoChunks(idStr, 2);
    // find current level
    let currentLevel = 0;
    for (const part of parts) {
      if (part === '00') {
        break;
      }
      currentLevel++;
    }

    const numberOfParents = currentLevel - 1;
    const parents = [];
    for (let i = 1; i <= numberOfParents; i++) {
      const parentIdParts = parts.slice(0, i);
      const zerosSize = parts.length - i;
      for (let x = 0; x < zerosSize; x++)
        parentIdParts.push('00');

      const parentId = parseInt(parentIdParts.join(''));
      parents.push(this.categoryIdMap[parentId]);
    }

    return parents;
  };
}

// export function CategoryBrowser(categories: object) {
//   const categoryCopy = JSON.parse(JSON.stringify(categories));
//   const categoryIdMap: LooseObject = {};
//
//   const initialize = (currentNode = categories, pathParts = []) => {
//     Object.entries(currentNode).forEach(
//       (key: string | any, value: any) => {
//         const currentPathParts = pathParts.concat(key);
//         categoryIdMap[value.id] = value;
//         initialize(value.children, currentPathParts);
//       });
//   };
//   initialize();
//
//   this.categoryIdMap = categoryIdMap;
// }
//
// CategoryBrowser.prototype.findParents = function (categoryId: number) {
//   let idStr: string = categoryId.toString();
//   if (idStr.length === 5) {
//     idStr = '0' + idStr;
//   }
//
//   // split into parts of 2
//   const parts = splitIntoChunks(idStr, 2);
//   // find current level
//   let currentLevel = 0;
//   for (const part of parts) {
//     if (part === '00') {
//       break;
//     }
//     currentLevel++;
//   }
//
//   const numberOfParents = currentLevel - 1;
//   const parents = [];
//   for (let i = 1; i <= numberOfParents; i++) {
//     const parentIdParts = parts.slice(0, i);
//     const zerosSize = parts.length - i;
//     for (let x = 0; x < zerosSize; x++)
//       parentIdParts.push('00');
//
//     const parentId = parseInt(parentIdParts.join(''));
//     parents.push(this.categoryIdMap[parentId]);
//   }
//
//   return parents;
// };