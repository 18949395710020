import React from 'react';
import HeadTitle from "../head_title";
import TimeoutAlert from "../timeout_alert";
import EditRegion from "../edit_region";
import {Region} from "../../api/models";
import {useParams} from "react-router-dom";
import Api from "../../api/api";
import {extractAxiosError} from "../../utils/error";

interface EditRegionQueryParams {
  country: string;
}

export default function EditRegionPage() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [region, setRegion] = React.useState<Region>();

  const params = useParams<EditRegionQueryParams>();

  const {country} = params;

  React.useEffect(() => {
    Api.admin.region.get(country)
      .then(res => {
        setRegion(res.data.region);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  }, [country]);

  return <>
    <HeadTitle
      title="Edit Region"
    />
    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <h1>Edit Region</h1>
    <EditRegion
      onError={setErrorMessage}
      region={region}
    />
  </>;
}