import React from 'react';
import Api from '../../api/api';
import ConfigValueFactory from '../config/config_value_factory';
import ConfigEditorFactory from '../config/config_editor_factory';
import HeadTitle from '../head_title';
import {ConfigKeysType, ConfigValueTypes} from "../../constants/config_keys";
import TimeoutAlert from "../timeout_alert";

function ConfigPage() {
  const [readOnlyConfigKeys, setReadOnlyConfigKeys] = React.useState<ConfigKeysType[]>([]);
  const [configurations, setConfigurations] = React.useState<ConfigValueTypes>();
  const [editingKey, setEditingKey] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    Api.admin.config.getAll()
      .then((res) => {
        setConfigurations(res.data.data);
        setReadOnlyConfigKeys(res.data.readOnlyKeys);
      });
  }, []);

  const handleEditKey = (key: string) => () => setEditingKey(key);
  const handleEditDone = (key: string) => (value: React.ReactNode) => {
    setEditingKey(null);
    Api.admin.config.set(key, value)
      .then(() => {
        const newConfig = Object.assign({}, configurations, {
          [key]: value,
        });
        setConfigurations(newConfig);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error);
      });
  };
  const handleEditCancel = () => setEditingKey(null);

  return <React.Fragment>
    <HeadTitle
      title="Configuration"
    />

    <h1>Configurations</h1>
    <TimeoutAlert onHide={() => setErrorMessage(null)} errorMessage={errorMessage} />

    <div className="table-responsive">
      <table className="table">
        <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>

        {configurations && Object.entries(configurations).map(([key, value], index) => (
          <tr key={index}>
            <td>{key}</td>
            <td>
              <ConfigValueFactory configKey={key} configValue={value} />
            </td>
            <td>
              {readOnlyConfigKeys.indexOf(key as ConfigKeysType) === -1 ? (
                <React.Fragment>
                  <button className="btn btn-link" onClick={handleEditKey(key)}>Edit</button>
                  {ConfigEditorFactory({
                    configurations: configurations,
                    configKey: key as ConfigKeysType,
                    configValue: value,
                    onDone: handleEditDone(key),
                    editing: editingKey === key,
                    onCancel: handleEditCancel
                  })}
                </React.Fragment>
              ) : "Read Only"}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  </React.Fragment>;
}

export default ConfigPage;
