import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {genericTextChange} from '../../utils/forms';
import {ModalProps} from "../../generic";
import {JourneyLog} from "../../api/models";


interface AddJourneyToLogModalProps extends ModalProps {
  currentJourneyLog: JourneyLog[];
}

export default function AddJourneyToLogModal({show, onHide, currentJourneyLog, onSave}: AddJourneyToLogModalProps) {
  const [details, setDetails] = React.useState('');

  const handleSave = () => {
    onSave(details);
    setDetails('');
  };

  return <Modal
    scrollable={true}
    show={show}
    onHide={onHide}
    animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Add To Journey Log</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h3>Current Journey Log</h3>
      {currentJourneyLog.length === 0 ? (
        <p>The log is empty</p>
      ) : (
        <table className="table">
          <thead>
          <tr>
            <td>Timestamp</td>
            <td>Details</td>
          </tr>
          </thead>
          <tbody>
          {currentJourneyLog.map((transitStep, index) => (
            <tr key={index}>
              <td>{transitStep.timestamp}</td>
              <td>{transitStep.details}</td>
            </tr>
          ))}
          </tbody>
        </table>
      )}

      <h3>Add</h3>
      <div className="form-group">
        <label>Details</label>
        <textarea
          className="form-control"
          value={details}
          onChange={genericTextChange(setDetails) as React.ChangeEventHandler<HTMLElement>}
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleSave as React.MouseEventHandler<HTMLElement>}>
        Save
      </Button>
      <Button variant="secondary" onClick={onHide as React.MouseEventHandler<HTMLElement>}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;
}

AddJourneyToLogModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  currentJourneyLog: PropTypes.arrayOf(PropTypes.object),
};