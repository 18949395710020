interface NumberValidationConfig {
  gt?: number;
  gte?: number;
  lt?: number;
  lte?: number;
  attrName?: string;
}

function checkNumberForError(numberStr: string, parseFn: (numStr: string) => number, config: NumberValidationConfig): string[] {
  const {
    gt,
    gte,
    lt,
    lte,
  } = config;
  let {attrName} = config;
  if (!attrName) {
    attrName = 'Number';
  }

  const errors = [];
  const num = parseFn(numberStr.trim());
  if (isNaN(Number(numberStr)) || isNaN(num)) {
    errors.push(attrName + ' is invalid');
  }

  if (gte !== undefined && num < gte) {
    errors.push(`${attrName} must be greater than or equal to ${gte}`);
  } else if (gt !== undefined && num <= gt) {
    errors.push(`${attrName} must be greater than ${gt}`);
  }

  if (lte !== undefined && num > lte) {
    errors.push(`${attrName} must be less than or equal to ${lte}`);
  } else if (lt !== undefined && num >= lt) {
    errors.push(`${attrName} must be less than ${lt}`);
  }

  return errors;
}

export function checkFloatForError(floatStr: string, config: NumberValidationConfig) {
  return checkNumberForError(floatStr, parseFloat, config);
}

export function checkIntForError(intStr: string, config: NumberValidationConfig) {
  return checkNumberForError(intStr, parseInt, config);
}

interface StringValidationConfig {
  attrName?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
}

export function checkStringForError(str: string | null | undefined, config: StringValidationConfig = {}): string[] {
  let { attrName } = config;
  const {
    minLength,
    maxLength,
    required,
  } = config;

  if (!attrName) {
    attrName = 'field';
  }

  if (typeof(str) !== 'string') {
    return [`${attrName} is not a string`];
  }

  if (required && !str) {
    return [`${attrName} is required`];
  }

  const errors = [];
  const trimmed = str.trim();
  if (minLength !== undefined && trimmed.length < minLength) {
    errors.push(`${attrName} must be at least ${minLength} in length`);
  }

  if (maxLength !== undefined && trimmed.length > maxLength) {
    errors.push(`${attrName} only can have up to ${maxLength} characters`);
  }

  return errors;
}