import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import AddressFields from '../constants/address_fields';
import {generateFieldsChangeHandler} from "../utils/forms";
import CountrySelect from "./country_select";
import Api from '../api/api';
import {extractAxiosError} from "../utils/error";
import {Region} from "../api/models";
import {SUPPORTED_CURRENCIES} from "../constants/currency";
import {InputFieldWithValidation} from "./fields_with_validation";
import {checkFloatForError} from "../lib/validation";
import {convertDineroAmtToFloatStr, convertToDineroAmt} from "../utils/number";
import {Currency} from "dinero.js";

interface EditRegionProps {
  onError: (errorMessage: string) => void;
  region?: Region;
}

interface Fields {
  country: string;
  enabledStates: string[];
  enabled: boolean;
  shippingPricePerHundredGram: string;
  shippingPricePerHundredGramCurrency: string;
  flatShippingFee: string;
  flatShippingFeeCurrency: string;
  isDefault: boolean;
}

type FieldErrors = {
  [Field in keyof Fields]?: string[];
};

const defaultFields: Fields = {
  country: 'SG',
  enabledStates: [],
  enabled: true,
  shippingPricePerHundredGram: '0',
  shippingPricePerHundredGramCurrency: 'SGD',
  flatShippingFee: '0',
  flatShippingFeeCurrency: 'SGD',
  isDefault: false
};

export default function EditRegion({onError, region}: EditRegionProps) {
  const [fields, setFields] = React.useState<Fields>(defaultFields);
  const [fieldErrors, setFieldErrors] = React.useState<FieldErrors>({});

  const history = useHistory();

  React.useEffect(() => {
    if (!region) return;

    let {
      shippingPricePerHundredGramCurrency
    } = region;
    if (!shippingPricePerHundredGramCurrency) {
      shippingPricePerHundredGramCurrency = 'SGD';
    }

    const newRegion = Object.assign({}, region, {
      shippingPricePerHundredGram: region.shippingPricePerHundredGram ?
        convertDineroAmtToFloatStr(region.shippingPricePerHundredGram, shippingPricePerHundredGramCurrency as Currency) :
        '0',
      flatShippingFee: region.flatShippingFee ?
        convertDineroAmtToFloatStr(region.flatShippingFee, region.flatShippingFeeCurrency as Currency) :
        '0'
    });
    setFields(newRegion);
  }, [region]);

  const {states} = AddressFields.countries[fields.country];
  const hasStates = Object.keys(states).length > 0;

  const isEditing = !!region;

  const handleEnabledStatesChange = (stateValue: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    const newEnabledStates = fields.enabledStates.slice();
    const index = fields.enabledStates.indexOf(stateValue);
    let changed = false;

    if (checked) {
      if (index === -1) {
        changed = true;
        newEnabledStates.push(stateValue);
      }
    } else {
      if (index !== -1) {
        changed = true;
        newEnabledStates.splice(index, 1);
      }
    }

    if (changed) {
      setFields({
        ...fields,
        enabledStates: newEnabledStates
      });
    }
  };

  const handleCheckboxChange = generateFieldsChangeHandler({
    fields, setFields, fieldErrors, setFieldErrors,
    valueExtractor: (e: React.ChangeEvent<HTMLInputElement>) => e.target.checked
  });

  const handleRawValueChange = generateFieldsChangeHandler({
    fields, setFields, fieldErrors, setFieldErrors,
    valueExtractor: (selectedCountry: string) => selectedCountry
  });

  const handleValueChange = generateFieldsChangeHandler({
    fields, setFields, fieldErrors, setFieldErrors,
    valueExtractor: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => e.target.value
  });

  const handleSelectAllEnabledStates = React.useCallback(() => {
    const {states} = AddressFields.countries[fields.country];
    setFields({
      ...fields,
      enabledStates: Object.keys(states)
    });
  }, [fields]);

  const handleDeselectAllEnabledStates = React.useCallback(() => {
    setFields({
      ...fields,
      enabledStates: []
    });
  }, [fields]);

  const handleIsDefaultChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const isDefault = e.target.checked;
    const enabled = fields.enabled || isDefault;
    setFields({
      ...fields,
      enabled,
      isDefault
    });
  }, [fields]);

  const validate = () => {
    const errors: FieldErrors = {
      shippingPricePerHundredGram: [],
      flatShippingFee: []
    };
    errors.shippingPricePerHundredGram = checkMoneyForError(fields.shippingPricePerHundredGram);
    errors.flatShippingFee = checkMoneyForError(fields.flatShippingFee);

    const hasError = Object.values(errors).some(errorList => errorList!.length > 0);
    setFieldErrors(errors);
    return !hasError;
  };

  const handleSave = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }

    const shippingPricePerUnit = convertToDineroAmt(fields.shippingPricePerHundredGram, fields.shippingPricePerHundredGramCurrency as Currency);
    const flatShippingFee = convertToDineroAmt(fields.flatShippingFee, fields.flatShippingFeeCurrency as Currency);
    const regionToSave = Object.assign({}, fields, {
      shippingPricePerHundredGram: shippingPricePerUnit,
      flatShippingFee
    });
    let promise;
    if (isEditing) {
      promise = Api.admin.region.update(regionToSave);
    } else {
      promise = Api.admin.region.create(regionToSave);
    }
    promise
      .then(() => {
        history.push('/regions');
      })
      .catch(error => {
        onError(extractAxiosError(error));
      });
  };

  return <>
    <div className="form-group">
      <label>Country</label>
      {isEditing ? (
          <input
            type="text"
            readOnly
            className="form-control-plaintext"
            value={AddressFields.countries[fields.country].displayValue || fields.country}
          />
      ) : (
        <CountrySelect
          value={fields.country}
          onChange={handleRawValueChange('country')}
        />
      )}
    </div>

    <div className="form-group">
      <label>Enabled States</label>

      {hasStates ? (
        <>
          <div>
            <button className="btn btn-link" onClick={handleSelectAllEnabledStates}>Select All</button>
            /
            <button className="btn btn-link" onClick={handleDeselectAllEnabledStates}>Deselect All</button>
          </div>
          <div className="row">
            <div className="col-lg-3">
              {Object.values(states).map((state, index) => {
                const id = `state_${state.stateValue}`;
                return <div className="form-check form-check-inline" key={index}>
                  <input
                    id={id}
                    className="form-check-input"
                    type="checkbox"
                    name={id}
                    checked={fields.enabledStates.includes(state.stateValue)}
                    onChange={handleEnabledStatesChange(state.stateValue)}
                  />
                  <label className="form-check-label" htmlFor={id}>
                    {state.displayValue}
                  </label>
                </div>;
              })}
            </div>
          </div>
        </>
      ) : (
        <p>Country has no states</p>
      )}
    </div>

    <div className="form-group">
      <label>Shipping Price / 100g</label>
      <div className="row">
        <div className="col-md-3">
          <select
            className="form-control"
            value={fields.shippingPricePerHundredGramCurrency}
            onChange={handleValueChange('shippingPricePerHundredGramCurrency')}
          >
            {SUPPORTED_CURRENCIES.map((currency, index) => (
              <option key={index} value={currency}>{currency}</option>
            ))}
          </select>
        </div>
        <div className="col-md-9">
          <InputFieldWithValidation
            type="number"
            className="form-control"
            onChange={handleValueChange('shippingPricePerHundredGram')}
            value={fields.shippingPricePerHundredGram}
            errors={fieldErrors.shippingPricePerHundredGram}
          />
        </div>
      </div>
    </div>

    <div className="form-group">
      <label>Flat Shipping Fee</label>
      <div className="row">
        <div className="col-md-3">
          <select
            className="form-control"
            value={fields.flatShippingFeeCurrency}
            onChange={handleValueChange('flatShippingFeeCurrency')}
          >
            {SUPPORTED_CURRENCIES.map((currency, index) => (
              <option key={index} value={currency}>{currency}</option>
            ))}
          </select>
        </div>
        <div className="col-md-9">
          <InputFieldWithValidation
            type="number"
            className="form-control"
            onChange={handleValueChange('flatShippingFee')}
            value={fields.flatShippingFee}
            errors={fieldErrors.flatShippingFee}
          />
        </div>
      </div>
    </div>

    <div className="form-group">
      <div className="form-check form-check-inline">
        <input
          id="enabled"
          className="form-check-input"
          type="checkbox"
          name="enabled"
          checked={fields.enabled}
          onChange={handleCheckboxChange('enabled')}
          disabled={fields.isDefault}
        />
        <label className="form-check-label" htmlFor="enabled">Region Enabled?</label>
      </div>
    </div>

    <div className="form-group">
      <div className="form-check form-check-inline">
        <input
          id="is-default"
          className="form-check-input"
          type="checkbox"
          name="is-default"
          checked={fields.isDefault}
          onChange={handleIsDefaultChange}
          disabled={region && region.isDefault}
        />
        <label className="form-check-label" htmlFor="is-default">Default Region?</label>
      </div>
    </div>

    <button className="btn btn-primary" onClick={handleSave}>
      Save
    </button>
    <Link className="btn btn-secondary" to="/regions">
      Cancel
    </Link>
  </>;
}

function checkMoneyForError(moneyStr: string): string[] {
  return checkFloatForError(moneyStr, {
    attrName: 'Price',
    gt: 0,
  });
}
