import React from 'react';
import { GoAlert } from "react-icons/go";
import Api from "../../api/api";
import {extractAxiosError} from "../../utils/error";
import {TextAreaWithValidation} from "../fields_with_validation";

interface FieldErrors {
  cancelReason?: string[]
}

interface CancelOrderFormProps {
  orderRef: string;
  onCancel: () => void;
  onCancelError: (message: string) => void;
  isRefundable?: boolean;
}

export default function CancelOrderForm({orderRef, onCancel, onCancelError, isRefundable}: CancelOrderFormProps) {
  const [cancelReason, setCancelReason] = React.useState('');
  const [fieldErrors, setFieldErrors] = React.useState<FieldErrors>({});
  const [isCancelling, setIsCancelling] = React.useState(false);

  const validateFields = () => {
    const newFieldErrors: Required<FieldErrors> = {
      cancelReason: []
    };
    const reasonTrimmed = cancelReason.trim();
    if (reasonTrimmed.length === 0) {
      newFieldErrors.cancelReason.push('Cancel reason cannot be empty');
    }

    const hasError = Object.values(newFieldErrors).some(errorList => errorList.length > 0);
    setFieldErrors(newFieldErrors);
    return !hasError;
  };

  const handleCancelOrder = () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }
    const confirmed = window.confirm('Confirm cancel order?');
    if (!confirmed) {
      return;
    }

    setIsCancelling(true);
    Api.staff.order.cancelOrder(orderRef, cancelReason)
        .then(() => {
          return onCancel();
        })
        .catch((e) => {
          const message = extractAxiosError(e);
          onCancelError(message);
        })
        .finally(() => {
          setIsCancelling(false);
        });
  };

  const handleCancelReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldErrors({
      ...fieldErrors,
      cancelReason: undefined
    });
    setCancelReason(e.target.value);
  };

  return <>
    <div className="form-group">
      <label>Reason</label>
      <TextAreaWithValidation
        className="form-control"
        placeholder="Why are you cancelling this order?"
        onChange={handleCancelReasonChange}
        value={cancelReason}
        errors={fieldErrors.cancelReason}
      />
    </div>
    <button className="btn btn-danger mr-3" onClick={handleCancelOrder} disabled={isCancelling}>
      Cancel
    </button>
    {isRefundable && (
        <div className="d-inline-block text-danger font-weight-semibold">
          <div className="d-inline-block mr-2">
            <GoAlert />
          </div>
          Cancelling this order will trigger a full refund!
        </div>
    )}
  </>;
}