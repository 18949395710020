import React from 'react';
import {sum} from "lodash";
import HeadTitle from "../head_title";
import TimeoutAlert from "../timeout_alert";
import {queryStringify, QueryStringParams, useQueryString} from "../../utils/route";
import Api from "../../api/api";
import {Link, useHistory, useLocation} from "react-router-dom";
import {isBlank} from '../../utils/string';
import {extractAxiosError} from "../../utils/error";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import isEmpty from 'lodash/isEmpty';
import {StaffSearchProductsRequestParams} from "../../api/request_models";
import {StaffSearchProductsResponse, GetHistoricProductWeightItem} from "../../api/response_models";
import {isSearchSupported, postProcessUrl} from "../../lib/search";
import {getSourceMarketPlaceUrl} from "../../utils/url";
import {formatDateTimeFromSeconds} from "../../utils/date";
import {overrideSourceLabels} from "../../constants/historic_product_weight";
import urljoin from "url-join";

const AVAILABLE_PRODUCT_SOURCE = [
  "tokopedia", "orami", "local",
];

interface ProductImportCheckInfo {
  timeoutId?: NodeJS.Timeout;
  retryTimes: number;
  reset: () => void;
}

export default function ProductSearch() {
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [searchText, setSearchText] = React.useState('');
  const [searchOverrideStatus, setSearchOverrideStatus] = React.useState('');
  const [searchSource, setSearchSource] = React.useState('');
  const [searchResult, setSearchResult] = React.useState<StaffSearchProductsResponse | null>(null);
  const [searchLocked, setSearchLocked] = React.useState(false);
  const [isTakingAwhile, setIsTakingAwhile] = React.useState(false);
  const [historicProductWeights, setHistoricProductWeights] = React.useState<Record<string, GetHistoricProductWeightItem[]>>({});
  const [usernames, setUsernames] = React.useState<Record<string, string>>({});

  const history = useHistory();
  const query = useQueryString();
  const location = useLocation();

  const isSearchTextURL = React.useMemo(() => {
    try {
      new URL(searchText);
      return true;
    } catch (e) {
      return false;
    }
  }, [searchText]);

  const handleImportProduct = React.useCallback(async (isImportAndOverride: boolean) => {
    setIsTakingAwhile(false);
    if (isBlank(searchText)) {
      setErrorMessage('Search text cannot be blank');
      return;
    }

    let url = null;
    try {
      url = new URL(searchText);
    } catch (error) {
      setErrorMessage('Pasted text is not a URL');
      return;
    }

    if (!isSearchSupported(url)) {
      setErrorMessage('Link is not supported');
      return;
    }
    const finalUrl = postProcessUrl(url);

    try {
      const response = await Api.product.searchExternal(finalUrl!);
      if (!response.data.pending) {
        if (isImportAndOverride) {
          history.push(`/product_search/${response.data.product._id}/edit`);
        } else {
          setSuccessMessage('Product has already been imported');
          setSearchText('');
        }
        return;
      }

      if (!isImportAndOverride) {
        setSuccessMessage('Product has been queued for import');
        setSearchText('');
        return;
      }
    } catch (error: any) {
      const errorMessage = extractAxiosError(error);
      setErrorMessage(errorMessage);
    }

    const onTick = () => {
      Api.product.checkPending(finalUrl!)
        .then((res) => {
          if (res.data && res.data.success) {
            setSearchLocked(false);
            const product = res.data.product;
            history.push(`/product_search/${product._id}/edit`);
            checkRef.current.timeoutId = undefined;
          }
        })
        .catch(() => {
          checkRef.current.retryTimes++;
          checkRef.current.timeoutId = setTimeout(onTick, 3000);
          if (checkRef.current.retryTimes > 4) {
            setIsTakingAwhile(true);
          }
        });
    };


    setSearchLocked(true);
    checkRef.current.timeoutId = setTimeout(onTick, 3000);
  }, [history, searchText]);

  const handleImportOnly = React.useCallback(() => handleImportProduct(false), [handleImportProduct]);

  const handleSearch = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isSearchTextURL) {
      await handleImportProduct(true);
    } else {
      if (isBlank(searchText)) {
        setErrorMessage("Search text can't be empty");
        return;
      }

      // console.log(searchText, searchOverrideStatus, searchSource);

      const searchQuery: QueryStringParams = {};

      if (searchText) {
        searchQuery.search = searchText;
      }

      if (searchOverrideStatus) {
        searchQuery.overrideStatus = searchOverrideStatus;
      }
      if (searchSource) {
        searchQuery.source = searchSource;
      }

      if (isEmpty(searchQuery)) {
        history.push(`${location.pathname}`);
      } else {
        history.push(`${location.pathname}?${queryStringify(searchQuery)}`);
      }
    }
  }, [handleImportProduct, isSearchTextURL, searchText, searchOverrideStatus, searchSource, history, location.pathname]);

  React.useEffect(() => {
    const params: StaffSearchProductsRequestParams = {};
    if (typeof(query.search) === 'string' && !isBlank(query.search)) {
      params.search = query.search;
      setSearchText(query.search);
    }

    if (typeof(query.overrideStatus) === 'string') {
      params.overrideStatus = query.overrideStatus;
      setSearchOverrideStatus(query.overrideStatus);
    }

    if (typeof(query.source) === 'string') {
      params.source = query.source;
      setSearchSource(query.source);
    }

    if (isEmpty(params)) {
      return;
    }

    setSearchLocked(true);
    Api.staff.product.searchProduct(params)
      .then(res => {
        setSearchResult(res.data);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      })
      .finally(() => setSearchLocked(false));
  }, [query]);

  const checkRef = React.useRef<ProductImportCheckInfo>({
    retryTimes: 0,
    reset() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.retryTimes = 0;
    }
  });

  React.useEffect(() => {

    return () => {
      checkRef.current.reset();
    };
  }, []);


  const handleCancelWait = React.useCallback(() => {
    setIsTakingAwhile(false);
    setSearchLocked(false);
    checkRef.current.reset();
  }, []);

  const handleQuotationHistoryClick = React.useCallback((productId: string) => () => {
    setHistoricProductWeights({
      ...historicProductWeights,
      [productId]: []
    });

    (async function () {
      const res = await Api.staff.historicProductWeight.getByProduct(productId);

      try {
        const userIds = res.data.historicProductWeights
            .filter(weight => !!weight.createdBy)
            .map(weight => weight.createdBy!);
        const userNamesRes = await Api.user.getNames(userIds);
        const updatedUsernames = {...usernames};
        for (const uid in userNamesRes.data.usernames) {
          const {firstName, lastName} = userNamesRes.data.usernames[uid];
          updatedUsernames[uid] = `${firstName} ${lastName}`;
        }
        setUsernames(updatedUsernames);
      } catch (e) {
        console.error(`Failed to fetch user names for historic product weight, product id: ${productId}`, e);
      }

      setHistoricProductWeights({
        ...historicProductWeights,
        [productId]: res.data.historicProductWeights
      });
    })();
  }, [historicProductWeights, usernames]);

  return <>
    <HeadTitle
      title="Product Search"
    />

    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />

    <h1>Product Search</h1>
    <HeadTitle
      title="Product Search"
    />

    <form onSubmit={handleSearch}>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder="Product name or product link"
          onChange={e => setSearchText(e.target.value)}
          value={searchText}
          autoFocus={true}
        />
      </div>
      <div className="form-group">
        <label className="form-check-label" htmlFor="enabled">Product marketplace source</label>
        <select className="form-control"
                value={searchSource}
                onChange={(e) => setSearchSource(e.target.value)}
                disabled={isSearchTextURL}
        >
          <option value="">Any</option>
          {AVAILABLE_PRODUCT_SOURCE.map((source, i) => (
            <option key={i} value={source}>{`${source[0].toUpperCase()}${source.slice(1)}`}</option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label className="form-check-label" htmlFor="enabled">Product override status</label>
        <select className="form-control"
                value={searchOverrideStatus}
                onChange={(e) => setSearchOverrideStatus(e.target.value)}
                disabled={isSearchTextURL}
        >
          <option value="">Any</option>
          <option value="override_yes">Has Weight Override</option>
          <option value="override_no">No Weight Override</option>
        </select>
      </div>
      <div className="form-group my-2">
        {isSearchTextURL ? (
          <>
            <button
              type="submit"
              className="mr-2 btn btn-primary"
              disabled={searchLocked}
            >
              Import and Override Product's Weight
            </button>

            <button
              onClick={handleImportOnly}
              className="mr-2 btn btn-primary"
              disabled={searchLocked}
              type="button"
            >
              Import Product Only
            </button>
          </>
        ) : (
          <button type="submit"
            className="mr-2 btn btn-primary">
            Search Product
          </button>
        )}

        {searchLocked && (
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}

        {isTakingAwhile && (
          <div>
            It's taking longer than expected...
            <button className="btn btn-link text-danger" onClick={handleCancelWait}>Cancel Wait</button>
          </div>
        )}
      </div>
    </form>

    {(searchResult && searchResult.products.length > 0) && (
      searchResult.products.map((product, index) => {
        const overridesSize = searchResult.additionalData[index].productWeightOverrides.length;
        let weightOverrideMessage = 'No Weight Override';
        if (overridesSize === 1) {
          const productWeightOverride = searchResult.additionalData[index].productWeightOverrides[0];
          weightOverrideMessage = `Overridden to ${productWeightOverride.weight}kg`;
        } else if (overridesSize > 1) {
          const avgWeight = sum(searchResult.additionalData[index].productWeightOverrides.map(i => i.weight)) / overridesSize;
          weightOverrideMessage = `Overridden multiple variations, average weight: ${avgWeight.toFixed(2)}kg`;
        }
        let editLink: string;
        if (product.sourceMarketPlace === 'local') {
          editLink = `/merchants/${product.merchantId}/products/${product._id}`;
        } else {
          editLink = `/product_search/${product._id}/edit`;
        }

        let productId = "";
        if (overridesSize > 0) {
          productId = searchResult.additionalData[index].productWeightOverrides[0].productId;
        }
        const productHistoricWeights = historicProductWeights[productId];

        return (
          <div className="card mb-2" key={index}>
            <div className="card-body">
              <a href={`${process.env.REACT_APP_STORE_ENDPOINT}/product/${product.paramName}`} target="_blank"
                 rel="noreferrer">
                <h5 className="card-title">{product.name}</h5>
              </a>
              <h6
                className="card-subtitle mb-2 text-muted">{`${product.sourceMarketPlace[0].toUpperCase()}${product.sourceMarketPlace.slice(1)}`}</h6>
              <h6
                className="card-subtitle mb-2 text-muted">{weightOverrideMessage}</h6>

              <a
                className="btn btn-secondary mr-1"
                href={getSourceMarketPlaceUrl(product)}
                target="_blank"
                rel="noreferrer"
              >
                Open Product Page (3rd Party)
              </a>

              <OverlayTrigger
                placement="right"
                delay={{show: 250, hide: 400}}
                overlay={<Tooltip id={`product-${index}`}>Click here to edit the product</Tooltip>}
              >
                <Link className="btn btn-primary mr-1" to={editLink}>
                  Edit Product
                </Link>
              </OverlayTrigger>

              {overridesSize > 0 && (
                  <button
                      className="btn btn-info"
                      onClick={handleQuotationHistoryClick(searchResult.additionalData[index].productWeightOverrides[0].productId)}
                      disabled={!!productHistoricWeights}
                  >
                    Quotation History
                  </button>
              )}
              {productHistoricWeights && (
                  <>
                    <div className="text-muted"><small>* Only quotation made after 02/05/2023 will have history</small></div>

                    <div className="px-2">
                      {productHistoricWeights.map((historicWeight) => (
                          <div className="card mt-1" key={historicWeight._id}>
                            <div className="card-body">
                              <dl>
                                <dt>Weight</dt>
                                <dd>{historicWeight.weight}{historicWeight.weightUnit}</dd>

                                {!isEmpty(historicWeight.description) && (
                                  <>
                                    <dt>Change Description</dt>
                                    <dd><pre className="d-inline">{historicWeight.description}</pre></dd>
                                  </>
                                )}

                                <dt>Edited On</dt>
                                <dd>{formatDateTimeFromSeconds(historicWeight.createdAt)}</dd>

                                <dt>Overridden by</dt>
                                <dd>{historicWeight.createdBy ? usernames[historicWeight.createdBy] : 'Unknown'}</dd>

                                <dt>Override source</dt>
                                <dd>{historicWeight.changeSource ? overrideSourceLabels[historicWeight.changeSource] : 'Unknown'}</dd>
                              </dl>

                              <div className="text-right">
                                <a
                                    className="btn btn-sm btn-outline-secondary mr-2"
                                    href={urljoin(process.env.REACT_APP_STORE_ENDPOINT!!, 'product', historicWeight.product.paramName)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                  Open Product Page (Indo4ward)
                                </a>

                                <a
                                    className="btn btn-sm btn-secondary"
                                    href={historicWeight.product.sourceMarketPlaceUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                  Open Product Page (3rd Party)
                                </a>
                              </div>
                            </div>
                          </div>
                      ))}
                    </div>
                  </>
              )}
            </div>
          </div>
        );
      })
    )}
  </>;
}