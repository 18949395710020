import React from 'react';
import {Link} from "react-router-dom";
import {capitalize} from "lodash";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Api from '../../../api/api';
import {ProductVariationDiffListItem} from "../../../types/responses/product_variation_diff";
import urljoin from "url-join";
import {formatDateTimeFromSeconds} from "../../../utils/date";
import ProductWeightDiffItemActionButtons from "../../product_weight_diff/item_action_buttons";
import HeadTitle from "../../head_title";
import ProductChangeDiffInfoModal from "../../product_weight_diff/diff_info_modal";
import styles from '../../../styles/product_weight_diff.module.scss';
import classNames from "classnames";
import ReactMarkdown from "react-markdown";

interface ProductVariationDiffProps {
    status: 'resolved' | 'unresolved';
}

export default function ProductVariationDiffPage({ status }: ProductVariationDiffProps) {
    const [productVariationDiffs, setProductVariationDiffs] = React.useState<ProductVariationDiffListItem[]>([]);
    const [showingInfoModal, setShowingInfoModal] = React.useState(false);

    React.useEffect(() => {
        const request = status === 'resolved' ? Api.staff.productVariationDiff.getResolved : Api.staff.productVariationDiff.getUnresolved;
        (async function() {
            const res = await request();
            setProductVariationDiffs(res.data.productVariationDiffs);
        })();
    }, [status]);

    const onResolvedHandler = React.useCallback((diffId: string) => {
        const index = productVariationDiffs.findIndex(i => i._id === diffId);
        if (index === -1) return;

        const copy = productVariationDiffs.slice();
        copy.splice(index, 1);
        setProductVariationDiffs(copy);
    }, [productVariationDiffs]);

    const hideInfoModal = React.useCallback(() => setShowingInfoModal(false), []);
    const showInfoModal = React.useCallback(() => setShowingInfoModal(true), []);

    return <>
        <HeadTitle
            title={`Product Variations Differences (${capitalize(status)})`}
        />

        <h1>Product Variations Differences</h1>
        <p>
            Variations that changed and flagged out by the system will be listed here.
            Please resolve them ensure price and weight accuracy.
        </p>
        <ul className="nav nav-tabs mb-3">
            {status === 'resolved' ? (
                <>
                    <li className={"nav-link active"}>Resolved</li>
                    <Link to={"unresolved"} className={`nav-link`}>Unresolved</Link>
                </>
            ) : (
                <>
                    <Link to={"resolved"} className={`nav-link`}>Resolved</Link>
                    <li className={"nav-link active"}>Unresolved</li>
                </>
            )}
        </ul>
        {
            // product name
            // description (if changed)
            // show local link
            // show remote link
            // flag time (create time)
            productVariationDiffs.map((diff) => {
                let title: string;
                let subtitle: string;
                if (diff.diffType === 'changed') {
                    title = "Product variation seems to have changed.";
                    subtitle = "Check that override weight is reflecting the updated variation's weight correctly.";
                } else {
                    title = "Product variation seems to have been removed.";
                    subtitle = "Override should be removed in case of overriding the wrong weight.";
                }
                return <div className="card mb-2" key={[diff._id, diff.resolved].join("_")}>
                    <div className="card-body">
                        <h5 className="card-title">
                            {title}
                            <AiOutlineInfoCircle
                                className={classNames(styles.infoModalTrigger, 'text-primary')}
                                onClick={showInfoModal}
                            />
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">{subtitle}</h6>

                        {diff.product ? (
                            <dl>
                                <dt>Product name</dt>
                                <dd>{diff.product.name}</dd>

                                <dt>Variation description</dt>
                                <dd>{diff.product.variationDescription || "-"}</dd>

                                <dt>Current Weight override</dt>
                                <dd>
                                    {diff.productWeightOverride ? (
                                        <>
                                            Weight: {diff.productWeightOverride.weight}kg
                                            <br/>
                                            Last overridden
                                            at: {formatDateTimeFromSeconds(diff.productWeightOverride.updatedAt)}
                                        </>
                                    ) : 'Weight override not found.'}
                                </dd>

                                <dt>Links</dt>
                                <dd>
                                    <a
                                        className="btn btn-sm btn-outline-secondary mr-1"
                                        href={urljoin(process.env.REACT_APP_STORE_ENDPOINT!!, 'product', diff.product.paramName)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Open Product Page (Indo4ward)
                                    </a>

                                    <a
                                        className="btn btn-sm btn-secondary"
                                        href={diff.product.sourceMarketPlaceUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Open Product Page (3rd Party)
                                    </a>
                                </dd>

                                <dt>Flagged time</dt>
                                <dd>{formatDateTimeFromSeconds(diff.createdAt)}</dd>

                                {diff.description && (<>
                                    <dt>What changed?</dt>
                                    <dd>
                                        <ReactMarkdown>
                                            {diff.description}
                                        </ReactMarkdown>
                                    </dd>
                                </>)}

                                {status === 'unresolved' ? (
                                    <ProductWeightDiffItemActionButtons
                                        diffItem={diff}
                                        showEditBtn={diff.diffType === "changed"}
                                        onResolved={onResolvedHandler}
                                    />
                                ) : (
                                    <>
                                        <dt>Resolved time</dt>
                                        <dd>{diff.resolvedAt ? formatDateTimeFromSeconds(diff.resolvedAt) : 'Unknown'}</dd>
                                    </>
                                )}
                            </dl>
                        ) : (
                            <>
                                <p>
                                    <strong>Link product cannot be found or already deleted.</strong>
                                </p>

                                <div>
                                    <div className="mb-2"><strong>Actions</strong></div>
                                    <button className="btn btn-danger ml-2">Remove weight override</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>;
            })
        }
        {productVariationDiffs.length === 0 && (
            <p>Nothing to list.</p>
        )}
        <ProductChangeDiffInfoModal
            shown={showingInfoModal}
            onHide={hideInfoModal}
        />
    </>;
}