import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/reducers/reducers";
import {actions} from "../store/actions";

export function useUser() {
  const {profile, signedIn, tfaPassed} = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!profile && signedIn && tfaPassed) {
      dispatch(actions.user.fetchProfile());
    }
  }, [dispatch, profile, signedIn, tfaPassed]);

  return profile;
}