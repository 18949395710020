import React from 'react';
import HeadTitle from "../head_title";
import TimeoutAlert from "../timeout_alert";
import EditRegion from "../edit_region";

export default function NewRegionPage() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  return <>
    <HeadTitle
      title="New Region"
    />
    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <h1>New Region</h1>
    <EditRegion
      onError={setErrorMessage}
    />
  </>;
}