import urljoin from 'url-join';
import {Product} from "../api/models";

function getBaseUrl(sourceMarketplace: string) {
  switch (sourceMarketplace) {
    case 'tokopedia':
      return 'https://tokopedia.com';
    case 'orami':
      return 'https://orami.co.id/shopping/product';
  }
  return '';
}

export function getSourceMarketPlaceUrl(product: Pick<Product, 'paramName' | 'sourceMarketPlace' | 'sourceUrlId'>) {
  if (product.sourceMarketPlace === 'local') {
    return urljoin(process.env.REACT_APP_STORE_ENDPOINT as string, 'product', product.paramName);
  } else {
    const baseUrl = getBaseUrl(product.sourceMarketPlace);
    return urljoin(baseUrl, product.sourceUrlId);
  }
}

const isUrlAbsoluteRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
export function isUrlAbsolute(urlString: string): boolean {
  return isUrlAbsoluteRegex.test(urlString);
}