import React, {ReactChild} from 'react';
import {GiCancel} from 'react-icons/gi';
import classNames from 'classnames';
import basicColors from 'color-namer/lib/colors/basic';
import {Link, useHistory, useParams} from 'react-router-dom';
import {Currency} from 'dinero.js';
import urljoin from 'url-join';
import CategorySelect from './category_select';
import {InputFieldWithValidation, TextAreaWithValidation} from './fields_with_validation';
import CurrencySelect from './currency_select';
import {getVariantLabel, VARIANT_TYPES} from '../constants/variant_types';
import styles from '../styles/edit_merchant_product.module.scss';
import {generateFieldsChangeHandler, genericCheckboxChange} from '../utils/forms';
import {checkFloatForError, checkIntForError} from '../lib/validation';
import {convertDineroAmtToFloatStr, convertToDineroAmt} from '../utils/number';
import Api from '../api/api';
import {extractAxiosError} from '../utils/error';
import {Product, Variant, VariantOption, VariantPaths} from "../api/models";
import {ProductParameters} from "../api/request_models";
import {FieldErrors} from "../generic";
import {actions} from "../store/actions";
import {useDispatch} from "react-redux";
import {ProductWeightUnit} from "../types/models/product";

AddVariant.DEFAULT_FIELDS = {
  name: '',
  variantType: VARIANT_TYPES.OTHERS.value.toString(),
};

interface AddVariantProps {
  onCancel: () => void;
  onAdd: (variant: LocalVariant) => void;
}

function AddVariant({onCancel, onAdd}: AddVariantProps) {
  const [fields, setFields] = React.useState(AddVariant.DEFAULT_FIELDS);
  const [fieldErrors, setFieldErrors] = React.useState({});

  const handleValueChange = generateFieldsChangeHandler({
    fields, setFields, fieldErrors, setFieldErrors,
    valueExtractor: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => e.target.value
  });

  function validate() {
    const newFieldErrors: { [key: string]: string[] } = {
      name: []
    };
    const {name} = fields;
    if (name.length === 0 || name.length > 100) {
      newFieldErrors.name.push('Variant name must be between length 1 and 100');
    }

    const hasError = Object.values(newFieldErrors).some(errorList => errorList.length > 0);
    setFieldErrors(newFieldErrors);
    return !hasError;
  }

  const handleAdd = () => {
    const isValid = validate();
    if (isValid) {
      const variant: LocalVariant = Object.assign({
        options: []
      }, fields);
      onAdd(variant);
      setFields(AddVariant.DEFAULT_FIELDS);
    }
  };

  return <div className="card">
    <div className="card-body">
      <h5 className="card-title">Add Variant</h5>

      <div className="form-group">
        <label>Name</label>
        <InputFieldWithValidation
          type="text"
          className="form-control"
          value={fields.name}
          onChange={handleValueChange('name')}
        />
      </div>

      <div className="form-group">
        <label>Type</label>
        <select
          className="form-control"
          onChange={handleValueChange('variantType')}
          value={fields.variantType}
        >
          {Object.values(VARIANT_TYPES).map(({label, value}, index) => (
            <option value={value} key={index}>{label}</option>
          ))}
        </select>
      </div>

      <button className="btn btn-sm btn-outline-primary" onClick={handleAdd}>
        Add
      </button>
      <button className="btn btn-sm btn-outline-dark" onClick={onCancel as React.MouseEventHandler}>
        Cancel
      </button>
    </div>
  </div>;
}

function computeVariantPaths(options: VariantOption[][]) {
  const paths: VariantOption[][] = [];
  const exploreOptions = function (rowPos = 0, path: VariantOption[] = []) {
    if (rowPos === options.length) {
      paths.push(path);
      return;
    }

    options[rowPos].forEach((element => {
      exploreOptions(rowPos + 1, path.concat(element));
    }));
  };
  exploreOptions();
  return paths;
}

interface ApplyToAllAttributes {
  stock?: number;
  originPrice?: number;
  isPriceDiscounted?: boolean;
  originPriceBeforeDiscount?: number;
}

function ApplyToAllVariantCombinations({onApply}: { onApply: Function }) {
  const [fields, setFields] = React.useState({
    isPriceDiscounted: false,
    stock: 1,
    originPrice: 1,
    originPriceBeforeDiscount: 1,
  });
  const [shouldApply, setShouldApply] = React.useState({
    stock: false,
    originPrice: false,
    priceDiscounted: false,
  });

  const handleShouldApplyChange = generateFieldsChangeHandler({
    fields: shouldApply,
    setFields: setShouldApply,
    valueExtractor: (e: React.ChangeEvent<HTMLFormElement>) => e.target.checked,
  });

  const handleValueChange = generateFieldsChangeHandler({
    fields, setFields,
    valueExtractor: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => e.target.value
  });
  const handleCheckedChange = generateFieldsChangeHandler({
    fields, setFields,
    valueExtractor: (e: React.ChangeEvent<HTMLInputElement>) => e.target.checked
  });

  const handleApply = () => {
    const confirmed = window.confirm('Are you sure? Values will be overidden');
    if (!confirmed) {
      return;
    }

    const appliedAttributes: ApplyToAllAttributes = {};

    if (shouldApply.stock) {
      appliedAttributes.stock = fields.stock;
    }

    if (shouldApply.originPrice) {
      appliedAttributes.originPrice = fields.originPrice;
    }

    if (shouldApply.priceDiscounted) {
      appliedAttributes.isPriceDiscounted = fields.isPriceDiscounted;
      appliedAttributes.originPriceBeforeDiscount = fields.originPriceBeforeDiscount;
    }

    onApply(appliedAttributes);
  };

  return <div className="card">
    <div className="card-body">
      <h6 className="card-title">Apply to all combinations</h6>

      <table className="table">
        <thead>
        <tr>
          <th>Apply?</th>
          <th>Value</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <input
              type="checkbox"
              onChange={handleShouldApplyChange('priceDiscounted') as unknown as React.ChangeEventHandler}
              checked={shouldApply.priceDiscounted}
            />
          </td>
          <td>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="all-combinations-price-discounted"
                onChange={handleCheckedChange('isPriceDiscounted') as unknown as React.ChangeEventHandler}
                checked={fields.isPriceDiscounted}
              />

              <label className="form-check-label" htmlFor="all-combinations-price-discounted">
                Price Discounted?
              </label>
            </div>

            {fields.isPriceDiscounted && (
              <div className="form-group">
                <label>Price Before Discount</label>
                <input
                  type="number"
                  className="form-control"
                  value={fields.originPriceBeforeDiscount}
                  onChange={handleValueChange('originPriceBeforeDiscount') as unknown as React.ChangeEventHandler}
                />
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <input
              type="checkbox"
              onChange={handleShouldApplyChange('originPrice') as unknown as React.ChangeEventHandler}
              checked={shouldApply.originPrice}
            />
          </td>
          <td>
            <div className="form-group">
              <label>Price</label>
              <input
                type="number"
                className="form-control"
                value={fields.originPrice}
                onChange={handleValueChange('originPrice') as unknown as React.ChangeEventHandler}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <input
              type="checkbox"
              onChange={handleShouldApplyChange('stock') as unknown as React.ChangeEventHandler}
              checked={shouldApply.stock}
            />
          </td>
          <td>
            <div className="form-group">
              <label>Stock</label>
              <input
                type="number"
                className="form-control"
                value={fields.stock}
                onChange={handleValueChange('stock') as unknown as React.ChangeEventHandler}
              />
            </div>
          </td>
        </tr>
        </tbody>
      </table>


      <button className="btn btn-outline-primary" onClick={handleApply}>
        Apply To All
      </button>
    </div>
  </div>;
}

interface VariantFieldsProps {
  value: LocalVariant[];
  onChange: (variants: LocalVariant[]) => void;
}

function VariantsField({value, onChange}: VariantFieldsProps) {
  const variants = value;
  const [isAddingVariation, setIsAddingVariation] = React.useState(false);

  const handleVariantAdd = (newVariant: LocalVariant) => {
    const newVariants = variants.slice();
    newVariants.push(newVariant);
    onChange(newVariants);
    setIsAddingVariation(false);
  };

  const handleVariantChange = (index: number) => (newVariant: LocalVariant) => {
    const newVariants: LocalVariant[] = variants.slice();
    newVariants[index] = newVariant;
    onChange(newVariants);
  };

  const handleVariantRemove = (index: number) => () => {
    const newVariants: LocalVariant[] = variants.slice();
    newVariants.splice(index, 1);
    onChange(newVariants);
  };

  return <>
    {isAddingVariation ? (
      <AddVariant
        onAdd={handleVariantAdd}
        onCancel={() => setIsAddingVariation(false)}
      />
    ) : (
      <button className="btn btn-success" onClick={() => setIsAddingVariation(true)}>
        Add Variant
      </button>
    )}

    {variants.map((variant, index) => {
      return (
        <VariantField
          onRemove={handleVariantRemove(index)}
          value={variant}
          onChange={handleVariantChange(index)}
          key={index}
        />
      );
    })}
  </>;
}

function checkMoneyForError(moneyStr: string) {
  return checkFloatForError(moneyStr, {
    attrName: 'Price',
    gt: 0,
  });
}

interface LocalVariantPath {
  isPriceDiscounted: boolean;
  originPriceBeforeDiscount: string;
  originPrice: string;
  stock: string;
  sku: string;
  hasError: boolean;
  options: string[];
  weight: string;
  weightUnit: ProductWeightUnit;
  weightHash?: string;
}

interface VariantCombinationFieldProps {
  value: LocalVariantPath;
  onChange: Function;
  combinationKey: string;
}

function VariantCombinationField({value, onChange, combinationKey}: VariantCombinationFieldProps) {
  const combination = value;
  const priceDiscountedCheckId = `vc-price-discounted-${combinationKey}`;

  const checkCombinationForError = React.useCallback((combination: LocalVariantPath) => {
    let priceErrors = checkMoneyForError(combination.originPrice);

    let priceBefDiscountErrors: string[] = [];
    if (combination.isPriceDiscounted) {
      priceBefDiscountErrors = checkMoneyForError(combination.originPriceBeforeDiscount);
    }

    let stockErrors: string[] = checkIntForError(combination.stock, {
      gt: -1,
    });

    return [priceErrors, priceBefDiscountErrors, stockErrors];
  }, []);

  const [priceErrors, priceBefDiscountErrors, stockErrors] = React.useMemo(() => checkCombinationForError(combination), [checkCombinationForError, combination]);

  const handleValueChange = React.useCallback((attrKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = {
      ...value,
      [attrKey]: e.target.value
    };
    newValue.hasError = checkCombinationForError(newValue).some(combination => combination.length > 0);
    onChange(newValue);
  }, [checkCombinationForError, onChange, value]);

  const handlePriceDiscountedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Object.assign({}, value, {
      isPriceDiscounted: e.target.checked,
    });
    onChange(newValue);
  };

  const weightChangeHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: LocalVariantPath = {
      ...value,
      weight: e.target.value
    };
    onChange(newValue);
  }, [onChange, value]);

  const gramBtnClickHandler = React.useCallback(() => {
    if (!onChange) return;

    const newValue: LocalVariantPath = {
      ...value,
      weightUnit: 'g'
    };
    onChange(newValue);
  }, [onChange, value]);

  const kilogramBtnClickHandler = React.useCallback(() => {
    if (!onChange) return;

    const newValue: LocalVariantPath = {
      ...value,
      weightUnit: 'kg'
    };
    onChange(newValue);
  }, [onChange, value]);

  const gramBtnClass = classNames('btn', {
    'btn-outline-primary': value.weightUnit !== 'g',
    'btn-primary': value.weightUnit === 'g'
  });
  const kilogramBtnClass = classNames('btn', {
    'btn-outline-primary': value.weightUnit !== 'kg',
    'btn-primary': value.weightUnit === 'kg'
  });

  return <>
    <td className="align-middle text-center">
      <input
        className=""
        type="checkbox"
        id={priceDiscountedCheckId}
        onChange={handlePriceDiscountedChange}
        checked={combination.isPriceDiscounted}
      />
    </td>

    <td className="align-middle text-center">
      <InputFieldWithValidation
        type="number"
        className="text-center"
        value={combination.originPriceBeforeDiscount}
        onChange={handleValueChange('originPriceBeforeDiscount')}
        errors={priceBefDiscountErrors as string[]}
        disabled={!combination.isPriceDiscounted}
      />
    </td>

    <td className="align-middle text-center">
      <InputFieldWithValidation
        type="number"
        className="text-center"
        value={combination.originPrice}
        onChange={handleValueChange('originPrice')}
        errors={priceErrors}
      />
    </td>

    <td className="align-middle text-center">
      <InputFieldWithValidation
        type="number"
        className="text-center"
        value={combination.stock}
        onChange={handleValueChange('stock')}
        errors={stockErrors}
      />
    </td>

    <td className="align-middle text-center">
      <InputFieldWithValidation
        type="text"
        className="text-center"
        value={combination.sku}
        onChange={handleValueChange('sku')}
      />
    </td>

    <td className="align-middle text-center">
      <div className="input-group mb-3">
        <input
            type="number"
            className="form-control"
            value={value.weight}
            onChange={weightChangeHandler}
        />

        <div className="input-group-append">
          <button className={gramBtnClass} onClick={gramBtnClickHandler} type="button">g</button>
          <button className={kilogramBtnClass} onClick={kilogramBtnClickHandler} type="button">kg</button>
        </div>
      </div>
    </td>
  </>;
}

interface VariantCombinationsFieldProps {
  variants: LocalVariant[];
  value: LocalVariantPaths;
  onChange: Function;
}

interface LocalVariantPath {
  isPriceDiscounted: boolean;
  originPriceBeforeDiscount: string;
  originPrice: string;
  stock: string;
  sku: string;
  hasError: boolean;
}

function VariantCombinationsField({variants, value, onChange}: VariantCombinationsFieldProps) {
  const variantPaths = value;
  // console.log('variantPaths', variantPaths)

  const handleCombinationChange = (key: string) => (combination: object) => {
    const newVariantPaths = Object.assign({}, variantPaths, {
      [key]: combination,
    });
    onChange(newVariantPaths);
  };

  return <>
    <h5>Variation List</h5>
    <table className="table table-bordered">
      <thead className="thead-light">
      <tr>
        {variants.map(variant => (
          <th className="align-middle text-center" scope="col" rowSpan={2}>{variant.name}</th>
        ))}
        <th className="align-middle text-center" scope="col" rowSpan={2}>Price Discounted</th>
        <th className="align-middle text-center" scope="col" colSpan={2}>Price</th>
        <th className="align-middle text-center" scope="col" rowSpan={2}>Stock</th>
        <th className="align-middle text-center" scope="col" rowSpan={2}>SKU</th>
        <th className="align-middle text-center" scope="col" rowSpan={2}>Weight</th>
      </tr>
      <tr>
        <th className="align-middle text-center" scope="col">Before Discount</th>
        <th className="align-middle text-center" scope="col">After Discount</th>
      </tr>
      </thead>
      <tbody>
      {Object.entries(variantPaths).map(([key, vp], index) => {
        const variantIndices = JSON.parse(key);
        const totalVariationOrder = variants.map(variant => {
          return variant.options.length;
        });

        let rowContent: ReactChild[] = [];
        for (let i = 0; i < variantIndices.length; i++) {
          let rowSpan = 1;
          const rowChildren = totalVariationOrder.map(x => x).splice(i + 1);
          if (rowChildren.length > 0) {
            rowSpan = rowChildren.reduce((a, b) => a * b);
          }
          if (index % rowSpan === 0) {
            rowContent.push(<th className="align-middle text-center" rowSpan={rowSpan}>{variantIndices[i]}</th>);
          }
        }

        rowContent.push(<VariantCombinationField
          key={index}
          combinationKey={key}
          value={vp}
          onChange={handleCombinationChange(key)}
        />);

        return <tr>
          {rowContent}
        </tr>;
      })}
      </tbody>
    </table>
  </>;
}

interface VariantFieldProps {
  value: LocalVariant;
  onChange: (variant: LocalVariant) => void;
  onRemove: React.MouseEventHandler;
}

function VariantField({value, onChange, onRemove}: VariantFieldProps) {
  const variant = value;

  const [name, setName] = React.useState('');
  const [selectedColor, setSelectedColor] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>();

  const isColorVariantType = variant.variantType === VARIANT_TYPES.COLOR.value.toString();

  const handleAdd = () => {
    if (name.length === 0 || name.length > 50) {
      setError('Name must be between 1 and 50');
      return;
    }

    const hasDuplicateOption = variant.options.some(o => o.value.toLowerCase() === name.toLowerCase());
    if (hasDuplicateOption) {
      setError('Option must be unique');
      return;
    }

    const options = variant.options.slice();
    const optionItem: { value: string, color?: string } = {
      value: name,
    };
    if (isColorVariantType) {
      optionItem.color = selectedColor as string;
    }
    options.push(optionItem);

    const newVariant = Object.assign({}, variant, {
      options,
    });

    onChange(newVariant);
    setName('');
    setSelectedColor(null);
    setError(null);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setName(e.target.value);
  };


  const handleOptionRemove = (optionIndex: number) => () => {
    const newVariant = Object.assign({}, variant);

    const newVariantOptions = newVariant.options.slice();
    newVariantOptions.splice(optionIndex, 1);

    newVariant.options = newVariantOptions;

    onChange(newVariant);
  };

  const handleColorClick = (colorName: string) => () => {
    setSelectedColor(colorName);
  };

  return <div className="card">
    <div className="card-body">
      <strong>{variant.name}</strong>
      <br/>
      <span className="text-muted">{getVariantLabel(variant.variantType)}</span>
      <br/>

      <div className="form-group">
        <label>Options</label>
        <br/>
        {variant.options.map((option, index: number) => {
          const color = basicColors.find(color => color.name === option.color);
          const hasColor = !!color;
          let colorStyle: React.CSSProperties = {
            height: '0.8rem',
            width: '0.8rem',
            marginRight: '0.5rem',
            display: 'inline-block',
          };
          if (hasColor) {
            colorStyle.backgroundColor = color!.hex;
          }
          return <div className="border bg-white p-1 d-inline-block" key={index}>
            {hasColor && <div style={colorStyle}/>}
            {option.value}

            <span className={"text-danger"}
                  style={{cursor: 'pointer'}} onClick={handleOptionRemove(index)}>
              <GiCancel/>
            </span>
          </div>;
        })}

        <div className="row">
          <div className="col">
            <InputFieldWithValidation
              className="form-control mb-2"
              value={name}
              onChange={handleNameChange}
              errors={(error ? [error] : error) as string[]}
            />


            {isColorVariantType && basicColors.map(({name, hex}: { name: string, hex: string }, index: number) => {
              const className = classNames(styles.colorOption, {
                [styles.active]: selectedColor === name,
              });
              const style = {
                backgroundColor: hex,
              };

              return <div
                key={index}
                className={className}
                style={style}
                onClick={handleColorClick(name)}
              />;
            })}
          </div>
          <div className="col-auto">
            <button className="btn btn-sm btn-outline-primary" onClick={handleAdd}>
              Add
            </button>
          </div>
        </div>
        {variant.options.length === 0 && (
          <div className="text-danger">
            <small>Must at least one variation</small>
          </div>
        )}
      </div>

      <button className="btn btn-sm btn-outline-danger" onClick={onRemove}>
        Remove Variant
      </button>
    </div>
  </div>;
}

interface LocalVariantPaths {
  [path: string]: LocalVariantPath;
}

interface Fields {
  name: string;
  description: string;
  conditionNew: string;
  weight: string;
  disclaimer: string;
  originPriceCurrency: string;
  originPrice: string;
  originPriceBeforeDiscount: string;
  category: number;
  minQuantity: string;
  maxQuantity: string;
  hasVariant: boolean;
  stock: string;
  sku: string;
  variants: LocalVariant[];
  variantPaths: LocalVariantPaths;
  disabled: boolean;
}

type LocalVariant = Omit<Variant, 'variantType'> & {
  variantType: string;
};

const defaultFields: Fields = {
  name: '',
  description: '',
  conditionNew: "true",
  weight: '0.1',
  disclaimer: '',
  originPriceCurrency: 'IDR',
  originPrice: '1',
  originPriceBeforeDiscount: '1',
  category: 0,
  minQuantity: '1',
  maxQuantity: '0',
  hasVariant: false,
  stock: '1',
  sku: '',
  variants: [] as LocalVariant[],
  variantPaths: {},
  disabled: false,
};
EditMerchantProduct.DEFAULT_FIELDS = defaultFields;

type EditMerchantProductFieldErrors = FieldErrors<Fields>;

interface EditMerchantProductProps {
  merchantId?: string;
  onError: (message: string) => void;
  product?: Product;
}

export default function EditMerchantProduct({
                                              merchantId,
                                              onError,
                                              product,
                                            }: EditMerchantProductProps) {
  const [fields, setFields] = React.useState<Fields>(EditMerchantProduct.DEFAULT_FIELDS);
  const [isSaving, setIsSaving] = React.useState(false);
  const [priceDiscounted, setPriceDiscounted] = React.useState(false);
  const [fieldErrors, setFieldErrors] = React.useState<EditMerchantProductFieldErrors>({});
  const [toBeAddedImages, setToBeAddedImages] = React.useState<{ url: string, file: File }[]>([]);
  const [toBeRemovedImages, setToBeRemovedImages] = React.useState(new Set());

  const params = useParams<{ merchantId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const isEditing = !!product;

  React.useEffect(() => {
    dispatch(actions.category.loadCategoriesAsync());
  }, [dispatch]);

  React.useEffect(() => {
    if (!product) {
      return;
    }

    // convert number fields to string
    const weight = product.weight.toString();

    const convertPriceToString = (originPriceAmt: number) => {
      return convertDineroAmtToFloatStr(originPriceAmt, product.originPriceCurrency as Currency);
    };

    const originPrice = convertPriceToString(product.originPrice as number);
    let originPriceBeforeDiscount = '1';
    if (product.originPriceBeforeDiscount) {
      originPriceBeforeDiscount = convertPriceToString(product.originPriceBeforeDiscount);
    }

    const minQuantity = product.minQuantity.toString();
    const maxQuantity = product.maxQuantity.toString();
    const stock = product.stock.toString();
    let variantPaths: LocalVariantPaths = {};

    const combinationKeys = Object.keys(product.variantPaths);
    if (combinationKeys.length > 0) {
      // remap the keys to the option values
      for (const key of combinationKeys) {
        const indices = key.split('/');
        const optionValues = [];
        for (let i = 0; i < indices.length; i++) {
          const variant = product.variants[i];
          const optionIndex = Number.parseInt(indices[i]);
          const optionValue = variant.options[optionIndex].value;
          optionValues.push(optionValue);
        }

        const originalCombination = product.variantPaths[key];
        let originPriceBeforeDiscount = '1';
        if (originalCombination.originPriceBeforeDiscount) {
          originPriceBeforeDiscount = convertPriceToString(originalCombination.originPriceBeforeDiscount);
        }

        const combination: LocalVariantPath = {
          ...originalCombination,
          sku: originalCombination.sku || '',
          hasError: false,
          isPriceDiscounted: !!originalCombination.originPriceBeforeDiscount,
          stock: originalCombination.stock.toString(),
          originPrice: convertPriceToString(originalCombination.originPrice),
          originPriceBeforeDiscount,
          options: optionValues,
          weight: originalCombination.weight.toString()
        };
        const localKey = JSON.stringify(optionValues);
        variantPaths[localKey] = combination;
      }
    }

    const localVariants = product.variants.map(variant => {
      const localVariant: LocalVariant = {
        ...variant,
        variantType: variant.variantType.toString()
      };
      return localVariant;
    });

    const newFields: Fields = {
      name: product.name,
      description: product.description,
      conditionNew: product.conditionNew.toString(),
      weight,
      disclaimer: product.disclaimer,
      originPriceCurrency: product.originPriceCurrency,
      originPrice,
      originPriceBeforeDiscount,
      category: product.category,
      minQuantity,
      maxQuantity,
      hasVariant: product.hasVariant,
      stock,
      sku: product.sku || '',
      variants: localVariants,
      variantPaths,
      disabled: product.disabled,
    };
    // console.log('newFields', newFields);

    setFields(newFields);
  }, [product]);

  React.useEffect(() => {
    return () => {
      for (const image of toBeAddedImages) {
        if (image.url) {
          URL.revokeObjectURL(image.url);
        }
      }
    };
  }, [toBeAddedImages]);

  const handleCategoryChange = React.useCallback((newCategoryId: number) => {
    const newFields = Object.assign({}, fields, {
      category: newCategoryId,
    });
    setFields(newFields);

    // console.log('handleCategoryChange', newCategoryId);
  }, [fields]);

  const handleCheckedChange = generateFieldsChangeHandler({
    fields,
    setFields,
    valueExtractor: (e: React.ChangeEvent<HTMLInputElement>) => e.target.checked
  });

  const handleValueChange = generateFieldsChangeHandler({
    fields, setFields, fieldErrors, setFieldErrors,
    valueExtractor: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => e.target.value
  });

  const handleRawValueChange = function <RawValueType>(attributeKey: keyof Fields) {
    const valueExtractor = (e: RawValueType) => e;
    return generateFieldsChangeHandler({
      fields, setFields, fieldErrors, setFieldErrors,
      valueExtractor
    })(attributeKey);
  };

  const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newToBeAddedImages = [];
    if (e.target.files) {
      for (const file of e.target.files) {
        newToBeAddedImages.push({
          file,
          url: URL.createObjectURL(file),
        });
      }
    }
    for (const image of toBeAddedImages) {
      if (image.url) {
        URL.revokeObjectURL(image.url);
      }
    }

    setToBeAddedImages(newToBeAddedImages);
    e.target.value = "";
  };

  const handleNewImageRemove = (index: number) => () => {
    const newToBeAddedImages = toBeAddedImages.slice();
    URL.revokeObjectURL(newToBeAddedImages[index].url);
    newToBeAddedImages.splice(index, 1);
    setToBeAddedImages(newToBeAddedImages);
  };

  const handleExistingImageRemove = (index: number) => () => {
    const newToBeRemovedImages = new Set(toBeRemovedImages);
    if (newToBeRemovedImages.has(index)) {
      newToBeRemovedImages.delete(index);
    } else {
      newToBeRemovedImages.add(index);
    }
    setToBeRemovedImages(newToBeRemovedImages);
  };

  function validate() {
    const errors: EditMerchantProductFieldErrors = {
      name: [],
      description: [],
      weight: [],
      originPrice: [],
      originPriceBeforeDiscount: [],
      category: [],
      minQuantity: [],
      maxQuantity: [],
      stock: [],
      variants: [],
      variantPaths: [],
    };

    const name = fields.name.trim();
    if (!name || name.length > 200) {
      errors.name!.push('Name must be between 1 and 200 characters in length');
    }

    const description = fields.description.trim();
    if (!description) {
      errors.description!.push('Description is required');
    }

    const weight = parseFloat(fields.weight.trim());
    if (isNaN(weight)) {
      errors.weight!.push('Weight is invalid');
    }

    if (weight < 0) {
      errors.weight!.push('Weight cannot be negative');
    }

    if (!fields.hasVariant) {
      const originPriceErrors = checkMoneyForError(fields.originPrice as string);
      errors.originPrice!.push(...originPriceErrors);

      if (priceDiscounted) {
        const originPriceBeforeDiscountErrors = checkMoneyForError(fields.originPriceBeforeDiscount as string);
        errors.originPriceBeforeDiscount!.push(...originPriceBeforeDiscountErrors);
      }
    }

    const {category} = fields;
    if (!category) {
      errors.category!.push('Category is required');
    }

    const minQuantityErrors = checkIntForError(fields.minQuantity, {
      attrName: 'Minimum Quantity',
      gt: 0,
    });
    errors.minQuantity!.push(...minQuantityErrors);

    const maxQuantityErrors = checkIntForError(fields.maxQuantity, {
      attrName: 'Maximum Quantity',
      gt: -1,
    });
    errors.maxQuantity!.push(...maxQuantityErrors);

    if (!fields.hasVariant) {
      const stockErrors = checkIntForError(fields.stock, {
        attrName: 'Stock',
        gt: -1,
      });
      errors.stock!.push(...stockErrors);
    } else {
      const variantsHasError = fields.variants.some(variant => {
        return variant.options.length === 0;
      });
      const variantPathsEntries = Object.entries(fields.variantPaths);
      const variantPathsHasError = variantPathsEntries.length === 0 ||
        variantPathsEntries.some(([_, combination]) => combination.hasError);

      if (variantsHasError) {
        errors.variants!.push('Some variants has error');
      }

      if (variantPathsHasError) {
        errors.variantPaths!.push('Some variant combination has error');
      }
    }


    const hasError = Object.values(errors).some(errorList => errorList!.length > 0);
    setFieldErrors(errors);
    // console.log('fieldErrors', errors);
    return !hasError;
  }

  function handleSave() {
    setIsSaving(true);
    const isValid = validate();
    if (!isValid) {
      setIsSaving(false);
      return;
    }

    // filter out and convert fields
    const localProduct = fields as Required<Fields>;

    let sku = localProduct.sku;
    if (localProduct.hasVariant) {
      sku = '';
    }

    const originPrice = convertToDineroAmt(localProduct.originPrice, localProduct.originPriceCurrency as Currency);
    let originPriceBeforeDiscount;
    if (priceDiscounted) {
      originPriceBeforeDiscount = convertToDineroAmt(localProduct.originPriceBeforeDiscount, localProduct.originPriceCurrency as Currency);
    } else {
      originPriceBeforeDiscount = 0;
    }

    const weight = parseFloat(localProduct.weight);
    const minQuantity = parseInt(localProduct.minQuantity);
    const maxQuantity = parseInt(localProduct.maxQuantity);
    const stock = parseInt(localProduct.stock);
    const variants = localProduct.variants.map(variant => {
      const localVariant: Variant = {
        ...variant,
        variantType: parseInt(variant.variantType)
      };
      return localVariant;
    });

    const variantPaths: VariantPaths = {};
    Object.entries<LocalVariantPath>(localProduct.variantPaths).forEach(([path, combination]) => {
      const {hasError, isPriceDiscounted, ...variantPath} = combination;
      let originPriceBeforeDiscount;
      if (isPriceDiscounted) {
        originPriceBeforeDiscount = convertToDineroAmt(combination.originPriceBeforeDiscount, localProduct.originPriceCurrency as Currency);
      } else {
        originPriceBeforeDiscount = 0;
      }
      // convert strings to number before submitting
      variantPaths[path] = Object.assign(variantPath, {
        originPriceBeforeDiscount,
        originPrice: convertToDineroAmt(combination.originPrice, localProduct.originPriceCurrency as Currency),
        stock: parseInt(combination.stock),
        weight: parseFloat(combination.weight),
        // weight hash will be recalculated at store service
        weightHash: ''
      });
    });

    const variantPathsWithUpdatedKeys: VariantPaths = {};
    // convert variantPaths key to indices
    for (const key of Object.keys(localProduct.variantPaths)) {
      const valuesCombined = JSON.parse(key);
      const indices = new Array(valuesCombined.length);
      // remap value to index
      for (let i = 0; i < valuesCombined.length; i++) {
        const value = valuesCombined[i];
        const variant = localProduct.variants[i];
        const {options} = variant;
        const index = options.findIndex((o: { value: string }) => o.value === value);
        indices[i] = index.toString();
      }

      const newKey = indices.join('/');
      variantPathsWithUpdatedKeys[newKey] = variantPaths[key];
    }

    const conditionNew = localProduct.conditionNew === 'true';

    const newImages = toBeAddedImages.map(image => image.file);

    const productToSubmit: ProductParameters.ProductChanges = {
      name: localProduct.name,
      description: localProduct.description,
      conditionNew,
      weight,
      weightUnit: 'kg',
      disclaimer: localProduct.disclaimer,
      originPriceCurrency: localProduct.originPriceCurrency,
      originPrice,
      originPriceBeforeDiscount,
      category: localProduct.category,
      minQuantity,
      maxQuantity,
      hasVariant: localProduct.hasVariant,
      stock,
      sku,
      variants,
      variantPaths: variantPathsWithUpdatedKeys,
      disabled: localProduct.disabled,
      merchantId,
    };

    // choose action
    let action;
    if (isEditing) {
      const removedImages = Array.from(toBeRemovedImages).map(index => {
        return product!.images[index as number];
      });
      action = () => Api.admin.product.update(product!._id, productToSubmit, newImages, removedImages);
    } else {
      action = () => Api.admin.product.create(productToSubmit, newImages);
    }

    action()
      .then(_ => {
        console.log('successfully created product');
        history.push(`/merchants/${params.merchantId}`);
      })
      .catch(error => {
        const message = extractAxiosError(error);
        onError(message);
      })
      .finally(() => setIsSaving(false));
    // console.log('dump', localProduct);
  }

  const handleApplyAllCombinations = (combinationAttributes: ApplyToAllAttributes) => {
    // console.log('combinationAttributes', combinationAttributes);
    const isEmpty = Object.keys(combinationAttributes).length === 0;
    if (isEmpty) {
      return;
    }

    const newVariantPaths = Object.assign(fields.variantPaths);
    for (const combination of Object.values(newVariantPaths)) {
      Object.assign(combination, combinationAttributes);
    }

    const newFields = Object.assign({}, fields, {
      variantPaths: newVariantPaths,
    });
    setFields(newFields);
  };

  const handleVariantsChange = (variants: LocalVariant[]) => {
    if (variants.length === 0) {
      setFields({
        ...fields,
        variants,
        variantPaths: {}
      });
    } else {
      const options2D = variants.map((variant) => variant.options);
      // variant path in this component is serialized array of option.value to allow proper mapping when open is deleted
      const paths = computeVariantPaths(options2D);
      const newVariantPaths: LocalVariantPaths = {};
      for (const pathParts of paths) {
        const options = pathParts.map(pp => pp.value);
        const key = JSON.stringify(options);

        if (fields.variantPaths[key]) {
          newVariantPaths[key] = fields.variantPaths[key];
        } else {
          newVariantPaths[key] = {
            options,
            isPriceDiscounted: false,
            originPriceBeforeDiscount: '1',
            originPrice: '1',
            stock: '1',
            sku: '',
            hasError: false,
            weightUnit: 'g',
            weight: '100'
          };
        }
      }
      setFields({
        ...fields,
        variants,
        variantPaths: newVariantPaths
      });
      // console.log('newVariantPaths', newVariantPaths)
    }
  };

  return <>
    <div className="form-group">
      <label>Name</label>
      <InputFieldWithValidation
        type="text"
        className="form-control"
        errors={fieldErrors.name}
        value={fields.name}
        onChange={handleValueChange('name')}
      />
    </div>

    <div className="form-group">
      <label>Add Images</label>
      <input
        type="file"
        className="form-control-file"
        onChange={handleSelectFiles as unknown as React.ChangeEventHandler<HTMLInputElement>}
        accept="image/x-png,image/gif,image/jpeg"
        multiple
      />
      {toBeAddedImages.map((image, index) => {
        return <div className={styles.previewImage} key={index}>
          <button
            className={classNames(styles.previewImage_remove, 'text-danger btn btn-link p-0')}
            onClick={handleNewImageRemove(index)}
          >
            <GiCancel/>
          </button>
          <img
            src={image.url}
            alt="to be added"
          />
        </div>;
      })}
    </div>

    {isEditing && (
      <div className="form-group">
        <label>Existing Image</label>
        <br/>
        {product!.images.map((imagePath, index) => {
          const imageUrl = urljoin(process.env.REACT_APP_IMAGES_ENDPOINT as string, `${imagePath}_t`);
          const previewClassName = classNames(styles.previewImage, {
            [styles.removing]: toBeRemovedImages.has(index),
          });
          return <div className={previewClassName} key={index}>
            <button
              className={classNames(styles.previewImage_remove, 'text-danger btn btn-link p-0')}
              onClick={handleExistingImageRemove(index)}
            >
              <GiCancel/>
            </button>
            <img
              src={imageUrl}
              alt="existing"
            />
          </div>;
        })}
      </div>
    )}

    <div className="form-group">
      <label>Description</label>
      <TextAreaWithValidation
        className="form-control"
        errors={fieldErrors.description}
        value={fields.description}
        onChange={handleValueChange('description')}
      />
    </div>

    <div className="form-group">
      <label>Condition</label>
      <select
        className="form-control"
        onChange={handleValueChange('conditionNew')}
        value={fields.conditionNew}
      >
        <option value="true">New</option>
        <option value="false">Used</option>
      </select>
    </div>

    <div className="form-group">
      <label>Weight</label>
      <div className="input-group mb-3">
        <InputFieldWithValidation
          type="text"
          className="form-control"
          errors={fieldErrors.weight}
          value={fields.weight}
          onChange={handleValueChange('weight')}
        />

        <div className="input-group-append">
          <span className="input-group-text">Kg</span>
        </div>
      </div>
    </div>

    <div className="form-group">
      <label>Disclaimer</label>
      <TextAreaWithValidation
        className="form-control"
        errors={fieldErrors.disclaimer}
        value={fields.disclaimer}
        onChange={handleValueChange('disclaimer')}
      />
    </div>


    <div className="form-group">
      <label>Price Currency</label>
      <CurrencySelect
        className="form-control"
        value={fields.originPriceCurrency}
        onChange={handleValueChange('originPriceCurrency')}
      />
    </div>

    <div className="form-check mb-2">
      <input
        className="form-check-input"
        type="checkbox"
        id="mp-price-discounted"
        onChange={genericCheckboxChange(setPriceDiscounted)}
        checked={priceDiscounted}
      />

      <label className="form-check-label" htmlFor="mp-price-discounted">
        Price Discounted?
      </label>
    </div>


    {priceDiscounted && (
      <div className="form-group">
        <label>Price Before Discount</label>
        <InputFieldWithValidation
          type="number"
          className="form-control"
          errors={fieldErrors.originPriceBeforeDiscount}
          value={fields.originPriceBeforeDiscount}
          onChange={handleValueChange('originPriceBeforeDiscount')}
        />
      </div>
    )}

    <div className="form-group">
      <label>Price</label>
      <InputFieldWithValidation
        type="number"
        className="form-control"
        errors={fieldErrors.originPrice}
        value={fields.originPrice}
        onChange={handleValueChange('originPrice')}
      />
    </div>

    <div className="form-group">
      <label>Category</label>
      <CategorySelect
        value={fields.category}
        onChange={handleCategoryChange}
      />
      {Array.isArray(fieldErrors.category) && fieldErrors.category.length > 0 && (
        <div className="text-danger">
          {fieldErrors.category.map((c, index) => (
            <React.Fragment key={index}>
              <small>{c}</small>
              <br/>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>

    <div className="form-group">
      <label>Minimum quantity</label>
      <InputFieldWithValidation
        type="number"
        className="form-control"
        errors={fieldErrors.minQuantity}
        value={fields.minQuantity}
        onChange={handleValueChange('minQuantity')}
      />
    </div>

    <div className="form-group">
      <label>Maximum quantity</label>
      <InputFieldWithValidation
        type="number"
        className="form-control"
        errors={fieldErrors.maxQuantity}
        value={fields.maxQuantity}
        onChange={handleValueChange('maxQuantity')}
      />
    </div>

    <div className="form-group">
      <label>Stock</label>
      <InputFieldWithValidation
        type="number"
        className="form-control"
        value={fields.stock}
        onChange={handleValueChange('stock')}
        disabled={fields.hasVariant}
      />
    </div>

    <div className="form-group">
      <label>SKU</label>
      <InputFieldWithValidation
        type="text"
        className="form-control"
        value={fields.sku}
        onChange={handleValueChange('sku')}
        disabled={fields.hasVariant}
      />
    </div>

    <div className="form-check mb-2">
      <input
        className="form-check-input"
        type="checkbox"
        id="mp-disabled"
        checked={fields.disabled}
        onChange={handleCheckedChange('disabled')}
      />

      <label className="form-check-label" htmlFor="mp-disabled">
        Disabled?
      </label>
    </div>

    <div className="form-check mb-2">
      <input
        className="form-check-input"
        type="checkbox"
        id="mp-has-variation"
        checked={fields.hasVariant}
        onChange={handleCheckedChange('hasVariant') as React.ChangeEventHandler}
      />

      <label className="form-check-label" htmlFor="mp-has-variation">
        Has Variation?
      </label>
    </div>

    {fields.hasVariant && (
      <>
        <h3>Variations</h3>

        <h5>Variants</h5>
        {Array.isArray(fieldErrors.variants) && fieldErrors.variants.length > 0 && (
          <div className="text-danger">
            {fieldErrors.variants[0]}
          </div>
        )}
        <VariantsField
          value={fields!.variants}
          onChange={handleVariantsChange}
        />

        <h5>Variant Combinations</h5>
        {Array.isArray(fieldErrors.variantPaths) && fieldErrors.variantPaths.length > 0 && (
          <div className="text-danger">
            {fieldErrors.variantPaths[0]}
          </div>
        )}

        <ApplyToAllVariantCombinations
          onApply={handleApplyAllCombinations}
        />

        <VariantCombinationsField
          variants={fields.variants}
          value={fields.variantPaths}
          onChange={handleRawValueChange('variantPaths')}
        />
      </>
    )}

    <button className="btn btn-primary mr-2" onClick={handleSave} disabled={isSaving}>
      Save
    </button>

    <Link className={classNames('btn btn-secondary', {
      disabled: isSaving
    })} to={`/merchants/${params!.merchantId}`}
          onClick={(isSaving ? (() => false) : null) as React.MouseEventHandler}>
      Cancel
    </Link>

    {isSaving && (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )}
  </>;
}