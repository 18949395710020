import React from 'react';
import {Link} from 'react-router-dom';
import HeadTitle from '../head_title';
import {formatDateTimeFromSeconds} from '../../utils/date';
import Api from '../../api/api';
import {extractAxiosError} from '../../utils/error';
import TimeoutAlert from '../timeout_alert';
import {usePagination} from '../../lib/paginator';

interface Popups {
  _id: number;
  name: string;
  updatedAt: number;
  link: string;
  active: boolean
}

export default function PopupsPage() {
  const [page, paginator, setTotalPages] = usePagination();
  const [popups, setPopups] = React.useState<Popups[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    Api.admin.popup.getAll(page)
      .then(res => {
        setPopups(res.data.popups);
        setTotalPages(res.data.totalPages);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  }, [setTotalPages, page]);

  const handleRemove = (index: number) => (e: React.FormEvent) => {
    e.preventDefault();

    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) {
      return;
    }

    const id = popups[index]._id;
    Api.admin.popup.remove(id)
      .then(() => {
        const newPopups = popups.slice();
        newPopups.splice(index, 1);
        setPopups(newPopups);

        setSuccessMessage('Successfully removed pop up');
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  };

  return <>
    <HeadTitle
      title="Pop Ups"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />

    <h1>Pop Ups</h1>
    <div className="mb-3">
      <Link to="/popups/new" className="btn btn-primary">New</Link>
    </div>

    {popups.map((popup, index) => {
      return (
        <div className="card mb-3" key={index}>
          <div className="card-body">
            <h5 className="card-title">{popup.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">Updated At: {formatDateTimeFromSeconds(popup.updatedAt)}</h6>

            <p className="card-text">
              <strong>Name:</strong>
              {' '}
              {popup.name}
              <br/>

              <strong>Link:</strong>
              {' '}
              {popup.link}
              <br/>

              <strong>Active:</strong>
              {' '}
              {popup.active.toString()}
              <br/>
            </p>

            <Link to={`/popups/${popup._id}/edit`} className="card-link btn btn-link">Edit</Link>
            <button className="card-link text-danger btn btn-link" onClick={handleRemove(index)}>Remove</button>
          </div>
        </div>
      );
    })}

    {paginator}
  </>;
}