import React from 'react';

interface UnauthorizedRootProps {
  children: React.ReactNode;
}

function UnauthorizedRoot(props: UnauthorizedRootProps) {
  return <React.Fragment>
    <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="/">Indo4ward</a>
      <ul className="navbar-nav px-3">
        <li className="nav-item text-nowrap">
          <a className="nav-link" href="/sign_in">Sign in</a>
        </li>
      </ul>
    </nav>
    <div className="container-fluid">
      {props.children}
    </div>
  </React.Fragment>;
}

export default UnauthorizedRoot;