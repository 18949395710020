export const EXCHANGE_RATES_CONFIG_KEY = 'exchange_rates';
export const APP_CURRENCY_CONFIG_KEY = 'app_currency';
export const SERVICE_FEE_CONFIG_KEY = 'service_fee';
export const MINIMUM_SERVICE_FEE_CONFIG_KEY = 'minimum_service_fee';
export const EXCHANGE_RATE_MARK_UP_CONFIG_KEY = 'exchange_rate_mark_up';
export const SHIPPING_FEE_PER_PRODUCT_CONFIG_KEY = 'shipping_fee_per_product';
export const OVERSEAS_SHIPPING_MARKUP_CONFIG_KEY = 'overseas_shipping_markup';
export const SERVICE_FEE_MODE_CONFIG_KEY = 'service_fee_mode';
export const OPERATION_NOTIFICATION_EMAIL_CONFIG_KEY = 'operation_notification_email';
export const TIMEZONE_KEY = 'timezone';
export const REMOTE_AREA_FEE_CONFIG_KEY = 'remote_area_fee';
export const MAX_PRODUCTS_IN_ORDER = 'max_products_in_order';

export interface ConfigValueTypes {
  exchange_rates: object;
  app_currency: string;
  service_fee: number;
  minimum_service_fee: number;
  exchange_rate_mark_up: number;
  shipping_fee_per_product: number;
  overseas_shipping_markup: number;
  service_fee_mode: string;
  operation_notification_email: string;
  timezone: string;
  remote_area_fee: number;
  max_products_in_order: number;
}

export type ConfigKeysType = keyof ConfigValueTypes;