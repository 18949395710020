import React from 'react';
import PageNavigation from '../components/page_navigation';
import {useQuery} from '../utils/route';

export function usePagination(): [number, JSX.Element, React.Dispatch<React.SetStateAction<number>>] {
  const query = useQuery();
  let page: string | number = query.get('page') || '1';
  try {
    page = parseInt(page);
  } catch (err) {
    page = 1;
  }

  const [totalPages, setTotalPages] = React.useState<number>(1);
  const paginator = <PageNavigation
    page={page}
    totalPages=
      {
        totalPages
      }
  />;

  return [page, paginator, setTotalPages];
}