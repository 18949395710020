const authErrorCodes = {
  'auth/email_taken': 'The email is already been taken by another user',
  'auth/invalid_email_verification_code': 'Email verification code is invalid',
  'auth/user_not_found': 'User with email is not found',
  'auth/wrong_email_or_password': 'Your email and/or password is incorrect, please try again',
  'auth/wrong_password': 'Wrong password, please try again',
  'auth/unverified_email': 'The given email address has not been verified, check your email for the verification instructions',
  'auth/invalid_new_password':	`Your new password is invalid
	Password must be at least 8 characters long
	and at least 1 symbol or number
	and at least 1 lower and 1 upper case letter`,
  'auth/invalid_password_reset_token': 'Invalid reset password token',
  'auth/password_reset_token_expired': 'Password reset token expired please request to forget password again',
  'auth/invalid_refresh_token': 'Refresh token is invalid',
  'auth/tfa_wrong_code': 'Wrong two factor authentication code',
  'auth/tfa_not_setup': 'Two factor authentication not set up',
  'auth/tfa_reset_token_invalid': 'Two factor authentication reset token has expired',
  'auth/tfa_already_setup': 'Two factor authentication has already set up',
  'auth/tfa_not_enabled': 'Two factor authentication is not enabled',
  'auth/tfa_not_verified': 'This feature requires you to complete two factor authentication to access',
  'auth/invalid_access_token': 'Invalid access token',
  'auth/invalid_state': 'Something when wrong with your session, please login again',
  'auth/invalid_user_agent': 'Invalid user agent',
  'auth/not_authorized': 'You are not authorized to perform this action',
  'auth/email_verified': 'Your email has already been verified',
  'auth/resend_verify_code_rate_limit': 'You have recently been sent a verification, please try again later'
};

export default authErrorCodes;