import React from 'react';
import urljoin from "url-join";
import classNames from "classnames";
import {useDispatch} from "react-redux";
import {getSourceMarketPlaceUrl, isUrlAbsolute} from "../../utils/url";
import {thumbUrl} from "../../utils/image";
import {
  InternalQuotationItem,
  QuotationItem,
  QuotationItemStatuses
} from "../../types/models/quotation_item";
import QuotationItemStatusBadgeComponent from "./status_badge";
import {formatDateTimeFromSeconds} from "../../utils/date";
import QuotationItemClosedAtLineComponent from "./item_closed_at_line";
import VariantInfo from "../variant_info";
import QuotationItemDeclineReasonLineComponent from "./item_decline_reason_line";
import QuotationItemResolvedInfoLineComponent from "./quotation_item_resolved_info_line";
import Api from "../../api/api";
import ResolveInternalQuotationItemDialog, {ResolveInternalQuotationItemDialogCloseData} from "./resolve_internal_item_dialog";
import DeclineInternalQuotationItemDialog, {DeclineInternalQuotationItemDialogCloseData} from "./decline_internal_item_dialog";
import {CartItemProductForOrder} from "../../types/models/cart_item";
import styles from '../../styles/quotation_item.module.scss';
import InternalQuotationItemPendingLine from "./internal_quotation_item_pending_line";
import {actions} from "../../store/actions";

interface InternalQuotationItemProps {
  quotationItem: InternalQuotationItem;
  onResolveOrDecline?: (quotationItem: QuotationItem, product?: CartItemProductForOrder) => void;
  product: CartItemProductForOrder;
  weightSource: string;
}

export default function InternalQuotationItemComponent(props: InternalQuotationItemProps) {
  const quotationItem = props.quotationItem as InternalQuotationItem;
  const {product, weightSource} = props;

  const [showResolveDialog, setShowResolveDialog] = React.useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = React.useState(false);

  const dispatch = useDispatch();

  const handleResolveBtn = React.useCallback(() => {
    setShowResolveDialog(true);
  }, []);

  const handleResolveDialogClose = React.useCallback((closeData?: ResolveInternalQuotationItemDialogCloseData) => {
    setShowResolveDialog(false);
    if (!closeData) {
      return;
    }

    const {weightInGrams} = closeData;
    Api.staff.quotationItem.resolveInternal(quotationItem._id, {
      weightInGrams
    })
      .then((res) => {
        if (props.onResolveOrDecline) {
          props.onResolveOrDecline(res.data.quotationItem, res.data.product);
        }
        dispatch(actions.quotation.decrementInternalPendingCount());
      })
      .catch((e) => {
        console.error('failed to resolve quotation item', e);
      });
  }, [props, quotationItem, dispatch]);

  const handleDeclineBtn = React.useCallback(() => {
    setShowDeclineDialog(true);
  }, []);

  const handleDeclineDialogClose = React.useCallback((closeData?: DeclineInternalQuotationItemDialogCloseData) => {
    setShowDeclineDialog(false);
    if (!closeData) {
      return;
    }

    const {declineReason} = closeData;
    Api.staff.quotationItem.decline(quotationItem._id, {
      declineReason
    })
      .then((res) => {
        if (props.onResolveOrDecline) {
          props.onResolveOrDecline(res.data.quotationItem);
        }
        dispatch(actions.quotation.decrementInternalPendingCount());
      })
      .catch((e) => {
        console.error('failed to decline quotation item', e);
      });
  }, [props, quotationItem, dispatch]);

  let imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg';
  if (quotationItem.mainImage) {
    imageUrl = quotationItem.mainImage;
  }
  if (!isUrlAbsolute(imageUrl)) {
    imageUrl = thumbUrl(imageUrl).toString();
  }

  const hasVariation = !!quotationItem.productSourceData.variantPath;
  const isClosureButtonsEnabled = quotationItem.status === QuotationItemStatuses.pending;

  return <>
    <div className="media">
      <div className={styles.imageContainer}>
        <img src={imageUrl} alt="quotation item" />
      </div>

      <div className="media-body">
        <h5 className="mt-0 col">
          <span className="mr-2">{quotationItem.name}</span>
          <QuotationItemStatusBadgeComponent
            status={quotationItem.status}
          />
        </h5>

        <dl className="text-muted">
          <dt className="col-auto">Reference Number:</dt>
          <dd className="col">{quotationItem.referenceNumber}</dd>

          <dt className="col-auto">Created On:</dt>
          <dd className="col">{formatDateTimeFromSeconds(quotationItem.createdAt)}</dd>

          {hasVariation && (
            <>
              <dt className="col-auto">Variation:</dt>
              <dd className="col">
                <VariantInfo
                  variants={product.variants}
                  variantPath={quotationItem.productSourceData.variantPath}
                />
              </dd>
            </>
          )}

          <dt className="col-auto">Weight Source:</dt>
          <dd className="col">{weightSource}</dd>

          <InternalQuotationItemPendingLine
            quotationItem={quotationItem}
            product={product}
          />

          <QuotationItemDeclineReasonLineComponent
            quotationItem={quotationItem}
          />

          <QuotationItemResolvedInfoLineComponent
            quotationItem={quotationItem}
          />

          <QuotationItemClosedAtLineComponent
            quotationItem={quotationItem}
          />
        </dl>

        <div className={classNames("text-right", styles.quotationItemButtonsContainer)}>
          <a
            className="btn btn-sm btn-outline-secondary"
            href={urljoin(process.env.REACT_APP_STORE_ENDPOINT!!, 'product', product.paramName)}
            target="_blank"
            rel="noreferrer"
          >
            Open Product Page (Indo4ward)
          </a>

          <a
            className="btn btn-sm btn-secondary"
            href={getSourceMarketPlaceUrl(product)}
            target="_blank"
            rel="noreferrer"
          >
            Open Product Page (3rd Party)
          </a>

          {isClosureButtonsEnabled && (
            <>
              <button
                className="btn btn-sm btn-outline-success"
                onClick={handleResolveBtn}
              >
                Resolve
              </button>
              <button
                className="btn btn-sm btn-outline-danger"
                onClick={handleDeclineBtn}
              >
                Decline
              </button>
            </>
          )}
        </div>
      </div>
    </div>
    <hr/>

    <ResolveInternalQuotationItemDialog
      show={showResolveDialog}
      onHide={handleResolveDialogClose}
      quotationItem={quotationItem}
      product={product}
      productWeightSource={weightSource}
    />

    <DeclineInternalQuotationItemDialog
      show={showDeclineDialog}
      onHide={handleDeclineDialogClose}
    />
  </>;
}