import React from 'react';
import {genericCheckboxChange, genericNumberChange} from '../utils/forms';
import {HOME_GROUP_TYPE_CATEGORY} from '../constants/home_group_types';
import {useCategories} from '../hooks/categories';
import {randomString} from '../utils/string';
import Api from '../api/api';
import {extractAxiosError} from '../utils/error';
import {HomeProductGroup} from "../api/models";
import {HomeProductGroupChanges} from "../api/request_models";
import {actions} from "../store/actions";
import {useDispatch} from "react-redux";

type CategoryMetadataFieldsOnChange = (changes: HomeProductGroup.CategoryMetadata) => void

interface CategoryMetadataFieldsProps {
  metadata: HomeProductGroup.CategoryMetadata;
  onChange: CategoryMetadataFieldsOnChange;
}

function CategoryMetadataFields({metadata, onChange}: CategoryMetadataFieldsProps) {
  const categories = useCategories();
  const {categoryId} = metadata;

  React.useEffect(() => {
    if (categories && !categoryId) {
      onChange({
        categoryId: Object.values(categories)[0].id
      });
    }
  }, [onChange, categories, categoryId]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange({
      categoryId: parseInt(e.target.value),
    });
  };

  return <>
    <div className="form-group">
      <label>Category</label>
      <select
        className="form-control"
        value={categoryId}
        onChange={handleChange}
      >
        {categories && Object.entries(categories).map(([key, category], index) => {
          return (
            <option key={index} value={category.id}>{category.name}</option>
          );
        })}
      </select>
    </div>
  </>;
}


export interface MetadataFieldsFactoryProps {
  groupType: HomeProductGroup.GroupTypes;
  metadata: HomeProductGroup.CategoryMetadata;
  onChange: CategoryMetadataFieldsOnChange;
}

function MetadataFieldsFactory({
                                 groupType,
                                 metadata,
                                 onChange
                               }: MetadataFieldsFactoryProps) {
  switch (groupType) {
    case HOME_GROUP_TYPE_CATEGORY:
      return <CategoryMetadataFields
        metadata={metadata}
        onChange={onChange}
      />;
  }
  return null;
}

export default function EditHomeProductGroup({
                                               onSave,
                                               onCancel,
                                               onError,
                                               showCancel
                                             }: { onSave: Function, onCancel: Function, onError: Function, showCancel: boolean }) {
  const [limit, setLimit] = React.useState<number>(10);
  const [isVisible, setIsVisible] = React.useState(true);
  const [groupType, setGroupType] = React.useState<HomeProductGroup.GroupTypes>(HOME_GROUP_TYPE_CATEGORY);
  const [metadata, setMetadata] = React.useState<HomeProductGroup.CategoryMetadata>({
    categoryId: 0
  });
  const [hasFile, setHasFile] = React.useState(false);

  const uploadRef = React.useRef<HTMLInputElement>( null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.category.loadCategoriesAsync());
  }, [dispatch]);

  const updateHasFile = React.useCallback(() => {
    setHasFile(!!uploadRef.current && !!uploadRef.current.files && uploadRef.current.files.length > 0);
  }, []);

  const clearUpload = React.useCallback(() => {
    if (uploadRef.current) {
      uploadRef.current.value = '';
    }

    updateHasFile();
  }, [updateHasFile]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const group: HomeProductGroupChanges = {
      limit,
      isVisible,
      groupType,
      metadata,
    };
    let headerImage: File | undefined;
    if (hasFile) {
      const {files} = uploadRef.current!;
      headerImage = files![0];
    }
    Api.admin.homeProductGroup.create(group, headerImage)
      .then(res => {
        onSave(res.data.homeProductGroup);

        // reset to default state
        setLimit(10);
        setIsVisible(true);
        setGroupType(HOME_GROUP_TYPE_CATEGORY);
        setMetadata({
          categoryId: 0
        });
        clearUpload();
      })
      .catch(error => {
        onError(extractAxiosError(error));
      });
  };

  const componentKey = randomString(6);
  const handleSelectImage = (_: React.ChangeEvent<HTMLInputElement>) => {
    updateHasFile();
  };

  const handleRemoveImage = () => {
    if (hasFile) {
      clearUpload();
    }
  };

  const handleGroupTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGroupType(e.target.value as HomeProductGroup.GroupTypes);
  };

  return <form onSubmit={handleSubmit}>
    <div className="form-group">
      <div className="form-check form-check-inline">
        <input
          id="homeProdctGroupIsVisible"
          className="form-check-input"
          type="checkbox"
          checked={isVisible}
          onChange={genericCheckboxChange(setIsVisible)}
        />
        <label className="form-check-label" htmlFor="homeProdctGroupIsVisible">Visible?</label>
      </div>
    </div>

    <div className="form-group">
      <label>Limit</label>
      <input
        type="text"
        className="form-control"
        value={limit}
        onChange={genericNumberChange(setLimit) as React.ChangeEventHandler}
      />
    </div>

    <div className="form-group">
      <label>Group Type</label>
      <select
        className="form-control"
        value={groupType}
        onChange={handleGroupTypeChange}
      >
        <option value={HOME_GROUP_TYPE_CATEGORY}>Category</option>
      </select>
    </div>

    <div className="form-group">
      <label htmlFor={`${componentKey}_homeProductGroupHeader`}>Header Image Background</label>
      <input
        type="file"
        className="form-control-file"
        id={`${componentKey}_homeProductGroupHeader`}
        ref={uploadRef as React.LegacyRef<any>}
        onChange={handleSelectImage}
        accept="image/*"
      />
      {hasFile && (
        <button className="btn btn-outline-danger" onClick={handleRemoveImage}>Remove Image</button>
      )}
    </div>

    <MetadataFieldsFactory
      groupType={groupType}
      metadata={metadata}
      onChange={setMetadata}
    />

    <button type="submit" className="btn btn-primary">Save</button>
    {showCancel && (
      <button type="button" className="btn btn-secondary"
              onClick={onCancel as React.MouseEventHandler<HTMLButtonElement>}>Cancel</button>
    )}
  </form>;
}