import React from "react";
import {FieldErrors} from "../generic";

interface GenerateFieldsChangeHandlerParams<FieldType, RawValueType, ValueType> {
  fields: FieldType;
  setFields: (value: FieldType) => void;
  fieldErrors?: FieldErrors<FieldType>;
  setFieldErrors?: (errors: FieldErrors<FieldType>) => void;
  valueExtractor: (rawValue: RawValueType) => ValueType;
}

export function genericRadioChange(setter: React.Dispatch<React.SetStateAction<string>>) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    if (checked) {
      setter(value);
    }
  };
}

export function genericRadioChangeWithValue<T>(setter: React.Dispatch<React.SetStateAction<T>>, checkedValue: T) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    if (checked) {
      setter(checkedValue);
    }
  };
}

export function genericCheckboxChange(setter: React.Dispatch<React.SetStateAction<boolean>>) {
  return (e: React.ChangeEvent<HTMLInputElement>) => setter(e.target.checked);
}

export function genericTextChange(setter: React.Dispatch<React.SetStateAction<string>>) {
  return (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => setter(e.target.value);
}

export function generateStep(precision: number) {
  const zeros = precision - 1;
  let step = '.';
  for (let i = 0; i < zeros; i++) {
    step += '0';
  }
  return step + '1';
}

export function genericNumberChange(setter: React.Dispatch<React.SetStateAction<number>>) {
  return (e: React.ChangeEvent<HTMLFormElement>) => {
    setter(e.target.value.replace(/\D/, ''));
  };
}

// generate a factory that creates a event handler given the attr name that will be changed
export function generateFieldsChangeHandler<FieldType, RawValueType, ValueType>({
                                              fields,
                                              setFields,
                                              fieldErrors,
                                              setFieldErrors,
                                              valueExtractor
                                            }: GenerateFieldsChangeHandlerParams<FieldType, RawValueType, ValueType>) {
  return (attrKey: keyof FieldType) => (e: RawValueType) => {
    if (!!fieldErrors && !!setFieldErrors) {
      const newFieldErrors = Object.assign({}, fieldErrors);
      delete newFieldErrors[attrKey];
      setFieldErrors(newFieldErrors);
    }

    const newFields = Object.assign({}, fields, {
      [attrKey]: valueExtractor!(e),
    });
    setFields(newFields);
  };
}

export function readOneFileAsDataURLFactory(onLoad: (data: string) => void) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        onLoad(reader.result as string);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
}