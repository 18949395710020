import '../styles/authorized_root.scss';
import React from 'react';
import {Collapse} from 'react-bootstrap';
import {
  FiTruck,
} from 'react-icons/fi';
import {
  AiFillSetting,
  AiOutlineTeam,
  AiOutlinePercentage,
  AiOutlineAccountBook,
  AiOutlineShop,
  AiOutlineFileSearch,
  AiOutlineDiff
} from 'react-icons/ai';
import {
  FaFileInvoiceDollar,
  FaRegImages,
  FaRegWindowMaximize,
  FaHistory
} from 'react-icons/fa';
import {
  RiHomeGearLine
} from 'react-icons/ri';
import {
  GiMeepleGroup,
  GiVerticalBanner
} from 'react-icons/gi';
import {
  IoLayersOutline,
  IoLayers
} from 'react-icons/io5';
import {
  HiOutlineReceiptTax
} from 'react-icons/hi';

import {Link, LinkProps, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {actions} from '../store/actions';
import {RootState} from "../store/reducers/reducers";
import quotationNavLinkStyles from '../styles/quotation_nav_link.module.scss';
import Api from "../api/api";

interface NavLinkProps extends LinkProps {
  children: React.ReactNode,
  className?: string;
  to: string;
}

function NavLink({children, className, to, ...others}: NavLinkProps) {
  const location = useLocation();
  const active = location.pathname.includes(to);

  const finalClassName = classNames('nav-link', className, {
    active,
  });

  return <Link to={to} {...others} className={finalClassName}>
    {children}
  </Link>;
}

function InternalPendingQuotationNavLink() {
  let internalPendingCount = useSelector((state: RootState) => state.quotation.internalPendingCount);
  if (internalPendingCount > 99) {
    internalPendingCount = 99;
  }

  return <NavLink to="/quotation_items/internal">
    <IoLayers className="feather"/>
    Price Dispute
    {internalPendingCount > 0 && (
      <div className={classNames('ml-1', 'font-weight-semibold', 'text-white', quotationNavLinkStyles.quotationsPageNavLink)}>
        {internalPendingCount}
      </div>
    )}
  </NavLink>;
}

function ExternalPendingQuotationNavLink() {
  let externalPendingCount = useSelector((state: RootState) => state.quotation.externalPendingCount);
  if (externalPendingCount > 99) {
    externalPendingCount = 99;
  }

  return <NavLink to="/quotation_items/external">
    <IoLayersOutline className="feather"/>
    External Quotations
    {externalPendingCount > 0 && (
      <div className={classNames('ml-1', 'font-weight-semibold', 'text-white', quotationNavLinkStyles.quotationsPageNavLink)}>
        {externalPendingCount}
      </div>
    )}
  </NavLink>;
}

function UnresolvedProductVariationDiffNavLink() {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    Api.staff.productVariationDiff.getUnresolvedCount()
        .then(res => {
          setCount(res.data.count);
        });
    const timeoutId = setTimeout(() => {
      Api.staff.productVariationDiff.getUnresolvedCount()
          .then(res => {
            setCount(res.data.count);
          });
    }, 60000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return <NavLink to="/product_variation_diff/unresolved">
    <AiOutlineDiff className="feather" />
    Variation Weight Differences
    <div className={classNames('ml-1', 'font-weight-semibold', 'text-white', quotationNavLinkStyles.quotationsPageNavLink)}>
      {count}
    </div>
  </NavLink>;
}

interface AuthorizedRootProps {
  children: React.ReactNode;
}

function AuthorizedRoot(props: AuthorizedRootProps) {
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(true);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const dispatch = useDispatch();
  const handleLogout = (e: React.FormEvent) => {
    e.preventDefault();
    // console.log('handle logout')
    dispatch(actions.user.processSignOut());
  };

  return <React.Fragment>
    <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="/">Indo4ward</a>
      <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse"
              data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
              aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" onClick={toggleSidebar}/>
      </button>
      <ul className="navbar-nav px-3">
        <li className="nav-item text-nowrap">
          <button className="btn btn-link nav-link" onClick={handleLogout}>
            Sign Out
          </button>
        </li>
      </ul>
    </nav>
    <div className="container-fluid">
      <div className="row">
        <Collapse /*tag="nav"*/ className="col-md-3 col-lg-2 d-md-block bg-light sidebar" in={sidebarOpen}>
          <div className="sidebar-sticky pt-3">
            <ul className="nav flex-column">
              <li className="sidebar-heading">Order</li>
              <li>
                <ul className="nav flex-column">
                  <li>
                    <NavLink to="/orders">
                      <FaFileInvoiceDollar className="feather"/>
                      Orders
                    </NavLink>
                  </li>
                  <li>
                    <InternalPendingQuotationNavLink />
                  </li>
                  <li>
                    <ExternalPendingQuotationNavLink />
                  </li>
                </ul>
              </li>


              <li className="sidebar-heading">Finance</li>
              <li>
                <ul className="nav flex-column">
                  <li>
                    <NavLink to="/reconcile_paynow_payments">
                      <AiOutlineAccountBook className="feather"/>
                      PayNow Reconciliations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/vouchers">
                      <AiOutlinePercentage className="feather"/>
                      Vouchers Management
                    </NavLink>
                  </li>
                </ul>
              </li>


              <li className="sidebar-heading">Marketing</li>
              <ul className="nav flex-column">
                <li>
                  <NavLink to="/home_product_groups">
                    <RiHomeGearLine className="feather"/>
                    Homepage Features
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/popups">
                    <FaRegWindowMaximize className="feather"/>
                    Homepage Popups
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/banners">
                    <GiVerticalBanner className="feather"/>
                    Homepage Banner Management
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/image_pages">
                    <FaRegImages className="feather"/>
                    iPages Management
                  </NavLink>
                </li>
              </ul>

              <li className="sidebar-heading">Merchant</li>
              <li>
                <ul className="nav flex-column">
                  <li>
                    <NavLink to="/merchants">
                      <AiOutlineShop/>
                      Merchants
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="sidebar-heading">Product</li>
              <li>
                <ul className="nav flex-column">
                  <li>
                    <NavLink to="/country_taxes">
                      <HiOutlineReceiptTax/>
                      Country Tax
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/product_search">
                      <AiOutlineFileSearch/>
                      Manual Product Import & Overrides
                    </NavLink>
                  </li>
                  <li>
                    <UnresolvedProductVariationDiffNavLink />
                  </li>

                  <li>
                    <NavLink to="/quotation_items/history">
                      <FaHistory className="feather"/>
                      Product Change Records
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="sidebar-heading">Accounts Management</li>
              <li>
                <ul className="nav flex-column">
                  <li>
                    <NavLink to="/users">
                      <AiOutlineTeam className="feather"/>
                      Users Management
                    </NavLink>
                  </li>
                  <li>
                    <span className="text-muted nav-link">User Roles Management</span>
                  </li>
                </ul>
              </li>

              <li className="sidebar-heading">System Setting</li>
              <li>
                <ul className="nav flex-column">
                  <li>
                    <NavLink to="/config">
                      <AiFillSetting className="feather"/>
                      Configurations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/category_settings">
                      <GiMeepleGroup className="feather"/>
                      Category Management
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/regions">
                      <FiTruck className="feather"/>
                      Regions Management
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Collapse>

        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
          {props.children}
        </main>
      </div>
    </div>
  </React.Fragment>;
}

export default AuthorizedRoot;