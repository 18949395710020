import React from "react";
import {useHistory, useParams} from "react-router-dom";

export default function ReplyMessagesPage() {
    const params = useParams<{ groupRef: string }>();
    const {groupRef} = params;

    const history = useHistory();

    React.useEffect(() => {
        if (!history || !groupRef) {
            return;
        }

        const isOrder = groupRef.startsWith('order_');
        const isExternalQuotation = groupRef.startsWith('external_quotation_');

        if (isOrder) {
            history.replace(`/order/${groupRef.replace('order_', '')}`);
            return;
        }

        if (isExternalQuotation) {
            history.replace(`/quotation_items/external/${groupRef.replace('external_quotation_', '')}`);
            return;
        }

        history.replace("/");
    }, [history, groupRef]);

    return <h1>Redirecting...</h1>;
}