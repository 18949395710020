import {DineroObject} from "dinero.js";
import {HOME_GROUP_TYPE_CATEGORY} from "../constants/home_group_types";
import {ConfigValueTypes} from "../constants/config_keys";
import {ProductWeightUnit} from "../types/models/product";

export interface ShippingMethod {
  _id: number;
  calculationModel: number,
  calculationType: string,
  methodName: string,
  minimumFee: number,
  fee: number,
  percent: number,
  feeCurrency: string,
  enabled: boolean,
  estimatedDays: number,
}

export interface UserProfile {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  createdAt: number;
  blacklisted: boolean
  role: string;
  verified: boolean;
  country: string | null;
  birthDate: number | null;
  gender: 'M' | 'F' | null;
}

export interface Merchant {
  _id: string;
  hasFile: boolean;
  merchantId: number;
  name: string;
  paramName: string;
  email: string;
  hasOwner: boolean
  owner?: string;
  createdAt: number;
  updatedAt: number;
  brandImage?: string;
  brandImagePath: string;
  removeBrandImage?: boolean;
}

export namespace CategorySetting {
  export type Sources = "tokopedia" | "local";
}

export interface CategorySetting {
  [key: string]: any;

  _id: number;
  categorySource: CategorySetting.Sources;
  weight: number;
  weightUnit: ProductWeightUnit;
  volumeMetricWeight: number;
  volumeMetricWeightUnit: ProductWeightUnit;
  isBlocked: boolean;
  isQuotation: boolean;
  isDisputable: boolean;
  isEqualPrecedence: boolean;
}

export type JourneyLog = {
  timestamp: number;
  details: string;
}

export interface VariantOption {
  value: string;
  color?: string;
}

export interface Variant {
  name: string;
  variantType: number;
  options: VariantOption[];
}

export interface VariantPath {
  originPriceBeforeDiscount: number;
  originPrice: number;
  priceBeforeDiscount?: number;
  price?: number;
  image?: string;
  sourceProductId?: string;
  stock: number;
  sku?: string;
  weight: number;
  weightUnit: ProductWeightUnit;
  weightHash: string;
}

export interface VariantPaths {
  [path: string]: VariantPath;
}

namespace ProductTypes {
  interface Wholesale {
    minQuantity: number;
    originPrice: number;
    price?: number;
  }

  interface TokopediaShippingDetail {
    minEta: number;
    maxEta: number;
    shipperName: string;
    shipperProduct: string;
    price: number;
  }

  interface TokopediaShippingDetails {
    [sourceProductId: string]: TokopediaShippingDetail | number | undefined;
    _version?: number;
  }

  interface Merchant {
    name: string;
    paramName: string;
  }

  export interface Product {
    _id: number;
    uuid: string;
    paramName: string;
    name: string;
    description: string;
    conditionNew: boolean;
    hasInsurance: boolean;
    weight: number;
    weightUnit: "kg" | "g";
    images: string[];
    originImages?: string[];
    disclaimer: string;
    originPriceBeforeDiscount?: number;
    originPrice: number;
    originPriceCurrency: string;
    priceBeforeDiscount?: number;
    price: DineroObject;
    priceCurrency: string;
    currencyRate: number;
    currencyRateTarget: string;
    currencyRateUpdatedAt: number;
    priceUpdatedAt: number;
    category: number;
    sourceCategory?: string;
    rating: number;
    minQuantity: number;
    maxQuantity: number;
    wholesale: Wholesale[];
    hasVariant: boolean;
    stock: number;
    shippingDetails: TokopediaShippingDetails;
    sourceMarketPlace: string;
    sourceId: string;
    sourceUrlId: string;
    createdAt: number;
    updatedAt: number;
    variants: Variant[];
    variantPaths: VariantPaths;
    sku?: string;
    merchantId?: string;
    merchant?: Merchant;
    disabled: boolean;
    soldCount: number;
  }
}
export type Product = ProductTypes.Product;

export interface ProductListing extends Pick<Product,
  '_id' |
  'name' |
  'price' |
  'priceCurrency' |
  'images' |
  'paramName' |
  'rating' |
  'sourceMarketPlace' |
  'merchant' |
  'soldCount' |
  'disabled' |
  'hasVariant'> {
}

export interface AdminSearchProductListing extends Pick<Product,
  '_id' |
  'name' |
  'price' |
  'priceCurrency' |
  'images' |
  'paramName' |
  'rating' |
  'sourceMarketPlace' |
  'sourceUrlId' |
  'merchant' |
  'merchantId' |
  'soldCount' |
  'disabled' |
  'hasVariant'> {
}


export interface CartItem {
  _id: string;
  productId: string;
  product: Product;
  message: string;
  quantity: number;
  variantPath: string;
}

export interface ShippingTier {
  upperWeightLimit: number;
  price: number;
  priceCurrency: string;
  destinationCountry: string;
}

export interface Metadata {
  category: string;
  categoryId: number;
  country_calling_codes: string[];
  countries: string[];
}

export namespace ImagePageTypes {
  export interface Hotspot {
    unit: string,
    x: number;
    y: number;
    width: number;
    height: number;
    link: string;
  }
}
export interface ImagePage {
  _id: string;
  name: string;
  pagePath: string;
  imagePath: string;
  active: boolean;
  hotspots: ImagePageTypes.Hotspot[];
  createdAt: number;
  updatedAt: number;
}

export interface ProductWeightOverride {
  productId: string;
  weight: number;
  updatedAt: number;
  variantPath?: string;
  variationWeightHash?: string;
}


export interface HomeProductGroup {
  _id: string;
  isVisible: boolean;
  groupType: string;
  limit: number;
  headerImagePath: string;
  metadata: HomeProductGroup.CategoryMetadata;
}

export namespace HomeProductGroup {
  export interface CategoryMetadata {
    categoryId: number;
  }

  export type GroupTypes = typeof HOME_GROUP_TYPE_CATEGORY;
}

export interface Popup {
  _id: number;
  name: string;
  link: string;
  active: boolean,
  imagePath: string;
}

export interface Category {
  name: string;
  children: CategoryMap;
  id: number
}

export interface CategoryMap {
  [categoryName: string]: Category;
}

export type ConfigData = ConfigValueTypes;

export interface Region {
  country: string;
  enabledStates: string[];
  enabled: boolean;
  shippingPricePerHundredGram: number;
  shippingPricePerHundredGramCurrency: string;
  flatShippingFee: number;
  flatShippingFeeCurrency: string;
  isDefault: boolean;
}

export enum HistoricProductWeightChangeSource {
  MANUAL_OVERRIDE = 'om',
  INTERNAL_QUOTATION = 'iq'
}

export interface HistoricProductWeight {
  _id: string;
  productId: string;
  name: string;
  description: string;
  weight: number;
  weightUnit: ProductWeightUnit;
  createdAt: number;
  createdBy?: string;
  changeSource?: HistoricProductWeightChangeSource;
}