import {call, put, takeLatest} from 'redux-saga/effects';
import {actions, actionTypes} from '../actions';
import Api from '../../api/api';
import {sagaGenericErrorHandling} from '../../utils/error';
import {AxiosResponse} from "axios";
import {MeResponse} from "../../api/response_models";

function* fetchProfile() {
  try {
    const response: AxiosResponse<MeResponse> = yield call(Api.auth.getMe);
    const {data} = response;
    if (data.role === 'shp') {
      if (data.signedIn) {
        window.location.href = process.env.REACT_APP_STORE_ENDPOINT as string;
      } else {
        data.signedIn = false;
        data.tfaPassed = false;
        data.profile = null;
      }
      return;
    }

    yield put(actions.user.setProfile(data));
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.user.setFetchProfileError);
  }
}

export function* watchFetchProfile() {
  yield takeLatest(actionTypes.user.FETCH_PROFILE, fetchProfile);
}

function* processSignOut() {
  try {
    yield call(Api.auth.signOut);
    yield put(actions.user.signOut());
  } catch (error) {
    console.error('signout error', error);
  }
}

export function* watchProcessSignOut() {
  yield takeLatest(actionTypes.user.PROCESS_SIGN_OUT, processSignOut);
}