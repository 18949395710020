import {authEndpoint, authHeadersEndpoint, serviceEndpoint, serviceHeadersEndpoint} from './axios_provider';
import {
  ApiResponse,
  CreateHomeProductGroupResponse,
  DisputeInfo,
  GetAllConfigResponse,
  GetAllHomeProductGroupResponse,
  GetAllProductWeightOverride,
  GetAllVouchersResponse,
  GetBannerResponse,
  GetVoucherResponse,
  MeResponse,
  MoveHomeProductGroupsResponse,
  OrderEstimateUpdatedPrice,
  PayNowReconcileResponse,
  SearchExternalProductResponse,
  SetProductWeightOverrideResponse,
  StaffGetHistoricProductWeightResponse,
  StaffPaginateHistoricProductWeightResponse,
  StaffSearchHistoricProductWeightResponse,
  StaffSearchProductsResponse
} from "./response_models";
import {CategorySetting, ProductWeightOverride, ShippingMethod, ShippingTier} from "./models";
import {LooseObject} from "../generic";
import {Banner} from "../components/pages/banners";
import {
  HomeProductGroupChanges,
  MerchantChanges,
  PayNowReconcileParameters,
  ProductParameters,
  StaffSearchProductsRequestParams,
  UpdateRegionParameters,
  UpdateVoucherParameters
} from "./request_models";
import {
  CancelOrderResponse,
  GetOrderRefundable,
  RefundOrderResponse,
  StaffAddJourneyLog,
  StaffGetOrderFlagsResponse,
  StaffGetOrderResponse,
  StaffGetOrdersResponse
} from "../types/responses/order";
import {UserAddress} from "../types/models/user_address";
import {GetUsernamesResponse, StaffGetUserResponse} from "../types/responses/user";
import {NextStepToInTransitMetadata, OrderRefundRequest} from "../types/requests/order";
import {QuotationItemSourceFilters, QuotationItemStatusFilters} from "../types/models/quotation_item";
import {
  GetQuotationItemResponse,
  GetQuotationItemsResponse,
  StaffDeclineQuotationItemResponse,
  StaffPendingQuotationCountResponse,
  StaffPreviewProductPriceResponse,
  StaffResolveQuotationItemResponse
} from "../types/responses/quotation_item";
import {
  DeclineQuotationItemRequest,
  ResolveExternalQuotationItemRequest,
  ResolveInternalQuotationItemRequest
} from "../types/requests/quotation_item";
import {
  GetCategoryProductWeightBulkResponse,
  GetCategoryProductWeightResponse,
  GetOverrideProductWeightBulkResponse,
  GetOverrideProductWeightResponse,
} from "../types/responses/product_weight";
import {
  CreateCountryTaxResponse,
  DeleteCountryTaxResponse,
  GetCountryTaxesResponse,
  GetCountryTaxResponse,
  UpdateCountryTaxResponse
} from "../types/responses/country_tax";
import {CreateCountryTaxBody, UpdateCountryTaxBody} from "../types/requests/country_tax";
import {
  GetProductVariationDiffList,
  GetProductVariationUnresolvedCount
} from "../types/responses/product_variation_diff";


const Api = {
  ping() {
    return Promise.all([
      serviceEndpoint.get('/ping'),
      authEndpoint.get('/ping'),
    ]);
  },
  product: {
    searchExternal(link: string) {
      return serviceEndpoint.post<SearchExternalProductResponse>('/product/searchExternal', {
        link,
      });
    },
    checkPending(link: string) {
      return serviceEndpoint.get('/product/checkPending', {
        params: {
          link,
        }
      });
    },
    searchCategorySpread(text: string) {
      const axios = serviceEndpoint;
      return axios.get('/product/search/categorySpread', {
        params: {
          text,
        }
      });
    },
    search(params: string) {
      const axios = serviceEndpoint;
      return axios.get('/product/search', {
        params,
      });
    },
    one(productKey: string) {
      const axios = serviceEndpoint;
      return axios.get('/product/one/' + productKey);
    },
    categories() {
      const axios = serviceEndpoint;
      return axios.get('/product/categories');
    }
  },
  auth: {
    login(email: string, password: string) {
      const axios = authEndpoint;
      return axios.post('/auth/login', {
        email,
        password
      });
    },
    forgotPassword(email: string) {
      const axios = authEndpoint;
      return axios.post('/auth/forgotPassword', {
        email,
      });
    },
    resetPassword(uid: string, resetPasswordToken: string, password: string) {
      const axios = authEndpoint;
      return axios.post('/auth/resetPassword', {
        uid,
        resetPasswordToken,
        password
      });
    },
    register(email: string, password: string, firstName: string, lastName: string, phoneNumber: string) {
      const axios = authEndpoint;
      return axios.post('/auth/register', {
        email,
        password,
        firstName,
        lastName,
        phoneNumber
      });
    },
    verifyEmail(code: string) {
      const axios = authEndpoint;
      return axios.post('/auth/verifyEmail', {
        code,
      });
    },
    signOut() {
      const axios = authHeadersEndpoint;
      return axios.delete('/auth/logout');
    },
    refreshAccessToken() {
      const axios = authEndpoint;
      return axios.post('/auth/refreshAccessToken');
    },
    getMe() {
      const axios = authEndpoint;
      return axios.get<MeResponse>('/auth/me');
    },
  },
  tfa: {
    requestReset() {
      const axios = authHeadersEndpoint;
      return axios.post('/tfa/requestReset');
    },
    reset(resetTfaToken: string) {
      const axios = authHeadersEndpoint;
      return axios.post('/tfa/reset', {
        resetTfaToken,
      });
    },
    setup() {
      const axios = authHeadersEndpoint;
      return axios.post('/tfa/setup');
    },
    verify(token: string) {
      const axios = authHeadersEndpoint;
      return axios.post('/tfa/verify', {
        token,
      });
    }
  },
  quotation: {},
  user: {
    getNames(userIds: string[]) {
      return authHeadersEndpoint.post<GetUsernamesResponse>('/user/names', {
        userIds
      });
    }
  },
  config: {
    getAll() {
      const axios = serviceEndpoint;
      return axios.get('/config');
    },
  },
  productWeight: {
    getOverrideWeight(productId: string) {
      return serviceHeadersEndpoint.get<GetOverrideProductWeightResponse>('/product_weights/override/' + productId);
    },
    getOverrideWeightBulk(productIds: string[]) {
      return serviceHeadersEndpoint.post<GetOverrideProductWeightBulkResponse>('/product_weights/override/bulk', {
        productIds
      });
    },
    getCategoryWeight(productId: string) {
      return serviceHeadersEndpoint.get<GetCategoryProductWeightResponse>('/product_weights/category/' + productId);
    },
    getCategoryWeightBulk(productIds: string[]) {
      return serviceHeadersEndpoint.post<GetCategoryProductWeightBulkResponse>('/product_weights/category/bulk', {
        productIds
      });
    }
  },
  staff: {
    product: {
      searchProduct(params: StaffSearchProductsRequestParams) {
        return serviceHeadersEndpoint.get<StaffSearchProductsResponse>('/staff/product/search', {
          params,
        });
      }
    },
    quotation: {
      getAll(status: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get(`/staff/quotation`, {
          params: {
            status,
          }
        });
      },
      accept(id: number, quotedPrice: number, shippingCost: number, validForHours: number) {
        const axios = serviceHeadersEndpoint;
        return axios.post(`/staff/quotation/${id}/accept`, {
          quotedPrice,
          shippingCost,
          validForHours,
        });
      },
      reject(id: number, rejectReason: string) {
        const axios = serviceHeadersEndpoint;
        return axios.post(`/staff/quotation/${id}/reject`, {
          rejectReason,
        });
      }
    },
    order: {
      getAll(params: LooseObject) {
        const axios = serviceHeadersEndpoint;
        return axios.get<StaffGetOrdersResponse>('/staff/order', {
          params,
        });
      },
      addJourneyLog(orderRef: string, details: string) {
        const axios = serviceHeadersEndpoint;
        return axios.post<StaffAddJourneyLog>(`/staff/order/${orderRef}/add_journey_log`, {
          details,
        });
      },
      nextStage(orderRef: string, metadata?: NextStepToInTransitMetadata) {
        return serviceHeadersEndpoint.post(`/staff/order/${orderRef}/next_step`, {
          metadata
        });
      },
      flagOrder(orderRef: string, message: string) {
        const axios = serviceHeadersEndpoint;
        return axios.post(`/staff/order/${orderRef}/flag`, {
          message
        });
      },
      get(orderRef: string) {
        return serviceHeadersEndpoint.get<StaffGetOrderResponse>('/staff/order/' + orderRef);
      },
      getDisputeInfo(orderRef: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get<DisputeInfo.Response>('/staff/order/' + orderRef + '/dispute_info');
      },
      resolveDispute(orderRef: string) {
        const axios = serviceHeadersEndpoint;
        return axios.post('/staff/order/' + orderRef + '/resolve_dispute');
      },
      updateAddress(orderRef: string, changes: Partial<UserAddress>) {
        const axios = serviceHeadersEndpoint;
        return axios.put('/staff/order/' + orderRef + '/address', changes);
      },
      getMessages(orderRef: string, after?: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/staff/order/' + orderRef + '/messages', {
          params: {
            after,
          }
        });
      },
      sendMessage(orderRef: string, message: string) {
        const axios = serviceHeadersEndpoint;
        return axios.post('/staff/order/' + orderRef + '/messages', {
          message,
        });
      },
      resendInvoice(orderRef: string) {
        const axios = serviceHeadersEndpoint;
        return axios.post(`/staff/order/${orderRef}/resend_invoice`);
      },
      estimateShippingCostByWeight(orderRef: string, weight: number) {
        const axios = serviceHeadersEndpoint;
        return axios.post(`/staff/order/${orderRef}/estimate_shipping_cost_by_weight`, {
          weight,
        });
      },
      estimateUpdatedPrice(orderRef: string) {
        const axios = serviceHeadersEndpoint;
        return axios.post<OrderEstimateUpdatedPrice>(`/staff/order/${orderRef}/estimate_updated_prices`);
      },
      cancelOrder(orderRef: string, reason: string) {
        return serviceHeadersEndpoint.post<CancelOrderResponse>(`/staff/order/${orderRef}/cancel`, {
          reason
        });
      },
      getStaffFlags(orderRef: string) {
        return serviceHeadersEndpoint.get<StaffGetOrderFlagsResponse>(`/staff/order/${orderRef}/staff_flags`);
      },
      capturePayment(orderRef: string) {
        return serviceHeadersEndpoint.post<ApiResponse>(`/staff/order/${orderRef}/payment/capture`);
      },
      getOrderRefundable(orderRef: string) {
        return serviceHeadersEndpoint.get<GetOrderRefundable>(`/staff/order/${orderRef}/refundable`);
      },
      refund(orderRef: string, body: OrderRefundRequest) {
        return serviceHeadersEndpoint.post<RefundOrderResponse>(`/staff/order/${orderRef}/refund`, body);
      }
    },
    quotationOrder: {
      getAll(params: LooseObject) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/staff/quotation_order', {
          params,
        });
      }
    },
    productWeightOverride: {
      getAll(search?: string) {
        return serviceHeadersEndpoint.get<GetAllProductWeightOverride.Response>('/staff/product_weight_override', {
          params: {
            search,
          },
        });
      },
      set(update: Pick<ProductWeightOverride, 'productId'> & Partial<Pick<ProductWeightOverride, 'weight' | 'variantPath'>>) {
        const axios = serviceHeadersEndpoint;
        return axios.put<SetProductWeightOverrideResponse>('/staff/product_weight_override', update);
      },
      remove(productId: string) {
        const axios = serviceHeadersEndpoint;
        return axios.delete<ApiResponse>('/staff/product_weight_override/' + productId);
      },
    },
    user: {
      getByEmail(email: string) {
        return authHeadersEndpoint.get<StaffGetUserResponse>(`/staff/user`, {
          params: {
            email
          }
        });
      },
      getByUid(uid: string) {
        return authHeadersEndpoint.get<StaffGetUserResponse>(`/staff/user`, {
          params: {
            uid
          }
        });
      }
    },
    quotationItem: {
      getAll(config: {
        status?: QuotationItemStatusFilters,
        source?: QuotationItemSourceFilters,
        beforeT?: number
      }) {
        return serviceHeadersEndpoint.get<GetQuotationItemsResponse>('/staff/quotation_item', {
          params: {
            status: config.status,
            source: config.source,
            before_t: config.beforeT
          }
        });
      },
      resolveExternal(id: string, body: ResolveExternalQuotationItemRequest) {
        return serviceHeadersEndpoint.post<StaffResolveQuotationItemResponse>(`/staff/quotation_item/external/${id}/resolve`, body);
      },
      resolveInternal(id: string, body: ResolveInternalQuotationItemRequest) {
        return serviceHeadersEndpoint.post<StaffResolveQuotationItemResponse>(`/staff/quotation_item/internal/${id}/resolve`, body);
      },
      decline(id: string, body: DeclineQuotationItemRequest) {
        return serviceHeadersEndpoint.post<StaffDeclineQuotationItemResponse>(`/staff/quotation_item/${id}/decline`, body);
      },
      previewInternalProductPrice(id: string, weightInGrams: number) {
        return serviceHeadersEndpoint.post<StaffPreviewProductPriceResponse>(`/staff/quotation_item/internal/${id}/preview_product_price`, {
          weightInGrams
        });
      },
      previewExternalProductPrice(id: string, weightInGrams: number, basePriceCurrency: string, basePrice: number) {
        return serviceHeadersEndpoint.post<StaffPreviewProductPriceResponse>(`/staff/quotation_item/external/${id}/preview_product_price`, {
          weightInGrams,
          basePriceCurrency,
          basePrice
        });
      },
      getPendingCount() {
        return serviceHeadersEndpoint.get<StaffPendingQuotationCountResponse>('/staff/quotation_item/pending_count');
      },
      get(quotationItemId: string) {
        return serviceHeadersEndpoint.get<GetQuotationItemResponse>(`/staff/quotation_item/${quotationItemId}`);
      }
    },
    historicProductWeight: {
      getByProduct(productId: string) {
        return serviceHeadersEndpoint.get<StaffGetHistoricProductWeightResponse>('/staff/historic_product_weight/product/' + productId);
      },
      search(query: string) {
        return serviceHeadersEndpoint.get<StaffSearchHistoricProductWeightResponse>('/staff/historic_product_weight/search', {
          params: {
            q: query
          }
        });
      },
      paginate(page=1) {
        return serviceHeadersEndpoint.get<StaffPaginateHistoricProductWeightResponse>('/staff/historic_product_weight');
      }
    },
    productVariationDiff: {
      getResolved() {
        return serviceHeadersEndpoint.get<GetProductVariationDiffList>('/staff/product_variation_diff/resolved');
      },
      getUnresolved() {
        return serviceHeadersEndpoint.get<GetProductVariationDiffList>('/staff/product_variation_diff/unresolved');
      },
      resolve(_id: string) {
        return serviceHeadersEndpoint.post(`/staff/product_variation_diff/${_id}/resolve`);
      },
      getUnresolvedCount() {
        return serviceHeadersEndpoint.get<GetProductVariationUnresolvedCount>('/staff/product_variation_diff/unresolved_count');
      }
    }
  },
  admin: {
    config: {
      getAll() {
        const axios = serviceHeadersEndpoint;
        return axios.get<GetAllConfigResponse>('/admin/config');
      },
      set(key: string, value: React.ReactNode) {
        const axios = serviceHeadersEndpoint;
        return axios.put('/admin/config', {
          key,
          value,
        });
      },
    },
    user: {
      getAll(page: number, emailFilter: string, roleFilter: string) {
        const axios = authHeadersEndpoint;
        return axios.get('/admin/user', {
          params: {
            page,
            emailFilter,
            roleFilter,
          }
        });
      },
      get(uid: string) {
        const axios = authHeadersEndpoint;
        return axios.get('/admin/user/' + uid);
      },
      changeRole(uid: string, isStaff: string) {
        const axios = authHeadersEndpoint;
        return axios.put(`/admin/user/${uid}/role`, {
          isStaff,
        });
      },
      forgotPassword(uid: string) {
        const axios = authHeadersEndpoint;
        return axios.post('/admin/user/' + uid + '/forgot_password');
      },
      deactivateAccount(uid: string) {
        const axios = authHeadersEndpoint;
        return axios.post('/admin/user/' + uid + '/deactivate_account');
      },
      activateAccount(uid: string) {
        const axios = authHeadersEndpoint;
        return axios.post('/admin/user/' + uid + '/activate_account');
      },
      sendVerificationEmail(uid: string) {
        const axios = authHeadersEndpoint;
        return axios.post('/admin/user/' + uid + '/send_verification_email');
      }
    },
    voucher: {
      create(voucher: UpdateVoucherParameters) {
        const axios = serviceHeadersEndpoint;
        return axios.post('/admin/voucher', voucher);
      },
      getAll(page: number) {
        const axios = serviceHeadersEndpoint;
        return axios.get<GetAllVouchersResponse>('/admin/voucher', {
          params: {
            page,
          },
        });
      },
      get(id: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get<GetVoucherResponse>('/admin/voucher/' + id);
      },
      update(id: string, voucherData: UpdateVoucherParameters) {
        const axios = serviceHeadersEndpoint;
        return axios.put('/admin/voucher/' + id, voucherData);
      }
    },
    shippingMethod: {
      create(shippingMethod: ShippingMethod) {
        const axios = serviceHeadersEndpoint;
        return axios.post('/admin/shipping_method', shippingMethod);
      },
      update(id: number, shippingMethodData: object) {
        const axios = serviceHeadersEndpoint;
        return axios.put('/admin/shipping_method/' + id, shippingMethodData);
      },
      getAll(page: number) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/shipping_method', {
          params: {
            page,
          },
        });
      },
      get(id: number) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/shipping_method/' + id);
      },
      delete(id: number) {
        const axios = serviceHeadersEndpoint;
        return axios.delete('/admin/shipping_method/' + id);
      },
    },
    categorySetting: {
      import(settings: object) {
        const axios = serviceHeadersEndpoint;
        return axios.post('/admin/category_setting/import', {
          settings,
        });
      },
      getAll() {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/category_setting');
      },
      delete(categorySource: string, category: string) {
        const axios = serviceHeadersEndpoint;
        return axios.delete('/admin/category_setting', {
          data: {
            categorySource,
            category,
          }
        });
      },
      update(categorySetting: CategorySetting) {
        const axios = serviceHeadersEndpoint;
        return axios.put('/admin/category_setting/update', categorySetting);
      },
    },
    shippingLocation: {
      get(destinationCountry: string, destinationState: string) {
        const axios = serviceHeadersEndpoint;
        let path = '/admin/shipping_location/' + destinationCountry;
        if (destinationState) {
          path += `/${destinationState}`;
        }

        return axios.get(path);
      },
      setEnabled(destinationCountry: string, destinationState: string, enabled: boolean) {
        const axios = serviceHeadersEndpoint;
        let path = '/admin/shipping_location/' + destinationCountry;
        if (destinationState) {
          path += `/${destinationState}`;
        }

        return axios.put(path, {
          enabled,
        });
      },
      getAll() {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/shipping_location');
      },
      shippingPriceTier: {
        add(destinationCountry: string, destinationState: string, shippingPriceTier: ShippingTier) {
          const axios = serviceHeadersEndpoint;
          let path = '/admin/shipping_location/' + destinationCountry;
          if (destinationState) {
            path += `/${destinationState}`;
          }
          path += '/price_tier';

          return axios.post(path, shippingPriceTier);
        },
        update(destinationCountry: string, destinationState: string, id: number, shippingPriceTier: ShippingTier) {
          const axios = serviceHeadersEndpoint;
          let path = '/admin/shipping_location/' + destinationCountry;
          if (destinationState) {
            path += `/${destinationState}`;
          }
          path += '/price_tier';
          path += `/${id}`;

          return axios.put(path, shippingPriceTier);
        },
        delete(destinationCountry: string, destinationState: string, id: number) {
          const axios = serviceHeadersEndpoint;
          let path = '/admin/shipping_location/' + destinationCountry;
          if (destinationState) {
            path += `/${destinationState}`;
          }
          path += '/price_tier';
          path += `/${id}`;

          return axios.delete(path);
        }
      }
    },
    paynow: {
      reconcile(transactions: PayNowReconcileParameters.Transaction[]) {
        const axios = serviceHeadersEndpoint;
        return axios.post<PayNowReconcileResponse>('/admin/paynow/transactions', {
          transactions,
        });
      },
    },
    homeProductGroup: {
      getAll() {
        const axios = serviceHeadersEndpoint;
        return axios.get<GetAllHomeProductGroupResponse>('/admin/home_product_group');
      },
      create(homeProductGroup: HomeProductGroupChanges, headerImage?: File) {
        const axios = serviceHeadersEndpoint;
        const formData = new FormData();
        formData.append("home_product_group_json", JSON.stringify(homeProductGroup));
        if (headerImage) {
          formData.append('header_image', headerImage);
        }

        return axios.post<CreateHomeProductGroupResponse>('/admin/home_product_group', formData);
      },
      move(oldIndex: number, newIndex: number) {
        const axios = serviceHeadersEndpoint;
        return axios.post<MoveHomeProductGroupsResponse>('/admin/home_product_group/move', {
          oldIndex,
          newIndex,
        });
      },
      delete(id: string) {
        const axios = serviceHeadersEndpoint;
        return axios.delete<ApiResponse>(`/admin/home_product_group/${id}`);
      },
    },
    imagePage: {
      getAll(page: number) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/image_page', {
          params: {
            page,
          }
        });
      },
      get(id: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/image_page/' + id);
      },
      create(imagePage: LooseObject) {
        const axios = serviceHeadersEndpoint;

        let data = imagePage;
        if (imagePage.image) {
          const formData = new FormData();
          formData.append("active", imagePage.active);
          formData.append("name", imagePage.name);
          formData.append("pagePath", imagePage.pagePath);
          formData.append("hotspots", JSON.stringify(imagePage.hotspots));
          formData.append("image", imagePage.image);
          data = formData;
        }

        return axios.post('/admin/image_page', data);
      },
      update(id: string, imagePage: LooseObject) {
        const axios = serviceHeadersEndpoint;
        return axios.put('/admin/image_page/' + id, imagePage);
      },
      remove(id: string) {
        const axios = serviceHeadersEndpoint;
        return axios.delete('/admin/image_page/' + id);
      },
    },
    popup: {
      getAll(page: number) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/popup', {
          params: {
            page,
          }
        });
      },
      get(id: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/popup/' + id);
      },
      create(popup: LooseObject) {
        const axios = serviceHeadersEndpoint;

        let data = popup;
        if (popup.image) {
          const formData = new FormData();
          formData.append("active", popup.active);
          formData.append("name", popup.name);
          formData.append("link", popup.link);
          formData.append("image", popup.image);
          data = formData;
        }

        return axios.post('/admin/popup', data);
      },
      update(id: number, popup: LooseObject) {
        const axios = serviceHeadersEndpoint;
        return axios.put('/admin/popup/' + id, popup);
      },
      remove(id: number) {
        const axios = serviceHeadersEndpoint;
        return axios.delete('/admin/popup/' + id);
      },
    },
    banner: {
      getAll() {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/banner');
      },
      get(id: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get<GetBannerResponse>('/admin/banner/' + id);
      },
      create(banner: Banner) {
        const axios = serviceHeadersEndpoint;

        let data = banner;
        if (banner.image) {
          const formData = new FormData();
          formData.append("active", (banner.active as boolean).toString());
          formData.append("name", banner.name as string);
          formData.append("link", banner.link as string);
          formData.append("image", banner.image);
          data = formData as Banner;
        }

        return axios.post('/admin/banner', data);
      },
      update(id: number, banner: Banner) {
        const axios = serviceHeadersEndpoint;
        return axios.put('/admin/banner/' + id, banner);
      },
      remove(id: number) {
        const axios = serviceHeadersEndpoint;
        return axios.delete('/admin/banner/' + id);
      },
      move(oldIndex: number, newIndex: number) {
        const axios = serviceHeadersEndpoint;
        return axios.post('/admin/banner/move', {
          oldIndex,
          newIndex,
        });
      },
      getNextPosition() {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/banner/next_position');
      },
    },
    merchant: {
      create(merchant: MerchantChanges) {
        const axios = serviceHeadersEndpoint;
        const merchantData = Object.assign({}, merchant);

        const formData = new FormData();
        if (merchantData.brandImage) {
          formData.append('brand_image', merchantData.brandImage);
          delete merchantData.brandImage;
        }

        formData.append('merchant_json', JSON.stringify(merchantData));

        return axios.post('/admin/merchant', formData);
      },
      update(id: string, merchant: MerchantChanges) {
        const axios = serviceHeadersEndpoint;
        const merchantData = Object.assign({}, merchant);
        console.log('merchantData', merchantData);

        const formData = new FormData();
        if (merchantData.brandImage) {
          formData.append('brand_image', merchantData.brandImage);
          delete merchantData.brandImage;
        }

        if (merchantData.removeBrandImage) {
          formData.append('remove_brand_image', '1');
        }

        const {removeBrandImage, ...merchantJson} = merchantData;
        formData.append('merchant_json', JSON.stringify(merchantJson));

        return axios.put('/admin/merchant/' + id, formData);
      },
      getAll(page: number) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/merchant', {
          params: {
            page,
          }
        });
      },
      remove(id: string) {
        const axios = serviceHeadersEndpoint;
        return axios.delete('admin/merchant/' + id);
      },
      get(id: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/merchant/' + id);
      },
    },
    product: {
      create(product: ProductParameters.ProductChanges, newImages: File[]) {
        const axios = serviceHeadersEndpoint;

        const formData = new FormData();
        formData.append("product_json", JSON.stringify(product));
        for (const image of newImages) {
          formData.append("new_images[]", image);
        }

        return axios.post('/admin/product', formData);
      },
      update(productId: number, product: ProductParameters.ProductChanges, newImages: File[], removedImages: string[]) {
        const axios = serviceHeadersEndpoint;

        const formData = new FormData();
        formData.append("product_json", JSON.stringify(product));
        for (const image of newImages) {
          formData.append("new_images[]", image);
        }
        formData.append('removed_images_json', JSON.stringify(removedImages));

        return axios.put(`/admin/product/${productId}`, formData);
      },
      getAll(merchantId: number) {
        const axios = serviceHeadersEndpoint;
        return axios.get('/admin/product', {
          params: {
            merchantId,
          }
        });
      },
      get(productId: string) {
        const axios = serviceHeadersEndpoint;
        return axios.get(`/admin/product/${productId}`);
      }
    },
    region: {
      getAll() {
        return serviceHeadersEndpoint.get('/admin/region');
      },
      get(country: string) {
        return serviceHeadersEndpoint.get(`/admin/region/${country}`);
      },
      create(region: UpdateRegionParameters) {
        return serviceHeadersEndpoint.post('/admin/region', region);
      },
      update(region: UpdateRegionParameters) {
        return serviceHeadersEndpoint.put(`/admin/region/${region.country}`, region);
      },
      remove(country: string) {
        return serviceHeadersEndpoint.delete(`/admin/region/${country}`);
      },
      shippingPriceTier: {
        add(destinationCountry: string, shippingPriceTier: ShippingTier) {
          const axios = serviceHeadersEndpoint;
          const path = `/admin/region/${destinationCountry}/price_tier`;

          return axios.post(path, shippingPriceTier);
        },
        update(destinationCountry: string, id: number, shippingPriceTier: ShippingTier) {
          const axios = serviceHeadersEndpoint;
          const path = `/admin/region/${destinationCountry}/price_tier/${id}`;

          return axios.put(path, shippingPriceTier);
        },
        delete(destinationCountry: string, id: number) {
          const axios = serviceHeadersEndpoint;
          const path = `/admin/region/${destinationCountry}/price_tier/${id}`;

          return axios.delete(path);
        }
      }
    },
    countryTaxes: {
      getAll() {
        return serviceHeadersEndpoint.get<GetCountryTaxesResponse>('/admin/country_tax');
      },
      get(id: string) {
        return serviceHeadersEndpoint.get<GetCountryTaxResponse>(`/admin/country_tax/${id}`);
      },
      create(body: CreateCountryTaxBody) {
        return serviceHeadersEndpoint.post<CreateCountryTaxResponse>('/admin/country_tax', body);
      },
      update(id: string, body: UpdateCountryTaxBody) {
        return serviceHeadersEndpoint.put<UpdateCountryTaxResponse>(`/admin/country_tax/${id}`, body);
      },
      remove(id: string) {
        return serviceHeadersEndpoint.delete<DeleteCountryTaxResponse>(`/admin/country_tax/${id}`);
      }
    }
  }
};

export default Api;