import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Link, useHistory, useLocation} from 'react-router-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Api from '../../api/api';
import {useQuery} from '../../utils/route';
import {formatDateTimeFromSeconds} from '../../utils/date';
import {genericTextChange} from '../../utils/forms';
import {extractAxiosError} from '../../utils/error';
import TimeoutAlert from '../timeout_alert';
import HumanizeRole from '../humanize_role';
import HeadTitle from '../head_title';
import {usePagination} from '../../lib/paginator';
import {UserProfile} from "../../api/models";

function UsersPage() {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const [page, paginator, setTotalPages] = usePagination();

  const [users, setUsers] = React.useState<UserProfile[]>([]);
  const [changingRole, setChangingRole] = React.useState(false);
  const [selectedRole, setSelectRole] = React.useState('');
  const [activeUser, setActiveUser] = React.useState<number | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  const [emailFilter, setEmailFilter] = React.useState('');
  const [roleFilter, setRoleFilter] = React.useState('all');

  React.useEffect(() => {
    const emailFilter = query.get('emailFilter');
    const roleFilter = query.get('roleFilter');
    if (emailFilter) {
      setEmailFilter(emailFilter);
    }

    if (roleFilter) {
      setRoleFilter(roleFilter);
    } else {
      setRoleFilter('all');
    }

    Api.admin.user.getAll(page, emailFilter as string, roleFilter as string)
      .then(res => {
        setUsers(res.data.users);
        setTotalPages(res.data.totalPages);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  }, [query, page, setTotalPages]);

  const openRoleToggle = (index: number) => () => {
    setActiveUser(index);
    setSelectRole(users[index].role);
    setChangingRole(true);
  };
  const closeRoleToggle = () => {
    setActiveUser(null);
    setChangingRole(false);
  };
  const handleChangeRole = () => {
    if (activeUser === null) return;

    const lastActiveUser = activeUser;
    const user = users[activeUser];
    const isStaff = selectedRole === 'stf' ? '1' : '0';
    Api.admin.user.changeRole(user.uid, isStaff)
      .then(() => {
        const newUsers = users.slice();
        newUsers[lastActiveUser] = Object.assign({}, {
          ...user,
          role: selectedRole
        });
        setUsers(newUsers);
        setSuccessMessage('Successfully changed user role for ' + user.email);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });

    closeRoleToggle();
  };

  const handleFilter = () => {
    const searchQuery = new URLSearchParams(query);
    searchQuery.delete('emailFilter');
    searchQuery.delete('roleFilter');
    if (roleFilter !== 'all') {
      searchQuery.set('roleFilter', roleFilter);
    }
    if (emailFilter.length > 0) {
      searchQuery.set('emailFilter', emailFilter);
    }
    history.push(`${location.pathname}?${searchQuery.toString()}`);
  };

  const handleResetPassword = (index: number) => () => {
    const user = users[index];
    Api.admin.user.forgotPassword(user.uid)
      .then(_ => {
        setSuccessMessage('Successfully sent reset password email to ' + user.email);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  const handleDeactivateAccount = (index: number) => () => {
    const user: UserProfile = users[index];
    Api.admin.user.deactivateAccount(user.uid)
      .then(_ => {
        const newUsers: UserProfile[] = users.slice();
        newUsers[index].blacklisted = true;
        setUsers(newUsers);

        setSuccessMessage('Successfully deactivated account');
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  const handleActivateAccount = (index: number) => () => {
    const user = users[index];
    Api.admin.user.activateAccount(user.uid)
      .then(_ => {
        const newUsers = users.slice();
        newUsers[index].blacklisted = false;
        setUsers(newUsers);

        setSuccessMessage('Successfully activated account');
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  const handleSendVerificationEmail = (index: number) => () => {
    const user = users[index];
    Api.admin.user.sendVerificationEmail(user.uid)
      .then(_ => {
        setSuccessMessage('Successfully sent verification email to ' + user.email);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  return <React.Fragment>
    <HeadTitle
      title="Users"
    />

    <h1>Account Listings</h1>
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />
    <TimeoutAlert
      errorMessage={successMessage as string}
      alertColor="success"
      onHide={() => setSuccessMessage(null)}
    />

    <h3>Advanced Filters</h3>
    <div className="form-group">
      <label>Email</label>
      <input
        type="text"
        className="form-control"
        value={emailFilter}
        onChange={genericTextChange(setEmailFilter)}
        placeholder="Filter user email"
      />
    </div>
    <div className="form-group">
      <label>Role</label>
      <select value={roleFilter} onChange={genericTextChange(setRoleFilter)} className="form-control">
        <option value="all">All</option>
        <option value="admin">Admin</option>
        <option value="shopper">Shopper</option>
        <option value="staff">Staff</option>
      </select>
    </div>

    <div className="mb-3">
      <button className="btn btn-primary mr-2" onClick={handleFilter}>Filter</button>
      <Link to="/users" className="btn btn-secondary">Reset</Link>
    </div>


    <table className="table">
      <thead>
      <tr>
        <th>Account Type</th>
        <th>Account Created Date</th>
        <th>Account Email</th>
        <th>Account Email Verification Status</th>
        <th>Quick Action</th>
      </tr>
      </thead>
      <tbody>
      {users.map((user, index) => (
        <tr key={index}>
          <td>
            <HumanizeRole
              role={user.role}
            />
          </td>
          <td>{formatDateTimeFromSeconds(user.createdAt)}</td>
          <td>{user.email}</td>
          <td>{user.verified ? "Verified" : "Unverified"}</td>
          <td>
            <DropdownButton title="Actions" /*alignRight*/>
              <Dropdown.Item onClick={openRoleToggle(index)}>Change Role</Dropdown.Item>
              {user.blacklisted ? (
                <Dropdown.Item onClick={handleActivateAccount(index)}>Activate Account</Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={handleDeactivateAccount(index)}>Deactivate Account</Dropdown.Item>
              )}
              {!user.verified && (
                <Dropdown.Item onClick={handleSendVerificationEmail(index)}>Send Verification Email</Dropdown.Item>
              )}
              <Dropdown.Item onClick={handleResetPassword(index)}>Reset Password</Dropdown.Item>
              <Dropdown.Item as={Link} to={`/users/${user.uid}`}>View Account</Dropdown.Item>
            </DropdownButton>
          </td>
        </tr>
      ))}
      </tbody>
    </table>

    {paginator}


    <Modal show={changingRole} onHide={closeRoleToggle}>
      <Modal.Header closeButton>Set Role</Modal.Header>
      <Modal.Body>
        <select className="form-control" onChange={(e) => setSelectRole(e.target.value)} value={selectedRole}>
          <option value="stf">Staff</option>
          <option value="shp">Shopper</option>
        </select>
      </Modal.Body>
      <Modal.Footer>

        <Button color="primary" onClick={handleChangeRole}>Change Role</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
}

export default UsersPage;