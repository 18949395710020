const addressErrorCodes = {
  'address/not_found': 'The address you are looking is not found',
  'address/invalid_full_name': 'Invalid full name, it must be between 1 and 200 in length',
  'address/invalid_phone_number': 'Invalid phone number',
  'address/invalid_address': 'Invalid address, it must be between 1 and 200 in length',
  'address/invalid_postal_code': 'Invalid postal code, it must be between 3 and 16 in length',
  'address/invalid_unit_number': 'Invalid unit number, up to a length of 30',
  'address/invalid_main': 'Invalid main, only true or false is allowed'
};

export default addressErrorCodes;