import React from 'react';
import {FiEdit, FiTrash2} from 'react-icons/fi';
import {useHistory, useLocation} from 'react-router-dom';
import qs from 'qs';
import Api from '../../api/api';
import currencyPrecision from '../../constants/currency_precision';
import {insertDecimalPoint} from '../../utils/number';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import {extractAxiosError} from '../../utils/error';
import AddressFields from '../../constants/address_fields';
import CountryStateSelect from '../country_state_select';
import {ShippingTier} from "../../api/models";
import {EditShippingTier} from "../edit_shipping_price_tier";
import {queryStringify} from "../../utils/route";

export default function ShippingPriceTiers() {
  const [destinationCountry, setDestinationCountry] = React.useState('SG');
  const [destinationState, setDestinationState] = React.useState('');
  const [shippingTiers, setShippingTiers] = React.useState<ShippingTier[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isEditing, setIsEditing] = React.useState<boolean[]>([]);
  const [isAdding, setIsAdding] = React.useState(false);

  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    let newDestinationState = '';
    let newDestinationCountry = 'SG';
    try {
      if (!location.search) {
        return;
      }

      const search = location.search.substring(1);
      const query = qs.parse(search);
      if (query.country) {
        const countryQuery = query.country;
        if (typeof countryQuery !== 'string') {
          return;
        }

        const countryUpper = countryQuery.toUpperCase();
        if (!AddressFields.countries[countryUpper]) {
          return;
        }
        newDestinationCountry = countryUpper;
      }

      if (query.state) {
        const stateQuery = query.state;
        if (typeof stateQuery !== 'string') {
          return;
        }

        newDestinationState = stateQuery.toUpperCase();
      }
    } finally {
      setDestinationCountry(newDestinationCountry);
      setDestinationState(newDestinationState);
    }
  }, [location.search]);

  React.useEffect(() => {
    if (!destinationCountry) {
      return;
    }

    Api.admin.shippingLocation.get(destinationCountry, destinationState)
      .then((res) => {
        const {shippingLocation} = res.data;
        if (shippingLocation) {
          setShippingTiers(shippingLocation.priceTiers);
        } else {
          setShippingTiers([]);
        }
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  }, [destinationCountry, destinationState]);

  React.useEffect(() => {
    if (!shippingTiers || shippingTiers.length === 0) {
      return;
    }

    const newIsEditing = new Array(shippingTiers.length);
    newIsEditing.fill(false);
    setIsEditing(newIsEditing);
  }, [shippingTiers]);

  const editShippingTier = (index: number) => () => {
    const newIsEditing: boolean[] = isEditing.slice();
    newIsEditing[index] = !newIsEditing[index];
    setIsEditing(newIsEditing);
  };

  const cancelEditShippingTeir = (index: number) => () => {
    const newIsEditing = isEditing.slice();
    newIsEditing[index] = false;
    setIsEditing(newIsEditing);
  };

  const checkHasOverlap = (index: number, newUpperWeightLimit: number) => {
    // check forward
    const nextIndex = index + 1;
    if (nextIndex < shippingTiers.length) {
      const nextItem = shippingTiers[nextIndex];
      if (newUpperWeightLimit >= nextItem.upperWeightLimit) {
        return true;
      }
    }

    // check backward
    const prevIndex = index - 1;
    if (prevIndex > 0) {
      const prevItem = shippingTiers[prevIndex];
      if (newUpperWeightLimit <= prevItem.upperWeightLimit) {
        return true;
      }
    }

    return false;
  };

  const handleUpdate = (index: number) => (shippingPriceTier: ShippingTier) => {
    const hasOverlap = checkHasOverlap(index, shippingPriceTier.upperWeightLimit);
    if (hasOverlap) {
      setErrorMessage("Shipping price tier is overlapping with another price tier");
      return;
    }

    Api.admin.shippingLocation.shippingPriceTier.update(destinationCountry, destinationState, index, shippingPriceTier)
      .then((res) => {
        setShippingTiers(res.data.shippingPriceTiers);
        setIsAdding(false);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  const handleCreate = (shippingPriceTier: ShippingTier) => {
    // check for duplicate upper weight limit
    const hasDuplicate = shippingTiers.some(t => t.upperWeightLimit === shippingPriceTier.upperWeightLimit);
    if (hasDuplicate) {
      setErrorMessage("Shipping price tier has already been defined");
      return;
    }

    Api.admin.shippingLocation.shippingPriceTier.add(destinationCountry, destinationState, shippingPriceTier)
      .then((res) => {
        setShippingTiers(res.data.shippingPriceTiers);
        setIsAdding(false);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  const handleDelete = (index: number) => () => {
    const confirmed = window.confirm("Are you sure?");
    if (!confirmed) {
      return;
    }

    Api.admin.shippingLocation.shippingPriceTier.delete(destinationCountry, destinationState, index)
      .then(() => {
        const newShippingTiers = shippingTiers.slice();
        newShippingTiers.splice(index, 1);
        setShippingTiers(newShippingTiers);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message);
      });
  };

  const handleCountryStateChange = (country: string, state: string) => {
    const queryParams: {state?: string, country: string} = {
      country,
    };
    if (state) {
      queryParams.state = state;
    }
    const query = queryStringify(queryParams);

    history.push(location.pathname + '?' + query);
  };

  let lastWeight = 0;
  const countryState = AddressFields.countries[destinationCountry].states[destinationState];

  return <React.Fragment>
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <HeadTitle
      title="Shipping Price Tiers"
    />

    <h2>Shipping Price Tiers</h2>
    <p>
      let x be the weight of the order
      <br/>
      Weight is in tier if the following is true
      <br/>
      <code>previous tier upper limit &#x3C;= x &#x3C; current tier upper limit</code>
    </p>

    <br/>
    <div>
      <strong>Current Location</strong>
      <br/>
      {destinationCountry ? AddressFields.countries[destinationCountry].displayValue : 'Loading'}
      {countryState ? `, ${countryState.displayValue}` : ''}
    </div>

    <div className="row">
      <CountryStateSelect
        countryLabel="Change Destination Country"
        countryValue={destinationCountry}
        countryClassName="col-md form-group"
        stateLabel="Change Destination Country"
        stateValue={destinationState}
        stateClassName="col-md form-group"
        onChange={handleCountryStateChange}
      />
    </div>

    <table className="table">
      <thead>
      <tr>
        <th>Range</th>
        <th>Price</th>
        <th/>
      </tr>
      </thead>
      <tbody>
      {shippingTiers.length > 0 ? (
        shippingTiers.map((tier, index) => {
          const row = <React.Fragment key={index}>
            <tr>
              <td>{`${lastWeight} to ${tier.upperWeightLimit}`} kg</td>
              <td>{tier.priceCurrency} {insertDecimalPoint(tier.price as number, currencyPrecision[tier.priceCurrency])}</td>
              <td>
                <button className="btn btn-link" onClick={editShippingTier(index)}>
                  <FiEdit className="feather"/>
                </button>
                <button className="btn btn-link" onClick={handleDelete(index)}>
                  <FiTrash2 className="feather"/>
                </button>
              </td>
            </tr>
            {isEditing[index] && (
              <tr>
                <td colSpan={3}>
                  <EditShippingTier
                    onCancel={cancelEditShippingTeir(index)}
                    onSave={handleUpdate(index)}
                    shippingPriceTier={tier}
                    destinationCountry={destinationCountry}
                  />
                </td>
              </tr>
            )}
          </React.Fragment>;
          lastWeight = tier.upperWeightLimit;
          return row;
        })
      ) : (
        <tr>
          <td colSpan={3}>No Shipping Price Tiers</td>
        </tr>
      )}
      </tbody>
    </table>

    {!isAdding &&
    <button className="btn btn-primary" onClick={() => setIsAdding(true)} disabled={!destinationCountry}>
        Add Tier
    </button>}

    {isAdding && (
      <EditShippingTier
        onCancel={() => setIsAdding(false)}
        onSave={handleCreate}
        destinationCountry={destinationCountry}
      />
    )}
  </React.Fragment>;
}