import React from 'react';
import {Button, Modal} from "react-bootstrap";

export interface AwbItem {
  awb: string;
  carrier: string;
  customCarrierName?: string;
  trackingLink?: string;
}

interface AwbDialogProps {
  show: boolean;
  onHide: () => void;
  onSave: (items: AwbItem[]) => void;
}

export default function AwbDialog({show, onHide, onSave}: AwbDialogProps) {
  const [items, setItems] = React.useState<AwbItem[]>([{
    awb: '',
    carrier: ''
  }]);
  const handleSave = React.useCallback(() => {
    onSave(items);
  }, [items, onSave]);

  const handleAddItem = React.useCallback(() => {
    setItems([...items, {
      awb: '',
      carrier: ''
    }]);
  }, [items]);

  const handleRemoveItem = React.useCallback((index: number) => () => {
    const newItems = items.slice();
    newItems.splice(index, 1);
    setItems(newItems);
  }, [items]);

  const handleValueChange = React.useCallback((index: number, key: keyof AwbItem) => (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const item = items[index];
    const newItems = items.slice();
    newItems[index] = {
      ...item,
      [key]: e.target.value
    };
    setItems(newItems);
  }, [items]);

  return <Modal
    scrollable={true}
    show={show}
    onHide={onHide}
    animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Add Airway Bill Information</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {items.map((item, index) => {
        return <React.Fragment key={index}>
          <div className="form-group">
            <label>Airway Bill Number</label>
            <input
              required
              type="text"
              value={item.awb}
              onChange={handleValueChange(index, 'awb')}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Carrier</label>
            <select
              required
              className="form-control"
              onChange={handleValueChange(index, 'carrier')}
            >
              <option>Select a carrier</option>
              <option value="rayspeed">Rayspeed</option>
              <option value="choirexpress">ChoirExpress</option>
              <option value="i4wexpress">Indo4ward Partnered Express</option>
              <option value="dhlexpress">DHL Express</option>
              <option value="fedex">FedEx</option>
              <option value="other">Other</option>
            </select>
          </div>

          {item.carrier === 'other' && (
            <>
              <div className="form-group">
                <label>Other Carrier Name</label>
                <input
                  required
                  type="text"
                  value={item.customCarrierName || ''}
                  onChange={handleValueChange(index, 'customCarrierName')}
                  className="form-control"
                  placeholder="Carrier Name (Speedy Express)"
                />
              </div>

              <div className="form-group">
                <label>Tracking Link</label>
                <input
                  required
                  type="text"
                  value={item.trackingLink || ''}
                  onChange={handleValueChange(index, 'trackingLink')}
                  className="form-control"
                  placeholder="https://Trackingvendor.com - FULL LINK ONLY include HTTPS://"
                />
              </div>
            </>
          )}

          {index > 0 && (
            <div className="text-right">
              <button className="btn btn-outline-danger" onClick={handleRemoveItem(index)}>Remove Item</button>
            </div>
          )}
          <hr/>
        </React.Fragment>;
      })}

      <button className="btn btn-success" onClick={handleAddItem}>Add Another AWB</button>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleSave}>
        Save
      </Button>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;

}