import React from 'react';
import {ConfigEditorProps} from "../../../generic";

export default function FlatOrPercentField(props: ConfigEditorProps<string>) {
  const {configValue, onDone, onCancel} = props;

  const [mode, setMode] = React.useState(configValue || 'f');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onDone(mode);
  };

  return <form className="form-inline" onSubmit={handleSubmit}>
    <div className="form-group">
      <select className="form-control"
              value={mode}
              onChange={(e) => setMode(e.target.value)}>
        <option value="f">Flat</option>
        <option value="p">Percentage</option>
      </select>

      <button type="submit" className="btn btn-primary mb-3">Save</button>
      <button type="button" className="btn btn-secondary mb-3" onClick={onCancel}>Cancel</button>
    </div>
  </form>;
}