import React, {ReactChild, useContext} from 'react';
import classNames from 'classnames';
import basicColors from 'color-namer/lib/colors/basic';
import {useHistory} from 'react-router-dom';
import {Currency} from 'dinero.js';
import urljoin from 'url-join';
import {getVariantLabel} from '../constants/variant_types';
import styles from '../styles/edit_merchant_product.module.scss';
import {convertDineroAmtToFloatStr, convertWeightToGrams} from '../utils/number';
import {Category, Product, ProductWeightOverride, Variant} from "../api/models";
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import Api from "../api/api";
import {extractAxiosError} from "../utils/error";
import {CategoryBrowser} from "../lib/categories";
import {useCategories} from "../hooks/categories";
import {ProductWeightUnit} from "../types/models/product";
import {convertWeightToKg} from "../utils/product";

interface EditProductOverrideContextValue {
  markVariantPathDirty(variantPath: string): void;
}

const EditProductOverrideContext = React.createContext<EditProductOverrideContextValue>({
  markVariantPathDirty(_: string) {}
});

interface VariantFieldsProps {
  value: LocalVariant[];
}

function VariantsField({value: variants}: VariantFieldsProps) {
  return <>
    {variants.map((variant, index) => {
      return (
        <VariantField
          value={variant}
          key={index}
        />
      );
    })}
  </>;
}

interface LocalVariantPath {
  isPriceDiscounted: boolean;
  originPriceBeforeDiscount: string;
  originPrice: string;
  stock: string;
  sku: string;
  hasError: boolean;
  options: string[];
  weight: string;
  weightUnit: ProductWeightUnit;
  originalWeight: number;
  originalWeightUnit: ProductWeightUnit;
  path: string;
  weightHash?: string;
}

interface VariantCombinationFieldProps {
  value: LocalVariantPath;
  combinationKey: string;
  onChange: (newValue: LocalVariantPath) => void;
}

function VariantCombinationField({value, onChange, combinationKey}: VariantCombinationFieldProps) {
  const combination = value;

  const context = useContext(EditProductOverrideContext);

  const weightChangeHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: LocalVariantPath = {
      ...value,
      weight: e.target.value
    };
    context.markVariantPathDirty(combinationKey);
    onChange(newValue);
  }, [context, combinationKey, onChange, value]);


  const gramBtnClass = classNames('btn', {
    'btn-outline-primary': value.weightUnit !== 'g',
    'btn-primary': value.weightUnit === 'g'
  });
  const kilogramBtnClass = classNames('btn', {
    'btn-outline-primary': value.weightUnit !== 'kg',
    'btn-primary': value.weightUnit === 'kg'
  });

  const gramBtnClickHandler = React.useCallback(() => {
    if (!onChange) return;

    const newValue: LocalVariantPath = {
      ...value,
      weightUnit: 'g'
    };
    onChange(newValue);
  }, [onChange, value]);
  const kilogramBtnClickHandler = React.useCallback(() => {
    if (!onChange) return;

    const newValue: LocalVariantPath = {
      ...value,
      weightUnit: 'kg'
    };
    onChange(newValue);
  }, [onChange, value]);

  return <>
    <td className="align-middle text-center">
      {combination.isPriceDiscounted
        ? <AiOutlineCheck/>
        : <AiOutlineClose/>
      }
    </td>

    <td className="align-middle text-center">
      <label>
        {combination.originPriceBeforeDiscount}
      </label>
    </td>

    <td className="align-middle text-center">
      <label>
        {combination.originPrice}
      </label>
    </td>

    <td className="align-middle text-center">
      <label>
        {combination.stock}
      </label>
    </td>

    <td className="align-middle text-center">
      <label>
        {combination.sku ? combination.sku : "-"}
      </label>
    </td>

    <td className="align-middle text-center">
      <div className="input-group mb-3">
        <input
            type="number"
            className="form-control"
            value={value.weight}
            onChange={weightChangeHandler}
        />

        <div className="input-group-append">
          <button className={gramBtnClass} onClick={gramBtnClickHandler} type="button">g</button>
          <button className={kilogramBtnClass} onClick={kilogramBtnClickHandler} type="button">kg</button>
        </div>
      </div>
      <small>Original: {`${value.originalWeight} ${value.originalWeightUnit}`}</small>
    </td>
  </>;
}

interface VariantCombinationsFieldProps {
  variants: LocalVariant[];
  value: LocalVariantPaths;
  onChange: (newValue: LocalVariantPaths) => void;
}

interface LocalVariantPath {
  isPriceDiscounted: boolean;
  originPriceBeforeDiscount: string;
  originPrice: string;
  stock: string;
  sku: string;
  hasError: boolean;
}

function VariantCombinationsField({variants, value: variantPaths, onChange}: VariantCombinationsFieldProps) {
  // console.log('variantPaths', variantPaths)

  return <>
    <h5>Variation List</h5>
    <table className="table table-bordered">
      <thead className="thead-light">
      <tr>
        {variants.map(variant => (
          <th className="align-middle text-center" scope="col" rowSpan={2}>{variant.name}</th>
        ))}
        <th className="align-middle text-center" scope="col" rowSpan={2}>Price Discounted</th>
        <th className="align-middle text-center" scope="col" colSpan={2}>Price</th>
        <th className="align-middle text-center" scope="col" rowSpan={2}>Stock</th>
        <th className="align-middle text-center" scope="col" rowSpan={2}>SKU</th>
        <th className="align-middle text-center" scope="col" rowSpan={2}>Weight</th>
      </tr>
      <tr>
        <th className="align-middle text-center" scope="col">Before Discount</th>
        <th className="align-middle text-center" scope="col">After Discount</th>
      </tr>
      </thead>
      <tbody>
      {Object.entries(variantPaths).map(([key, vp], index) => {
        const variantIndices = JSON.parse(key);
        const totalVariationOrder = variants.map(variant => {
          return variant.options.length;
        });

        let rowContent: ReactChild[] = [];
        for (let i = 0; i < variantIndices.length; i++) {
          let rowSpan = 1;
          const rowChildren = totalVariationOrder.map(x => x).splice(i + 1);
          if (rowChildren.length > 0) {
            rowSpan = rowChildren.reduce((a, b) => a * b);
          }
          if (index % rowSpan === 0) {
            rowContent.push(<th className="align-middle text-center" rowSpan={rowSpan}>{variantIndices[i]}</th>);
          }
        }

        const changeHandler = (newValue: LocalVariantPath) => {
          onChange({
            ...variantPaths,
            [key]: newValue
          });
        };

        rowContent.push(<VariantCombinationField
          key={key}
          combinationKey={key}
          value={vp}
          onChange={changeHandler}
        />);

        return <tr>
          {rowContent}
        </tr>;
      })}
      </tbody>
    </table>
  </>;
}

interface VariantFieldProps {
  value: LocalVariant;
}

function VariantField({value}: VariantFieldProps) {
  const variant = value;

  return <div className="card">
    <div className="card-body">
      <strong>{variant.name}</strong>
      <br/>
      <span className="text-muted">{getVariantLabel(variant.variantType)}</span>
      <br/>

      <div className="form-group">
        <label>Options</label>
        <br/>
        {variant.options.map((option, index: number) => {
          const color = basicColors.find(color => color.name === option.color);
          const hasColor = !!color;
          let colorStyle: React.CSSProperties = {
            height: '0.8rem',
            width: '0.8rem',
            marginRight: '0.5rem',
            display: 'inline-block',
          };
          if (hasColor) {
            colorStyle.backgroundColor = color!.hex;
          }
          return <div className="border bg-white p-1 d-inline-block" key={index}>
            {hasColor && <div style={colorStyle}/>}
            {option.value}
          </div>;
        })}
      </div>
    </div>
  </div>;
}

interface LocalVariantPaths {
  [path: string]: LocalVariantPath;
}

interface Fields {
  name: string;
  description: string;
  conditionNew: string;
  disclaimer: string;
  originPriceCurrency: string;
  originPrice: string;
  originPriceBeforeDiscount: string;
  category: Category[];
  minQuantity: string;
  maxQuantity: string;
  hasVariant: boolean;
  stock: string;
  sku: string;
  variants: LocalVariant[];
  variantPaths: LocalVariantPaths;
  disabled: boolean;
  weight: string;
}

type LocalVariant = Omit<Variant, 'variantType'> & {
  variantType: string;
};

const defaultFields: Fields = {
  name: '',
  description: '',
  conditionNew: "true",
  disclaimer: '',
  originPriceCurrency: 'IDR',
  originPrice: '1',
  originPriceBeforeDiscount: '1',
  category: [],
  minQuantity: '1',
  maxQuantity: '0',
  hasVariant: false,
  stock: '1',
  sku: '',
  variants: [] as LocalVariant[],
  variantPaths: {},
  disabled: false,
  weight: '0'
};
EditProductOverride.DEFAULT_FIELDS = defaultFields;


interface EditProductOverrideProps {
  onError: (message: string) => void;
  product: Product | null;
}

export default function EditProductOverride({
                                              product,
                                              onError
                                            }: EditProductOverrideProps) {
  const [fields, setFields] = React.useState<Fields>(EditProductOverride.DEFAULT_FIELDS);
  const [isSaving, setIsSaving] = React.useState(false);
  const [priceDiscounted, setPriceDiscounted] = React.useState(false);
  const variationWeightChangedMapRef = React.useRef<Record<string, boolean>>({});

  const history = useHistory();
  const categories = useCategories();

  React.useEffect(() => {
    if (!product) {
      return;
    }

    setPriceDiscounted(!!product.originPriceBeforeDiscount);

    // fetch weight overrides and populate the fields
    (async () => {
      const weightOverrideRes = await Api.productWeight.getOverrideWeight(product.uuid);
      const variationWeightOverrides: Record<string, ProductWeightOverride> = {};
      let weightOverrideGrams: number | null = null;

      for (const override of weightOverrideRes.data.overrides) {
        if (override.variantPath) {
          variationWeightOverrides[override.variantPath] = override;
        } else {
          weightOverrideGrams = convertWeightToGrams('kg', override.weight);
        }
      }

      const categoryBrowser = new CategoryBrowser(categories!);
      const levels = categoryBrowser.findParents(product.category);

      const lastLevel = levels[levels.length - 1];
      const currentCategory = Object.values(lastLevel.children).filter(category => category.id === product.category);

      const convertPriceToString = (originPriceAmt: number) => {
        return convertDineroAmtToFloatStr(originPriceAmt, product.originPriceCurrency as Currency);
      };

      const originPrice = convertPriceToString(product.originPrice as number);
      let originPriceBeforeDiscount = '1';
      if (product.originPriceBeforeDiscount) {
        originPriceBeforeDiscount = convertPriceToString(product.originPriceBeforeDiscount);
      }

      const minQuantity = product.minQuantity.toString();
      const maxQuantity = product.maxQuantity.toString();
      const stock = product.stock.toString();
      const variantPaths: LocalVariantPaths = {};

      const combinationKeys = Object.keys(product.variantPaths);
      if (combinationKeys.length > 0) {
        // remap the keys to the option values
        for (const key of combinationKeys) {
          const indices = key.split('/');
          const optionValues = [];
          for (let i = 0; i < indices.length; i++) {
            const variant = product.variants[i];
            const optionIndex = Number.parseInt(indices[i]);
            const optionValue = variant.options[optionIndex].value;
            optionValues.push(optionValue);
          }

          const originalCombination = product.variantPaths[key];
          let originPriceBeforeDiscount = '1';
          if (originalCombination.originPriceBeforeDiscount) {
            originPriceBeforeDiscount = convertPriceToString(originalCombination.originPriceBeforeDiscount);
          }

          let weightStr: string;
          let weight: number;
          let weightUnit = originalCombination.weightUnit;
          if (originalCombination.weight) {
            weightStr = originalCombination.weight.toString();
            weight = originalCombination.weight;
          } else {
            weight = product.weight;
            weightStr = product.weight.toString();
            weightUnit = product.weightUnit;
          }
          if (variationWeightOverrides[key]) {
            weightStr = variationWeightOverrides[key].weight.toString();
            weightUnit = 'kg';
          }

          const combination = {
            ...originalCombination,
            sku: originalCombination.sku || '',
            hasError: false,
            isPriceDiscounted: !!originalCombination.originPriceBeforeDiscount,
            stock: originalCombination.stock.toString(),
            originPrice: convertPriceToString(originalCombination.originPrice),
            originPriceBeforeDiscount,
            options: optionValues,
            weightHash: originalCombination.weightHash,
            weight: weightStr,
            weightUnit,
            originalWeightUnit: weightUnit,
            originalWeight: weight,
            path: key
          };
          const localKey = JSON.stringify(optionValues);
          variantPaths[localKey] = combination;
        }
      }

      const localVariants = product.variants.map(variant => {
        const localVariant: LocalVariant = {
          ...variant,
          variantType: variant.variantType.toString()
        };
        return localVariant;
      });

      const newFields: Fields = {
        name: product.name,
        description: product.description,
        conditionNew: product.conditionNew.toString(),
        disclaimer: product.disclaimer,
        originPriceCurrency: product.originPriceCurrency,
        originPrice,
        originPriceBeforeDiscount,
        category: [...levels, ...currentCategory],
        minQuantity,
        maxQuantity,
        hasVariant: product.hasVariant,
        stock,
        sku: product.sku || '',
        variants: localVariants,
        variantPaths,
        disabled: product.disabled,
        weight: convertWeightToGrams(product.weightUnit, product.weight).toString()
      };
      if (weightOverrideGrams) {
        newFields.weight = weightOverrideGrams.toString();
      }
      // console.log('newFields', newFields);

      setFields(newFields);
      variationWeightChangedMapRef.current = {};
    })();
  }, [categories, product]);

  const variationChangeHandler = React.useCallback((newVariantPaths: LocalVariantPaths) => {
    setFields({
      ...fields,
      variantPaths: newVariantPaths
    });
  }, [fields]);

  const variationWeightDirtyHandler = React.useCallback((variantPath: string) => {
    variationWeightChangedMapRef.current = {
      ...variationWeightChangedMapRef.current,
      [variantPath]: true
    };
  }, []);

  const handleSave = React.useCallback(() => {
    const variationPromiseCombined: Promise<void>[] = [];
    for (const [variantPath, changed] of Object.entries(variationWeightChangedMapRef.current)) {
      if (!changed) {
        continue;
      }

      const updatedVariation = fields.variantPaths[variantPath];

      const promise = Api.staff.productWeightOverride.set({
        productId: product!.uuid,
        weight: convertWeightToKg(parseFloat(updatedVariation.weight), updatedVariation.weightUnit),
        variantPath: updatedVariation.path
      })
          .then(() => {
            console.log('successfully added product weight override for variation path: ' + variantPath);
          });
      variationPromiseCombined.push(promise);
    }

    Promise.all(variationPromiseCombined)
        .then(() => Api.staff.productWeightOverride.set({
          productId: product!.uuid,
          weight: parseFloat(fields.weight) / 1000
        }))
        .then(_ => {
          console.log('successfully added product weight override');
          history.goBack();
        })
        .catch(error => {
          const message = extractAxiosError(error);
          onError(message);
        })
        .finally(() => setIsSaving(false));
  }, [history, product, fields, onError]);

  const handleCancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return <>
    <div className="form-group">
      <label>Name</label>
      <h5>{fields.name}</h5>
    </div>

    <div className="form-group">
      <label>Existing Image</label>
      <br/>
      {product && product!.images.map((imagePath, index) => {
        const imageUrl = urljoin(process.env.REACT_APP_IMAGES_ENDPOINT as string, `${imagePath}_t`);
        const previewClassName = classNames(styles.previewImage);
        return <div className={previewClassName} key={index}>
          <img
            src={imageUrl}
            alt="existing"
          />
        </div>;
      })}
    </div>

    <div className="form-group">
      <label>Description</label>
      <textarea
        className="form-control"
        value={fields.description}
        disabled
      />
    </div>

    <div className="form-group">
      <label>Condition</label>
      <h5>{fields.conditionNew ? "New" : "Used"}</h5>
    </div>

    <div className="form-group">
      <label>Weight</label>
      <div className="input-group mb-3">
        <input
          type="number"
          className="form-control"
          value={fields.weight}
          onChange={e => setFields({...fields, weight: e.target.value})}
        />

        <div className="input-group-append">
          <span className="input-group-text">g</span>
        </div>
      </div>
    </div>

    {fields.disclaimer && (
      <div className="form-group">
        <label>Disclaimer</label>
        <h5>{fields.disclaimer}</h5>
      </div>
    )}


    <div className="form-group">
      <label>Price Currency</label>
      <h5>{fields.originPriceCurrency}</h5>
    </div>

    {priceDiscounted && (
      <div className="form-group">
        <label>Price Before Discount</label>
        <h5>{fields.originPriceBeforeDiscount}</h5>
      </div>
    )}

    <div className="form-group">
      <label>Price</label>
      <h5>{fields.originPrice}</h5>
    </div>

    <div className="form-group">
      <label>Category</label>
      <h5>Top{fields.category.map(category => (` > ${category.name}`))}</h5>
    </div>

    <div className="form-group">
      <label>Minimum quantity</label>
      <h5>{fields.minQuantity}</h5>
    </div>

    <div className="form-group">
      <label>Maximum quantity</label>
      <h5>{fields.maxQuantity}</h5>
    </div>

    <div className="form-group">
      <label>Stock</label>
      <h5>{fields.stock}</h5>
    </div>

    {!fields.hasVariant && (
      <div className="form-group">
        <label>SKU</label>
        <h6>{fields.sku ? fields.sku : "-"}</h6>
      </div>
    )}

    <div className="form-check mb-2 p-0">
      <label className="form-check-label">
        {fields.disabled
          ? "Product is disabled"
          : "Product is enabled"
        }
      </label>
    </div>

    <div className="form-check mb-2 p-0">
      <label className="form-check-label" htmlFor="mp-has-variation">
        {fields.hasVariant
          ? "Product have variant"
          : "Product doesn't have any variant"
        }
      </label>
    </div>

    {fields.hasVariant && (
      <>
        <h3>Variations</h3>

        <h5>Variants</h5>
        <VariantsField
          value={fields!.variants}
        />

        <EditProductOverrideContext.Provider value={{
          markVariantPathDirty: variationWeightDirtyHandler
        }}>

          <VariantCombinationsField
              variants={fields.variants}
              value={fields.variantPaths}
              onChange={variationChangeHandler}
          />
        </EditProductOverrideContext.Provider>
      </>
    )}

    <button className="btn btn-primary mr-2" onClick={handleSave} disabled={isSaving}>
      Save
    </button>

    <button
      className={classNames('btn btn-secondary', {
        disabled: isSaving
      })}
      onClick={handleCancel}
    >
      Cancel
    </button>

    {isSaving && (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )}
  </>;
}