import React from 'react';
import {Link} from 'react-router-dom';
import urljoin from 'url-join';
import Api from '../../api/api';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import {usePagination} from '../../lib/paginator';
import {extractAxiosError} from '../../utils/error';
import {formatDateTimeFromSeconds} from '../../utils/date';
import {Merchant} from "../../api/models";
import {queryStringify} from "../../utils/route";

export default function MerchantsPage() {
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [merchants, setMerchants] = React.useState<Merchant[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [page, paginator, setTotalPages] = usePagination();

  React.useEffect(() => {
    setIsLoading(true);
    Api.admin.merchant.getAll(page)
      .then(res => {
        setMerchants(res.data.merchants);
        setTotalPages(res.data.totalPages);
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      })
      .finally(() => setIsLoading(false));
  }, [setTotalPages, page]);

  const handleRemove = (index: number) => () => {
    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) {
      return;
    }

    const {_id} = merchants[index];
    Api.admin.merchant.remove(_id)
      .then(_ => {
        const newMerchants = merchants.slice();
        newMerchants.splice(index, 1);
        setMerchants(newMerchants);

        setSuccessMessage('Successfully removed merchant');
      })
      .catch(error => {
        setErrorMessage(extractAxiosError(error));
      });
  };

  return <>
    <HeadTitle
      title="Merchants"
    />

    <TimeoutAlert
      errorMessage={successMessage}
      onHide={() => setSuccessMessage(null)}
    />

    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <h1>Merchants</h1>

    <div className="mb-3">
      <Link to="/merchants/new" className="btn btn-primary">New</Link>
    </div>

    {isLoading && (
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )}

    {!isLoading && merchants.length === 0 && (
      <p>No Merchants</p>
    )}

    {!isLoading && merchants.length > 0 && (
      merchants.map((merchant, index) => {
        const searchWithQuery = `search?${queryStringify({
          merchant: merchant.paramName,
        })}`;

        const merchantProductListing = urljoin(process.env.REACT_APP_STORE_ENDPOINT as string, 'product', searchWithQuery);

        return (
          <div className="card mb-3" key={index}>
            <div className="card-body">
              <h5 className="card-title">{merchant.name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Updated
                At: {formatDateTimeFromSeconds(merchant.updatedAt)}</h6>

              <p className="card-text">
                <strong>Email:</strong>
                {' '}
                {merchant.email}
                <br/>
              </p>

              <Link to={`/merchants/${merchant._id}`} className="card-link">
                View Products
              </Link>
              <a href={merchantProductListing} className="card-link" target="_blank" rel="noreferrer">
                View Products at store
              </a>
              <Link to={`/merchants/${merchant._id}/edit`} className="card-link btn btn-link">Edit</Link>
              <button className="card-link text-danger btn btn-link" onClick={handleRemove(index)}>Remove</button>
            </div>
          </div>
        );
      })
    )}

    {paginator}
  </>;
}