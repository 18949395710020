import React from 'react';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import EditMerchant from '../edit_merchant';

export default function NewMerchant() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  return <>
    <HeadTitle
      title="New Merchant"
    />

    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <h1>New Merchant</h1>
    <EditMerchant
      onError={setErrorMessage}
    />
  </>;
}