import React from 'react';
import {useParams} from "react-router-dom";
import ExternalQuotationItemComponent from "../../../quotation_items/external_quotation_item";
import {ExternalQuotationItem, QuotationItemSources} from "../../../../types/models/quotation_item";
import Api from "../../../../api/api";
import TimeoutAlert from "../../../timeout_alert";
import HeadTitle from "../../../head_title";
import {extractAxiosError} from "../../../../utils/error";
import useMessagingSocket from "../../../../hooks/use_messaging_socket";
import {GroupInfo} from "../../../../types/responses/messaging";

interface FetchOrderFn {
    (): void;
    active: boolean;
}

export default function ViewExternalQuotationItemsPage() {
    const [quotationItem, setQuotationItem] = React.useState<ExternalQuotationItem | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
    const [groupsInfo, setGroupsInfo] = React.useState<Record<string, GroupInfo> | null>(null);
    const params = useParams<{ quotationItemId: string }>();
    const {quotationItemId} = params;

    const messagingErrorCallback = React.useCallback((message: string) => setErrorMessage(message), []);

    const socket = useMessagingSocket(messagingErrorCallback);

    React.useEffect(() => {
        if (!socket) {
            return;
        }

        socket.off('groupsInfo');
        socket.once('groupsInfo', async (groupsInfo: Record<string, GroupInfo>) => {
            setGroupsInfo(groupsInfo);
        });

        socket.emit('getGroupsInfo');
    }, [socket]);

    const fetchQuotationItem = React.useMemo(() => {
        const result: FetchOrderFn = Object.assign(() => {
            Api.staff.quotationItem.get(quotationItemId)
                .then(res => {
                    if (!result.active) {
                        return;
                    }
                    if (res.data.source === QuotationItemSources.external) {
                        setQuotationItem(res.data.quotationItem);
                    } else {
                        setErrorMessage('External quotation item not found.');
                    }
                })
                .catch(err => {
                    if (!result.active) {
                        return;
                    }
                    setErrorMessage(extractAxiosError(err));
                });
        }, {
            active: true
        });
        return result;
    }, [quotationItemId]);

    React.useEffect(() => {
        const fn = fetchQuotationItem;
        fn();
        return () => {
            fn.active = false;
        };
    }, [fetchQuotationItem]);

    React.useEffect(() => {
        if (!quotationItem || !groupsInfo) {
            return;
        }

        const key = Object.keys(groupsInfo).find(groupKey => groupKey.includes(quotationItem.referenceNumber));
        if (key) {
            setUnreadMessageCount(groupsInfo[key].unseenMessagesCount);
        }
    }, [quotationItem, groupsInfo]);

    let title = 'External Quotation Item';
    if (quotationItem) {
        title = `External Quotation Item #${quotationItem.referenceNumber}`;
    }
    return <>
        <HeadTitle
            title={title}
        />

        <TimeoutAlert
            errorMessage={errorMessage}
            onHide={() => setErrorMessage(null)}
        />

        <h1>{title}</h1>

        {quotationItem && (
            <ExternalQuotationItemComponent
                key={quotationItem.referenceNumber}
                quotationItem={quotationItem as ExternalQuotationItem}
                unreadMessageCount={unreadMessageCount}
                onResolveOrDecline={fetchQuotationItem}
                socket={socket}
                showChatAtStart
            />
        )}

    </>;
}