import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import EmailValidator from "email-validator";
import {useDispatch} from 'react-redux';
import Dinero from 'dinero.js';
import {useHistory, useParams} from 'react-router-dom';
import {
  generateStep,
  genericCheckboxChange,
  genericRadioChange,
  genericRadioChangeWithValue,
  genericTextChange
} from '../../utils/forms';
import {InputFieldWithValidation, TextAreaWithValidation} from '../fields_with_validation';
import {actions} from '../../store/actions';
import currencyPrecision from '../../constants/currency_precision';
import Api from '../../api/api';
import {deserializeDate, serializeDate} from '../../utils/date';
import {findPrecision, insertDecimalPoint} from '../../utils/number';
import {useAppCurrency} from '../../lib/config';
import {extractAxiosError} from '../../utils/error';
import TimeoutAlert from '../timeout_alert';
import HeadTitle from '../head_title';
import {UpdateVoucherParameters} from "../../api/request_models";
import {VoucherAudiences, VoucherReductionTargets, VoucherReductionTypes} from "../../types/models/voucher";

interface ValidateFieldsErrors {
  code?: string[];
  reductionAmount?: string[];
  totalUsageLimit?: string[];
  userUsageLimit?: string[];
  referralEmail?: string[];
  description?: string[];
}

export default function EditVoucherPage() {
  const [code, setCode] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [aud, setAud] = React.useState('r');
  const [audEmails, setAudEmails] = React.useState(new Set<string>());
  const [newAudEmail, setNewAudEmail] = React.useState('');
  const [reductionType, setReductionType] = React.useState('f');
  const [reductionAmount, setReductionAmount] = React.useState('');
  const [reductionTarget, setReductionTarget] = React.useState('t');
  const [dateRange, setDateRange] = React.useState([new Date(), new Date()]);
  const [isReferral, setIsReferral] = React.useState(false);
  const [totalUsageLimit, setTotalUsageLimit] = React.useState('100');
  const [userUsageLimit, setUserUsageLimit] = React.useState('1');
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [referralEmail, setReferralEmail] = React.useState('');

  const [isApiLoading, setIsApiLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);

  const [newAudEmailError, setNewAudEmailError] = React.useState<string | null>();
  const [fieldErrors, setFieldErrors] = React.useState<ValidateFieldsErrors>({});

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  const history = useHistory();
  const params = useParams<{id: string}>();
  const dispatch = useDispatch();

  const voucherId = params.id;

  React.useEffect(() => {
    dispatch(actions.config.fetchAll());

    if (voucherId) {
      Api.admin.voucher.get(voucherId)
        .then((resp) => {
          const {voucher} = resp.data;
          setCode(voucher.code);
          setDescription(voucher.description || '');
          setAud(voucher.audience);
          setAudEmails(new Set(voucher.audienceEmails));
          setReductionType(voucher.reductionType);
          const {reductionAmount} = voucher;
          setReductionAmount(insertDecimalPoint(reductionAmount, currencyPrecision[voucher.reductionCurrency]));
          setReductionTarget(voucher.reductionTarget);
          setDateRange([deserializeDate(voucher.validityStartDate), deserializeDate(voucher.validityEndDate)]);
          setIsReferral(voucher.isReferral);
          setTotalUsageLimit(voucher.totalUsageLimit.toString());
          setUserUsageLimit(voucher.userUsageLimit.toString());
          setIsEnabled(voucher.enabled);
          setReferralEmail(voucher.referralEmail || '');
        })
        .catch(error => {
          const errorMessage = extractAxiosError(error);
          setErrorMessage(errorMessage);
        })
        .finally(() => {
          setIsApiLoading(false);
        });
    } else {
      setIsApiLoading(false);
    }
  }, [dispatch, voucherId]);

  const appCurrency = useAppCurrency();
  const isLoading = isApiLoading || !appCurrency;

  let reductionAmountStep;
  if (reductionType === 'p') {
    // percent
    reductionAmountStep = ".01";
  } else {
    // flat
    if (appCurrency) {
      reductionAmountStep = generateStep(currencyPrecision[appCurrency]);
    } else {
      reductionAmountStep = "1";
    }
  }

  const handleAddAudEmail = () => {
    if (!EmailValidator.validate(newAudEmail)) {
      setNewAudEmailError("Invalid email address");
      return;
    }

    const newAudEmails = new Set(audEmails);
    newAudEmails.add(newAudEmail.trim());
    setAudEmails(newAudEmails);
    setNewAudEmail('');
  };

  const handleRemoveAudEmail = (email: string) => () => {
    const newAudEmails = new Set(audEmails);
    newAudEmails.delete(email);
    setAudEmails(newAudEmails);
  };

  const validateFields = () => {
    const errors: Required<ValidateFieldsErrors> = {
      code: [],
      reductionAmount: [],
      totalUsageLimit: [],
      userUsageLimit: [],
      referralEmail: [],
      description: [],
    };

    if (code.length < 6) {
      errors.code.push('code must be at least 6 characters');
    }

    if (description.length > 80) {
      errors.description.push('description cannot be exceed 80 characters');
    }

    if (!(/^[\w\d]{6,}$/).test(code)) {
      errors.code.push('code must only be alphanumeric');
    }

    if (reductionAmount.trim().length === 0 || Number.isNaN(reductionAmount)) {
      errors.reductionAmount.push('reduction must be a valid number');
    } else {
      const reductionAmountF = parseFloat(reductionAmount);
      if (reductionAmountF < 0) {
        errors.reductionAmount.push('reduction amount must be positive');
      }
    }

    if (totalUsageLimit.trim().length === 0 || Number.isNaN(totalUsageLimit)) {
      errors.totalUsageLimit.push('total usage limit must be a valid number');
    } else {
      const totalUsageLimitF = parseFloat(totalUsageLimit);
      if (totalUsageLimitF < 0) {
        errors.totalUsageLimit.push('total usage limit must be positive');
      }

      if (!Number.isInteger(totalUsageLimitF)) {
        errors.totalUsageLimit.push('total usage limit must be an integer');
      }
    }

    if (userUsageLimit.trim().length === 0 || Number.isNaN(userUsageLimit)) {
      errors.userUsageLimit.push('user usage limit must be a valid number');
    } else {
      const userUsageLimitF = parseFloat(userUsageLimit);
      if (userUsageLimitF < 0) {
        errors.userUsageLimit.push('user usage limit must be positive');
      }

      if (!Number.isInteger(userUsageLimitF)) {
        errors.userUsageLimit.push('user usage limit must be an integer');
      }
    }

    if (isReferral && !EmailValidator.validate(referralEmail)) {
      errors.referralEmail.push('invalid email address');
    }
    console.log('fieldErrors', errors);
    const hasError = Object.values(errors).some(errorList => errorList.length > 0);
    if (hasError) {
      setFieldErrors(errors);
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const isValid = validateFields();

    if (!isValid) {
      console.log('validateFields has errors');
      return;
    }

    const currency = appCurrency!;

    let targetPrecision = 2;
    if (reductionType === 'f') {
      targetPrecision = currencyPrecision[currency];
    }
    const precision = findPrecision(reductionAmount);
    const reductionAmountNum = Dinero({
      amount: parseInt(reductionAmount.replace('.', '')),
      precision,
      currency
    })
      .convertPrecision(targetPrecision)
      .getAmount();

    const validityStartDate = serializeDate(dateRange[0]);
    const validityEndDate = serializeDate(dateRange[1]);


    const voucher: UpdateVoucherParameters = {
      code,
      description,
      audience: aud as VoucherAudiences,
      audienceEmails: Array.from(audEmails),
      reductionType: reductionType as VoucherReductionTypes,
      reductionCurrency: currency,
      reductionTarget: reductionTarget as VoucherReductionTargets,
      isReferral,
      totalUsageLimit: parseInt(totalUsageLimit),
      userUsageLimit: parseInt(userUsageLimit),
      enabled: isEnabled,
      reductionAmount: reductionAmountNum,
      validityStartDate,
      validityEndDate
    };

    if (voucher.isReferral) {
      voucher.referralEmail = referralEmail;
    }

    let action = Api.admin.voucher.create;
    if (voucherId) {
      action = (_voucher) => Api.admin.voucher.update(voucherId, _voucher);
    }
    setIsSaving(true);
    action(voucher)
      .then(_ => {
        history.push('/vouchers');
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
        setIsSaving(false);
      });
  };

  const handleNewAudEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAudEmailError(null);

    setNewAudEmail(e.target.value);
  };

  const clearErrorOnChangeWrapper = (errorKey: keyof ValidateFieldsErrors, handler: Function) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    console.log('errorKey', errorKey);
    const newFieldErrors = Object.assign({}, fieldErrors);
    delete newFieldErrors[errorKey];
    setFieldErrors(newFieldErrors);

    handler(e);
  };

  let content;
  if (isLoading) {
    content = <div className="spinner-grow" role="status">
      <span className="sr-only">Loading...</span>
    </div>;
  } else {
    content = <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Code</label>
        <InputFieldWithValidation
          type="text"
          className="form-control"
          value={code}
          onChange={clearErrorOnChangeWrapper('code', genericTextChange(setCode))}
          errors={fieldErrors.code}
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <TextAreaWithValidation
          className="form-control"
          value={description}
          onChange={clearErrorOnChangeWrapper('description', genericTextChange(setDescription))}
          errors={fieldErrors.description}
        />
      </div>
      <div className="form-group">
        <label>Voucher Audience</label>
        <br/>
        <div className="form-check form-check-inline">
          <input
            id="singleAud"
            className="form-check-input"
            type="radio"
            name="aud"
            value="r"
            checked={aud === 'r'}
            onChange={genericRadioChange(setAud)}/>
          <label className="form-check-label" htmlFor="singleAud">Restricted</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            id="multipleAud"
            className="form-check-input"
            type="radio"
            name="aud"
            value="p"
            checked={aud === 'p'}
            onChange={genericRadioChange(setAud)}/>
          <label className="form-check-label" htmlFor="multipleAud">Public</label>
        </div>
      </div>

      {aud === 'r' && (
        <div className="form-group">
          <label>Restricted User Emails</label>
          {Array.from(audEmails).map((email, index) => {
            return <p key={index}>
              {email}
              <button className="btn btn-danger" onClick={handleRemoveAudEmail(email as string)}>X</button>
            </p>;
          })}

          <InputFieldWithValidation
            type="text"
            className="form-control"
            value={newAudEmail}
            onChange={handleNewAudEmailChange}
            errors={(newAudEmailError ? [newAudEmailError] : null) as string[]}
          />
          <button className="btn btn-secondary" type="button" onClick={handleAddAudEmail}>Add Email</button>
        </div>
      )}

      <div className="form-group">
        <label>Reduction Type</label>
        <br/>
        <div className="form-check form-check-inline">
          <input
            id="flatReduction"
            className="form-check-input"
            type="radio"
            name="reductionType"
            value="f"
            checked={reductionType === 'f'}
            onChange={genericRadioChange(setReductionType)}/>
          <label className="form-check-label" htmlFor="flatReduction">Flat</label>
        </div>

        <div className="form-check form-check-inline">
          <input
            id="percentReduction"
            className="form-check-input"
            type="radio"
            name="reductionType"
            value="p"
            checked={reductionType === 'p'}
            onChange={genericRadioChange(setReductionType)}/>
          <label className="form-check-label" htmlFor="percentReduction">Percentage</label>
        </div>
      </div>

      <div className="form-group">
        <label>Reduction Amount</label>
        <InputFieldWithValidation
          type="number"
          name="reductionAmount"
          className="form-control"
          step={reductionAmountStep}
          value={reductionAmount}
          errors={fieldErrors.reductionAmount}
          onChange={clearErrorOnChangeWrapper('reductionAmount', genericTextChange(setReductionAmount))}
        />
      </div>

      <div className="form-group">
        <label>Reduction Target</label>
        <br/>
        <div className="form-check form-check-inline">
          <input
            id="totalCostReduction"
            className="form-check-input"
            type="radio"
            name="reductionTarget"
            value="t"
            checked={reductionTarget === 't'}
            onChange={genericRadioChange(setReductionTarget)}/>
          <label className="form-check-label" htmlFor="totalCostReduction">Total Cost</label>
        </div>

        <div className="form-check form-check-inline">
          <input
            id="shippingCostReduction"
            className="form-check-input"
            type="radio"
            name="reductionTarget"
            value="s"
            checked={reductionTarget === 's'}
            onChange={genericRadioChange(setReductionTarget)}/>
          <label className="form-check-label" htmlFor="shippingCostReduction">Shipping Cost</label>
        </div>
      </div>

      <div className="form-group">
        <label>Validity Date Range</label>
        <br/>
        <DateRangePicker
          onChange={setDateRange}
          value={dateRange}
        />
      </div>

      <div className="form-group">
        <label>Is this a referral voucher?</label>
        <br/>
        <div className="form-check form-check-inline">
          <input
            id="isReferral"
            className="form-check-input"
            type="radio"
            name="isReferral"
            checked={isReferral}
            onChange={genericRadioChangeWithValue<boolean>(setIsReferral, true)}/>
          <label className="form-check-label" htmlFor="isReferral">Yes</label>
        </div>

        <div className="form-check form-check-inline">
          <input
            id="isNotReferral"
            className="form-check-input"
            type="radio"
            name="isReferral"
            checked={!isReferral}
            onChange={genericRadioChangeWithValue<boolean>(setIsReferral, false)}/>
          <label className="form-check-label" htmlFor="isNotReferral">No</label>
        </div>
      </div>

      {isReferral && <div className="form-group">
          <label>Referral Email</label>
          <InputFieldWithValidation
              type="text"
              className="form-control"
              value={referralEmail}
              onChange={genericTextChange(setReferralEmail)}
              errors={fieldErrors.referralEmail}
          />
      </div>}

      <div className="form-group">
        <label>Total Usage Limit</label>
        <InputFieldWithValidation
          type="number"
          className="form-control"
          value={totalUsageLimit}
          onChange={genericTextChange(setTotalUsageLimit)}
          errors={fieldErrors.totalUsageLimit}
        />
      </div>

      <div className="form-group">
        <label>Usage Limit Per User</label>
        <InputFieldWithValidation
          type="number"
          className="form-control"
          value={userUsageLimit}
          onChange={clearErrorOnChangeWrapper('userUsageLimit', genericTextChange(setUserUsageLimit))}
          errors={fieldErrors.userUsageLimit}
        />
      </div>

      <div className="form-group">
        <div className="form-check form-check-inline">
          <input
            id="enabled"
            className="form-check-input"
            type="checkbox"
            name="enabled"
            value="s"
            checked={isEnabled}
            onChange={genericCheckboxChange(setIsEnabled)}/>
          <label className="form-check-label" htmlFor="enabled">Enabled?</label>
        </div>
      </div>

      <button type="submit" className="btn btn-primary mr-2" disabled={isSaving}>Save</button>
      <button className="btn btn-secondary" onClick={() => history.goBack()} disabled={isSaving}>Back</button>
    </form>;
  }

  let pageTitle;
  if (voucherId) {
    pageTitle = 'Edit Voucher';
  } else {
    pageTitle = 'New Voucher';
  }

  return <React.Fragment>
    <HeadTitle
      title={pageTitle}
    />

    <h1>{pageTitle}</h1>

    <TimeoutAlert
      errorMessage={successMessage as string}
      onHide={() => setSuccessMessage(null)}
      alertColor="success"
    />

    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    {content}
  </React.Fragment>;
}