import React from "react";
import {InputFieldWithValidation} from "../../fields_with_validation";
import {checkIntForError} from "../../../lib/validation";
import TimeoutAlert from "../../timeout_alert";
import Api from "../../../api/api";
import {extractAxiosError} from "../../../utils/error";
import {convertWeightToKg} from "../../../utils/product";

interface FormErrors {
    updatedWeight?: string[];
}

export interface EditBtnContentProps {
    productId: string;
    variantPath?: string;
    onCancel(): void;
    onWeightChange(updatedWeightKg: number): void;
}

export default function EditBtnContent(props: EditBtnContentProps) {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [updatedWeight, setUpdatedWeight] = React.useState('100');
    const [errors, setErrors] = React.useState<FormErrors>({});
    const [isUpdating, setIsUpdating] = React.useState(false);

    const handleUpdatedWeightChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUpdatedWeight(e.target.value), []);
    const handleConfirmClick = React.useCallback(() => {
        if (isUpdating) return;

        const errors: Required<FormErrors> = {
            updatedWeight: checkIntForError(updatedWeight, {
                attrName: 'updated weight',
                gt: 99
            })
        };
        const hasError = Object.values(errors).some(i => i.length !== 0);
        if (hasError) {
            setErrors(errors);
            return;
        }

        setIsUpdating(true);
        const {productId, variantPath} = props;
        const weightInGrams = parseInt(updatedWeight);
        const weightInKg = convertWeightToKg(weightInGrams, 'g');
        Api.staff.productWeightOverride.set({
            productId,
            weight: weightInKg,
            variantPath
        })
            .then(() => props.onWeightChange(weightInKg))
            .catch((e) => setErrorMessage(extractAxiosError(e)))
            .finally(() => setIsUpdating(false));
        setErrors({});
        props.onWeightChange(parseInt(updatedWeight));
    }, [props, updatedWeight, isUpdating]);

    return <div className={"card"}>
        <div className={"card-body"}>
            <TimeoutAlert
                errorMessage={errorMessage}
                onHide={() => setErrorMessage(null)}
            />

            <div className="form-group">
                <label>Updated Product Weight In Grams</label>
                <InputFieldWithValidation
                    type="number"
                    step={1}
                    min={100}
                    className="form-control"
                    value={updatedWeight}
                    onChange={handleUpdatedWeightChange}
                    errors={errors.updatedWeight}
                />
            </div>
            <div className={"text-right"}>
                <button className="btn btn-primary" onClick={handleConfirmClick}>Confirm</button>
                <button className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>
            </div>
        </div>
    </div>;
}