import {createReducer} from "@reduxjs/toolkit";
import {actions} from '../actions';

const {setPendingCount, decrementInternalPendingCount, decrementExternalPendingCount, setFetchPendingCountError} = actions.quotation;

export interface QuotationState {
  internalPendingCount: number;
  externalPendingCount: number;
  fetchPendingCountError?: string;
}

const defaultState: QuotationState = {
  internalPendingCount: 0,
  externalPendingCount: 0
};

const quotationReducer = createReducer(defaultState, builder => {
  builder
    .addCase(setPendingCount, (state, action) => {
      state.internalPendingCount = action.payload.internal;
      state.externalPendingCount = action.payload.external;
    })
    .addCase(decrementInternalPendingCount, (state) => {
      if (state.internalPendingCount > 0) {
        state.internalPendingCount--;
      }
    })
    .addCase(decrementExternalPendingCount, (state) => {
      if (state.externalPendingCount > 0) {
        state.externalPendingCount--;
      }
    })
    .addCase(setFetchPendingCountError, (state, action) => {
      state.fetchPendingCountError = action.payload;
    });
});

export default quotationReducer;