export const NO_PROCESSING_HOSTNAMES = [
	'www.orami.co.id', 
	'orami.co.id',
	'www.tokopedia.com', 
	'tokopedia.com', 
	'm.tokopedia.com', 
	'tokopedia.link'
];

export function isSearchSupported(url: URL): boolean {
	if (NO_PROCESSING_HOSTNAMES.indexOf(url.hostname) !== -1) {
		return true;
	}

	if (url.hostname === 'ta.tokopedia.com' && url.searchParams.has('r')) {
		const tokopediaLink = url.searchParams.get('r');
		if (!tokopediaLink) {
			return false;
		}

		try {
			const tokopediaLinkURL = new URL(tokopediaLink);
			return isSearchSupported(tokopediaLinkURL);
		} catch (error) {
			return false;
		}
	}
	return false;
}

export function postProcessUrl(url: URL) {
	if (url.hostname === 'ta.tokopedia.com') {
		// handle tokopedia promoted listing
		return url.searchParams.get('r');
	} else if (url.hostname === 'm.tokopedia.com') {
		url.hostname = 'tokopedia.com';
	}
	return url.toString();
}