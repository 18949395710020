import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import CategorySettingsTable from './category_settings_table';
import {CategorySetting} from "../api/models";

const tableColumns = [
  ...CategorySettingsTable.DEFAULT_TABLE_COLUMNS,
  {key: 'error', width: 100, title: 'Error'},
];

interface CategorySettingInvalidsModalProps {
  show: boolean;
  onHide: () => void;
  invalidSettings: CategorySetting[];
}

export default function CategorySettingInvalidsModal({
                                                       show,
                                                       onHide,
                                                       invalidSettings
                                                     }: CategorySettingInvalidsModalProps) {

  return <Modal scrollable={true} size="xl" show={show} onHide={onHide} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Some Settings failed to be saved due to errors</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CategorySettingsTable
        categorySettings={invalidSettings}
        columns={tableColumns}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide as React.MouseEventHandler}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>;

}