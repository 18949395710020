import React from 'react';
import {ConfigEditorProps} from "../../../generic";
import EmailValidator from "email-validator";

export default function EmailField(props: ConfigEditorProps<string>) {
  const {configValue, onDone, onCancel} = props;

  const [email, setEmail] = React.useState(configValue || '');
  const isEmailValid = EmailValidator.validate(email);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEmailValid) {
      onDone(email);
    }
  };


  return <form onSubmit={handleSubmit}>
    <div className="form-group">
      <input
        type="text"
        className="form-control"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {!isEmailValid && <div className="text-danger">Email is invalid</div>}

      <button type="submit" className="btn btn-primary">Save</button>
      <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
    </div>
  </form>;
}