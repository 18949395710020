import React from "react";
import {Modal} from "react-bootstrap";

interface ProductChangeDiffInfoModalProps {
    shown: boolean;
    onHide: () => void;
}

export default function ProductChangeDiffInfoModal({shown, onHide}: ProductChangeDiffInfoModalProps) {
    return <Modal show={shown} onHide={onHide} animation={false}>
        <Modal.Header closeButton>
            <Modal.Title>Product changed information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            You are seeing this because either of the following variation attributes changed:
            <ul>
                <li>Variant name</li>
                <li>Variant options</li>
                <li>Variation weight</li>
            </ul>

            <img className="img-fluid" src={'/images/product_diff_info_1.png'} alt="information illustration" />
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-secondary" onClick={onHide}>
                Close
            </button>
        </Modal.Footer>
    </Modal>;
}

