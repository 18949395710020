import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {CategorySetting} from "../api/models";

interface CategorySettingConflictModalProps {
  show: boolean;
  onHide: () => void;
  onResolve: (settings: CategorySetting[]) => void;
  conflictingSettings: Record<string, CategorySetting[]>;
}

type CategoryKeyToSettingListPair = [string, CategorySetting[]];

export default function CategorySettingConflictModal({
                                                       show,
                                                       onHide,
                                                       onResolve,
                                                       conflictingSettings
                                                     }: CategorySettingConflictModalProps) {
  const [conflictedSettings, setConflictedSettings] = React.useState<CategoryKeyToSettingListPair[]>([]);
  const [checkedConflictedSettings, setCheckedConflictedSettings] = React.useState<Record<string, number>>({});

  React.useEffect(() => {
    const conflicting = Object.entries(conflictingSettings).filter(pair => pair[1].length > 1);
    const checked: Record<string, number> = {};
    for (const entry of conflicting) {
      const key = entry[0];
      checked[key] = 0;
    }
    setCheckedConflictedSettings(checked);
    setConflictedSettings(conflicting);
  }, [conflictingSettings]);

  const handleCheck = (key: string, index: number) => (_: React.ChangeEvent) => {
    const checked = Object.assign({}, checkedConflictedSettings);
    checked[key] = index;
    setCheckedConflictedSettings(checked);
  };

  const handleResolve = () => {
    const newSettings = [];
    for (const [key, conflict] of Object.entries(conflictingSettings)) {
      const index = checkedConflictedSettings[key];
      if (typeof index === 'undefined') {
        newSettings.push(conflict[0]);
      } else {
        newSettings.push(conflict[index]);
      }
    }
    onResolve(newSettings);
  };

  return <Modal scrollable={true} size="xl" show={show} onHide={onHide} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Resolve Conflicts</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Resolve the conflicts by selecting the right ones
        {conflictedSettings.map(([key, conflictedSettings]) => {
        return (
          <div className="card" key={key}>
            <div className="card-header">
              {key}
            </div>
            <div className="card-body">
              {conflictedSettings.map((cs, index) => (
                <div className="row" key={index}>
                  <div className="col-auto">
                    <input
                      type="radio"
                      checked={checkedConflictedSettings[key] === index}
                      onChange={handleCheck(key, index)}
                    />
                  </div>
                  <div className="col">
                    {Object.entries(cs).map(([attr, value], index) => (
                      <div key={index}>
                        <strong>{attr}:</strong>
                        {value}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleResolve}>
        Resolve
      </Button>
      <Button variant="secondary" onClick={onHide as React.MouseEventHandler}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;

}