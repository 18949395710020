import React from 'react';
import {FiEdit, FiTrash2} from 'react-icons/fi';
import {Link, useParams} from 'react-router-dom';
import Api from '../../api/api';
import currencyPrecision from '../../constants/currency_precision';
import {insertDecimalPoint} from '../../utils/number';
import HeadTitle from '../head_title';
import TimeoutAlert from '../timeout_alert';
import {extractAxiosError} from '../../utils/error';
import AddressFields from '../../constants/address_fields';
import {ShippingTier} from "../../api/models";
import {EditShippingTier} from "../edit_shipping_price_tier";

interface RegionShippingPriceTiersParams {
  country?: string;
}

type ViewStatus = 'loading' | 'country_invalid' | 'normal';

interface ViewStatusWrapperProps {
  viewStatus: ViewStatus;
  children: React.ReactNode;
}

function ViewStatusWrapper({viewStatus, children}: ViewStatusWrapperProps) {
  let content = children;
  switch(viewStatus) {
    case "loading":
      content = (
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
      break;
    case "country_invalid":
      content = (
        <div>
          <p>Country is invalid</p>
          <Link to="/regions">Back to regions</Link>
        </div>
      );
      break;
  }
  return <>{content}</>;
}

export default function EditRegionShippingPriceTiersPage() {
  const [shippingTiers, setShippingTiers] = React.useState<ShippingTier[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isEditing, setIsEditing] = React.useState<boolean[]>([]);
  const [isAdding, setIsAdding] = React.useState(false);
  const [viewStatus, setViewStatus] = React.useState<ViewStatus>('loading');

  const params = useParams<RegionShippingPriceTiersParams>();
  const destinationCountry = params.country as string;

  React.useEffect(() => {
    if (!params.country) {
      setViewStatus('country_invalid');
      return;
    }
    const countryUpper = params.country.toUpperCase();
    if (!AddressFields.countries[countryUpper]) {
      setViewStatus('country_invalid');
      return;
    }

    Api.admin.region.get(countryUpper)
      .then((res) => {
        const {region} = res.data;
        if (region && region.shippingPriceTiers) {
          setShippingTiers(region.shippingPriceTiers);
        } else {
          setShippingTiers([]);
        }
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        setViewStatus('normal');
      });
  }, [params]);


  React.useEffect(() => {
    if (!shippingTiers || shippingTiers.length === 0) {
      return;
    }

    const newIsEditing = new Array(shippingTiers.length);
    newIsEditing.fill(false);
    setIsEditing(newIsEditing);
  }, [shippingTiers]);

  const editShippingTier = (index: number) => () => {
    const newIsEditing: boolean[] = isEditing.slice();
    newIsEditing[index] = !newIsEditing[index];
    setIsEditing(newIsEditing);
  };

  const cancelEditShippingTeir = (index: number) => () => {
    const newIsEditing = isEditing.slice();
    newIsEditing[index] = false;
    setIsEditing(newIsEditing);
  };

  const checkHasOverlap = (index: number, newUpperWeightLimit: number) => {
    // check forward
    const nextIndex = index + 1;
    if (nextIndex < shippingTiers.length) {
      const nextItem = shippingTiers[nextIndex];
      if (newUpperWeightLimit >= nextItem.upperWeightLimit) {
        return true;
      }
    }

    // check backward
    const prevIndex = index - 1;
    if (prevIndex > 0) {
      const prevItem = shippingTiers[prevIndex];
      if (newUpperWeightLimit <= prevItem.upperWeightLimit) {
        return true;
      }
    }

    return false;
  };

  const handleUpdate = (index: number) => (shippingPriceTier: ShippingTier) => {
    const hasOverlap = checkHasOverlap(index, shippingPriceTier.upperWeightLimit);
    if (hasOverlap) {
      setErrorMessage("Shipping price tier is overlapping with another price tier");
      return;
    }

    Api.admin.region.shippingPriceTier.update(destinationCountry, index, shippingPriceTier)
      .then((res) => {
        setShippingTiers(res.data.shippingPriceTiers);
        setIsAdding(false);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  const handleCreate = (shippingPriceTier: ShippingTier) => {
    // check for duplicate upper weight limit
    const hasDuplicate = shippingTiers.some(t => t.upperWeightLimit === shippingPriceTier.upperWeightLimit);
    if (hasDuplicate) {
      setErrorMessage("Shipping price tier has already been defined");
      return;
    }

    Api.admin.region.shippingPriceTier.add(destinationCountry, shippingPriceTier)
      .then((res) => {
        setShippingTiers(res.data.shippingPriceTiers);
        setIsAdding(false);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  };

  const handleDelete = (index: number) => () => {
    const confirmed = window.confirm("Are you sure?");
    if (!confirmed) {
      return;
    }

    Api.admin.region.shippingPriceTier.delete(destinationCountry, index)
      .then(() => {
        const newShippingTiers = shippingTiers.slice();
        newShippingTiers.splice(index, 1);
        setShippingTiers(newShippingTiers);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message);
      });
  };
  
  let lastWeight = 0;

  return <React.Fragment>
    <TimeoutAlert
      errorMessage={errorMessage as string}
      onHide={() => setErrorMessage(null)}
    />

    <HeadTitle
      title="Shipping Price Tiers"
    />

    <ViewStatusWrapper viewStatus={viewStatus}>
      <h2>Region Shipping Price Tiers</h2>
      <p>
        let x be the weight of the order
        <br/>
        Weight is in tier if the following is true
        <br/>
        <code>previous tier upper limit &#x3C;= x &#x3C; current tier upper limit</code>
      </p>

      <br/>
      <div>
        <strong>Current Location</strong>
        <br/>
        {destinationCountry ? AddressFields.countries[destinationCountry].displayValue : 'Loading'}
      </div>

      <table className="table">
        <thead>
        <tr>
          <th>Range</th>
          <th>Price</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {shippingTiers.length > 0 ? (
          shippingTiers.map((tier, index) => {
            const row = <React.Fragment key={index}>
              <tr>
                <td>{`${lastWeight} to ${tier.upperWeightLimit}`} kg</td>
                <td>{tier.priceCurrency} {insertDecimalPoint(tier.price as number, currencyPrecision[tier.priceCurrency])}</td>
                <td>
                  <button className="btn btn-link" onClick={editShippingTier(index)}>
                    <FiEdit className="feather"/>
                  </button>
                  <button className="btn btn-link" onClick={handleDelete(index)}>
                    <FiTrash2 className="feather"/>
                  </button>
                </td>
              </tr>
              {isEditing[index] && (
                <tr>
                  <td colSpan={3}>
                    <EditShippingTier
                      onCancel={cancelEditShippingTeir(index)}
                      onSave={handleUpdate(index)}
                      shippingPriceTier={tier}
                      destinationCountry={destinationCountry}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>;
            lastWeight = tier.upperWeightLimit;
            return row;
          })
        ) : (
          <tr>
            <td colSpan={3}>No Shipping Price Tiers</td>
          </tr>
        )}
        </tbody>
      </table>

      {!isAdding &&
      <button className="btn btn-primary mr-2" onClick={() => setIsAdding(true)} disabled={!destinationCountry}>
          Add Tier
      </button>}

      <Link to="/regions" className="btn btn-secondary">Back to Regions</Link>

      {isAdding && (
        <EditShippingTier
          onCancel={() => setIsAdding(false)}
          onSave={handleCreate}
          destinationCountry={destinationCountry}
        />
      )}
    </ViewStatusWrapper>
  </React.Fragment>;
}