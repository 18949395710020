import React from 'react';

const USER_ROLES: { [key: string]: string } = {
  'adm': 'Admin',
  'stf': 'Staff',
  'shp': 'Shopper',
};
export default function HumanizeRole({role}: { role: string }) {
  return <>
    {USER_ROLES[role]}
  </>;
}