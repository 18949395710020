import React from 'react';
import {useParams} from 'react-router-dom';
import {extractAxiosError} from '../../utils/error';
import {formatDateTimeFromSeconds, formatDateTimeFromSerializedDate} from '../../utils/date';
import Api from '../../api/api';
import HumanizeRole from '../humanize_role';
import HeadTitle from '../head_title';
import {UserProfile} from "../../api/models";
import TimeoutAlert from "../timeout_alert";
import addressFields from "../../constants/address_fields";

export default function ViewUser() {
  const [user, setUser] = React.useState<UserProfile | null>(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  // const [successMessage, setSuccessMessage] = React.useState(null)

  const params = useParams<{uid: string}>();
  const {uid} = params;

  React.useEffect(() => {
    Api.admin.user.get(uid)
      .then(res => {
        setUser(res.data.user);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  }, [uid]);

  let content;
  if (user === null) {
    content = <p>Loading...</p>;
  } else {
    content = <React.Fragment>
      <h1>User Info</h1>
      <dl className="row">
        <dt className="col-sm-3">First Name</dt>
        <dd className="col-sm-9">{user.firstName}</dd>

        <dt className="col-sm-3">Last Name</dt>
        <dd className="col-sm-9">{user.lastName}</dd>

        <dt className="col-sm-3">Email</dt>
        <dd className="col-sm-9">{user.email}</dd>

        <dt className="col-sm-3">Email Verification Status</dt>
        <dd className="col-sm-9">{user.verified ? "Verified" : "Unverified"}</dd>

        <dt className="col-sm-3">Phone Number</dt>
        <dd className="col-sm-9">{user.phoneNumber}</dd>

        <dt className="col-sm-3">Role</dt>
        <dd className="col-sm-9">
          <HumanizeRole
            role={user.role}
          />
        </dd>

        <dt className="col-sm-3">Country</dt>
        <dd className="col-sm-9">{user.country ?
          (addressFields.countries[user.country] ?
            `${addressFields.countries[user.country].displayValue} (${user.country})` :
            user.country) :
          'N.A.'}</dd>

        <dt className="col-sm-3">Birthday</dt>
        <dd className="col-sm-9">{user.birthDate ? formatDateTimeFromSerializedDate(user.birthDate) : 'N.A.'}</dd>

        <dt className="col-sm-3">Gender</dt>
        <dd className="col-sm-9">{user.gender ? user.gender : 'N.A.'}</dd>

        <dt className="col-sm-3">Account Created At</dt>
        <dd className="col-sm-9">{formatDateTimeFromSeconds(user.createdAt)}</dd>
      </dl>




    </React.Fragment>;
  }

  return <>
    <HeadTitle
      title="User Info"
    />

    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />
    {content}
  </>;
}