import moment from 'moment';

export function epochTime() {
  return Math.floor(Date.now() / 1000);
}

export function hasSecondsPassed(timePeriod: number, refSeconds: number) {
  const currentSeconds = epochTime();
  return currentSeconds - refSeconds >= timePeriod;
}

export function formatDateTimeFromSeconds(seconds: number) {
  return moment.unix(seconds).format('MMMM Do YYYY, h:mm:ss a');
}

export function formatDateTimeFromSerializedDate(serializedDate: string | number | undefined): string {
  if (serializedDate) {
    return moment(deserializeDate(serializedDate)).format('MMMM Do YYYY');
  } else {
    return 'Invalid Date';
  }
}

export function serializeDate(date: Date) {
  let serializedDate = 0;
  serializedDate += date.getFullYear() * 10000;
  serializedDate += (date.getMonth() + 1) * 100;
  serializedDate += date.getDate();

  return serializedDate;
}

export function deserializeDate(serializedDate: string | number) {
  const year = parseInt(serializedDate.toString().substring(0, 4));
  const month = parseInt(serializedDate.toString().substring(4, 6));
  const day = parseInt(serializedDate.toString().substring(6));

  return new Date(year, month - 1, day, 0, 0, 0);
}

export function validateSerializedDate(date: Date) {
  const dateStr: string = date.toString();
  const year: string = dateStr.slice(0, 4);
  const month: string = dateStr.slice(4, 6);
  const day: string = dateStr.slice(6);

  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return false;
  }
  const monthInt = parseInt(month);
  const dayInt = parseInt(day);
  if (monthInt < 1 || monthInt > 12) {
    return false;
  }

  return !(dayInt < 1 || dayInt > 31);
}