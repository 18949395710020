import React from 'react';
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import github from 'react-syntax-highlighter/dist/esm/styles/hljs/github';


SyntaxHighlighter.registerLanguage('json', json);

export default function wrapper({children, ...otherProps}: {children: React.ReactNode, [key: string]: any}) {
  return <SyntaxHighlighter style={github} {...otherProps}>
    {children}
  </SyntaxHighlighter>;
}