import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import EmailValidator from "email-validator";
import urljoin from 'url-join';
import classNames from 'classnames';
import Api from '../api/api';
import {InputFieldWithValidation} from './fields_with_validation';
import {extractAxiosError} from '../utils/error';
import {extractProperties} from '../utils/object';
import styles from '../styles/edit_merchant.module.scss';
import {Merchant} from "../api/models";
import {MerchantChanges} from "../api/request_models";

interface EditMerchantProps {
  onError: (message: string) => void;
  merchant?: Merchant;
}

interface MerchantFields {
  name: string;
  email: string;
  hasFile: boolean;
  removeBrandImage: boolean;
}

export default function EditMerchant({merchant, onError}: EditMerchantProps) {
  const [fields, setFields] = React.useState<MerchantFields>({
    name: '',
    email: '',
    hasFile: false,
    removeBrandImage: false,
  });
  const [fieldErrors, setFieldErrors] = React.useState<Record<string, string[]>>({});

  const uploadRef = React.useRef<HTMLInputElement>();
  const history = useHistory();

  const updateHasFile = () => {
    setFields(Object.assign({}, fields, {
      hasFile: uploadRef.current && uploadRef.current.files && uploadRef.current.files.length > 0
    }));
  };
  const clearUpload = () => {
    if (uploadRef.current) {
      uploadRef.current.value = '';
      updateHasFile();
    }
  };

  const handleSelectImage = (_: React.ChangeEvent<HTMLInputElement>) => {
    updateHasFile();
  };

  const isEditing = !!merchant;

  React.useEffect(() => {
    if (!merchant) {
      return;
    }

    const newFields = {
      ...fields,
      ...extractProperties(merchant, 'name', 'email')
    };
    setFields(newFields);
  }, [fields, merchant]);

  const handleValueChange = (attrKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFieldErrors = Object.assign({}, fieldErrors);
    delete newFieldErrors[attrKey];
    setFieldErrors(newFieldErrors);

    const newFields = Object.assign({}, fields, {
      [attrKey]: e.target.value,
    });
    setFields(newFields);
  };

  const validateFields = () => {
    const errors: {name: string[], email: string[]} = {
      name: [],
      email: [],
    };

    const name = fields.name.trim();
    if (name.length > 200) {
      errors.name.push('Name can only have up to 200 characters');
    }

    const email = fields.email.trim();
    if (!EmailValidator.validate(email)) {
      errors.email.push('Email is invalid');
    }

    const hasError = Object.values(errors).some(errorList => errorList.length > 0);
    if (hasError) {
      setFieldErrors(errors);
      return false;
    }
    return true;
  };


  const handleSave = () => {
    const isValid = validateFields();

    if (!isValid) {
      return;
    }

    const merchantData: MerchantChanges = {
      ...fields
    };
    if (fields.hasFile) {
      merchantData.brandImage = uploadRef.current!.files![0];
    }

    let action;
    if (isEditing) {
      action = (updateData: MerchantChanges) => Api.admin.merchant.update(merchant!._id, updateData);
    } else {
      action = (updateData: MerchantChanges) => Api.admin.merchant.create(updateData);
    }

    action(merchantData)
      .then(_ => {
        history.push('/merchants');
      })
      .catch(error => {
        onError(extractAxiosError(error));
      });
  };

  const markImageForRemoval = () => {
    setFields(Object.assign({}, fields, {
      removeBrandImage: true,
    }));
  };

  const unmarkImageForRemoval = () => {
    setFields(Object.assign({}, fields, {
      removeBrandImage: false,
    }));
  };

  return <>
    <div className="form-group">
      <label>Name</label>
      <InputFieldWithValidation
        type="text"
        value={fields.name}
        onChange={handleValueChange('name')}
        className="form-control"
        errors={fieldErrors.name}
      />
    </div>

    <div className="form-group">
      <label>Email</label>
      <InputFieldWithValidation
        type="email"
        value={fields.email}
        onChange={handleValueChange('email')}
        className="form-control"
        errors={fieldErrors.email}
      />
    </div>

    <div className="form-group">
      <label>Brand Image</label>
      <input
        type="file"
        className="form-control-file"
        id="merchantBrandImage"
        accept="image/*"
        onChange={handleSelectImage}
        ref={uploadRef as React.LegacyRef<any>}
      />
      <small>
        * Optimal image ratio W:H, 2:1, max width 250px, max height 125px
      </small>

      {fields.hasFile && (
        <>
          <br/>
          <button className="btn btn-outline-danger" onClick={clearUpload}>Remove Image</button>
        </>
      )}
    </div>

    {(isEditing && merchant!.brandImagePath) && (
      <div className="form-group">
        <label>Existing Brand Image</label>
        <br/>
        <img
          src={urljoin(process.env.REACT_APP_IMAGES_ENDPOINT as string, merchant!.brandImagePath)}
          alt="existing brand"
          className={classNames('img-fluid', {
            [styles.removingBrandImage]: fields.removeBrandImage
          })}
        />
        {!fields.removeBrandImage && (
          <>
            <br/>
            <button
              className="btn btn-outline-danger"
              onClick={markImageForRemoval}
            >
              Mark for removal
            </button>
          </>
        )}
        {fields.removeBrandImage && (
          <>
            <br/>
            <button
              className="btn btn-outline-success"
              onClick={unmarkImageForRemoval}
            >
              Unmark for removal
            </button>
          </>
        )}
      </div>
    )}

    <button className="btn btn-primary" onClick={handleSave}>
      Save
    </button>
    <Link className="btn btn-secondary" to="/merchants">Cancel</Link>
  </>;
}