import React from 'react';
import './app.scss';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {Route, Router, Switch} from 'react-router-dom';
import countries from 'i18n-iso-countries';
import history from './routes/history';
import {persistor, store} from './store/store';
import {actions} from './store/actions';
import LoggedInRoute from './routes/logged_in_route';
import TfaPassedRoute from './routes/tfa_passed_route';
import UnauthorizedRoot from './components/unauthorized_root';
import AuthorizedRoot from './components/authorized_root';
import HomePage from './components/pages/home';
import LoginPage from './components/pages/login';
import TfaPage from './components/pages/tfa';
import ConfigPage from './components/pages/config';
import UsersPage from './components/pages/users';
import VouchersPage from './components/pages/vouchers';
import EditVoucherPage from './components/pages/edit_voucher';
import CategorySettingsPage from './components/pages/category_settings';
import ShippingPriceTiersPage from './components/pages/shipping_price_tiers';
import OrdersPage from './components/pages/orders';
import ViewOrderPage from './components/pages/view_order';
import ResolveShippingDisputePage from './components/pages/resolve_shipping_dispute';
import ProductWeightOverridesPage from './components/pages/product_weight_overrides';
import ViewUserPage from './components/pages/view_user';
import ReconcilePaynowPaymentsPage from './components/pages/reconcile_paynow_payments';
import HomeProductGroupsPage from './components/pages/home_product_groups';
import ImagePagesPage from './components/pages/image_pages';
import NewImagePagePage from './components/pages/new_image_page';
import EditImagePagePage from './components/pages/edit_image_page';
import Popupspage from './components/pages/popups';
import NewPopupPage from './components/pages/new_popup';
import EditPopupPage from './components/pages/edit_popup';
import Bannerspage from './components/pages/banners';
import NewBannerPage from './components/pages/new_banner';
import EditBannerPage from './components/pages/edit_banner';
import NewMerchantPage from './components/pages/new_merchant';
import MerchantsPage from './components/pages/merchants';
import EditMerchantPage from './components/pages/edit_merchant';
import ViewMerchantPage from './components/pages/view_merchant';
import NewMerchantProductPage from './components/pages/new_merchant_product';
import EditMerchantProductPage from './components/pages/edit_merchant_product';
import RegionsPage from "./components/pages/regions";
import NewRegionPage from "./components/pages/new_region";
import EditRegionPage from './components/pages/edit_region';
import EditRegionShippingPriceTiersPage from "./components/pages/edit_region_shipping_price_tiers";
import ProductSearch from "./components/pages/product_search";
import OverrideProduct from "./components/pages/override_product";
import QuotationItemsPage from "./components/pages/quotation_items";
import InternalQuotationItemsPage from "./components/pages/quotation_items/internal";
import ExternalQuotationItemsPage from "./components/pages/quotation_items/external";
import CountryTaxesPage from "./components/pages/country_taxes";
import NewCountryTaxPage from "./components/pages/country_taxes/new_country_tax";
import EditCountryTaxPage from "./components/pages/country_taxes/edit_country_tax";
import QuotationItemHistoricPricesPage from "./components/pages/quotation_items/history";
import UnresolvedProductVariationDiffPage from "./components/pages/product_variation_diff/unresolved";
import ResolvedProductVariationDiffPage from "./components/pages/product_variation_diff/resolved";
import ViewExternalQuotationItemsPage from './components/pages/quotation_items/external/view';
import ReplyMessagesPage from "./components/pages/reply_messages";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function App() {
  React.useEffect(() => {
    store.dispatch(actions.user.fetchProfile());
  }, []);

  return <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <Switch>
          <Route path="/login">
            <UnauthorizedRoot>
              <LoginPage/>
            </UnauthorizedRoot>
          </Route>
          <Route path="/reply_messages/:groupRef">
            <UnauthorizedRoot>
              <ReplyMessagesPage />
            </UnauthorizedRoot>
          </Route>
          <LoggedInRoute path="/tfa">
            <UnauthorizedRoot>
              <TfaPage/>
            </UnauthorizedRoot>
          </LoggedInRoute>
          <TfaPassedRoute path="/users/:uid">
            <AuthorizedRoot>
              <ViewUserPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/users">
            <AuthorizedRoot>
              <UsersPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/config">
            <AuthorizedRoot>
              <ConfigPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/vouchers/new">
            <AuthorizedRoot>
              <EditVoucherPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/vouchers/:id/edit">
            <AuthorizedRoot>
              <EditVoucherPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/vouchers">
            <AuthorizedRoot>
              <VouchersPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/quotations">
            <AuthorizedRoot>
              <p>Quotations is temporarily disabled</p>
              {/*<QuotationsPage />*/}
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/category_settings">
            <AuthorizedRoot>
              <CategorySettingsPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/shipping_price_tiers">
            <AuthorizedRoot>
              <ShippingPriceTiersPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/orders/:orderRef/resolve_dispute">
            <AuthorizedRoot>
              <ResolveShippingDisputePage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/orders/:orderRef">
            <AuthorizedRoot>
              <ViewOrderPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/orders">
            <AuthorizedRoot>
              <OrdersPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/quotation_orders/:orderRef">
            <AuthorizedRoot>
              <p>Quotations is temporarily disabled</p>
              {/*<QuotationOrderPage />*/}
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/quotation_orders">
            <AuthorizedRoot>
              <p>Quotations is temporarily disabled</p>
              {/*<QuotationOrdersPage />*/}
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/product_weight_overrides">
            <AuthorizedRoot>
              <ProductWeightOverridesPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/reconcile_paynow_payments">
            <AuthorizedRoot>
              <ReconcilePaynowPaymentsPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/home_product_groups">
            <AuthorizedRoot>
              <HomeProductGroupsPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/image_pages/new">
            <AuthorizedRoot>
              <NewImagePagePage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/image_pages/:id/edit">
            <AuthorizedRoot>
              <EditImagePagePage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/image_pages">
            <AuthorizedRoot>
              <ImagePagesPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/popups/new">
            <AuthorizedRoot>
              <NewPopupPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/popups/:id/edit">
            <AuthorizedRoot>
              <EditPopupPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/popups">
            <AuthorizedRoot>
              <Popupspage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/banners/new">
            <AuthorizedRoot>
              <NewBannerPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/banners/:id/edit">
            <AuthorizedRoot>
              <EditBannerPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/banners">
            <AuthorizedRoot>
              <Bannerspage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute exact path="/merchants/new">
            <AuthorizedRoot>
              <NewMerchantPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute exact path="/merchants/:id">
            <AuthorizedRoot>
              <ViewMerchantPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/merchants/:id/edit">
            <AuthorizedRoot>
              <EditMerchantPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/merchants/:merchantId/products/new">
            <AuthorizedRoot>
              <NewMerchantProductPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/merchants/:merchantId/products/:productId">
            <AuthorizedRoot>
              <EditMerchantProductPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute exact path="/merchants">
            <AuthorizedRoot>
              <MerchantsPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute exact path="/regions/new">
            <AuthorizedRoot>
              <NewRegionPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute exact path="/regions">
            <AuthorizedRoot>
              <RegionsPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/regions/:country/edit">
            <AuthorizedRoot>
              <EditRegionPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/regions/:country/shipping_price_tiers">
            <AuthorizedRoot>
              <EditRegionShippingPriceTiersPage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/product_search/:productId/edit">
            <AuthorizedRoot>
              <OverrideProduct/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/product_search">
            <AuthorizedRoot>
              <ProductSearch/>
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/quotation_items/internal">
            <AuthorizedRoot>
              <InternalQuotationItemsPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/quotation_items/external" exact>
            <AuthorizedRoot>
              <ExternalQuotationItemsPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/quotation_items/external/:quotationItemId" exact>
            <AuthorizedRoot>
              <ViewExternalQuotationItemsPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/quotation_items/history" exact>
            <AuthorizedRoot>
              <QuotationItemHistoricPricesPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/quotation_items" exact>
            <AuthorizedRoot>
              <QuotationItemsPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/country_taxes/new">
            <AuthorizedRoot>
              <NewCountryTaxPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/country_taxes/:countryTaxId/edit">
            <AuthorizedRoot>
              <EditCountryTaxPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/country_taxes">
            <AuthorizedRoot>
              <CountryTaxesPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/product_variation_diff/resolved">
            <AuthorizedRoot>
              <ResolvedProductVariationDiffPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/product_variation_diff/unresolved">
            <AuthorizedRoot>
              <UnresolvedProductVariationDiffPage />
            </AuthorizedRoot>
          </TfaPassedRoute>
          <TfaPassedRoute path="/">
            <AuthorizedRoot>
              <HomePage/>
            </AuthorizedRoot>
          </TfaPassedRoute>
        </Switch>
      </Router>
    </PersistGate>
  </Provider>;
}

export default App;
